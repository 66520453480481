import axios from "axios";
import { TrackingTypes } from "./types";

axios.defaults.baseURL = "http://localhost:3000";
axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

export const fetchTrackingStart = () => ({
  type: TrackingTypes.TRACKING_PENDING,
});

export const fetchTrackingSuccess = (locations) => ({
  type: TrackingTypes.TRACKING_SUCCESS,
  payload: {
    locations,
  },
});

export const fetchTrackingFail = (error) => ({
  type: TrackingTypes.TRACKING_FAIL,
  payload: {
    error,
  },
});

export const getLocations = (fromData) => (dispatch) => {
  dispatch(fetchTrackingStart());

  axios
    .post(
      `http://138.197.190.17:5050/externalapi/v1/zndd/getRealTimeVehicleInfo`,
      fromData
    )
    .then((res) => {
      dispatch(fetchTrackingSuccess(res.data.data));
    })
    .catch((err) => dispatch(fetchTrackingFail(err)));
};
