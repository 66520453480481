import axios from "axios";
import { BusActionTypes } from "./type";
import { busAdminTokenKey, BASE_URI } from "../../config/key.config";

export const fetchBusStart = () => ({
  type: BusActionTypes.BUS_PENDING,
});

export const fetchBusSuccess = (bus) => ({
  type: BusActionTypes.BUS_SUCCESS,
  payload: {
    bus,
  },
});

export const fetchBusFailure = (error) => ({
  type: BusActionTypes.BUS_FAIL,
  payload: { error },
});

export const clearFetchBus = () => ({
  type: BusActionTypes.CLEAR_BUS,
});

export const fetchBusesStart = () => ({
  type: BusActionTypes.BUSES_PENDING,
});

export const fetchBusesSuccess = (buses) => ({
  type: BusActionTypes.BUSES_SUCCESS,
  payload: { buses },
});

export const fetchBusesFailure = (error) => ({
  type: BusActionTypes.BUSES_FAIL,
  payload: { error },
});

export const clearFetchBuses = () => ({
  type: BusActionTypes.CLEAR_BUSES,
});

export const createBusStart = () => ({
  type: BusActionTypes.CREATE_BUS_PENDING,
});

export const createBusSuccess = (bus) => ({
  type: BusActionTypes.CREATE_BUS_SUCCESS,
  payload: { bus },
});

export const createBusFail = (error) => ({
  type: BusActionTypes.CREATE_BUS_FAIL,
  payload: { error },
});

export const clearCreateBus = () => ({
  type: BusActionTypes.CLEAR_CREATE_BUS,
});

export const updateBusStart = () => ({
  type: BusActionTypes.UPDATE_BUS_PENDING,
});

export const updateBusSuccess = (id, bus) => ({
  type: BusActionTypes.UPDATE_BUS_SUCCESS,
  payload: {
    id,
    bus,
  },
});
export const updateBusFail = (error) => ({
  type: BusActionTypes.UPDATE_BUS_FAIL,
  payload: { error },
});

export const clearUpdateBus = () => ({
  type: BusActionTypes.CLEAR_UPDATE_BUS,
});

export const deleteBusStart = () => ({
  type: BusActionTypes.DELETE_BUS_PENDING,
});

export const deleteBusSuccess = (id) => ({
  type: BusActionTypes.DELETE_BUS_SUCCESS,
  payload: { id },
});

export const deleteBusFail = (error) => ({
  type: BusActionTypes.DELETE_BUS_FAIL,
  payload: { error },
});

export const clearDeleteBus = () => ({
  type: BusActionTypes.CLEAR_DELETE_BUS,
});

export const getBuses = (dispatch) => {
  dispatch(fetchBusesStart());

  axios
    .get(`${BASE_URI}/bus`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) => {
      const datas = res.data.map((data) => {
        return {
          key: data.id,
          id: data.id,
          sideNumber: data.sideNumber,
          plateNumber: data.plateNumber,
          status: data.status,
          seater: data.busStructure.seater,
          seaterId: data.busStructure.id,
        };
      });
      dispatch(fetchBusesSuccess(datas));
    })
    .catch((err) => dispatch(fetchBusesFailure(err)));
};

export const getActiveBuses = (dispatch) => {
  dispatch(fetchBusesStart());

  axios
    .get(`${BASE_URI}/bus`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) => {
      const response = res.data;
      const responseDatas = [];

      response.forEach((data) => {
        if (data.status !== "INACTIVE") {
          responseDatas.push({
            key: data.id,
            id: data.id,
            sideNumber: data.sideNumber,
            plateNumber: data.plateNumber,
            // seater: data.busStructure.seater,
            // seaterId: data.busStructure.id,
            status: data.status,
          });
        }
      });
      dispatch(fetchBusesSuccess(responseDatas));
    })
    .catch((err) => dispatch(fetchBusesFailure(err)));
};

export const getBus = (id) => (dispatch) => {
  dispatch(fetchBusStart());

  axios
    .get(`${BASE_URI}/bus/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) => dispatch(fetchBusSuccess(res.data)))
    .catch((err) => dispatch(fetchBusFailure(err)));
};

export const createBus = (formData, history) => (dispatch) => {
  dispatch(createBusStart());

  axios
    .post(`${BASE_URI}/bus`, formData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) => {
      dispatch(createBusSuccess(res.data));
      history.push("/director/bus");
      // window.location.href = "http://localhost:3000/director/bus";
    })
    .catch((err) => dispatch(createBusFail(err)));
};

export const updateBus = (formData, id) => (dispatch) => {
  dispatch(updateBusStart());

  axios
    .put(`${BASE_URI}/bus/${id}`, formData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) => {
      dispatch(updateBusSuccess(id, res.data));
    })
    .catch((err) => dispatch(updateBusFail(err)));
};

export const deleteBus = (id) => (dispatch) => {
  dispatch(deleteBusStart());

  axios
    .delete(`${BASE_URI}/bus/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) => dispatch(deleteBusSuccess(id)))
    .catch((err) => dispatch(deleteBusFail(err)));
};
