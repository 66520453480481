import React, { useState, useEffect } from "react";
import { Row, Col, Card, Table, Input, Button, Space } from "antd";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import { getTickets } from "../../redux/ticket/action";
import { useDispatch, useSelector } from "react-redux";

function Tickets() {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState(false);
  const [searchedColumn, setSearchedColumn] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [filteredInfo, setFilteredInfo] = useState(null);
  const [sortedInfo, setSortedInfo] = useState(null);

  const { ticket, tickets } = useSelector((state) => state.ticket);
  useEffect(() => {
    dispatch(getTickets);
  });
  let getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            setSearchInput(node);
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>

          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => setSearchInput(""), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  let handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  let handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
    setFilteredInfo(null);
    setSortedInfo(null);
  };

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      width: "7%",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      width: "17%",
      ...getColumnSearchProps("date"),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: "32%",
      ...getColumnSearchProps("description"),
      // sorter: (a, b) => a.from.length - b.from.length,
      // sortDirections: ["descend", "ascend"],
    },
    {
      title: "Booking Number",
      dataIndex: "bookingNumber",
      key: "bookingNumber",
      width: "12%",
      ...getColumnSearchProps("to"),
      sorter: (a, b) => a.to.length - b.to.length,
      sortDirections: ["descend", "ascend"],
    },
    // {
    //   title: "Bus",
    //   dataIndex: "bus",
    //   key: "bus",
    //   width: "12%",
    //   ...getColumnSearchProps("bus"),
    // },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "15%",
    },
    // {
    //   title: "",
    //   key: "action",
    //   width: "20%",
    //   render: (text, record) => (
    //     <Space size="middle">
    //       <Button
    //         style={{ background: "#eba134", borderColor: "#eba134" }}
    //         type="primary"
    //         shape="round"
    //         icon={<MoreOutlined rotate={90} />}
    //         size="small"
    //       />
    //     </Space>
    //   ),
    // },
  ];
  return (
    <div className="tabled">
      <Row gutter={[24, 0]}>
        <Col xs={24} xl={24}>
          <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title="Fillter data"
            // extra={
            //   <Link to="/new-trip">
            //     <Button type="primary" icon={<SwapOutlined />} size="large">
            //       New Trip
            //     </Button>
            //   </Link>
            // }
          >
            <Table columns={columns} dataSource={tickets} />
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default Tickets;
