export const AmenityActionTypes = {
  AMENITY_PENDING: "AMENITY_PENDING",
  AMENITY_SUCCESS: "AMENITY_SUCCESS",
  AMENITY_FAIL: "AMENITY_FAIL",
  CLEAR_AMENITY: "CLEAR_AMENITY",

  AMENITYS_PENDING: "AMENITYS_PENDING",
  AMENITYS_SUCCESS: "AMENITYS_SUCCESS",
  AMENITYS_FAIL: "AMENITYS_FAIL",
  CLEAR_AMENITYS: "CLEAR_AMENITYS",

  CREATE_AMENITY_PENDING: "CREATE_AMENITY_PENDING",
  CREATE_AMENITY_SUCCESS: "CREATE_AMENITY_SUCCESS",
  CREATE_AMENITY_FAIL: "CREATE_AMENITY_FAIL",
  CLEAR_CREATE_AMENITY: "CLEAR_CREATE_AMENITY",

  UPDATE_AMENITY_PENDING: "UPDATE_AMENITY_PENDING",
  UPDATE_AMENITY_SUCCESS: "UPDATE_AMENITY_SUCCESS",
  UPDATE_AMENITY_FAIL: "UPDATE_AMENITY_FAIL",
  CLEAR_UPDATE_AMENITY: "CLEAR_UPDATE_AMENITY",

  DELETE_AMENITY_PENDING: "DELETE_AMENITY_PENDING",
  DELETE_AMENITY_SUCCESS: "DELETE_AMENITY_SUCCESS",
  DELETE_AMENITY_FAIL: "DELETE_AMENITY_FAIL",
  CLEAR_DELETE_AMENITY: "CLEAR_DELETE_AMENITY",
};
