import { CityTypes } from "./types";

const INITIAL_STATE = {
  error: null,
  city: {},
  citys: [],
  cityDropPoint: [],

  cityLoading: false,
  citySuccess: false,
  cityFail: false,

  citysLoading: false,
  citysSuccess: false,
  citysFail: false,

  createCityLoading: false,
  createCitySuccess: false,
  createCityFail: false,

  updateCityLoading: false,
  updateCitySuccess: false,
  upateCityFail: false,

  deleteCityLoading: false,
  deleteCitySuccess: false,
  deleteCityFail: false,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CityTypes.CITY_PENDING:
      return {
        ...state,
        cityLoading: true,
      };
    case CityTypes.CITY_SUCCESS:
      return {
        ...state,
        citySuccess: true,
        cityLoading: false,
        city: {
          ...state.city,
          [action.payload.id]: action.payload.city,
        },
        // cityDropPoint: action.payload.cityDropPoint,
      };
    case CityTypes.CITY_FAIL:
      return {
        ...state,
        cityLoading: false,
        citySuccess: false,
        error: action.payload.error,
      };
    case CityTypes.CLEAR_CITY:
      return {
        ...state,
        cityLoading: false,
        citySuccess: false,
        cityFail: false,
      };

    case CityTypes.CITYS_PENDING:
      return {
        ...state,
        citysLoading: true,
      };
    case CityTypes.CITYS_SUCCESS:
      return {
        ...state,
        citysSuccess: true,
        citysLoading: false,
        citys: action.payload.citys,
      };
    case CityTypes.CITYS_FAIL:
      return {
        ...state,
        citysLoading: false,
        citysSuccess: false,
        error: action.payload.error,
      };
    case CityTypes.CLEAR_CITYS:
      return {
        ...state,
        citysSuccess: false,
        citysLoading: false,
        citysFail: false,
      };

    case CityTypes.CREATE_CITY_PENDING:
      return {
        ...state,
        createCityLoading: true,
      };
    case CityTypes.CREATE_CITY_SUCCESS:
      return {
        ...state,
        createCitySuccess: true,
        createCityLoading: false,
        citys: [action.payload.city, ...state.citys],
      };
    case CityTypes.CREATE_CITY_FAIL:
      return {
        ...state,
        createCityLoading: false,
        createCitySuccess: false,
        error: action.payload.error,
      };
    case CityTypes.CREATE_CLEAR_CITY:
      return {
        ...state,
        createCitySuccess: false,
        createCityLoading: false,
        createCityFail: false,
      };

    case CityTypes.UPDATE_CITY_PENDING:
      return {
        ...state,
        updateCityLoading: true,
      };
    case CityTypes.UPDATE_CITY_SUCCESS:
      return {
        ...state,
        updateCitySuccess: true,
        updateCityLoading: false,
        city: {
          ...state.city,
          [action.payload.id]: action.payload.city,
        },
      };
    case CityTypes.UPDATE_CITY_FAIL:
      return {
        ...state,
        updateCityLoading: false,
        updateCitySuccess: false,
        error: action.payload.error,
      };
    case CityTypes.UPDATE_CLEAR_CITY:
      return {
        ...state,
        updateCitySuccess: false,
        updateCityLoading: false,
        upateCityFail: false,
      };

    case CityTypes.DELETE_CITY_PENDING:
      return {
        ...state,
        deleteCityLoading: true,
      };
    case CityTypes.DELETE_CITY_SUCCESS:
      return {
        ...state,
        deleteCitySuccess: true,
        deleteCityLoading: false,
      };
    case CityTypes.DELETE_CITY_FAIL:
      return {
        ...state,
        deleteCityLoading: false,
        deleteCitySuccess: false,
        error: action.payload.error,
      };
    case CityTypes.DELETE_CLEAR_CITY:
      return {
        ...state,
        deleteCitySuccess: false,
        deleteCityLoading: false,
        deleteCityFail: false,
      };

    default:
      return state;
  }
};

export default reducer;
