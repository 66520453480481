import * as React from "react";
import { useState, useMemo, useEffect } from "react";
import { render } from "react-dom";
import Map, {
  Marker,
  Popup,
  NavigationControl,
  FullscreenControl,
  ScaleControl,
  GeolocateControl,
} from "react-map-gl";
import { useDispatch, useSelector } from "react-redux";
import { getLocations } from "../../redux/tracking/action";
import { getBuses } from "../../redux/bus/action";
import { Col, Row, Select, Card } from "antd";

import ControlPanel from "./control-panel";
import Pin from "./pin";

const TOKEN =
  "pk.eyJ1IjoibXR0dCIsImEiOiJjbDFiNWdlNG4yNHZ5M2RrYXVwZmtidWQ3In0.CjD4WPKjEUUGdIRmgL4sdw";

const { Option } = Select;

function Tracking2() {
  const dispatch = useDispatch();
  const [popupInfo, setPopupInfo] = useState(null);
  const [showLocation, SetShowLocation] = useState(true);
  const [currentBus, setCurrentBus] = useState([]);
  const [currBus, setCurrBus] = useState({});
  const [locationData, setLocationData] = useState([]);

  const { locations, locationsSuccess } = useSelector(
    (state) => state.tracking
  );

  const onChange = (value) => {
    SetShowLocation(false);
    setCurrBus(value);
    const response = locationData.filter((res) => res.vehicleValue === value);
    setCurrentBus(response);
  };

  useEffect(() => {
    const formData = {
      appId: "YxaTXe9v",
      timestamp: 1688000998,
      type: "vehicleCode",
      vehicleValues: [7115, 7113],
      signature: "1E0EFE7F901FF91D8606CF4C510A3254CFBD8FE5",
    };
    dispatch(getBuses);
    dispatch(getLocations(formData));

    let interval = setInterval(() => {
      fetch(
        `http://138.197.190.17:5050/externalapi/v1/zndd/getRealTimeVehicleInfo`,
        {
          method: "POST",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          setLocationData(res.data);
          if (!showLocation) {
            const response = locationData.filter(
              (res) => res.vehicleValue === currBus
            );
            setCurrentBus(response);
          }
        })
        .catch((err) => {});
    }, 500);
    return () => {
      window.clearInterval(interval);
    };
  }, [dispatch, currBus, showLocation, locationData]);
  const { buses } = useSelector((state) => state.bus);
  const busesData = [];
  let count = 0;

  buses.forEach((bus) =>
    busesData.push({
      key: count++,
      value: bus.id,
      plateNumber: bus.plateNumber,
    })
  );

  const pins = useMemo(
    () =>
      locationData.map((city, index) => (
        <Marker
          key={`marker-${index}`}
          longitude={city.gpsLongitude}
          latitude={city.gpsLatitude}
          anchor="bottom"
        >
          <Pin onClick={() => setPopupInfo(city)} />
        </Marker>
      )),
    [locationData]
  );

  const pin = useMemo(
    () =>
      currentBus.map((city, index) => (
        <Marker
          key={`marker-${index}`}
          longitude={city.gpsLongitude}
          latitude={city.gpsLatitude}
          anchor="bottom"
        >
          <Pin onClick={() => setPopupInfo(city)} />
        </Marker>
      )),
    [currentBus]
  );

  return (
    <>
      <Row gutter={[24, 0]}>
        <Col xs={5} xl={5}>
          <Card
            bordered={false}
            className="criclebox tablespace mb-24 pb-24"
            title="Bus"
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Select
                style={{ width: "80%", padding: "3rem 0" }}
                placeholder="Select a bus"
                onChange={onChange}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {locations.map((option) => (
                  <Option value={option.vehicleValue}>
                    {option.vehicleValue}
                  </Option>
                ))}
              </Select>
            </div>
          </Card>
        </Col>
        <Col xs={19} xl={19} style={{ height: "100rem" }}>
          {showLocation ? (
            <>
              {locationsSuccess ? (
                <Map
                  initialViewState={{
                    latitude: 3.97709023454607,
                    longitude: 38.74129226958945,
                    zoom: 5.5,
                    bearing: 0,
                    pitch: 0,
                  }}
                  mapStyle="mapbox://styles/mapbox/streets-v9"
                  mapboxAccessToken={TOKEN}
                >
                  <GeolocateControl position="top-left" />
                  <FullscreenControl position="top-left" />
                  <NavigationControl position="top-left" />
                  <ScaleControl />

                  {pins}

                  {popupInfo && (
                    <Popup
                      anchor="top"
                      longitude={Number(popupInfo.longitude)}
                      latitude={Number(popupInfo.latitude)}
                      closeOnClick={false}
                      onClose={() => setPopupInfo(null)}
                    >
                      <div>
                        {popupInfo.city}, {popupInfo.state} |{" "}
                        <a
                          target="_new"
                          href={`http://en.wikipedia.org/w/index.php?title=Special:Search&search=${popupInfo.city}, ${popupInfo.state}`}
                        >
                          Wikipedia
                        </a>
                      </div>
                      <img width="100%" src={popupInfo.image} alt="" />
                    </Popup>
                  )}
                </Map>
              ) : (
                <Map
                  initialViewState={{
                    latitude: 3.97709023454607,
                    longitude: 38.74129226958945,
                    zoom: 5.5,
                    bearing: 0,
                    pitch: 0,
                  }}
                  mapStyle="mapbox://styles/mapbox/streets-v9"
                  mapboxAccessToken={TOKEN}
                >
                  <GeolocateControl position="top-left" />
                  <FullscreenControl position="top-left" />
                  <NavigationControl position="top-left" />
                  <ScaleControl />

                  {popupInfo && (
                    <Popup
                      anchor="top"
                      longitude={Number(popupInfo.longitude)}
                      latitude={Number(popupInfo.latitude)}
                      closeOnClick={false}
                      onClose={() => setPopupInfo(null)}
                    >
                      <div>
                        {popupInfo.city}, {popupInfo.state} |{" "}
                        <a
                          target="_new"
                          href={`http://en.wikipedia.org/w/index.php?title=Special:Search&search=${popupInfo.city}, ${popupInfo.state}`}
                        >
                          Wikipedia
                        </a>
                      </div>
                      <img width="100%" src={popupInfo.image} alt="" />
                    </Popup>
                  )}
                </Map>
              )}
            </>
          ) : (
            <Map
              initialViewState={{
                latitude: currentBus[0].gpsLatitude - 0.25,
                longitude: currentBus[0].gpsLongitude,
                zoom: 10,
                bearing: 0,
                pitch: 0,
              }}
              mapStyle="mapbox://styles/mapbox/streets-v9"
              mapboxAccessToken={TOKEN}
            >
              <GeolocateControl position="top-left" />
              <FullscreenControl position="top-left" />
              <NavigationControl position="top-left" />
              <ScaleControl />

              {pin}

              {popupInfo && (
                <Popup
                  anchor="top"
                  longitude={Number(popupInfo.longitude)}
                  latitude={Number(popupInfo.latitude)}
                  closeOnClick={false}
                  onClose={() => setPopupInfo(null)}
                >
                  <div>
                    {popupInfo.city}, {popupInfo.state} |{" "}
                    <a
                      target="_new"
                      href={`http://en.wikipedia.org/w/index.php?title=Special:Search&search=${popupInfo.city}, ${popupInfo.state}`}
                    >
                      Wikipedia
                    </a>
                  </div>
                  <img width="100%" src={popupInfo.image} alt="" />
                </Popup>
              )}
            </Map>
          )}
          <ControlPanel />
        </Col>
      </Row>
    </>
  );
}

export default Tracking2;
