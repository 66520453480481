export const HumanResourceTypes = {
  HUMAN_RESOURCE_PENDING: "HUMAN_RESOURCE_PENDING",
  HUMAN_RESOURCE_SUCCESS: "HUMAN_RESOURCE_SUCCESS",
  HUMAN_RESOURCE_FAIL: "HUMAN_RESOURCE_FAIL",
  CLEAR_HUMAN_RESOURCE: "CLEAR_HUMAN_RESOURCE",

  HUMAN_RESOURCES_PENDING: "HUMAN_RESOURCES_PENDING",
  HUMAN_RESOURCES_SUCCESS: "HUMAN_RESOURCES_SUCCESS",
  HUMAN_RESOURCES_FAIL: "HUMAN_RESOURCES_FAIL",
  CLEAR_HUMAN_RESOURCES: "CLEAR_HUMAN_RESOURCES",

  CREATE_HUMAN_RESOURCE_PENDING: "CREATE_HUMAN_RESOURCE_PENDING",
  CREATE_HUMAN_RESOURCE_SUCCESS: "CREATE_HUMAN_RESOURCE_SUCCESS",
  CREATE_HUMAN_RESOURCE_FAIL: "CREATE_HUMAN_RESOURCE_FAIL",
  CREATE_CLEAR_HUMAN_RESOURCE: "CREATE_CLEAR_HUMAN_RESOURCE",

  UPDATE_HUMAN_RESOURCE_PENDING: "UPDATE_HUMAN_RESOURCE_PENDING",
  UPDATE_HUMAN_RESOURCE_SUCCESS: "UPDATE_HUMAN_RESOURCE_SUCCESS",
  UPDATE_HUMAN_RESOURCE_FAIL: "UPDATE_HUMAN_RESOURCE_FAIL",
  UPDATE_CLEAR_HUMAN_RESOURCE: "UPDATE_CLEAR_HUMAN_RESOURCE",
  
  DELETE_HUMAN_RESOURCE_PENDING: "DELETE_HUMAN_RESOURCE_PENDING",
  DELETE_HUMAN_RESOURCE_SUCCESS: "DELETE_HUMAN_RESOURCE_SUCCESS",
  DELETE_HUMAN_RESOURCE_FAIL: "DELETE_HUMAN_RESOURCE_FAIL",  
  DELETE_CLEAR_HUMAN_RESOURCE: "DELETE_CLEAR_HUMAN_RESOURCE",

  ASSIGN_BUS_HUMAN_RESOURCE_PENDING: "ASSIGN_BUS_HUMAN_RESOURCE_PENDING",
  ASSIGN_BUS_HUMAN_RESOURCE_SUCCESS: "ASSIGN_BUS_HUMAN_RESOURCE_SUCCESS",
  ASSIGN_BUS_HUMAN_RESOURCE_FAIL: "ASSIGN_BUS_HUMAN_RESOURCE_FAIL",
  CLEAR_ASSIGN_BUS_HUMAN_RESOURCE: "ASSIGN_BUS_HUMAN_RESOURCE",
};
