export const RefundTypes = {
  REFUND_PENDING: "REFUND_PENDING",
  REFUND_SUCCESS: "REFUND_SUCCESS",
  REFUND_FAIL: "REFUND_FAIL",
  CLEAR_REFUND: "CLEAR_REFUND",

  REFUNDS_PENDING: "REFUNDS_PENDING",
  REFUNDS_SUCCESS: "REFUNDS_SUCCESS",
  REFUNDS_FAIL: "REFUNDS_FAIL",
  CLEAR_REFUNDS: "CLEAR_REFUNDS",

  CREATE_REFUND_PENDING: "CREATE_REFUND_PENDING",
  CREATE_REFUND_SUCCESS: "CREATE_REFUND_SUCCESS",
  CREATE_REFUND_FAIL: "CREATE_REFUND_FAIL",
  CLEAR_CREATE_REFUND: "CLEAR_CREATE_REFUND",
  CREATE_REFUND_SUCCESSFULLY: "CREATE_REFUND_SUCCESSFULLY",
  CREATE_REFUND_SUCCESS_FAILED: "CREATE_REFUND_SUCCESS_FAILED",

  REFUND_ACCEPT_PENDING: "REFUND_ACCEPT_PENDING",
  REFUND_ACCEPT_SUCCESS: "REFUND_ACCEPT_SUCCESS",
  REFUND_ACCEPT_FAIL: "REFUND_ACCEPT_FAIL",
  CLEAR_REFUND_ACCEPT: "CLEAR_REFUND_ACCEPT",

  REFUND_REJECT_PENDING: "REFUND_REJECT_PENDING",
  REFUND_REJECT_SUCCESS: "REFUND_REJECT_SUCCESS",
  REFUND_REJECT_FAIL: "REFUND_REJECT_FAIL",
  REFUND_REJECT_CLEAR: "REFUND_REJECT_CLEAR",
};
