import axios from "axios";
import { CancellationPolicyActionTypes } from "./types";
import { busAdminTokenKey, BASE_URI } from "../../config/key.config";

export const fetchCancellationStart = () => ({
  type: CancellationPolicyActionTypes.CANCELLATION_PENDING,
});

export const fetchCancellationSuccess = (cancellation) => ({
  type: CancellationPolicyActionTypes.CANCELLATION_SUCCESS,
  payload: {
    cancellation,
  },
});

export const fetchCancellationFailure = (error) => ({
  type: CancellationPolicyActionTypes.CANCELLATION_FAIL,
  payload: { error },
});

export const clearFetchCancellation = () => ({
  type: CancellationPolicyActionTypes.CLEAR_CANCELLATION,
});

export const fetchCancellationsStart = () => ({
  type: CancellationPolicyActionTypes.CANCELLATIONS_PENDING,
});

export const fetchCancellationsSuccess = (cancellations) => ({
  type: CancellationPolicyActionTypes.CANCELLATIONS_SUCCESS,
  payload: { cancellations },
});

export const fetchCancellationsFailure = (error) => ({
  type: CancellationPolicyActionTypes.CANCELLATIONS_FAIL,
  payload: { error },
});

export const clearFetchCancellations = () => ({
  type: CancellationPolicyActionTypes.CLEAR_CANCELLATIONS,
});

export const createCancellationStart = () => ({
  type: CancellationPolicyActionTypes.CREATE_CANCELLATION_PENDING,
});

export const createCancellationSuccess = (cancellation) => ({
  type: CancellationPolicyActionTypes.CREATE_CANCELLATION_SUCCESS,
  payload: { cancellation },
});

export const createCancellationFail = (error) => ({
  type: CancellationPolicyActionTypes.CREATE_CANCELLATION_FAIL,
  payload: { error },
});

export const clearCreateCancellation = () => ({
  type: CancellationPolicyActionTypes.CLEAR_CREATE_CANCELLATION,
});

export const updateCancellationStart = () => ({
  type: CancellationPolicyActionTypes.UPDATE_CANCELLATION_PENDING,
});

export const updateCancellationSuccess = (id, cancellation) => ({
  type: CancellationPolicyActionTypes.UPDATE_CANCELLATION_SUCCESS,
  payload: {
    id,
    cancellation,
  },
});
export const updateCancellationFail = (error) => ({
  type: CancellationPolicyActionTypes.UPDATE_CANCELLATION_FAIL,
  payload: { error },
});

export const clearUpdateCancellation = () => ({
  type: CancellationPolicyActionTypes.CLEAR_UPDATE_CANCELLATION,
});

export const deleteCancellationStart = () => ({
  type: CancellationPolicyActionTypes.DELETE_CANCELLATION_PENDING,
});

export const deleteCancellationSuccess = (id) => ({
  type: CancellationPolicyActionTypes.DELETE_CANCELLATION_SUCCESS,
  payload: { id },
});

export const deleteCancellationFail = (error) => ({
  type: CancellationPolicyActionTypes.DELETE_CANCELLATION_FAIL,
  payload: { error },
});

export const clearDeleteCancellation = () => ({
  type: CancellationPolicyActionTypes.CLEAR_DELETE_CANCELLATION,
});

export const getCancellations = (dispatch) => {
  dispatch(fetchCancellationsStart());

  axios
    .get(`${BASE_URI}/cancellation-policy`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) => {
      const response = res.data;
      const responseData = [];

      response.forEach((data) =>
        responseData.push({
          key: data.id,
          id: data.id,
          name: `Before ${data.name} Days`,
          status: data.status,
          charge: data.charge,
        })
      );
      dispatch(fetchCancellationsSuccess(responseData));
    })
    .catch((err) => dispatch(fetchCancellationsFailure(err)));
};

export const getCancellation = (id) => (dispatch) => {
  dispatch(fetchCancellationStart());

  axios
    .get(`${BASE_URI}/cancellation-policy/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) => dispatch(fetchCancellationSuccess(res.data)))
    .catch((err) => dispatch(fetchCancellationFailure(err)));
};

export const createCancellation = (formData, history) => (dispatch) => {
  dispatch(createCancellationStart());

  axios
    .post(`${BASE_URI}/cancellation-policy`, formData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) => {
      dispatch(createCancellationSuccess(res.data));
      history.push("/director/cancellation-policy");
    })
    .catch((err) => dispatch(createCancellationFail(err)));
};

export const updateCancellation = (id, formData) => (dispatch) => {
  dispatch(updateCancellationStart());

  axios
    .put(`${BASE_URI}/cancellation-policy/${id}`, formData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) => dispatch(updateCancellationSuccess(id, res.data)))
    .catch((err) => dispatch(updateCancellationFail(err)));
};

export const deleteCancellation = (id) => (dispatch) => {
  dispatch(deleteCancellationStart());

  axios
    .delete(`${BASE_URI}/cancellation-policy/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) => dispatch(deleteCancellationSuccess(id)))
    .catch((err) => dispatch(deleteCancellationFail(err)));
};
