import axios from "axios";
import { TripsTypes } from "./types";
import { busAdminTokenKey, BASE_URI } from "../../config/key.config";

export const fetchTripStart = () => ({
  type: TripsTypes.TRIP_PENDING,
});

export const fetchTripSuccess = (id, trip) => ({
  type: TripsTypes.TRIP_SUCCESS,
  payload: {
    id,
    trip,
  },
});

export const fetchTripFail = (error) => ({
  type: TripsTypes.TRIP_FAIL,
  payload: {
    error,
  },
});

export const clearFetchTrip = () => ({
  type: TripsTypes.CLEAR_TRIP,
});

export const fetchTripsStart = () => ({
  type: TripsTypes.TRIPS_PENDING,
});

export const fetchTripsSuccess = (trips) => ({
  type: TripsTypes.TRIPS_SUCCESS,
  payload: {
    trips,
  },
});

export const fetchTripsFail = (error) => ({
  type: TripsTypes.TRIPS_FAIL,
  payload: {
    error,
  },
});

export const clearFetchTrips = () => ({
  type: TripsTypes.CLEAR_TRIPS,
});
//TODO create Route
export const createTripStart = () => ({
  type: TripsTypes.CREATE_TRIP_PENDING,
});

export const createTripSuccess = (trip) => ({
  type: TripsTypes.CREATE_TRIP_SUCCESS,
  payload: {
    trip,
  },
});

export const createTripFail = (error) => ({
  type: TripsTypes.CREATE_TRIP_FAIL,
  payload: {
    error,
  },
});

export const createCreateTrip = () => ({
  type: TripsTypes.CREATE_CLEAR_TRIP,
});

//TODO Update Route
export const updateTripStart = () => ({
  type: TripsTypes.UPDATE_TRIP_PENDING,
});

export const updateTripSuccess = (id, trip) => ({
  type: TripsTypes.UPDATE_TRIP_SUCCESS,
  payload: {
    id,
    trip,
  },
});

export const updateTripFail = (error) => ({
  type: TripsTypes.UPDATE_TRIP_FAIL,
  payload: {
    error,
  },
});

export const clearUpdateTrip = () => ({
  type: TripsTypes.UPDATE_CLEAR_TRIP,
});

//TODO delete route
export const deleteTripStart = () => ({
  type: TripsTypes.DELETE_TRIP_PENDING,
});

export const deleteTripSuccess = (id) => ({
  type: TripsTypes.DELETE_TRIP_SUCCESS,
  payload: {
    id,
  },
});

export const deleteTripFail = (error) => ({
  type: TripsTypes.DELETE_TRIP_FAIL,
  payload: {
    error,
  },
});

export const clearDeleteTrip = () => ({
  type: TripsTypes.DELETE_CLEAR_TRIP,
});

export const getTrips = (dispatch) => {
  dispatch(fetchTripsStart());

  axios
    .get(`${BASE_URI}/trips`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
      params: {
        _limit: 10,
      },
    })
    .then((res) => {
      const response = res.data;
      const responsesData = [];
      response.forEach((data) =>
        responsesData.push({
          key: data.id,
          id: data.id,
          date: data.travelDate.slice(0, 10),
          destination: `${data.from} - ${data.to}`,
          bus: data.bus.plateNumber,
          status: data.status,
          routeId: data.route.id,
          busId: data.bus.id,
          subRoute: data.route?.subRoute,
          booking: data.booking,
        })
      );
      dispatch(fetchTripsSuccess(responsesData));
    })
    .catch((err) => {
      dispatch(fetchTripsFail(err));
    });
};

export const getTripsDashboard = (dispatch) => {
  dispatch(fetchTripsStart());

  axios
    .get(`${BASE_URI}/trips`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
      params: {
        _limit: 10,
      },
    })
    .then((res) => {
      const response = res.data;
      const responseData = [];

      response.forEach((data) =>
        responseData.push({
          key: data.id,
          id: data.id,
          date: data.travelDate,
          destination: `${data.from} - ${data.to}`,
          bus: data.bus.plateNumber,
          status: data.status,
          routeId: data.route.id,
          busId: data.bus.id,
        })
      );

      const temp = responseData.reduce((items, item) => {
        const { key, id, date, destination, bus, status, routeId, busId } =
          item;
        const itemIndex = items.findIndex((item) => item.routeId === routeId);
        if (itemIndex === -1) {
          items.push({
            key,
            id,
            date,
            destination,
            bus,
            status,
            routeId,
            busId,
          });
        }
        return items;
      }, []);
      dispatch(fetchTripsSuccess(temp));
    })
    .catch((err) => {
      dispatch(fetchTripsFail(err));
    });
};

// export const getTripsDashboard = (dispatch) => {
//   dispatch(fetchTripsStart());

//   axios
//     .get(`${BASE_URI}/trips`, {
//       headers: {
//         Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
//       },
//       params: {
//         _limit: 10,
//       },
//     })
//     .then((res) => {
//       const response = res.data;
//       let prevRoute = `${response[0].from} - ${response[0].to}`;
//       const responseData = [];

//       response.forEach((data) => {
//         let curRoute = `${data.from} - ${data.to}`;
//         if (prevRoute === curRoute) {
//           responseData.push({
//             key: data.id,
//             id: data.id,
//             date: data.travelDate,
//             destination: `${data.from} - ${data.to}`,
//             bus: data.bus.plateNumber,
//             status: data.status,
//             routeId: data.route.id,
//             busId: data.bus.id,
//           });
//           console.log(responseData, "responseData");
//         } else {
//           prevRoute = curRoute;
//         }
//       });

//       dispatch(fetchTripsSuccess(responseData));
//     })
//     .catch((err) => {
//       dispatch(fetchTripsFail(err));
//     });
// };

export const getTripsByRoute = (routeId) => (dispatch) => {
  dispatch(fetchTripsStart());

  axios
    .get(`${BASE_URI}/trips`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
      params: {
        _limit: 10,
      },
    })
    .then((res) => {
      const response = res.data;
      let responseDatas = [];
      response.forEach((data) =>
        responseDatas.push({
          key: data.id,
          id: data.id,
          date: data.travelDate,
          destination: `${data.from} - ${data.to}`,
          bus: data.bus.plateNumber,
          status: data.status,
          routeId: data.route.id,
        })
      );
      responseDatas = responseDatas.filter((res) => res.routeId === routeId);

      dispatch(fetchTripsSuccess(responseDatas));
    })
    .catch((err) => {
      dispatch(fetchTripsFail(err));
    });
};

export const getTripsByBus = (busId) => (dispatch) => {
  dispatch(fetchTripsStart());

  axios
    .get(`${BASE_URI}/trips`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
      params: {
        _limit: 10,
      },
    })
    .then((res) => {
      const response = res.data;
      let responseDatas = [];
      response.forEach((data) =>
        responseDatas.push({
          key: data.id,
          id: data.id,
          date: data.travelDate,
          destination: `${data.from} - ${data.to}`,
          bus: data.bus.plateNumber,
          status: data.status,
          routeId: data.route.id,
          busId: data.bus.id,
        })
      );
      responseDatas = responseDatas.filter((res) => res.busId === busId);
      dispatch(fetchTripsSuccess(responseDatas));
    })
    .catch((err) => {
      dispatch(fetchTripsFail(err));
    });
};

export const getTripsByDate = (date) => (dispatch) => {
  dispatch(fetchTripsStart());

  axios
    .get(`${BASE_URI}/trips`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
      params: {
        _limit: 10,
      },
    })
    .then((res) => {
      const response = res.data;
      let responseDatas = [];
      response.forEach((data) =>
        responseDatas.push({
          key: data.id,
          id: data.id,
          date: data.travelDate,
          destination: `${data.from} - ${data.to}`,
          bus: data.bus.plateNumber,
          status: data.status,
          routeId: data.route.id,
          busId: data.bus.id,
        })
      );
      responseDatas = responseDatas.filter(
        (res) => res.date.slice(0, 10) === date
      );
      dispatch(fetchTripsSuccess(responseDatas));
    })
    .catch((err) => {
      dispatch(fetchTripsFail(err));
    });
};

export const getTripsByDestinations = (from, to) => (dispatch) => {
  dispatch(fetchTripsStart());

  axios
    .get(`${BASE_URI}/trips`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
      params: {
        _limit: 10,
      },
    })
    .then((res) => {
      const response = res.data;
      let responseDatas = [];
      response.forEach((data) =>
        responseDatas.push({
          key: data.id,
          id: data.id,
          date: data.travelDate,
          destination: `${data.from} - ${data.to}`,
          bus: data.bus.plateNumber,
          status: data.status,
          routeId: data.route.id,
          fromId: data.route.from.id,
          toId: data.route.to.id,
          subRoute: data.route?.subRoute,
        })
      );
      responseDatas = responseDatas.filter(
        (res) => `${res.fromId} - ${res.toId}` === `${from} - ${to}`
      );

      dispatch(fetchTripsSuccess(responseDatas));
    })
    .catch((err) => {
      dispatch(fetchTripsFail(err));
    });
};

export const getTrip = (id, history) => (dispatch) => {
  dispatch(fetchTripStart());

  axios
    .get(`${BASE_URI}/trips/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) => {
      const response = res.data;

      dispatch(fetchTripSuccess(id, response));
    })
    .catch((err) => {
      dispatch(fetchTripFail(err));
    });
};

export const createTrip = (bus, route, dateData, history) => (dispatch) => {
  dispatch(createTripStart());

  axios
    .post(`${BASE_URI}/trips/generate/${bus}/${route}/${dateData}`, null, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) => {
      dispatch(createTripSuccess(res.data));
      // window.location.href = "http://localhost:3000/director/trips";
      history.push("/director/trips");
    })
    .catch((err) => {
      dispatch(createTripFail(err));
    });
};

export const updateTrip = (formData, id) => (dispatch) => {
  dispatch(updateTripStart());

  axios
    .put(`${BASE_URI}/trips/update-blocked-seat/${id}`, formData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) => {
      dispatch(updateTripSuccess(id, res.data));
    })
    .catch((err) => {
      dispatch(updateTripFail(err));
    });
};

export const deleteTrip = (id) => (dispatch) => {
  dispatch(deleteTripStart());

  axios
    .delete(`${BASE_URI}/trips/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) => {
      dispatch(deleteTripSuccess(id));
    })
    .catch((err) => {
      dispatch(deleteTripFail(err));
    });
};
