import axios from "axios";
import { RefundTypes } from "./types";

import { busAdminTokenKey, BASE_URI } from "../../config/key.config";

//TODO single refund
export const fetchRefundStart = () => ({
  type: RefundTypes.REFUND_PENDING,
});

export const fetchRefundSuccess = (id, refund) => ({
  type: RefundTypes.REFUND_SUCCESS,
  payload: {
    id,
    refund,
  },
});

export const fetchRefundFail = (error) => ({
  type: RefundTypes.REFUND_FAIL,
  payload: {
    error,
  },
});

export const clearFetchRefund = () => ({
  type: RefundTypes.CLEAR_REFUND,
});

//TODO list of refunds
export const fetchRefundsStart = () => ({
  type: RefundTypes.REFUNDS_PENDING,
});

export const fetchRefundsSuccess = (refunds) => ({
  type: RefundTypes.REFUNDS_SUCCESS,
  payload: {
    refunds,
  },
});

export const fetchRefundsFail = (error) => ({
  type: RefundTypes.REFUNDS_FAIL,
  payload: {
    error,
  },
});

export const clearFetchRefunds = () => ({
  type: RefundTypes.CLEAR_REFUNDS,
});

//TODO create refund
export const createRefundStart = () => ({
  type: RefundTypes.CREATE_REFUND_PENDING,
});
export const createRefundSuccess = (refund) => ({
  type: RefundTypes.CREATE_REFUND_SUCCESS,
  payload: {
    refund,
  },
});

export const createRefundSuccessFail = () => ({
  type: RefundTypes.CREATE_REFUND_SUCCESS_FAILED,
});
export const createRefundFail = (error) => ({
  type: RefundTypes.CREATE_REFUND_FAIL,
  payload: {
    error,
  },
});
export const clearCreateRefund = () => ({
  type: RefundTypes.CLEAR_CREATE_REFUND,
});

//TODO Accept Refund
export const acceptRefundStart = () => ({
  type: RefundTypes.REFUND_ACCEPT_PENDING,
});

export const acceptRefundSuccess = (id, refund) => ({
  type: RefundTypes.REFUND_ACCEPT_SUCCESS,
  payload: {
    id,
    refund,
  },
});
export const acceptRefundFail = (error) => ({
  type: RefundTypes.REFUND_ACCEPT_FAIL,
  payload: {
    error,
  },
});
export const clearAcceptRefund = () => ({
  type: RefundTypes.CLEAR_REFUND_ACCEPT,
});

//TODO Reject Refund
export const rejectRefundStart = () => ({
  type: RefundTypes.REFUND_REJECT_PENDING,
});

export const rejectRefundSuccess = (id, refund) => ({
  type: RefundTypes.REFUND_REJECT_SUCCESS,
  payload: {
    id,
    refund,
  },
});

export const rejectRefundFail = (error) => ({
  type: RefundTypes.REFUND_REJECT_FAIL,
  payload: {
    error,
  },
});

export const clearRejectRefund = () => ({
  type: RefundTypes.REFUND_REJECT_CLEAR,
});

export const getRefunds = (statusValue) => (dispatch) => {
  dispatch(fetchRefundsStart());

  axios
    .get(`${BASE_URI}/refunds/by-status/${statusValue}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) => {
      const response = res.data;
      const responseData = [];
      response.forEach((res) =>
        responseData.push({
          key: res.id,
          id: res.id,
          payedAmount: res.payedAmount,
          cancelationFeePercent: res.cancelationFeePercent,
          cancelationFee: res.cancelationFee,
          status: res.status,
          cause: res.cause,
          updatedAt: res.updatedAt.slice(0, 10),
          createdAt: res.createdAt.slice(0, 10),
        })
      );
      dispatch(fetchRefundsSuccess(responseData));
    })
    .catch((err) => {
      dispatch(fetchRefundsFail(err));
    });
};

export const getRefund = (id) => (dispatch) => {
  dispatch(fetchRefundStart());

  axios
    .get(`${BASE_URI}/refunds/check-refund-condition/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) => {
      dispatch(fetchRefundSuccess(id, res.data));
    })
    .catch((err) => {
      dispatch(fetchRefundFail(err));
    });
};

export const createRefund = (formData) => (dispatch) => {
  dispatch(createRefundStart());

  axios
    .post(`${BASE_URI}/refunds/request-refund`, formData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) => {
      if (res.data.success === false) {
        dispatch(createRefundSuccessFail());
      }
      dispatch(createRefundSuccess(res.data));
    })
    .catch((err) => {
      dispatch(createRefundFail(err));
    });
};

export const acceptRefund = (id) => (dispatch) => {
  dispatch(acceptRefundStart());

  axios
    .put(`${BASE_URI}/refunds/accept/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) => {
      dispatch(acceptRefundSuccess(id, res.data));
    })
    .catch((err) => {
      dispatch(acceptRefundFail(err));
    });
};

export const rejectRefund = (id) => (dispatch) => {
  dispatch(rejectRefundStart());

  axios
    .delete(`${BASE_URI}/refunds/reject/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) => {
      dispatch(rejectRefundSuccess(id, res.data));
    })
    .catch((err) => {
      dispatch(rejectRefundFail(err));
    });
};
