import { BusActionTypes } from "./type";

const InitialState = {
  error: null,
  bus: null,
  buses: [],

  busLoading: false,
  busSuccess: false,
  busFail: false,

  busesLoading: false,
  busesSuccess: false,
  busesFail: false,

  createBusLoading: false,
  createBusSuccess: false,
  createBusFail: false,

  updateBusLoading: false,
  updateBusSuccess: false,
  updateBusFail: false,

  deleteBusLoading: false,
  deleteBusSuccess: false,
  deleteBusFail: false,
};

const reducer = (state = InitialState, action) => {
  switch (action.type) {
    case BusActionTypes.BUS_PENDING:
      return {
        ...state,
        busLoading: true,
      };
    case BusActionTypes.BUS_FAIL:
      return {
        ...state,
        busLoading: false,
        busSuccess: false,
        error: action.payload.error,
      };
    case BusActionTypes.BUS_SUCCESS:
      return {
        ...state,
        busLoading: false,
        busSuccess: true,
        bus: {
          ...state.bus,
          [action.payload.id]: action.payload.bus,
        },
      };
    case BusActionTypes.CLEAR_BUS:
      return {
        ...state,
        busLoading: false,
        busSuccess: false,
        busFail: false,
      };
    case BusActionTypes.BUSES_PENDING:
      return {
        ...state,
        busesLoading: true,
      };
    case BusActionTypes.BUSES_SUCCESS:
      return {
        ...state,
        busesLoading: false,
        busesSuccess: true,
        buses: action.payload.buses,
      };
    case BusActionTypes.BUSES_FAIL:
      return {
        ...state,
        busesLoading: false,
        busesSuccess: false,
        error: action.payload.error,
      };
    case BusActionTypes.CLEAR_BUSES:
      return {
        ...state,
        busesLoading: false,
        busesSuccess: false,
        busesFail: false,
      };
    case BusActionTypes.CREATE_BUS_PENDING:
      return {
        ...state,
        createBusLoading: true,
      };
    case BusActionTypes.CREATE_BUS_SUCCESS:
      return {
        ...state,
        createBusLoading: false,
        createBusSuccess: true,
        buses: [action.payload.bus, ...state.buses],
      };
    case BusActionTypes.CREATE_BUS_FAIL:
      return {
        ...state,
        createBusLoading: false,
        createBusSuccess: false,
        error: action.payload.error,
      };
    case BusActionTypes.CLEAR_CREATE_BUS:
      return {
        ...state,
        createBusLoading: false,
        createBusSuccess: false,
        createBusFail: false,
      };
    case BusActionTypes.UPDATE_BUS_PENDING:
      return {
        ...state,
        updateBusLoading: true,
      };
    case BusActionTypes.UPDATE_BUS_SUCCESS:
      return {
        ...state,
        updateBusLoading: false,
        updateBusSuccess: true,
        bus: {
          ...state.bus,
          [action.payload.id]: action.payload.bus,
        },
      };
    case BusActionTypes.UPDATE_BUS_FAIL:
      return {
        ...state,
        updateBusLoading: false,
        updateBusSuccess: false,
        error: action.payload.error,
      };
    case BusActionTypes.CLEAR_UPDATE_BUS:
      return {
        ...state,
        updateBusLoading: false,
        updateBusSuccess: false,
        updateBusFail: false,
      };
    case BusActionTypes.DELETE_BUS_PENDING:
      return {
        ...state,
        deleteBusLoading: true,
      };
    case BusActionTypes.DELETE_BUS_SUCCESS:
      return {
        ...state,
        deleteBusLoading: false,
        deleteBusSuccess: true,
        bus: {},
      };
    case BusActionTypes.DELETE_BUS_FAIL:
      return {
        ...state,
        deleteBusLoading: false,
        deleteBusSuccess: false,
        error: action.payload.error,
      };
    case BusActionTypes.CLEAR_DELETE_BUS:
      return {
        ...state,
        deleteBusLoading: false,
        deleteBusSuccess: false,
        deleteBusFail: false,
      };
    default:
      return state;
  }
};

export default reducer;
