import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Table,
  Input,
  Button,
  Space,
  Select,
  Modal,
  Badge,
  Layout,
  Form,
  Divider,
  Spin,
} from "antd";
import Highlighter from "react-highlight-words";
import { useHistory } from "react-router-dom";
import {
  SearchOutlined,
  EditOutlined,
  DeleteOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import {
  getAmenities,
  createAmenity,
  updateAmenity,
  deleteAmenity,
} from "../../redux/amenties/action";
import { useDispatch, useSelector } from "react-redux";
const { Option } = Select;
const { Content } = Layout;
const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "white" }} spin />
);
function Services() {
  const dispatch = useDispatch();
  const history = useHistory();

  const [searchText, setSearchText] = useState(false);
  const [searchedColumn, setSearchedColumn] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [filteredInfo, setFilteredInfo] = useState(null);
  const [sortedInfo, setSortedInfo] = useState(null);
  const [name, setName] = useState("");
  const [rowData, setRowData] = useState({});
  const [status, setStatus] = useState(null);
  const [isEditModalVisible, setEditModalVisible] = useState(false);
  const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);

  const {
    amenities,
    amenityLoading,
    amenitysLoading,
    createAmenityLoading,
    updateAmenityLoading,
    deleteAmenityLoading,
  } = useSelector((state) => state.amenity);
  useEffect(() => {
    dispatch(getAmenities);
  }, [dispatch, deleteAmenityLoading, createAmenityLoading]);

  const showEditModal = (record) => {
    setRowData(record);
    setStatus(record.status);
    setEditModalVisible(true);
  };

  const showDeleteModal = (record) => {
    setRowData(record);
    setDeleteModalVisible(true);
  };

  const handleEditModalCancel = () => {
    setRowData({});
    setEditModalVisible(false);
    // setCurrId("");
  };
  const handleDeleteModalCancel = () => {
    setRowData({});
    setDeleteModalVisible(false);
    // setCurrId("");
  };

  function handleChangeStatus(value) {
    setStatus(value);
  }

  const handleUpdate = () => {           
    const req = { name: rowData.name, status };
    dispatch(updateAmenity(rowData.id, req));
    handleEditModalCancel();

    dispatch(getAmenities);
    dispatch(getAmenities);
  };

  const handleSubmit = () => {
    const busAssociation = "1";
    const formData = { name, busAssociation };
    dispatch(createAmenity(formData, history));

    setName(null);
  };

  const handleDelete = () => {
    dispatch(deleteAmenity(rowData.id));
    handleDeleteModalCancel();
  };
  let getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            setSearchInput(node);
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>

          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => setSearchInput(""), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  let handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  let handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
    setFilteredInfo(null);
    setSortedInfo(null);
  };

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      ...getColumnSearchProps("name"),
    },
    {
      title: "Status",
      dataIndex: "status",
      // key: "status",
      render: (text, record) => {
        const active = (
          <Badge
            className="site-badge-count-109"
            count={"Active"}
            style={{ backgroundColor: "#52c41a" }}
          />
        );

        const inactive = <Badge count={"Cancelled"} />;

        const show = record.status === "ACTIVE" ? active : inactive;

        return show;
      },
    },
    {
      title: "Action",
      // key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Button
            style={{ background: "#eba134", borderColor: "#eba134" }}
            type="primary"
            shape="round"
            icon={<EditOutlined />}
            size="small"
            onClick={() => showEditModal(record)} />
          <Button
            style={{ background: "#db0925", borderColor: "#db0925" }}
            shape="round"
            type="primary"
            icon={<DeleteOutlined />}
            size="small"
            onClick={() => {
              setRowData(record);
              showDeleteModal(record);
            }}
          />
        </Space>
      ),
    },
  ];
  return (
    <div className="tabled">
      <Row gutter={[24, 0]}>
        <Col xs="24" xl={24}>
          <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title="Informations"            
          >
            <Row
              gutter={[24, 0]}
              style={{
                paddingTop: "2%",
                paddingLeft: "2%",
              }}
            >
              <Col
                md={8}
                style={{
                  alignItems: "center",
                  paddingLeft: "1px",
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <h3>New Amenities: </h3>
                <Input
                  value={name}
                  name="name"
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Add New Amenities"
                  style={{ width: "70%" }}
                />
              </Col>
              <Col
                md={1}
                style={{
                  //   alignItems: "left",
                  paddingLeft: "1px",
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <Button
                  type="primary"
                  size="large"
                  onClick={() => handleSubmit()}
                >
                  {createAmenityLoading ? <Spin indicator={antIcon} /> : "Save"}
                </Button>
              </Col>
            </Row>
            <Divider />
            {/* <p>{amenities}</p> */}
            <Table
              columns={columns}
              dataSource={amenities}
              loading={amenitysLoading}
            />
          </Card>
        </Col>
      </Row>

      <Modal
        title="Edit Amenities"
        visible={isEditModalVisible}
        onCancel={handleEditModalCancel}
        footer={[
          <Button key="back" onClick={handleEditModalCancel}>
            cancel
          </Button>,
          <Button key="submit" type="primary" onClick={() => handleUpdate()}>
            Update
          </Button>,
        ]}
        width={600}
      >
        <Content className="p-0">
          <Form
            name="basic"
            initialValues={{ remember: true }}
            // onFinish={onFinish}
            // onFinishFailed={onFinishFailed}
            layout="vertical"
            className="row-col"
          >
            <Row>
              <Col
                xs={24}
                xl={24}
                style={{
                  padding: "1em",
                }}
              >
                <label>Amenity</label>
                <Form.Item>
                 <Input
                  name="name"
                  value={rowData.name}
                  onChange={(e) => setRowData({...rowData, [e.target.name]:e.target.value})}
                  placeholder="Edit name"
                  style={{ width: "100%" }}
                />   
                </Form.Item>             
              </Col>
            </Row>
            <Row>
              <Col
                xs={24}
                xl={24}
                style={{
                  padding: "1em",
                }}
              >
                <label>Status</label>
                <Select
                  className="status"
                  label="Status"
                  onChange={handleChangeStatus}
                  rules={[
                    {
                      required: true,
                      message: "Please input Status!",
                    },
                  ]}
                  style={{ width: "100%" }}
                  size="large"          
                  value={status}
                >
                  <Option value="ACTIVE">Active</Option>
                  <Option value="INACTIVE">InActive</Option>
                </Select>
                {/* </Form.Item> */}
              </Col>
            </Row>            
          </Form>
        </Content>
      </Modal>

      <Modal
        title="Delete Route"
        visible={isDeleteModalVisible}
        onCancel={handleDeleteModalCancel}
        footer={[
          <Button key="back" onClick={handleDeleteModalCancel}>
            cancel
          </Button>,
          <Button
            key="submit"
            danger
            type="primary"
            onClick={() => handleDelete()}
          >
            Delete
          </Button>,
        ]}
      >
        <p>Are you sure?</p>
        <p>you want to delete the following Service?</p>
        <p>Service Name - {rowData.name}</p>
      </Modal>
    </div>
  );
}

export default Services;
