import axios from "axios";
import { CustomerTypes } from "./types";

import { busAdminTokenKey, BASE_URI } from "../../config/key.config";

export const fetchCustomerStart = () => ({
  type: CustomerTypes.CUSTOMER_PENDING,
});

export const fetchCustomerSuccess = (customer) => ({
  type: CustomerTypes.CUSTOMER_SUCCESS,
  payload: {
    customer,
  },
});

export const fetchCustomerFail = (error) => ({
  type: CustomerTypes.CUSTOMER_PENDING,
  payload: {
    error,
  },
});

//List of customers
export const fetchCustomersStart = () => ({
  type: CustomerTypes.CUSTOMERS_PENDING,
});

export const fetchCustomersSuccess = (customers) => ({
  type: CustomerTypes.CUSTOMERS_SUCCESS,
  payload: {
    customers,
  },
});

export const fetchCustomersFail = (error) => ({
  type: CustomerTypes.CUSTOMERS_PENDING,
  payload: {
    error,
  },
});

export const getCustomers = (dispatch) => {
  dispatch(fetchCustomersStart());

  axios
    .get(`${BASE_URI}/customers`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) => {
      dispatch(fetchCustomersSuccess(res.data));
    })
    .catch((err) => {
      dispatch(fetchCustomersFail(err));
    });
};

export const getCustomer = (id) => (dispatch) => {
  dispatch(fetchCustomerStart());

  axios
    .get(`${BASE_URI}/customers/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) => {      
      dispatch(fetchCustomerSuccess(res.data));
    })
    .catch((err) => {
      dispatch(fetchCustomerFail(err));
    });
};
