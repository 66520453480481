import { store } from "./store";
import { Provider } from "react-redux";
import "antd/dist/antd.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import jwt_decode from "jwt-decode";

import setAuthToken from "./utils/setAuthToken";
import { setCurrentUser } from "./redux/auth/action";

import Navigation from "./navigation";

if (localStorage.busAdminTokenKey) {
  // Set auth token header auth
  setAuthToken(localStorage.busAdminTokenKey);
  // Decode token and get user info and exp
  const decoded = jwt_decode(localStorage.busAdminTokenKey);
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));
}
function App() {
  return (
    <Provider store={store}>
      <Navigation />
    </Provider>
  );
}

export default App;
