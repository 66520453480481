export const RouteTypes = {
  ROUTE_PENDING: "ROUTE_PENDING",
  ROUTE_SUCCESS: "ROUTE_SUCCESS",
  ROUTE_FAIL: "ROUTE_FAIL",
  CLEAR_ROUTE: "CLEAR_ROUTE",

  ROUTES_PENDING: "ROUTES_PENDING",
  ROUTES_SUCCESS: "ROUTES_SUCCESS",
  ROUTES_FAIL: "ROUTES_FAIL",
  CLEAR_ROUTES: "CLEAR_ROUTES",

  ROUTES_STATUS_PENDING: "ROUTES_STATUS_PENDING",
  ROUTES_STATUS_SUCCESS: "ROUTES_STATUS_SUCCESS",
  ROUTES_STATUS_FAIL: "ROUTES_STATUS_FAIL",
  CLEAR_STATUS_ROUTES: "CLEAR_STATUS_ROUTES",

  CREATE_ROUTE_PENDING: "CREATE_ROUTE_PENDING",
  CREATE_ROUTE_SUCCESS: "CREATE_ROUTE_SUCCESS",
  CREATE_ROUTE_FAIL: "CREATE_ROUTE_FAIL",
  CLEAR_CREATE_ROUTE: "CLEAR_CREATE_ROUTE",

  UPDATE_ROUTE_PENDING: "UPDATE_ROUTE_PENDING",
  UPDATE_ROUTE_SUCCESS: "UPDATE_ROUTE_SUCCESS",
  UPDATE_ROUTE_FAIL: "UPDATE_ROUTE_FAIL",
  CLEAR_UPDATE_ROUTE: "CLEAR_UPDATE_ROUTE",

  DELETE_ROUTE_PENDING: "DELETE_ROUTE_PENDING",
  DELETE_ROUTE_SUCCESS: "DELETE_ROUTE_SUCCESS",
  DELETE_ROUTE_FAIL: "DELETE_ROUTE_FAIL",
  CLEAR_DELETE_ROUTE: "CLEAR_DELETE_ROUTE",
};
