import axios from "axios";
import { CityTypes } from "./types";
import { busAdminTokenKey, BASE_URI } from "../../config/key.config";

export const fetchCityStart = () => ({
  type: CityTypes.CITY_PENDING,
});

export const fetchCitySuccess = (id, city) => ({
  type: CityTypes.CITY_SUCCESS,
  payload: {
    id,
    city,
  },
});

export const fetchCityFail = (error) => ({
  type: CityTypes.CITY_FAIL,
  payload: {
    error,
  },
});

export const clearFetchCity = () => ({
  type: CityTypes.CLEAR_CITY,
});

export const fetchCitysStart = () => ({
  type: CityTypes.CITYS_PENDING,
});

export const fetchCitysSuccess = (citys) => ({
  type: CityTypes.CITYS_SUCCESS,
  payload: {
    citys,
  },
});

export const fetchCityDropPoint = (cityDropPoint) => ({
  type: CityTypes.CITYS_SUCCESS,
  payload: {
    cityDropPoint,
  },
});

export const fetchCitysFail = (error) => ({
  type: CityTypes.CITYS_FAIL,
  payload: {
    error,
  },
});

export const clearFetchCitys = () => ({
  type: CityTypes.CLEAR_CITYS,
});
//TODO create Route
export const createCityStart = () => ({
  type: CityTypes.CREATE_CITY_PENDING,
});

export const createCitySuccess = (city) => ({
  type: CityTypes.CREATE_CITY_SUCCESS,
  payload: {
    city,
  },
});

export const createCityFail = (error) => ({
  type: CityTypes.CREATE_CITY_FAIL,
  payload: {
    error,
  },
});

export const createCreateCity = () => ({
  type: CityTypes.CREATE_CLEAR_CITY,
});

//TODO Update Route
export const updateCityStart = () => ({
  type: CityTypes.UPDATE_CITY_PENDING,
});

export const updateCitySuccess = (id, city) => ({
  type: CityTypes.UPDATE_CITY_SUCCESS,
  payload: {
    id,
    city,
  },
});

export const updateCityFail = (error) => ({
  type: CityTypes.UPDATE_CITY_FAIL,
  payload: {
    error,
  },
});

export const clearUpdateCity = () => ({
  type: CityTypes.UPDATE_CLEAR_CITY,
});

//TODO delete route
export const deleteCityStart = () => ({
  type: CityTypes.DELETE_CITY_PENDING,
});

export const deleteCitySuccess = (id) => ({
  type: CityTypes.DELETE_CITY_SUCCESS,
  payload: {
    id,
  },
});

export const deleteCityFail = (error) => ({
  type: CityTypes.DELETE_CITY_FAIL,
  payload: {
    error,
  },
});

export const clearDeleteCity = () => ({
  type: CityTypes.DELETE_CLEAR_CITY,
});

export const getCities = (dispatch) => {
  dispatch(fetchCitysStart());

  axios
    .get(`${BASE_URI}/city`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) => {
      dispatch(fetchCitysSuccess(res.data));
    })
    .catch((err) => {
      dispatch(fetchCitysFail(err));
    });
};

export const getCity = (id) => (dispatch) => {
  dispatch(fetchCityStart());

  axios
    .get(`${BASE_URI}/city/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) => {
      dispatch(fetchCitySuccess(id, res.data));
    })
    .catch((err) => {
      dispatch(fetchCityFail(err));
    });
};

export const createCity = (dispatch) => {
  dispatch(createCityStart());

  axios
    .post(`${BASE_URI}/city`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) => {
      dispatch(createCitySuccess(res.data));
    })
    .catch((err) => {
      dispatch(createCityFail(err));
    });
};

export const updateCity = (id) => (dispatch) => {
  dispatch(updateCityStart());

  axios
    .put(`${BASE_URI}/city/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) => {
      dispatch(updateCitySuccess(id, res.data));
    })
    .catch((err) => {
      dispatch(updateCityFail(err));
    });
};

export const deleteCity = (id) => (dispatch) => {
  dispatch(deleteCityStart());

  axios
    .delete(`${BASE_URI}/city/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) => {
      dispatch(deleteCitySuccess(id));
    })
    .catch((err) => {
      dispatch(deleteCityFail(err));
    });
};
