import { CancellationPolicyActionTypes } from "./types";

const InitialState = {
  error: null,
  cancellation: {},
  cancellations: [],

  cancellationLoading: false,
  cancellationSuccess: false,
  cancellationFail: false,

  cancellationsLoading: false,
  cancellationsSuccess: false,
  cancellationsFail: false,

  createCancellationLoading: false,
  createCancellationSuccess: false,
  createCancellationFail: false,

  updateCancellationLoading: false,
  updateCancellationSuccess: false,
  updateCancellationFail: false,

  deleteCancellationLoading: false,
  deleteCancellationSuccess: false,
  deleteCancellationFail: false,
};

const reducer = (state = InitialState, action) => {
  switch (action.type) {
    case CancellationPolicyActionTypes.CANCELLATION_PENDING:
      return {
        ...state,
        cancellationLoading: true,
      };
    case CancellationPolicyActionTypes.CANCELLATION_FAIL:
      return {
        ...state,
        cancellationLoading: false,
        cancellationSuccess: false,
        error: action.payload.error,
      };
    case CancellationPolicyActionTypes.CANCELLATION_SUCCESS:
      return {
        ...state,
        cancellationLoading: false,
        cancellationSuccess: true,
        cancellation: {
          ...state.cancellation,
          [action.payload.id]: action.payload.cancellation,
        },
      };
    case CancellationPolicyActionTypes.CLEAR_CANCELLATION:
      return {
        ...state,
        cancellationLoading: false,
        cancellationSuccess: false,
        cancellationFail: false,
      };
    case CancellationPolicyActionTypes.CANCELLATIONS_PENDING:
      return {
        ...state,
        cancellationsLoading: true,
      };
    case CancellationPolicyActionTypes.CANCELLATIONS_SUCCESS:
      return {
        ...state,
        cancellationsLoading: false,
        cancellationsSuccess: true,
        cancellations: action.payload.cancellations,
      };
    case CancellationPolicyActionTypes.CANCELLATIONS_FAIL:
      return {
        ...state,
        cancellationsLoading: false,
        cancellationsSuccess: false,
        error: action.payload.error,
      };
    case CancellationPolicyActionTypes.CLEAR_CANCELLATIONS:
      return {
        ...state,
        cancellationsLoading: false,
        cancellationsSuccess: false,
        cancellationsFail: false,
      };
    case CancellationPolicyActionTypes.CREATE_CANCELLATION_PENDING:
      return {
        ...state,
        createCancellationLoading: true,
      };
    case CancellationPolicyActionTypes.CREATE_CANCELLATION_SUCCESS:
      return {
        ...state,
        createCancellationLoading: false,
        createCancellationSuccess: true,
        cancellations: [action.payload.cancellation, ...state.cancellations],
      };
    case CancellationPolicyActionTypes.CREATE_CANCELLATION_FAIL:
      return {
        ...state,
        createCancellationLoading: false,
        createCancellationSuccess: false,
        error: action.payload.error,
      };
    case CancellationPolicyActionTypes.CLEAR_CREATE_CANCELLATION:
      return {
        ...state,
        createCancellationLoading: false,
        createCancellationSuccess: false,
        createCancellationFail: false,
      };
    case CancellationPolicyActionTypes.UPDATE_CANCELLATION_PENDING:
      return {
        ...state,
        updateCancellationLoading: true,
      };
    case CancellationPolicyActionTypes.UPDATE_CANCELLATION_SUCCESS:
      return {
        ...state,
        updateCancellationLoading: false,
        updateCancellationSuccess: true,
        cancellation: {
          ...state.cancellation,
          [action.payload.id]: action.payload.cancellation,
        },
      };
    case CancellationPolicyActionTypes.UPDATE_CANCELLATION_FAIL:
      return {
        ...state,
        updateCancellationLoading: false,
        updateCancellationSuccess: false,
        error: action.payload.error,
      };
    case CancellationPolicyActionTypes.CLEAR_UPDATE_CANCELLATION:
      return {
        ...state,
        updateCancellationLoading: false,
        updateCancellationSuccess: false,
        updateCancellationFail: false,
      };
    case CancellationPolicyActionTypes.DELETE_CANCELLATION_PENDING:
      return {
        ...state,
        deleteCancellationLoading: true,
      };
    case CancellationPolicyActionTypes.DELETE_CANCELLATION_SUCCESS:
      return {
        ...state,
        deleteCancellationLoading: false,
        deleteCancellationSuccess: true,
        cancellation: {},
      };
    case CancellationPolicyActionTypes.DELETE_CANCELLATION_FAIL:
      return {
        ...state,
        deleteCancellationLoading: false,
        deleteCancellationSuccess: false,
        error: action.payload.error,
      };
    case CancellationPolicyActionTypes.CLEAR_DELETE_CANCELLATION:
      return {
        ...state,
        deleteCancellationLoading: false,
        deleteCancellationSuccess: false,
        deleteCancellationFail: false,
      };
    default:
      return state;
  }
};

export default reducer;
