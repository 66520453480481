export const BookingTypes = {
  BOOKING_PENDING: "BOOKING_PENDING",
  BOOKING_SUCCESS: "BOOKING_SUCCESS",
  BOOKING_FAIL: "BOOKING_FAIL",
  CLEAR_BOOKING: "CLEAR_BOOKING",

  BOOKINGS_PENDING: "BOOKINGS_PENDING",
  BOOKINGS_SUCCESS: "BOOKINGS_SUCCESS",
  BOOKINGS_FAIL: "BOOKINGS_FAIL",
  CLEAR_BOOKINGS: "CLEAR_BOOKINGS",

  CREATE_BOOKING_PENDING: "CREATE_BOOKING_PENDING",
  CREATE_BOOKING_SUCCESS: "CREATE_BOOKING_SUCCESS",
  CREATE_BOOKING_FAIL: "CREATE_BOOKING_FAIL",
  CLEAR_CREATE_BOOKING: "CLEAR_CREATE_BOOKING",

  UPDATE_BOOKING_PENDING: "UPDATE_BOOKING_PENDING",
  UPDATE_BOOKING_SUCCESS: "UPDATE_BOOKING_SUCCESS",
  UPDATE_BOOKING_FAIL: "UPDATE_BOOKING_FAIL",
  CLEAR_UPDATE_BOOKING: "CLEAR_UPDATE_BOOKING",

  DELETE_BOOKING_PENDING: "DELETE_BOOKING_PENDING",
  DELETE_BOOKING_SUCCESS: "DELETE_BOOKING_SUCCESS",
  DELETE_BOOKING_FAIL: "DELETE_BOOKING_FAIL",
  CLEAR_DELETE_BOOKING: "CLEAR_DELETE_BOOKING",
};
