import axios from "axios";
import { DropOffPickUpTypes } from "./types";
import { busAdminTokenKey, BASE_URI } from "../../config/key.config";

export const fetchDropOffPickUpStart = () => ({
  type: DropOffPickUpTypes.DROP_OFF_PICKUP_PENDING,
});

export const fetchDropOffPickUpSuccess = (id, drop_pick) => ({
  type: DropOffPickUpTypes.DROP_OFF_PICKUP_SUCCESS,
  payload: {
    id,
    drop_pick,
  },
});

export const fetchDropOffPickUpFail = (error) => ({
  type: DropOffPickUpTypes.DROP_OFF_PICKUP_FAIL,
  payload: {
    error,
  },
});

export const clearFetchDropOffPickUp = () => ({
  type: DropOffPickUpTypes.CLEAR_DROP_OFF_PICKUP,
});

export const fetchDropOffPickUpsStart = () => ({
  type: DropOffPickUpTypes.DROP_OFF_PICKUPS_PENDING,
});

export const fetchDropOffPickUpsSuccess = (drop_picks) => ({
  type: DropOffPickUpTypes.DROP_OFF_PICKUPS_SUCCESS,
  payload: {
    drop_picks,
  },
});

export const fetchDropOffPickUpsFail = (error) => ({
  type: DropOffPickUpTypes.DROP_OFF_PICKUPS_FAIL,
  payload: {
    error,
  },
});

//PDropping Points//TODO

export const fetchDropPointsLoading = () => ({
  type: DropOffPickUpTypes.DROP_POINT_PENDING,
});
export const fetchDropPointsSuccess = (drops) => ({
  type: DropOffPickUpTypes.DROP_POINT_SUCCESS,
  payload: {
    drops,
  },
});

export const fetchDropPointsFail = (error) => ({
  type: DropOffPickUpTypes.DROP_POINT_FAIL,
  payload: {
    error,
  },
});

//PickPoint//TODO

export const fetchPickPointsLoading = () => ({
  type: DropOffPickUpTypes.PICK_POINT_PENDING,
});
export const fetchPickPointsSuccess = (picks) => ({
  type: DropOffPickUpTypes.PICK_POINT_SUCCESS,
  payload: {
    picks,
  },
});

export const fetchPickPointsFail = (error) => ({
  type: DropOffPickUpTypes.PICK_POINT_FAIL,
  payload: {
    error,
  },
});

export const clearFetchDropOffPickUps = () => ({
  type: DropOffPickUpTypes.CLEAR_DROP_OFF_PICKUPS,
});
//TODO create Route
export const createDropOffPickUpStart = () => ({
  type: DropOffPickUpTypes.CREATE_DROP_OFF_PICKUP_PENDING,
});

export const createDropOffPickUpSuccess = (drop_pick) => ({
  type: DropOffPickUpTypes.CREATE_DROP_OFF_PICKUP_SUCCESS,
  payload: {
    drop_pick,
  },
});

export const createDropOffPickUpFail = (error) => ({
  type: DropOffPickUpTypes.CREATE_DROP_OFF_PICKUP_FAIL,
  payload: {
    error,
  },
});

export const createCreateDropOffPickUp = () => ({
  type: DropOffPickUpTypes.CREATE_CLEAR_DROP_OFF_PICKUP,
});

//TODO Update Route
export const updateDropOffPickUpStart = () => ({
  type: DropOffPickUpTypes.UPDATE_DROP_OFF_PICKUP_PENDING,
});

export const updateDropOffPickUpSuccess = (id, drop_pick) => ({
  type: DropOffPickUpTypes.UPDATE_DROP_OFF_PICKUP_SUCCESS,
  payload: {
    id,
    drop_pick,
  },
});

export const updateDropOffPickUpFail = (error) => ({
  type: DropOffPickUpTypes.UPDATE_DROP_OFF_PICKUP_FAIL,
  payload: {
    error,
  },
});

export const clearUpdateDropOffPickUp = () => ({
  type: DropOffPickUpTypes.UPDATE_CLEAR_DROP_OFF_PICKUP,
});

//TODO delete route
export const deleteDropOffPickUpStart = () => ({
  type: DropOffPickUpTypes.DELETE_DROP_OFF_PICKUP_PENDING,
});

export const deleteDropOffPickUpSuccess = (id) => ({
  type: DropOffPickUpTypes.DELETE_DROP_OFF_PICKUP_SUCCESS,
  payload: {
    id,
  },
});

export const deleteDropOffPickUpFail = (error) => ({
  type: DropOffPickUpTypes.DELETE_DROP_OFF_PICKUP_FAIL,
  payload: {
    error,
  },
});

export const clearDeleteDropOffPickUp = () => ({
  type: DropOffPickUpTypes.DELETE_CLEAR_DROP_OFF_PICKUP,
});

export const getDropOffPickUps = (dispatch) => {
  dispatch(fetchDropOffPickUpsStart());

  axios
    .get(`${BASE_URI}/dropoff-pickup`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) => {
      const response = res.data;
      const responseData = [];

      response.forEach((value) =>
        responseData.push({
          key: value.id,
          id: value.id,
          dropingPoint: value.name,
          city: value.city.name,
          cityId: value.city.id,
          status: value.status,
        })
      );

      dispatch(fetchDropOffPickUpsSuccess(responseData));
    })
    .catch((err) => {
      dispatch(fetchDropOffPickUpsFail(err));
    });
};

export const getPickUpPoint = (id) => (dispatch) => {
  dispatch(fetchPickPointsLoading());

  axios
    .get(`${BASE_URI}/dropoff-pickup`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) => {
      const response = res.data;
      let responseData = [];

      response.forEach((value) =>
        responseData.push({
          key: value.id,
          id: value.id,
          dropingPoint: value.name,
          city: value.city.name,
          cityId: value.city.id,
          status: value.status,
        })
      );

      responseData = responseData.filter((data) => data.cityId === id);

      dispatch(fetchPickPointsSuccess(responseData));
    })
    .catch((err) => {
      dispatch(fetchPickPointsFail(err));
    });
};

export const getDropPoint = (id) => (dispatch) => {
  dispatch(fetchDropPointsLoading());

  axios
    .get(`${BASE_URI}/dropoff-pickup`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) => {
      const response = res.data;
      let responseData = [];

      response.forEach((value) =>
        responseData.push({
          key: value.id,
          id: value.id,
          dropingPoint: value.name,
          city: value.city.name,
          cityId: value.city.id,
          status: value.status,
        })
      );

      responseData = responseData.filter((data) => data.cityId === id);
      dispatch(fetchDropPointsSuccess(responseData));
    })
    .catch((err) => {
      dispatch(fetchDropPointsFail(err));
    });
};

export const getDropOffPickUp = (id) => (dispatch) => {
  dispatch(fetchDropOffPickUpStart());

  axios
    .get(`${BASE_URI}/dropoff-pickup/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) => {
      dispatch(fetchDropOffPickUpSuccess(id, res.data));
    })
    .catch((err) => {
      dispatch(fetchDropOffPickUpFail(err));
    });
};

export const createDropOffPickUp =
  ({ name, city }, history) =>
  (dispatch) => {
    dispatch(createDropOffPickUpStart());

    axios
      .post(
        `${BASE_URI}/dropoff-pickup`,
        { name, city },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
          },
        }
      )
      .then((res) => {
        dispatch(createDropOffPickUpSuccess(res.data));
        // dispatch(getDropOffPickUps());

        history.push("/director/locations");
        // window.location.href = "http://localhost:3000/director/locations";
      })
      .catch((err) => {
        dispatch(createDropOffPickUpFail(err));
      });
  };

export const updateDropOffPickUp = (formData, id) => (dispatch) => {
  dispatch(updateDropOffPickUpStart());

  axios
    .put(`${BASE_URI}/dropoff-pickup/${id}`, formData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) => {
      dispatch(updateDropOffPickUpSuccess(id, res.data));
    })
    .catch((err) => {
      dispatch(updateDropOffPickUpFail(err));
    });
};

export const deleteDropOffPickUp = (id) => (dispatch) => {
  dispatch(deleteDropOffPickUpStart());

  axios
    .delete(`${BASE_URI}/dropoff-pickup/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) => {
      dispatch(deleteDropOffPickUpSuccess(id));
    })
    .catch((err) => {
      dispatch(deleteDropOffPickUpFail(err));
    });
};
