import React, { useState } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Table,
  Space,
  Modal,
  Layout,
  Form,
  Input,
  Select,
  Badge,
} from "antd";
import { Link } from "react-router-dom";
import Highlighter from "react-highlight-words";
import {
  EditOutlined,
  UserAddOutlined,
  DeleteOutlined,
  SearchOutlined,
} from "@ant-design/icons";
//
const { Content } = Layout;
const { Option } = Select;
const data = [
  {
    id: "1",
    bankName: "Abyssinia",
    accountName: "Tesfa",
    accountNumber: "123123",
    status: [
      <Badge
        className="site-badge-count-109"
        count={"Active"}
        style={{ backgroundColor: "#52c41a" }}
      />,
      // <Badge count={"Inactive"} />,
    ],
  },
  {
    id: "2",
    bankName: "Abay",
    accountName: "Melate",
    accountNumber: "10231033",
    status: [
      // <Badge
      //   className="site-badge-count-109"
      //   count={"Active"}
      //   style={{ backgroundColor: "#52c41a" }}
      // />,
      <Badge count={"Inactive"} />,
    ],
  },
  {
    id: "3",
    bankName: "Nibe",
    accountName: "Feyesa",
    accountNumber: "10101934",
    status: [<Badge count={"Inactive"} />],
  },
];
function RejectedTicketPage() {
  const [isEditModalVisible, setEditModalVisible] = useState(false);
  const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
  const [accountModal, setAccountModal] = useState({});

  function columns(showModal) {
    return [
      {
        title: "Id",
        dataIndex: "id",
        key: "id",
      },
      {
        title: "Bank Name",
        dataIndex: "bankName",
        key: "bankName",
        ...getColumnSearchProps("bankName"),
      },
      {
        title: "Account Name",
        dataIndex: "accountName",
        key: "accountName",
        ...getColumnSearchProps("accountName"),
      },
      {
        title: "Account Number",
        dataIndex: "accountNumber",
        key: "accountNumber",
        ...getColumnSearchProps("accountNumber"),
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        // ...getColumnSearchProps("status"),
      },
      {
        title: "Action",
        key: "action",
        render: (text, record) => (
          <Space size="middle">
            <Button
              style={{ background: "#eba134", borderColor: "#eba134" }}
              type="primary"
              shape="round"
              icon={<EditOutlined />}
              size="small"
              onClick={() => showModal(record)}
            />
            <Button
              style={{ background: "#db0925", borderColor: "#db0925" }}
              shape="round"
              type="primary"
              icon={<DeleteOutlined />}
              size="small"
              onClick={showModal}
            />
          </Space>
        ),
      },
    ];
  }
  // Handle edit modal
  const showEditModal = () => {
    setEditModalVisible(true);
  };

  const handleEditModalCancel = () => {
    setAccountModal({});
    setEditModalVisible(false);
  };

  // Handle delete modal
  const showDeleteModal = () => {
    setDeleteModalVisible(true);
  };

  const handleDeleteModalCancel = () => {
    setDeleteModalVisible(false);
  };

  // Form handle
  const onFinish = (values) => {};

  const onFinishFailed = (errorInfo) => {};

  const modalToggle = (record, rowIndex) => {
    setAccountModal(record);

    setEditModalVisible(true);
  };

  // TODO searching and sorting table functionality for Conductors page
  const [searchText, setSearchText] = useState(false);
  const [searchedColumn, setSearchedColumn] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [filteredInfo, setFilteredInfo] = useState(null);
  const [sortedInfo, setSortedInfo] = useState(null);
  let getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            setSearchInput(node);
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>

          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => setSearchInput(""), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  let handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  let handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
    setFilteredInfo(null);
    setSortedInfo(null);
  };

  // Columns data

  return (
    <div className="tabled">
      <Row gutter={[24, 0]}>
        <Col span={24}>
          <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title="Bank Accounts Information"
            extra={
              <Link to="new-bank">
                <Button type="primary" icon={<UserAddOutlined />} size="large">
                  Add Bank
                </Button>
              </Link>
            }
          >
            <div
              style={{
                display: "flex",
                padding: "2em 2em 2em 2em",
                width: "100%",
              }}
            >
              <Table
                style={{ width: "100%" }}
                dataSource={data}
                columns={columns(modalToggle)}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: (e) => {
                      modalToggle(record, rowIndex);
                    }, // click row
                  };
                }}
                scroll={{ x: 400 }}
              />
            </div>
          </Card>
        </Col>
      </Row>

      {/*
        Edit Conductor Modal
      */}
      <Modal
        title="Edit Account"
        visible={isEditModalVisible}
        onCancel={handleEditModalCancel}
        footer={[
          <Button key="back" onClick={handleEditModalCancel}>
            cancel
          </Button>,
          <Button key="submit" type="primary">
            Update
          </Button>,
        ]}
        width={1000}
      >
        <Content className="p-0">
          <Form
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
            className="row-col"
          >
            <Row>
              <Col
                xs="8"
                xl={8}
                style={{
                  padding: "1em",
                }}
              >
                <Form.Item
                  className="bankName"
                  label="Bank Name"
                  name="bankName"
                  rules={[
                    {
                      required: true,
                      message: "Please input Bank name!",
                    },
                  ]}
                >
                  <Input
                    placeholder="Bank Name..."
                    defaultValue={accountModal.bankName}
                  />
                </Form.Item>
              </Col>
              <Col
                xs={8}
                xl={8}
                style={{
                  padding: "1em",
                }}
              >
                <Form.Item
                  className="accountName"
                  label="Account Name"
                  name="accountName"
                  rules={[
                    {
                      required: true,
                      message: "Please input Account Name!",
                    },
                  ]}
                >
                  <Input defaultValue={accountModal.accountName} />
                </Form.Item>
              </Col>
              <Col
                xs={8}
                xl={8}
                style={{
                  padding: "1em",
                }}
              >
                <Form.Item
                  className="accountNumber"
                  label="Account Number"
                  name="accountNumber"
                  rules={[
                    {
                      required: true,
                      message: "Please input Account Number!",
                    },
                  ]}
                >
                  <Input
                    placeholder="Account Number..."
                    defaultValue={accountModal.accountNumber}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col
                xs="8"
                xl={8}
                style={{
                  padding: "1em",
                }}
              >
                <Form.Item
                  className="status"
                  label="Status"
                  name="status"
                  rules={[
                    {
                      required: true,
                      message: "Please input the Status!",
                    },
                  ]}
                >
                  <Select
                    defaultValue="active"
                    // style={{ width: 120 }}
                    // onChange={handleChange}
                    // defaultValue={accountModal.status}
                  >
                    <Option value="active">Active</Option>
                    <Option value="inactive">InActive</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Content>
      </Modal>

      {/*
        Delete Conductor Modal
      */}
      <Modal
        title="Delete Conductor"
        visible={isDeleteModalVisible}
        onCancel={handleDeleteModalCancel}
        footer={[
          <Button key="back" onClick={handleDeleteModalCancel}>
            cancel
          </Button>,
          <Button key="submit" danger type="primary">
            Delete
          </Button>,
        ]}
      >
        <p>Are you sure?</p>
        <p>you want to delete the following Account ?</p>
        <p>Account Name Full Name</p>
      </Modal>
    </div>
  );
}

export default RejectedTicketPage;
