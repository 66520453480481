import { BookingTypes } from "./types";

const INITIAL_STATE = {
  error: null,
  id: "",
  booking: {},
  bookings: [],
  bookedBookings: [],
  pendingBookings: [],
  rejectedBookings: [],

  bookingLoading: false,
  bookingSuccess: false,
  bookingFail: false,

  bookingsLoading: false,
  bookingsSuccess: false,
  bookingsFail: false,

  createBookingLoading: false,
  createBookingSuccess: false,
  createBookingFail: false,

  updateBookingLoading: false,
  updateBookingSuccess: false,
  updateBookingFail: false,

  deleteBookingLoading: false,
  deleteBookingSuccess: false,
  deleteBookingFail: false,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case BookingTypes.BOOKING_PENDING:
      return {
        ...state,
        bookingLoading: true,
      };
    case BookingTypes.BOOKING_SUCCESS:
      return {
        ...state,
        bookingSuccess: true,
        bookingLoading: false,
        id: action.payload.id,
        booking: action.payload.booking,
      };
    case BookingTypes.BOOKING_FAIL:
      return {
        ...state,
        bookingLoading: false,
        bookingSuccess: false,
        error: action.payload.error,
      };
    // case BookingTypes.CLEAR_BOOKING:
    //   return {
    //     ...state,
    //     bookingLoading: false,
    //     bookingSuccess: false,
    //     bookingFail: false,
    //   };

    case BookingTypes.BOOKINGS_PENDING:
      return {
        ...state,
        bookingsLoading: true,
      };
    case BookingTypes.BOOKINGS_SUCCESS:
      return {
        ...state,
        bookingsSuccess: true,
        bookingsLoading: false,
        booking: {},
        bookings: action.payload.bookings,
        bookedBookings: action.payload.bookedBookings,
        pendingBookings: action.payload.pendingBookings,
        rejectedBookings: action.payload.rejectedBookings,
      };
    case BookingTypes.BOOKINGS_FAIL:
      return {
        ...state,
        bookingsLoading: false,
        bookingsSuccess: false,
        error: action.payload.error,
      };
    // case BookingTypes.CLEAR_BOOKINGS:
    //   return {
    //     ...state,
    //     bookingsSuccess: false,
    //     bookingsLoading: false,
    //     bookingsFail: false,
    //   };

    case BookingTypes.CREATE_BOOKING_PENDING:
      return {
        ...state,
        createBookingLoading: true,
      };
    case BookingTypes.CREATE_BOOKING_SUCCESS:
      return {
        ...state,
        createBookingSuccess: true,
        createBookingLoading: false,
        bookings: [action.payload.booking, ...state.bookings],
      };
    case BookingTypes.CREATE_BOOKING_FAIL:
      return {
        ...state,
        createBookingLoading: false,
        createBookingSuccess: false,
        error: action.payload.error,
      };
    // case BookingTypes.CLEAR_CREATE_BOOKING:
    //   return {
    //     ...state,
    //     createBookingSuccess: false,
    //     createBookingLoading: false,
    //     createBookingFail: false,
    //   };

    case BookingTypes.UPDATE_BOOKING_PENDING:
      return {
        ...state,
        updateBookingLoading: true,
      };
    case BookingTypes.UPDATE_BOOKING_SUCCESS:
      return {
        ...state,
        updateBookingSuccess: true,
        updateBookingLoading: false,
        booking: {
          ...state.booking,
          [action.payload.id]: action.payload.booking,
        },
      };
    case BookingTypes.UPDATE_BOOKING_FAIL:
      return {
        ...state,
        updateBookingLoading: false,
        updateBookingFail: true,
        updateBookingSuccess: false,
        error: action.payload.error,
      };
    // case BookingTypes.CLEAR_UPDATE_BOOKING:
    //   return {
    //     ...state,
    //     updateBookingSuccess: false,
    //     updateBookingLoading: false,
    //     upateBookingFail: false,
    //   };

    case BookingTypes.DELETE_BOOKING_PENDING:
      return {
        ...state,
        deleteBookingLoading: true,
      };
    case BookingTypes.DELETE_BOOKING_SUCCESS:
      return {
        ...state,
        deleteBookingSuccess: true,
        deleteBookingLoading: false,
      };
    case BookingTypes.DELETE_BOOKING_FAIL:
      return {
        ...state,
        deleteBookingLoading: false,
        deleteBookingSuccess: false,
        error: action.payload.error,
      };
    // case BookingTypes.CLEAR_DELETE_BOOKING:
    //   return {
    //     ...state,
    //     deleteBookingSuccess: false,
    //     deleteBookingLoading: false,
    //     deleteBookingFail: false,
    //   };

    default:
      return state;
  }
};

export default reducer;
