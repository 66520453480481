import React from "react";
import { Col } from "antd";
import SeatIcon from "../../assets/logo/seatIcon.jpg";

const style = {
  background: "#fff",
};
function BusStructureFour() {
  return (
    <>
      <Col className="gutter-row" span={4}>
        <div style={style}>
          <img src={SeatIcon} alt="" />
        </div>
      </Col>
      <Col className="gutter-row" span={4}>
        <div style={style}>
          <img src={SeatIcon} alt="" />
        </div>
      </Col>
      <Col className="gutter-row" span={4}>
        <div></div>
      </Col>
      <Col className="gutter-row" span={4}>
        <div style={style}>
          <img src={SeatIcon} alt="" />
        </div>
      </Col>
      <Col className="gutter-row" span={4}>
        <div style={style}>
          <img src={SeatIcon} alt="" />
        </div>
      </Col>
    </>
  );
}

function BusStructureFive() {
  return (
    <>
      <Col className="gutter-row" span={4}>
        <div style={style}>
          <img src={SeatIcon} alt="" />
        </div>
      </Col>
      <Col className="gutter-row" span={4}>
        <div style={style}>
          <img src={SeatIcon} alt="" />
        </div>
      </Col>
      <Col className="gutter-row" span={4}>
        <div style={style}>
          <img src={SeatIcon} alt="" />
        </div>
      </Col>
      <Col className="gutter-row" span={4}>
        <div>
          <img src={SeatIcon} alt="" />
        </div>
      </Col>
      <Col className="gutter-row" span={4}>
        <div style={style}>
          <img src={SeatIcon} alt="" />
        </div>
      </Col>
    </>
  );
}

function BusStructureTwo() {
  return (
    <>
      <Col className="gutter-row" span={4}>
        <div style={style}>
          <img src={SeatIcon} alt="" />
        </div>
      </Col>
      <Col className="gutter-row" span={4}>
        <div style={style}>
          <img src={SeatIcon} alt="" />
        </div>
      </Col>
    </>
  );
}

function BusStructureOne() {
  return (
    <>
      <Col className="gutter-row" span={4}>
        <div style={style}>
          <img src={SeatIcon} alt="" />
        </div>
      </Col>
    </>
  );
}
export {
  BusStructureOne,
  BusStructureTwo,
  //   BusStructureThree,
  BusStructureFour,
  BusStructureFive,
};
