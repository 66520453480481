import { Layout, Row, Col, Button, Card, Form, Input } from "antd";
const { Content } = Layout;

function NewBooking() {
  const onFinish = (values) => {
  };

  const onFinishFailed = (errorInfo) => {
  };
  return (
    <div className="tabled">
      <Row gutter={[24, 0]}>
        <Col xs="24" xl={24}>
          <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title="Add New Booking Information"
          >
            <div
              style={{
                display: "flex",
                padding: "2em 2em 2em 2em",
                width: "100%",
              }}
            >
              <Content className="p-0">
                <Form
                  name="basic"
                  initialValues={{ remember: true }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  layout="vertical"
                  className="row-col"
                >
                  <Row>
                    <Col
                      xs="8"
                      xl={8}
                      style={{
                        padding: "1em",
                      }}
                    >
                      <Form.Item
                        className="name"
                        label="Name"
                        name="name"
                        rules={[
                          {
                            required: true,
                            message: "Please input name!",
                          },
                        ]}
                      >
                        <Input placeholder="Name..." />
                      </Form.Item>
                    </Col>
                    <Col
                      xs="8"
                      xl={8}
                      style={{
                        padding: "1em",
                      }}
                    >
                      <Form.Item
                        className="routeName"
                        label="Route Name"
                        name="routeName"
                        rules={[
                          {
                            required: true,
                            message: "Please input Route Name!",
                          },
                        ]}
                      >
                        <Input placeholder="Route name..." />
                      </Form.Item>
                    </Col>
                    <Col
                      xs="8"
                      xl={8}
                      style={{
                        padding: "1em",
                      }}
                    >
                      <Form.Item
                        className="totalSeat"
                        label="Total Seat"
                        name="totalSeat"
                        rules={[
                          {
                            required: true,
                            message: "Please input Total Seat!",
                          },
                        ]}
                      >
                        <Input placeholder="Total Seat..." />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      xs="8"
                      xl={8}
                      style={{
                        padding: "1em",
                      }}
                    >
                      <Form.Item
                        className="paymentStatus"
                        label="Payment Status"
                        name="paymentStatus"
                        rules={[
                          {
                            required: true,
                            message: "Please input Payment Status!",
                          },
                        ]}
                      >
                        <Input placeholder="Payment Status..." />
                      </Form.Item>
                    </Col>
                    <Col
                      xs="8"
                      xl={8}
                      style={{
                        padding: "1em",
                      }}
                    >
                      <Form.Item
                        className="price"
                        label="Price"
                        name="price"
                        rules={[
                          {
                            required: true,
                            message: "Please input Price!",
                          },
                        ]}
                      >
                        <Input placeholder="Price..." />
                      </Form.Item>
                    </Col>
                    <Col
                      xs="8"
                      xl={8}
                      style={{
                        padding: "1em",
                      }}
                    >
                      <Form.Item
                        className="totalSeat"
                        label="Total Seat"
                        name="totalSeat"
                        rules={[
                          {
                            required: true,
                            message: "Please input Total Seat!",
                          },
                        ]}
                      >
                        <Input placeholder="Total Seat..." />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Form.Item>
                    <div
                      style={{
                        paddingTop: 30,
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        type="primary"
                        htmlType="submit"
                        style={{
                          width: "20%",
                        }}
                      >
                        Register
                      </Button>
                    </div>
                  </Form.Item>
                </Form>
              </Content>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default NewBooking;
