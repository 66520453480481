import {
  GET_ACCOUNTS,
  ADD_ACCOUNT,
  DELETE_ACCOUNT,
  UPDATE_ACCOUNT,
  ERROR_ACCOUNT,
} from "./types";

const initialState = {
  accounts: [
    {
      id: "1",
      bankName: "Dashen Bank ",
      accountName: "Public Bus",
      accountNumber: "5155310696011",
      status: "ACTIVE",
    },
    {
      id: "2",
      bankName: "Abay Bank",
      accountName: "Public Bus",
      accountNumber: "1651117861444013",
      status: "ACTIVE",
    },
    {
      id: "3",
      bankName: "Debub Global Bank",
      accountName: "Public Bus",
      accountNumber: "1092106061011",
      status: "ACTIVE",
    },
    {
      id: "4",
      bankName: "Wegagen Bank",
      accountName: "Public Bus",
      accountNumber: "0921594830101",
      status: "ACTIVE",
    },
    {
      id: "5",
      bankName: "Cooperative Bank Of Oromia",
      accountName: "Public Bus",
      accountNumber: "1000400067795",
      status: "ACTIVE",
    },
    {
      id: "6",
      bankName: "Awash Bank",
      accountName: "Public Bus",
      accountNumber: "01321726003000",
      status: "ACTIVE",
    },
    {
      id: "6",
      bankName: "Oromia Bank",
      accountName: "Public Bus",
      accountNumber: "1495092900002",
      status: "ACTIVE",
    },
    {
      id: "6",
      bankName: "Enat Bank",
      accountName: "Public Bus",
      accountNumber: "0261123695340001",
      status: "ACTIVE",
    },
    {
      id: "6",
      bankName: "Abyssinia Bank",
      accountName: "Public Bus",
      accountNumber: "92800814",
      status: "ACTIVE",
    },
  ],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ACCOUNTS:
      return {
        ...state,
        // accounts: action.payload
      };

    case ADD_ACCOUNT:
      return {
        ...state,
        accounts: [action.payload, ...state.accounts],
      };

    case DELETE_ACCOUNT:
      return {
        ...state,
        accounts: state.accounts.filter((acc) => acc.id !== action.payload),
      };

    case UPDATE_ACCOUNT:
      const index = state.accounts.findIndex(
        (acc) => acc.id === action.payload.id
      );
      state.accounts[index] = { ...state.accounts[index], ...action.payload };
      return {
        ...state,
        accounts: state.accounts,
      };

    case ERROR_ACCOUNT:
      return state;

    default:
      return state;
  }
}
