import React, { Component } from "react";
import SeatPicker from "react-seat-picker";
import { Input, Button } from "antd";

class Seat47 extends Component {
  constructor(props) {
    super(props);
    const arr = props.seats ? props.seats.split(",") : [];

    this.state = {
      loading: false,
      seats: arr,
    };
  }

  addSeatCallback = ({ row, number, id }, addCb) => {
    const { seats } = this.state;
    seats.push(id);
    this.setState(async () => {
      await new Promise((resolve) => setTimeout(resolve, 100));

      addCb(row, number, id);
      this.setState({ loading: false });
    });
  };

  removeSeatCallback = ({ row, number, id }, removeCb) => {
    this.setState((state) => {
      return { seats: state.seats.filter((value) => value != number) };
    });
    this.setState(async () => {
      await new Promise((resolve) => setTimeout(resolve, 100));
      const newTooltip = this.state.seats.includes(number) ? null : "";
      removeCb(row, number, newTooltip);
    });
  };

  render() {
    const rows = [
      [
        {
          id: 1,
          number: "1",
          isSelected: this.state.seats.includes("1") ? true : false,
        },
        {
          id: 2,
          number: "2",
          isSelected: this.state.seats.includes("2") ? true : false,
        },
        null,
        {
          id: 3,
          number: "3",
          isSelected: this.state.seats.includes("3") ? true : false,
        },
        {
          id: 4,
          number: "4",
          isSelected: this.state.seats.includes("4") ? true : false,
        },
      ],
      [
        {
          id: 5,
          number: "5",
          isSelected: this.state.seats.includes("5") ? true : false,
        },
        {
          id: 6,
          number: "6",
          isSelected: this.state.seats.includes("6") ? true : false,
        },
        null,
        {
          id: 7,
          number: "7",
          isSelected: this.state.seats.includes("7") ? true : false,
        },
        {
          id: 8,
          number: "8",
          isSelected: this.state.seats.includes("8") ? true : false,
        },
      ],
      [
        {
          id: 9,
          number: "9",
          isSelected: this.state.seats.includes("9") ? true : false,
        },
        {
          id: 10,
          number: "10",
          isSelected: this.state.seats.includes("10") ? true : false,
        },
        null,
        null,
        null,
      ],
      [
        {
          id: 11,
          number: "11",
          isSelected: this.state.seats.includes("11") ? true : false,
        },
        {
          id: 12,
          number: "12",
          isSelected: this.state.seats.includes("12") ? true : false,
        },
        null,
        null,
        null,
      ],
      [
        {
          id: 13,
          number: "13",
          isSelected: this.state.seats.includes("13") ? true : false,
        },
        {
          id: 14,
          number: "14",
          isSelected: this.state.seats.includes("14") ? true : false,
        },
        null,
        {
          id: 15,
          number: "15",
          isSelected: this.state.seats.includes("15") ? true : false,
        },
        {
          id: 16,
          number: "16",
          isSelected: this.state.seats.includes("16") ? true : false,
        },
      ],
      [
        {
          id: 17,
          number: "17",
          isSelected: this.state.seats.includes("17") ? true : false,
        },
        {
          id: 18,
          number: "18",
          isSelected: this.state.seats.includes("18") ? true : false,
        },
        null,
        {
          id: 19,
          number: "19",
          isSelected: this.state.seats.includes("19") ? true : false,
        },
        {
          id: 20,
          number: "20",
          isSelected: this.state.seats.includes("20") ? true : false,
        },
      ],
      [
        {
          id: 21,
          number: "21",
          isSelected: this.state.seats.includes("21") ? true : false,
        },
        {
          id: 22,
          number: "22",
          isSelected: this.state.seats.includes("22") ? true : false,
        },
        null,
        null,
        null,
      ],
      [
        {
          id: 23,
          number: "23",
          isSelected: this.state.seats.includes("23") ? true : false,
        },
        {
          id: 24,
          number: "24",
          isSelected: this.state.seats.includes("24") ? true : false,
        },
        null,
        {
          id: 25,
          number: "25",
          isSelected: this.state.seats.includes("25") ? true : false,
        },
        {
          id: 26,
          number: "26",
          isSelected: this.state.seats.includes("26") ? true : false,
        },
      ],

      [
        {
          id: 27,
          number: "27",
          isSelected: this.state.seats.includes("27") ? true : false,
        },
        {
          id: 28,
          number: "28",
          isSelected: this.state.seats.includes("28") ? true : false,
        },
        null,
        {
          id: 29,
          number: "29",
          isSelected: this.state.seats.includes("29") ? true : false,
        },
        {
          id: 30,
          number: "30",
          isSelected: this.state.seats.includes("30") ? true : false,
        },
      ],
      [
        {
          id: 31,
          number: "31",
          isSelected: this.state.seats.includes("31") ? true : false,
        },
        {
          id: 32,
          number: "32",
          isSelected: this.state.seats.includes("32") ? true : false,
        },
        null,
        {
          id: 33,
          number: "33",
          isSelected: this.state.seats.includes("33") ? true : false,
        },
        {
          id: 34,
          number: "34",
          isSelected: this.state.seats.includes("34") ? true : false,
        },
      ],
      [
        {
          id: 35,
          number: "35",
          isSelected: this.state.seats.includes("35") ? true : false,
        },
        {
          id: 36,
          number: "36",
          isSelected: this.state.seats.includes("36") ? true : false,
        },
        null,
        {
          id: 37,
          number: "37",
          isSelected: this.state.seats.includes("37") ? true : false,
        },
        {
          id: 38,
          number: "38",
          isSelected: this.state.seats.includes("38") ? true : false,
        },
      ],
      [
        {
          id: 39,
          number: "39",
          isSelected: this.state.seats.includes("39") ? true : false,
        },
        {
          id: 40,
          number: "40",
          isSelected: this.state.seats.includes("40") ? true : false,
        },
        null,
        {
          id: 41,
          number: "41",
          isSelected: this.state.seats.includes("41") ? true : false,
        },
        {
          id: 42,
          number: "42",
          isSelected: this.state.seats.includes("42") ? true : false,
        },
      ],
      [
        {
          id: 43,
          number: "43",
          isSelected: this.state.seats.includes("43") ? true : false,
        },
        {
          id: 44,
          number: "44",
          isSelected: this.state.seats.includes("44") ? true : false,
        },
        {
          id: 45,
          number: "45",
          isSelected: this.state.seats.includes("45") ? true : false,
        },
        {
          id: 46,
          number: "46",
          isSelected: this.state.seats.includes("46") ? true : false,
        },
        {
          id: 47,
          number: "47",
          isSelected: this.state.seats.includes("47") ? true : false,
        },
      ],
    ];
    return (
      <div>
        {/* <h1>Please Select Your Seats:</h1> */}
        <div
          style={{
            marginTop: "100px",
            width: "70%",
            display: "flex",
            // justifyContent: "space-around",
          }}
        >
          <SeatPicker
            addSeatCallback={this.addSeatCallback}
            removeSeatCallback={this.removeSeatCallback}
            rows={rows}
            maxReservableSeats={49}
            // alpha
            visible
            selectedByDefault
            // loading={loading}
            tooltipProps={{ multiline: true }}
            style={{ padding: "10rem" }}
            continuous
          />
          <div>
            <Input
              value={this.state.seats}
              placeholder="Enter Seat to Block"
              disabled
            />
            <Button
              type="primary"
              size="large"
              style={{ marginTop: "1rem" }}
              onClick={() => this.props.handleBlockSeat(this.state.seats)}
            >
              Block
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
export default Seat47;
