import { AmenityActionTypes } from "./types";

const InitialState = {
  error: null,
  amenity: {},
  amenities: [],

  amenityLoading: false,
  amenitySuccess: false,
  amenityFail: false,

  amenitysLoading: false,
  amenitysSuccess: false,
  amenitysFail: false,

  createAmenityLoading: false,
  createAmenitySuccess: false,
  createAmenityFail: false,

  updateAmenityLoading: false,
  updateAmenitySuccess: false,
  updateAmenityFail: false,

  deleteAmenityLoading: false,
  deleteAmenitySuccess: false,
  deleteAmenityFail: false,
};

const reducer = (state = InitialState, action) => {
  switch (action.type) {
    case AmenityActionTypes.AMENITY_PENDING:
      return {
        ...state,
        amenityLoading: true,
      };
    case AmenityActionTypes.AMENITY_FAIL:
      return {
        ...state,
        amenityLoading: false,
        amenitySuccess: false,
        error: action.payload.error,
      };
    case AmenityActionTypes.AMENITY_SUCCESS:
      return {
        ...state,
        amenityLoading: false,
        amenitySuccess: true,
        amenity: {
          ...state.amenity,
          [action.payload.id]: action.payload.amenity,
        },
      };
    case AmenityActionTypes.CLEAR_AMENITY:
      return {
        ...state,
        amenityLoading: false,
        amenitySuccess: false,
        amenityFail: false,
      };
    case AmenityActionTypes.AMENITYS_PENDING:
      return {
        ...state,
        amenitysLoading: true,
      };
    case AmenityActionTypes.AMENITYS_SUCCESS:
      return {
        ...state,
        amenitysLoading: false,
        amenitysSuccess: true,
        amenities: action.payload.amenities,
      };
    case AmenityActionTypes.AMENITYS_FAIL:
      return {
        ...state,
        amenitysLoading: false,
        amenitysSuccess: false,
        error: action.payload.error,
      };
    case AmenityActionTypes.CLEAR_AMENITYS:
      return {
        ...state,
        amenitysLoading: false,
        amenitysSuccess: false,
        amenitysFail: false,
      };
    case AmenityActionTypes.CREATE_AMENITY_PENDING:
      return {
        ...state,
        createAmenityLoading: true,
      };
    case AmenityActionTypes.CREATE_AMENITY_SUCCESS:
      return {
        ...state,
        createAmenityLoading: false,
        createAmenitySuccess: true,
        amenities: [action.payload.amenity, ...state.amenities],
      };
    case AmenityActionTypes.CREATE_AMENITY_FAIL:
      return {
        ...state,
        createAmenityLoading: false,
        createAmenitySuccess: false,
        error: action.payload.error,
      };
    case AmenityActionTypes.CLEAR_CREATE_AMENITY:
      return {
        ...state,
        createAmenityLoading: false,
        createAmenitySuccess: false,
        createAmenityFail: false,
      };
    case AmenityActionTypes.UPDATE_AMENITY_PENDING:
      return {
        ...state,
        updateAmenityLoading: false,
      };
    case AmenityActionTypes.UPDATE_AMENITY_SUCCESS:
      return {
        ...state,
        updateAmenityLoading: false,
        updateAmenitySuccess: true,
        amenity: {
          ...state.amenity,
          [action.payload.id]: action.payload.amenity,
        },
      };
    case AmenityActionTypes.UPDATE_AMENITY_FAIL:
      return {
        ...state,
        updateAmenityLoading: false,
        updateAmenitySuccess: false,
        error: action.payload.error,
      };
    case AmenityActionTypes.CLEAR_UPDATE_AMENITY:
      return {
        ...state,
        updateAmenityLoading: false,
        updateAmenitySuccess: false,
        updateAmenityFail: false,
      };
    case AmenityActionTypes.DELETE_AMENITY_PENDING:
      return {
        ...state,
        deleteAmenityLoading: true,
      };
    case AmenityActionTypes.DELETE_AMENITY_SUCCESS:
      return {
        ...state,
        deleteAmenityLoading: false,
        deleteAmenitySuccess: true,
        amenity: {},
      };
    case AmenityActionTypes.DELETE_AMENITY_FAIL:
      return {
        ...state,
        deleteAmenityLoading: false,
        deleteAmenitySuccess: false,
        error: action.payload.error,
      };
    case AmenityActionTypes.CLEAR_DELETE_AMENITY:
      return {
        ...state,
        deleteAmenityLoading: false,
        deleteAmenitySuccess: false,
        deleteAmenityFail: false,
      };
    default:
      return state;
  }
};

export default reducer;
