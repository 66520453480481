import { useHistory } from "react-router-dom";
import { Button, Result } from "antd";
import jwt_decode from "jwt-decode";
import { busAdminTokenKey } from "../../config/key.config";

const PageNotFound = () => {
  const history = useHistory();  
  const tokenCheck = localStorage.getItem(busAdminTokenKey);
  const userRole = tokenCheck ? jwt_decode(tokenCheck) : null;    

  const handleBackBtn = () => {        
    // if (userRole.user.role === "user_director") {
    //   history.push("/director/dashboard");
    // } else if (userRole.user.role === "user_basic") {
    //   history.push("/basic-access/dashboard");
    // } else if (userRole.user.role === "user_finance") {
    //   history.push("/finance/dashboard");
    // } else if (userRole.user.role === "user_oprator") {
    //   history.push("/operation/dashboard");
    // } else if (userRole.user.role === "user_reporter") {
    //   history.push("/reporter/dashboard");
    // }
  }

  return (
    <div className="d-flex justify-content-center align-items-center">
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={<Button type="primary" onClick={handleBackBtn}>Back Home</Button>}
      />
    </div>
  );
};

export default PageNotFound;
