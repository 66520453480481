import axios from "axios";
import { AmenityActionTypes } from "./types";
import { busAdminTokenKey, BASE_URI } from "../../config/key.config";

export const fetchAmenityStart = () => ({
  type: AmenityActionTypes.AMENITY_PENDING,
});

export const fetchAmenitySuccess = (amenity) => ({
  type: AmenityActionTypes.AMENITY_SUCCESS,
  payload: {
    amenity,
  },
});

export const fetchAmenityFailure = (error) => ({
  type: AmenityActionTypes.AMENITY_FAIL,
  payload: { error },
});

export const clearFetchAmenity = () => ({
  type: AmenityActionTypes.CLEAR_AMENITY,
});

export const fetchAmenitysStart = () => ({
  type: AmenityActionTypes.AMENITYS_PENDING,
});

export const fetchAmenitysSuccess = (amenities) => ({
  type: AmenityActionTypes.AMENITYS_SUCCESS,
  payload: { amenities },
});

export const fetchAmenitysFailure = (error) => ({
  type: AmenityActionTypes.AMENITYS_FAIL,
  payload: { error },
});

export const clearFetchAmenitys = () => ({
  type: AmenityActionTypes.CLEAR_AMENITYS,
});

export const createAmenityStart = () => ({
  type: AmenityActionTypes.CREATE_AMENITY_PENDING,
});

export const createAmenitySuccess = (amenity) => ({
  type: AmenityActionTypes.CREATE_AMENITY_SUCCESS,
  payload: { amenity },
});

export const createAmenityFail = (error) => ({
  type: AmenityActionTypes.CREATE_AMENITY_FAIL,
  payload: { error },
});

export const clearCreateAmenity = () => ({
  type: AmenityActionTypes.CLEAR_CREATE_Amenity,
});

export const updateAmenityStart = () => ({
  type: AmenityActionTypes.UPDATE_AMENITY_PENDING,
});

export const updateAmenitySuccess = (id, amenity) => ({
  type: AmenityActionTypes.UPDATE_AMENITY_SUCCESS,
  payload: {
    id,
    amenity,
  },
});
export const updateAmenityFail = (error) => ({
  type: AmenityActionTypes.UPDATE_AMENITY_FAIL,
  payload: { error },
});

export const clearUpdateAmenity = () => ({
  type: AmenityActionTypes.CLEAR_UPDATE_Amenity,
});

export const deleteAmenityStart = () => ({
  type: AmenityActionTypes.DELETE_AMENITY_PENDING,
});

export const deleteAmenitySuccess = (id) => ({
  type: AmenityActionTypes.DELETE_AMENITY_SUCCESS,
  payload: { id },
});

export const deleteAmenityFail = (error) => ({
  type: AmenityActionTypes.DELETE_AMENITY_FAIL,
  payload: { error },
});

export const clearDeleteAmenity = () => ({
  type: AmenityActionTypes.CLEAR_DELETE_CANCELLATION,
});

export const getAmenities = (dispatch) => {
  dispatch(fetchAmenitysStart());

  axios
    .get(`${BASE_URI}/amenities`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) => {
      const response = res.data;
      const responseData = [];

      response.forEach((data) =>
        responseData.push({
          key: data.id,
          id: data.id,
          name: data.name,
          status: data.status,
          // charge: `${data.charge} %`,
        })
      );
      dispatch(fetchAmenitysSuccess(responseData));
    })
    .catch((err) => dispatch(fetchAmenitysFailure(err)));
};

export const getAmenity = (id) => (dispatch) => {
  dispatch(fetchAmenityStart());

  axios
    .get(`${BASE_URI}/amenities/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) => dispatch(fetchAmenitySuccess(res.data)))
    .catch((err) => dispatch(fetchAmenityFailure(err)));
};

export const createAmenity = (formData, history) => (dispatch) => {
  dispatch(createAmenityStart());

  axios
    .post(`${BASE_URI}/amenities`, formData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) => {
      dispatch(createAmenitySuccess(res.data));
      history.push("/director/services");
    })
    .catch((err) => dispatch(createAmenityFail(err)));
};

export const updateAmenity = (id, formData) => (dispatch) => {
  dispatch(updateAmenityStart());

  axios
    .put(`${BASE_URI}/amenities/${id}`, formData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) => dispatch(updateAmenitySuccess(id, res.data)))
    .catch((err) => dispatch(updateAmenityFail(err)));
};

export const deleteAmenity = (id) => (dispatch) => {
  dispatch(deleteAmenityStart());

  axios
    .delete(`${BASE_URI}/amenities/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) => dispatch(deleteAmenitySuccess(id)))
    .catch((err) => dispatch(deleteAmenityFail(err)));
};
