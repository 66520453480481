import axios from "axios";
import { BookingTypes } from "./types";

import { busAdminTokenKey, BASE_URI } from "../../config/key.config";
//TODO single booking
export const fetchBookingStart = () => ({
  type: BookingTypes.BOOKING_PENDING,
});

export const fetchBookingSuccess = (id, booking) => ({
  type: BookingTypes.BOOKING_SUCCESS,
  payload: {
    id,
    booking,
  },
});

export const fetchBookingFail = (error) => ({
  type: BookingTypes.BOOKING_FAIL,
  payload: {
    error,
  },
});

export const clearFetchBooking = () => ({
  type: BookingTypes.CLEAR_BOOKING,
});

//TODO list of bookings
export const fetchBookingsStart = () => ({
  type: BookingTypes.BOOKINGS_PENDING,
});

export const fetchBookingsSuccess = (
  bookings,
  bookedBookings,
  pendingBookings,
  rejectedBookings
) => ({
  type: BookingTypes.BOOKINGS_SUCCESS,
  payload: {
    bookings,
    bookedBookings,
    pendingBookings,
    rejectedBookings,
  },
});

export const fetchBookingsFail = (error) => ({
  type: BookingTypes.BOOKINGS_FAIL,
  payload: {
    error,
  },
});

export const clearFetchBookings = () => ({
  type: BookingTypes.CLEAR_BOOKINGS,
});

//TODO create booking
export const createBookingStart = () => ({
  type: BookingTypes.CREATE_ROUTE_PENDING,
});

export const createBookingSuccess = (booking) => ({
  type: BookingTypes.CREATE_BOOKING_PENDING,
  payload: {
    booking,
  },
});

export const createBookingFail = (error) => ({
  type: BookingTypes.CREATE_BOOKING_FAIL,
  payload: {
    error,
  },
});

export const createCreateBooking = () => ({
  type: BookingTypes.CREATE_BOOKING_FAIL,
});

//TODO Update Booking
export const updateBookingStart = () => ({
  type: BookingTypes.UPDATE_BOOKING_PENDING,
});

export const updateBookingSuccess = (id, booking) => ({
  type: BookingTypes.UPDATE_BOOKING_SUCCESS,
  payload: {
    id,
    booking,
  },
});

export const updateBookingFail = (error) => ({
  type: BookingTypes.UPDATE_BOOKING_FAIL,
  payload: {
    error,
  },
});

export const clearUpdateBooking = () => ({
  type: BookingTypes.CLEAR_UPDATE_BOOKING,
});

//TODO delete booking
export const deleteBookingStart = () => ({
  type: BookingTypes.DELETE_BOOKING_PENDING,
});

export const deleteBookingSuccess = (id) => ({
  type: BookingTypes.DELETE_BOOKING_SUCCESS,
  payload: {
    id,
  },
});

export const deleteBookingFail = (error) => ({
  type: BookingTypes.DELETE_BOOKING_FAIL,
  payload: {
    error,
  },
});

// export const clearDeleteBooking = () => ({
//   type: BookingTypes.CLEAR_DELETE_BOOKING,
// });

export const getBookings = (dispatch) => {
  dispatch(fetchBookingsStart());

  axios
    .get(`${BASE_URI}/booking`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) => {
      const response = res.data;
      const responseData = [];

      response.forEach((data) =>
        responseData.unshift({
          key: data.id,
          id: data.id,
          bookingDate: data.firstSeatReserved,
          bookedBy: data.conductor
            ? `${data.conductor.firstName} ${data.conductor.lastName}`
            : "unknown",
          phone: data.conductor ? data.conductor.phoneNo : "",
          refNumber: data.refNumber,
          routeName: data.selectedRoute[0],
          price: "",
          totalSeat: "",
          paymentType: "",
          paymentStatus: "",
          passengers: data.passengers,
          seatNum: data.seat,
          status: data.status,
          firstSeatReserved: data.firstSeatReserved,
          sideNo: data.bus ? data.bus.sideNumber : "",
        })
      );
      const bookedTicket = response.filter((res) => res.status === "BOOKED");
      const pendingTicket = response.filter((res) => res.status === "PENDING");
      const rejectedTicket = response.filter(
        (res) => res.status === "REJECTED"
      );
      dispatch(
        fetchBookingsSuccess(
          responseData,
          bookedTicket,
          pendingTicket,
          rejectedTicket
        )
      );
    })
    .catch((err) => {
      dispatch(fetchBookingsFail(err));
    });
};

// export const showBookDetail = (id) => {

// };

export const getBooking = (id, history) => (dispatch) => {
  dispatch(fetchBookingStart());

  axios
    .get(`${BASE_URI}/booking/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) => {
      const response = res.data;
      const selectedRoute = JSON.parse(response.selectedRoute);
      const responseData = {
        id: response.id,
        refNumber: response.refNumber,
        phoneNumber: response.phoneNumber,
        passengers: response.passengers,
        seat: response.seat,
        status: response.status,
        pickup: response.pickup,
        dropoff: response.dropoff,
        bookedByConductor: response.bookedByConductor,
        bookedByCustomer: response.bookedByCustomer,
        bookedByCallCenter: response.bookedByCallCenter,
        firstSeatReserved: response.firstSeatReserved,
        routeName: `${selectedRoute.from} - ${selectedRoute.to}`,
        routeId: selectedRoute.id,
      };
      history.push("/director/book-detail");
      dispatch(fetchBookingSuccess(id, responseData));
    })
    .catch((err) => {
      dispatch(fetchBookingFail(err));
    });
};

export const createBooking = (dispatch) => {
  dispatch(createBookingStart());

  axios
    .post(`${BASE_URI}/booking`, null, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) => {
      dispatch(createBookingSuccess(res.data));
    })
    .catch((err) => {
      dispatch(createBookingFail(err));
    });
};

export const updateBooking = (id) => (dispatch) => {
  dispatch(updateBookingStart());

  axios
    .put(`${BASE_URI}/booking/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) => {
      dispatch(updateBookingSuccess(id, res.data));
    })
    .catch((err) => {
      dispatch(updateBookingFail(err));
    });
};       

export const deleteBooking = (id) => (dispatch) => {
  dispatch(deleteBookingStart());

  axios
    .delete(`${BASE_URI}/booking/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) => {
      dispatch(deleteBookingSuccess(id));
    })
    .catch((err) => {
      dispatch(deleteBookingFail(err));
    });
};
