import axios from "axios";
import { TermsAndConditionsActionTypes } from "./types";
import { busAdminTokenKey, BASE_URI } from "../../config/key.config";

export const fetchTermsConditionStart = () => ({
  type: TermsAndConditionsActionTypes.TERMS_CONDITION_PENDING,
});

export const fetchTermsConditionSuccess = (term_condition) => ({
  type: TermsAndConditionsActionTypes.TERMS_CONDITION_SUCCESS,
  payload: {
    term_condition,
  },
});

export const fetchTermsConditionFailure = (error) => ({
  type: TermsAndConditionsActionTypes.TERMS_CONDITION_FAIL,
  payload: { error },
});

export const clearFetchTermsCondition = () => ({
  type: TermsAndConditionsActionTypes.CLEAR_TERMS_CONDITION,
});

export const fetchTermsConditionsStart = () => ({
  type: TermsAndConditionsActionTypes.TERMS_CONDITIONS_PENDING,
});

export const fetchTermsConditionsSuccess = (term_conditions, desc) => ({
  type: TermsAndConditionsActionTypes.TERMS_CONDITIONS_SUCCESS,
  payload: { term_conditions, desc },
});

export const fetchTermsConditionsFailure = (error) => ({
  type: TermsAndConditionsActionTypes.TERMS_CONDITIONS_FAIL,
  payload: { error },
});

export const clearFetchTermsConditions = () => ({
  type: TermsAndConditionsActionTypes.CLEAR_TERMS_CONDITIONS,
});

export const createTermsConditionStart = () => ({
  type: TermsAndConditionsActionTypes.CREATE_TERMS_CONDITION_PENDING,
});

export const createTermsConditionSuccess = (term_condition) => ({
  type: TermsAndConditionsActionTypes.CREATE_TERMS_CONDITION_SUCCESS,
  payload: { term_condition },
});

export const createTermsConditionFail = (error) => ({
  type: TermsAndConditionsActionTypes.CREATE_TERMS_CONDITION_FAIL,
  payload: { error },
});

export const clearCreateTermsCondition = () => ({
  type: TermsAndConditionsActionTypes.CLEAR_CREATE_TERMS_CONDITION,
});

export const updateTermsConditionStart = () => ({
  type: TermsAndConditionsActionTypes.UPDATE_TERMS_CONDITION_PENDING,
});

export const updateTermsConditionSuccess = (id, term_condition) => ({
  type: TermsAndConditionsActionTypes.UPDATE_TERMS_CONDITION_SUCCESS,
  payload: {
    id,
    term_condition,
  },
});
export const updateTermsConditionFail = (error) => ({
  type: TermsAndConditionsActionTypes.UPDATE_TERMS_CONDITION_FAIL,
  payload: { error },
});

export const clearUpdateTermsCondition = () => ({
  type: TermsAndConditionsActionTypes.CLEAR_UPDATE_TERMS_CONDITION,
});

export const deleteTermsConditionStart = () => ({
  type: TermsAndConditionsActionTypes.DELETE_TERMS_CONDITION_PENDING,
});

export const deleteTermsConditionSuccess = (id) => ({
  type: TermsAndConditionsActionTypes.DELETE_TERMS_CONDITION_SUCCESS,
  payload: { id },
});

export const deleteTermsConditionFail = (error) => ({
  type: TermsAndConditionsActionTypes.DELETE_TERMS_CONDITION_FAIL,
  payload: { error },
});

export const clearDeleteTermsCondition = () => ({
  type: TermsAndConditionsActionTypes.CLEAR_DELETE_TERMS_CONDITION,
});

export const getTermsConditions = (dispatch) => {
  dispatch(fetchTermsConditionsStart());

  axios
    .get(`${BASE_URI}/terms-and-conditions`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) => {
      const response = res.data[0].description;
      dispatch(fetchTermsConditionsSuccess(res.data, response));
    })
    .catch((err) => dispatch(fetchTermsConditionsFailure(err)));
};

export const getTermsCondition = (id) => (dispatch) => {
  dispatch(fetchTermsConditionStart());

  axios
    .get(`${BASE_URI}/terms-and-conditions/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) => dispatch(fetchTermsConditionSuccess(res.data)))
    .catch((err) => dispatch(fetchTermsConditionFailure(err)));
};

export const createTermsCondition = (formData, history) => (dispatch) => {
  dispatch(createTermsConditionStart());

  axios
    .post(`${BASE_URI}/terms-and-conditions`, formData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) => {
      dispatch(createTermsConditionSuccess(res.data));
      history.push("/director/terms-and-conditions");
    })
    .catch((err) => dispatch(createTermsConditionFail(err)));
};

export const updateTermsCondition = (id, formData) => (dispatch) => {
  dispatch(updateTermsConditionStart());

  axios
    .put(`${BASE_URI}/terms-and-conditions/${id}`, formData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) => dispatch(updateTermsConditionSuccess(id, res.data)))
    .catch((err) => dispatch(updateTermsConditionFail(err)));
};

export const deleteTermsCondition = (id) => (dispatch) => {
  dispatch(deleteTermsConditionStart());

  axios
    .post(`${BASE_URI}/terms-and-conditions/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) => dispatch(deleteTermsConditionSuccess(id)))
    .catch((err) => dispatch(deleteTermsConditionFail(err)));
};
