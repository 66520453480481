import { useState, useEffect } from "react";
import {
  Layout,
  Row,
  Col,
  Button,
  Card,
  Form,
  Input,
  notification,
  Spin,
} from "antd";
import { createRefund } from "../../redux/refund/action";
import { useDispatch, useSelector } from "react-redux";
import {
  LoadingOutlined,
  RadiusUpleftOutlined,
  RadiusUprightOutlined,
  RadiusBottomleftOutlined,
  RadiusBottomrightOutlined,
  BorderTopOutlined,
  BorderBottomOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router";

const { Content } = Layout;

const openNotification = (placement) => {
  notification.info({
    message: `Notification ${placement}`,
    description:
      "This is the content of the notification. This is the content of the notification. This is the content of the notification.",
    placement,
  });
};

const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "white" }} spin />
);

function NewRefund() {
  const dispatch = useDispatch();
  const history = useHistory();

  const { refund, createRefundLoading, createRefundSuccessFailed } =
    useSelector((state) => state.refund);
  // useEffect(() => {
  //   if (createRefundSuccessFailed === true) {
  //     openNotification("top");
  //   } else {
  //     openNotification("bottom");
  //   }
  // }, [createRefundSuccessFailed]);

  const onFinish = (values) => {
    const { ticketId } = values;
    const formData = { ticketId: ticketId };
    dispatch(createRefund(formData));
    if (createRefundSuccessFailed === true) {
      openNotification("top");
    } else {
    }
  };

  const onFinishFailed = (errorInfo) => {};
  return (
    <div className="tabled">
      <Row gutter={[24, 0]}>
        <Col xs={24} xl={24}>
          <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title="Add New Booking Information"
          >
            <div
              style={{
                display: "flex",
                padding: "2em 2em 2em 2em",
                width: "100%",
              }}
            >
              <Content className="p-0">
                <Form
                  name="basic"
                  initialValues={{ remember: true }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  layout="vertical"
                  className="row-col"
                >
                  <Row>
                    <Col
                      xs={8}
                      xl={8}
                      style={{
                        padding: "1em",
                      }}
                    >
                      <Form.Item
                        className="ticketId"
                        label="Ticket ID"
                        name="ticketId"
                        rules={[
                          {
                            required: true,
                            message: "Please input refund Ticket Id!",
                          },
                        ]}
                      >
                        <Input placeholder="Ticket Id..." />
                      </Form.Item>
                    </Col>
                    <Col
                      xs={8}
                      xl={8}
                      style={{
                        padding: "1em",
                      }}
                    >
                      <Form.Item
                        className="passengerName"
                        label="Passenger Name"
                        name="PassengerName"
                        rules={[
                          {
                            required: true,
                            message: "Please input Passenger Name!",
                          },
                        ]}
                      >
                        <Input placeholder="Passenger Name..." />
                      </Form.Item>
                    </Col>
                    {/* <Col
                      xs={8}
                      xl={8}
                      style={{
                        padding: "1em",
                      }}
                    >
                      <Form.Item
                        className="price"
                        label="Cancelation Fees *"
                        name="price"
                        rules={[
                          {
                            required: true,
                            message: "Please input Cancelation Fee!",
                          },
                        ]}
                      >
                        <Input placeholder="Cancelation Fee..." />
                      </Form.Item>
                    </Col> */}
                    <Col
                      xs={8}
                      xl={8}
                      style={{
                        padding: "1em",
                      }}
                    >
                      <Form.Item
                        className="causes"
                        label="Causes"
                        name="causes"
                      >
                        <Input placeholder="Causeses..." />
                      </Form.Item>
                    </Col>
                    <Col
                      xs={8}
                      xl={8}
                      style={{
                        padding: "1em",
                      }}
                    >
                      <Form.Item
                        className="comment"
                        label="Comment"
                        name="comment"
                      >
                        <Input placeholder="Causeses..." />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Form.Item>
                    <div
                      style={{
                        paddingTop: 30,
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        type="primary"
                        htmlType="submit"
                        style={{
                          width: "20%",
                        }}
                      >
                        {createRefundLoading ? (
                          <Spin indicator={antIcon} />
                        ) : (
                          "Save"
                        )}
                      </Button>
                    </div>
                  </Form.Item>
                </Form>
              </Content>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default NewRefund;
