import { sample } from "lodash";
import { Badge } from "antd";
// ----------------------------------------------------------------------

const customers = [...Array(4)].map(() => ({
  id: sample(["1", "2", "3", "4"]),

  firstName: sample(["Anduamlak", "Mekdem", "Nahome"]),
  lastName: sample(["Abebe", "T.", "L."]),

  email: sample([
    "anduamlak@gmail.com",
    "mekdem@gmail.com",
    "Nahome@gmail.com",
  ]),
  phoneNo: sample(["0911581886", "0979060679", "0918472677", "0918472677"]),
  address: sample(["Stadium", "Hawassa", "Bahir dar", "Gondar"]),
  avatar: sample([
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIMAAACDCAMAAACZQ1hUAAAAaVBMVEX19fUAAAD5+fnX19dfX1/////8/Pxzc3OKioqOjo7x8fHj4+Pp6emxsbHd3d3u7u7KyspAQEC9vb2cnJxFRUU2NjYXFxcmJiaAgIDR0dGkpKR5eXlnZ2e3t7eUlJRTU1MtLS0QEBAdHR3HtiwFAAAEpklEQVR4nO1a2XKrMAxFApmUHbMvCQn//5FXJu1Mt1xMGqV94Ly0yTjmoNWS7Dg7duzYsWPHjh3PAyqFr1D4K88np+tf/Kksy8k/FFlAT+aBFMYTfETeJ09kgSoZGoBLPhbHrHPdTBd+OQPUY/csFio48GtXvesQXQ1CETlJXPK3Q6SeQYF0DeB3n9WPSEnLLHqSp4AnVv33MkcVspEMjrQ+kPXQOrcEjliwjIRJEFOI/ydt0kxCVB3UAxz/b3WqY0EJksAEoFjbn2KATE4b6MG4vrs6QCVGQWm4RDZvWK1L626U0NuEIOZaRTIMMAOw0zM1EMvES/LhYCdi1UMupAyAzk4OGM4QSrgGq8La3GmCWIRDAaOtgOkErQQHNdh5hQHLrJQwSlXahz8TUEV0AZBY7xtVkAqQQKjtbT0tZRxjS/ALJnAFOAR/gMMmOaQyHBDm0J5DLmIP2/ziDMHjKTgq/wPxwbdPyNhBLhInTzDY5gtO3oMEBzxCaZ2zRihEdBFCY3WaNKiEjtZ8QtOWZxiuMSQYLDWWpUGoAjyZs5w509oZGp2lzrROUIK22ZqjQyOSNRnEIrbZGgd7L94KjKxO1uxAMknzuntrU2+S1aq7OUQWJbXJFZ1gV4pe4LzGgWsLIce8AtMKXlZ6IDHU9jn+HqjjiqBVZNEm+SmJAx+nbr8mBpWkQb6hhPJm6YCOtyGx3Q2MaihvdP9QjbI+8QblXiBPv3uQkQLoJ/RpmUTSwKzpiyioq+CSPYWCUUcOcPgkCuUUNczuU1rmC4lgMI17VHgVBqJSxxLAe07X/pUEZSwKOOgQiUhF+sSf5uNX/ciywGWQc2mqvGpqM8YpgufPtJSTtdW8jJHq85ClTzLGT0DWQqe17lgjvzHXe6Nxxe8R+AWY4ZmB+XNjyTJ0XdYIDPiQdZ/ovvXy5mKc8ttpEnJdwWgqbyiOiXrs0FWp8Og37weqlf4iC1RZ+X7JPMYJPihmoQr0aAg0pd/rzE2SMOOPXua8e09+mOvzmmOYJG6m+2Eyv6i9OHiAwSIFxdm8+CmLOCS+TtiXKWv+0pkoaaDcYlomrG8LiNJs+ao+RT8NHCp4MU87hZ+US+lp0c3Zaw/tWJl/L230IUowkag3NNofsUA8sgxKHXy1LlSp9vP6VfeXfDxG3y1yMj5SzP39E09MR2N9twxLUZBkcd/3sXZTurHoaqlTcqdxEtfZUNyMBcsTOBiQUv+1O6T4sjKYvQnj7N69/D/uFLHLDHc4iGrNYPsxUWYZg3ubSdDw0GsExLXRtJGEkcJDT8jkzuBt+wWfzaxaLvZQLmzqWapM4D6H0rChS4WpyPUBYsO0blOhD5PEXY5lXzsYTYjUKqZUtRxP4gQHmVpF9XBOrShomdmDAZ05+Nusm+AkVSuoGGaLvTGEWa6flFo19Flngm01OtgM49Fbu330E2AH5/VV6YZAcgeiZn17TCC3cp97Ma4nIo7qor09TsirXscm2Uo2U/h0tpqKOGtbXv65k0MM/to70svOYeewc9g5/G0OkrEa7ThUoyeIcp0D5yxpWOREVxoWl5ZQGusUduzYsWPHjh1/Gv8A4rc2sVtzhpkAAAAASUVORK5CYII=",
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIMAAACDCAMAAACZQ1hUAAAAaVBMVEX19fUAAAD5+fnX19dfX1/////8/Pxzc3OKioqOjo7x8fHj4+Pp6emxsbHd3d3u7u7KyspAQEC9vb2cnJxFRUU2NjYXFxcmJiaAgIDR0dGkpKR5eXlnZ2e3t7eUlJRTU1MtLS0QEBAdHR3HtiwFAAAEpklEQVR4nO1a2XKrMAxFApmUHbMvCQn//5FXJu1Mt1xMGqV94Ly0yTjmoNWS7Dg7duzYsWPHjh3PAyqFr1D4K88np+tf/Kksy8k/FFlAT+aBFMYTfETeJ09kgSoZGoBLPhbHrHPdTBd+OQPUY/csFio48GtXvesQXQ1CETlJXPK3Q6SeQYF0DeB3n9WPSEnLLHqSp4AnVv33MkcVspEMjrQ+kPXQOrcEjliwjIRJEFOI/ydt0kxCVB3UAxz/b3WqY0EJksAEoFjbn2KATE4b6MG4vrs6QCVGQWm4RDZvWK1L626U0NuEIOZaRTIMMAOw0zM1EMvES/LhYCdi1UMupAyAzk4OGM4QSrgGq8La3GmCWIRDAaOtgOkErQQHNdh5hQHLrJQwSlXahz8TUEV0AZBY7xtVkAqQQKjtbT0tZRxjS/ALJnAFOAR/gMMmOaQyHBDm0J5DLmIP2/ziDMHjKTgq/wPxwbdPyNhBLhInTzDY5gtO3oMEBzxCaZ2zRihEdBFCY3WaNKiEjtZ8QtOWZxiuMSQYLDWWpUGoAjyZs5w509oZGp2lzrROUIK22ZqjQyOSNRnEIrbZGgd7L94KjKxO1uxAMknzuntrU2+S1aq7OUQWJbXJFZ1gV4pe4LzGgWsLIce8AtMKXlZ6IDHU9jn+HqjjiqBVZNEm+SmJAx+nbr8mBpWkQb6hhPJm6YCOtyGx3Q2MaihvdP9QjbI+8QblXiBPv3uQkQLoJ/RpmUTSwKzpiyioq+CSPYWCUUcOcPgkCuUUNczuU1rmC4lgMI17VHgVBqJSxxLAe07X/pUEZSwKOOgQiUhF+sSf5uNX/ciywGWQc2mqvGpqM8YpgufPtJSTtdW8jJHq85ClTzLGT0DWQqe17lgjvzHXe6Nxxe8R+AWY4ZmB+XNjyTJ0XdYIDPiQdZ/ovvXy5mKc8ttpEnJdwWgqbyiOiXrs0FWp8Og37weqlf4iC1RZ+X7JPMYJPihmoQr0aAg0pd/rzE2SMOOPXua8e09+mOvzmmOYJG6m+2Eyv6i9OHiAwSIFxdm8+CmLOCS+TtiXKWv+0pkoaaDcYlomrG8LiNJs+ao+RT8NHCp4MU87hZ+US+lp0c3Zaw/tWJl/L230IUowkag3NNofsUA8sgxKHXy1LlSp9vP6VfeXfDxG3y1yMj5SzP39E09MR2N9twxLUZBkcd/3sXZTurHoaqlTcqdxEtfZUNyMBcsTOBiQUv+1O6T4sjKYvQnj7N69/D/uFLHLDHc4iGrNYPsxUWYZg3ubSdDw0GsExLXRtJGEkcJDT8jkzuBt+wWfzaxaLvZQLmzqWapM4D6H0rChS4WpyPUBYsO0blOhD5PEXY5lXzsYTYjUKqZUtRxP4gQHmVpF9XBOrShomdmDAZ05+Nusm+AkVSuoGGaLvTGEWa6flFo19Flngm01OtgM49Fbu330E2AH5/VV6YZAcgeiZn17TCC3cp97Ma4nIo7qor09TsirXscm2Uo2U/h0tpqKOGtbXv65k0MM/to70svOYeewc9g5/G0OkrEa7ThUoyeIcp0D5yxpWOREVxoWl5ZQGusUduzYsWPHjh1/Gv8A4rc2sVtzhpkAAAAASUVORK5CYII=",
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIMAAACDCAMAAACZQ1hUAAAAaVBMVEX19fUAAAD5+fnX19dfX1/////8/Pxzc3OKioqOjo7x8fHj4+Pp6emxsbHd3d3u7u7KyspAQEC9vb2cnJxFRUU2NjYXFxcmJiaAgIDR0dGkpKR5eXlnZ2e3t7eUlJRTU1MtLS0QEBAdHR3HtiwFAAAEpklEQVR4nO1a2XKrMAxFApmUHbMvCQn//5FXJu1Mt1xMGqV94Ly0yTjmoNWS7Dg7duzYsWPHjh3PAyqFr1D4K88np+tf/Kksy8k/FFlAT+aBFMYTfETeJ09kgSoZGoBLPhbHrHPdTBd+OQPUY/csFio48GtXvesQXQ1CETlJXPK3Q6SeQYF0DeB3n9WPSEnLLHqSp4AnVv33MkcVspEMjrQ+kPXQOrcEjliwjIRJEFOI/ydt0kxCVB3UAxz/b3WqY0EJksAEoFjbn2KATE4b6MG4vrs6QCVGQWm4RDZvWK1L626U0NuEIOZaRTIMMAOw0zM1EMvES/LhYCdi1UMupAyAzk4OGM4QSrgGq8La3GmCWIRDAaOtgOkErQQHNdh5hQHLrJQwSlXahz8TUEV0AZBY7xtVkAqQQKjtbT0tZRxjS/ALJnAFOAR/gMMmOaQyHBDm0J5DLmIP2/ziDMHjKTgq/wPxwbdPyNhBLhInTzDY5gtO3oMEBzxCaZ2zRihEdBFCY3WaNKiEjtZ8QtOWZxiuMSQYLDWWpUGoAjyZs5w509oZGp2lzrROUIK22ZqjQyOSNRnEIrbZGgd7L94KjKxO1uxAMknzuntrU2+S1aq7OUQWJbXJFZ1gV4pe4LzGgWsLIce8AtMKXlZ6IDHU9jn+HqjjiqBVZNEm+SmJAx+nbr8mBpWkQb6hhPJm6YCOtyGx3Q2MaihvdP9QjbI+8QblXiBPv3uQkQLoJ/RpmUTSwKzpiyioq+CSPYWCUUcOcPgkCuUUNczuU1rmC4lgMI17VHgVBqJSxxLAe07X/pUEZSwKOOgQiUhF+sSf5uNX/ciywGWQc2mqvGpqM8YpgufPtJSTtdW8jJHq85ClTzLGT0DWQqe17lgjvzHXe6Nxxe8R+AWY4ZmB+XNjyTJ0XdYIDPiQdZ/ovvXy5mKc8ttpEnJdwWgqbyiOiXrs0FWp8Og37weqlf4iC1RZ+X7JPMYJPihmoQr0aAg0pd/rzE2SMOOPXua8e09+mOvzmmOYJG6m+2Eyv6i9OHiAwSIFxdm8+CmLOCS+TtiXKWv+0pkoaaDcYlomrG8LiNJs+ao+RT8NHCp4MU87hZ+US+lp0c3Zaw/tWJl/L230IUowkag3NNofsUA8sgxKHXy1LlSp9vP6VfeXfDxG3y1yMj5SzP39E09MR2N9twxLUZBkcd/3sXZTurHoaqlTcqdxEtfZUNyMBcsTOBiQUv+1O6T4sjKYvQnj7N69/D/uFLHLDHc4iGrNYPsxUWYZg3ubSdDw0GsExLXRtJGEkcJDT8jkzuBt+wWfzaxaLvZQLmzqWapM4D6H0rChS4WpyPUBYsO0blOhD5PEXY5lXzsYTYjUKqZUtRxP4gQHmVpF9XBOrShomdmDAZ05+Nusm+AkVSuoGGaLvTGEWa6flFo19Flngm01OtgM49Fbu330E2AH5/VV6YZAcgeiZn17TCC3cp97Ma4nIo7qor09TsirXscm2Uo2U/h0tpqKOGtbXv65k0MM/to70svOYeewc9g5/G0OkrEa7ThUoyeIcp0D5yxpWOREVxoWl5ZQGusUduzYsWPHjh1/Gv8A4rc2sVtzhpkAAAAASUVORK5CYII=",
  ]),
  bookingHistory: sample([
    <Badge
      className="site-badge-count-109"
      count={"Active"}
      style={{ backgroundColor: "#52c41a" }}
    />,
    <Badge count={"Complete"} style={{ backgroundColor: "#29968c" }} />,
    <Badge count={"Cancelled"} style={{ backgroundColor: "#333" }} />,
    <Badge count={"Missed"} />,
  ]),
  date: sample(["2021-03-12", "2022-01-11", "2022-02-10", "2022-03-12"]),
  route: sample([
    "Addis Ababa - Gondar",
    "Addis Ababa - Hawassa",
    "Gondar - Addis Ababa",
    "Addis Ababa - Jimma",
  ]),
  status: sample([
    <Badge
      className="site-badge-count-109"
      count={"Active"}
      style={{ backgroundColor: "#52c41a" }}
    />,
    <Badge count={"Inactive"} />,
  ]),
}));

export default customers;
