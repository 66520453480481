import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  Card,
  Button,
  Table,
  Space,
  Divider,
  Image,
  Typography,
} from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import CUSTOMER from "../../_mocks_/customers";
import { getCustomer } from "../../redux/customer/action";
const { Title } = Typography;

const CustomerDeatil = () => {
  const { id } = useParams();  
  const dispatch = useDispatch();
  const { customer } = useSelector(state => state.customer)
  const [searchText, setSearchText] = useState(false);
  const [searchedColumn, setSearchedColumn] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [filteredInfo, setFilteredInfo] = useState(null);
  const [sortedInfo, setSortedInfo] = useState(null);    

  useEffect(() => {
    dispatch(getCustomer(id))
  }, [id])  

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Booking History",
      dataIndex: "bookingHistory",
      key: "bookingHistory",
      //   ...getColumnSearchProps("firstName"),
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      //   ...getColumnSearchProps("lastName"),
    },
    {
      title: "Route",
      dataIndex: "route",
      key: "route",
      //   ...getColumnSearchProps("phoneNo"),
    },

    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Button
            style={{ background: "#eba134", borderColor: "#eba134" }}
            type="primary"
            shape="round"
            icon={<EditOutlined />}
            size="small"
            // onClick={showEditModal}
          />
          <Button
            style={{ background: "#db0925", borderColor: "#db0925" }}
            shape="round"
            type="primary"
            icon={<DeleteOutlined />}
            size="small"
            // onClick={showDeleteModal}
          />
        </Space>
      ),
    },
  ];
  return (   
    <div className="tabled">
      <Row gutter={[24, 0]}>
        <Col span={6}>
          <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title="Customer"
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <Row
                style={{
                  padding: "2em 2em 2em 2em",
                  width: "100%",
                }}
              >
                <Col span={24} offset={2}>
                  <Image
                    style={{ width: "100%" }}                    
                    src="error"
                    fallback="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIMAAACDCAMAAACZQ1hUAAAAaVBMVEX19fUAAAD5+fnX19dfX1/////8/Pxzc3OKioqOjo7x8fHj4+Pp6emxsbHd3d3u7u7KyspAQEC9vb2cnJxFRUU2NjYXFxcmJiaAgIDR0dGkpKR5eXlnZ2e3t7eUlJRTU1MtLS0QEBAdHR3HtiwFAAAEpklEQVR4nO1a2XKrMAxFApmUHbMvCQn//5FXJu1Mt1xMGqV94Ly0yTjmoNWS7Dg7duzYsWPHjh3PAyqFr1D4K88np+tf/Kksy8k/FFlAT+aBFMYTfETeJ09kgSoZGoBLPhbHrHPdTBd+OQPUY/csFio48GtXvesQXQ1CETlJXPK3Q6SeQYF0DeB3n9WPSEnLLHqSp4AnVv33MkcVspEMjrQ+kPXQOrcEjliwjIRJEFOI/ydt0kxCVB3UAxz/b3WqY0EJksAEoFjbn2KATE4b6MG4vrs6QCVGQWm4RDZvWK1L626U0NuEIOZaRTIMMAOw0zM1EMvES/LhYCdi1UMupAyAzk4OGM4QSrgGq8La3GmCWIRDAaOtgOkErQQHNdh5hQHLrJQwSlXahz8TUEV0AZBY7xtVkAqQQKjtbT0tZRxjS/ALJnAFOAR/gMMmOaQyHBDm0J5DLmIP2/ziDMHjKTgq/wPxwbdPyNhBLhInTzDY5gtO3oMEBzxCaZ2zRihEdBFCY3WaNKiEjtZ8QtOWZxiuMSQYLDWWpUGoAjyZs5w509oZGp2lzrROUIK22ZqjQyOSNRnEIrbZGgd7L94KjKxO1uxAMknzuntrU2+S1aq7OUQWJbXJFZ1gV4pe4LzGgWsLIce8AtMKXlZ6IDHU9jn+HqjjiqBVZNEm+SmJAx+nbr8mBpWkQb6hhPJm6YCOtyGx3Q2MaihvdP9QjbI+8QblXiBPv3uQkQLoJ/RpmUTSwKzpiyioq+CSPYWCUUcOcPgkCuUUNczuU1rmC4lgMI17VHgVBqJSxxLAe07X/pUEZSwKOOgQiUhF+sSf5uNX/ciywGWQc2mqvGpqM8YpgufPtJSTtdW8jJHq85ClTzLGT0DWQqe17lgjvzHXe6Nxxe8R+AWY4ZmB+XNjyTJ0XdYIDPiQdZ/ovvXy5mKc8ttpEnJdwWgqbyiOiXrs0FWp8Og37weqlf4iC1RZ+X7JPMYJPihmoQr0aAg0pd/rzE2SMOOPXua8e09+mOvzmmOYJG6m+2Eyv6i9OHiAwSIFxdm8+CmLOCS+TtiXKWv+0pkoaaDcYlomrG8LiNJs+ao+RT8NHCp4MU87hZ+US+lp0c3Zaw/tWJl/L230IUowkag3NNofsUA8sgxKHXy1LlSp9vP6VfeXfDxG3y1yMj5SzP39E09MR2N9twxLUZBkcd/3sXZTurHoaqlTcqdxEtfZUNyMBcsTOBiQUv+1O6T4sjKYvQnj7N69/D/uFLHLDHc4iGrNYPsxUWYZg3ubSdDw0GsExLXRtJGEkcJDT8jkzuBt+wWfzaxaLvZQLmzqWapM4D6H0rChS4WpyPUBYsO0blOhD5PEXY5lXzsYTYjUKqZUtRxP4gQHmVpF9XBOrShomdmDAZ05+Nusm+AkVSuoGGaLvTGEWa6flFo19Flngm01OtgM49Fbu330E2AH5/VV6YZAcgeiZn17TCC3cp97Ma4nIo7qor09TsirXscm2Uo2U/h0tpqKOGtbXv65k0MM/to70svOYeewc9g5/G0OkrEa7ThUoyeIcp0D5yxpWOREVxoWl5ZQGusUduzYsWPHjh1/Gv8A4rc2sVtzhpkAAAAASUVORK5CYII="
                  />
                </Col>
                <Col span={22} offset={2}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      paddingTop: "2rem",
                    }}
                  >
                    <div>
                      {" "}
                      <Title level={3}>{customer.displayName}</Title>
                      <Title level={5}>Addis Ababa</Title>
                      <Title level={5}>{customer.phoneNumber}</Title>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Card>
        </Col>
        <Col span={18}>
          <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title="Customer"
            // extra={
            //   <Link to="new-user">
            //     <Button type="primary" icon={<UserAddOutlined />} size="large">
            //       Add Users
            //     </Button>
            //   </Link>
            // }
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <Row
                style={{
                  padding: "2em 2em 2em 2em",
                  width: "100%",
                }}
              >
                <Col xs={{ span: 8 }} lg={{ span: 8 }}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <p>First Name</p>
                    <Title level={5}>{(customer.displayName)?.split(" ")[0]}</Title>
                  </div>
                </Col>
                <Col xs={{ span: 8 }} lg={{ span: 8 }}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <p>Last Name</p>
                    <Title level={5}>{(customer.displayName)?.split(" ")[1]}</Title>
                  </div>
                </Col>
                <Col xs={{ span: 8 }} lg={{ span: 8 }}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <p>Phone Number</p>
                    <Title level={5}>{customer.phoneNumber}</Title>
                  </div>
                </Col>
              </Row>

              <Row
                style={{
                  padding: "2.5em 2em 2em 2em",
                  width: "100%",
                }}
              >
                <Col xs={{ span: 8 }} lg={{ span: 8 }}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <p>Address</p>
                    <Title level={5}>Addis Ababa</Title>
                  </div>
                </Col>
                <Col xs={{ span: 8 }} lg={{ span: 8 }}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <p>Last Booked</p>
                    <Title level={5}>2022-03-29</Title>
                  </div>
                </Col>
                <Col xs={{ span: 8 }} lg={{ span: 8 }}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <p>Total Tickets</p>
                    <Title level={5}>10</Title>
                  </div>
                </Col>
              </Row>
              <Divider />
              <Row
                style={{
                  padding: "2.5em 2em 2em 2em",
                  width: "100%",
                }}
              >
                <Table
                  style={{ width: "100%" }}
                  dataSource={CUSTOMER}
                  columns={columns}
                />
              </Row>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default CustomerDeatil;
