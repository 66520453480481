import { UserTypes } from "./types";

const INITIAL_STATE = {
  error: null,
  user: null,
  users: [],

  userLoading: false,
  userSuccess: false,
  userFail: false,

  usersLoading: false,
  usersSuccess: false,
  usersFail: false,

  createUserLoading: false,
  createUserSuccess: false,
  createUserFail: false,

  updateUserLoading: false,
  updateUserSuccess: false,
  updateUserFail: false,

  deleteUserLoading: false,
  deleteUserSuccess: false,
  deleteUserFail: false,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UserTypes.USER_PENDING:
      return {
        ...state,
        userLoading: true,
      };

    case UserTypes.USER_SUCCESS:
      return {
        ...state,
        userLoading: false,
        userSuccess: true,
        user: action.payload.user,
      };
    case UserTypes.USER_FAIL:
      return {
        ...state,
        userLoading: false,
        userSuccess: false,
        error: action.payload.error,
      };
    case UserTypes.USERS_PENDING:
      return {
        ...state,
        usersLoading: true,
      };
    case UserTypes.USERS_SUCCESS:
      return {
        ...state,
        usersLoading: false,
        usersSuccess: true,
        users: action.payload.users,
      };
    case UserTypes.USERS_FAIL:
      return {
        ...state,
        usersLoading: false,
        usersSuccess: false,
        error: action.payload.error,
      };

    case UserTypes.CREATE_USER_PENDING:
      return {
        ...state,
        createUserLoading: true,
      };
    case UserTypes.CREATE_USER_SUCCESS:
      return {
        ...state,
        createUserLoading: false,
        createUserSuccess: true,
        user: action.payload.user,
      };
    case UserTypes.CREATE_USER_FAIL:
      return {
        ...state,
        createUserLoading: false,
        createUserSuccess: false,
        error: action.payload.error,
      };

    case UserTypes.UPDATE_USER_PENDING:
      return {
        ...state,
        updateUserLoading: true,
      };
    case UserTypes.UPDATE_USER_SUCCESS:
      return {
        ...state,
        updateUserLoading: false,
        updateUserSuccess: true,
        user: action.payload.user,
      };
    case UserTypes.UPDATE_USER_FAIL:
      return {
        ...state,
        updateUserLoading: false,
        updateUserSuccess: false,
        error: action.payload.error,
      };
    case UserTypes.DELETE_USER_PEDING:
      return {
        ...state,
        deleteUserLoading: true,
      };
    case UserTypes.DELETE_USER_SUCCESS:
      return {
        ...state,
        deleteUserLoading: false,
        deleteUserSuccess: false,
        user: null,
      };
    case UserTypes.DELETE_USER_FAIL:
      return {
        ...state,
        deleteUserLoading: false,
        deleteUserSuccess: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default reducer;
