import React, { useState, useEffect, useRef } from "react";
import { Typography } from "antd";
import { getBookings } from "../../redux/booking/action";
import { useDispatch, useSelector } from "react-redux";
import { busAdminTokenKey } from "../../config/key.config";

function EChart2() {
  const valueRef = useRef();

  const dispatch = useDispatch();
  const [booked, setBooked] = useState([]);
  const { Title } = Typography;
  const { bookings, bookingsLoading, bookingsSuccess } = useSelector(
    (state) => state.booking
  );
  useEffect(() => {
    dispatch(getBookings);

    let interval = setInterval(() => {
      fetch(`https://publicbus-dev-api.liyubus.com/booking`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          const pendingBooking = res.filter((r) => r.status === "ACTIVE");
          setBooked(pendingBooking);
        })
        .catch((err) => {});
    }, 500);

    return () => {
      window.clearInterval(interval);
    };
  }, [dispatch]);

  return (
    <>
      <div className="chart-vistior">
        <Title level={5}>Live Ticket Booking</Title>
        <Title
          style={{
            width: "100%",
            height: "8rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: booked.length > 0 ? "#ff4d4f" : "#05DBF2",
            borderRadius: "10px",
            color: "#fff",
          }}
        >
          {bookingsSuccess ? booked.length : "0"}
        </Title>
      </div>
    </>
  );
}

export default EChart2;
