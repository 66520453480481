import React, { useState } from "react";
import {  Tabs } from "antd";

import AllBooking from "./AllBooking";
import BookedBooking from "./BookedBooking";
import PendingBooking from "./PendingBooking";
import RejectedBooking from "./RejectedBooking";

function BookingDetails() {  
  const [tab, setTab] = useState("1");  

  return (
    <div className="tabled">
      <Tabs
        defaultActiveKey="agent"
        tabPosition="top"
        onChange={(e) => setTab(e)}
      >
        <Tabs.TabPane tab="All Tickets" key="all-ticket">
          <AllBooking />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Booked Tickets" key="booked-ticket">
          <BookedBooking />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Pending Tickets" key="pending-ticket">
          <PendingBooking />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Cancelled Tickets" key="cancelled-ticket">
          <RejectedBooking />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
}

export default BookingDetails;
