export const DropOffPickUpTypes = {
  DROP_OFF_PICKUP_PENDING: "DROP_OFF_PICKUP_PENDING",
  DROP_OFF_PICKUP_SUCCESS: "DROP_OFF_PICKUP_SUCCESS",
  DROP_OFF_PICKUP_FAIL: "DROP_OFF_PICKUP_FAIL",
  CLEAR_DROP_OFF_PICKUP: "CLEAR_DROP_OFF_PICKUP",

  DROP_OFF_PICKUPS_PENDING: "DROP_OFF_PICKUPS_PENDING",
  DROP_OFF_PICKUPS_SUCCESS: "DROP_OFF_PICKUPS_SUCCESS",
  DROP_OFF_PICKUPS_FAIL: "DROP_OFF_PICKUPS_FAIL",
  CLEAR_DROP_OFF_PICKUPS: "CLEAR_DROP_OFF_PICKUPS",

  CREATE_DROP_OFF_PICKUP_PENDING: "CREATE_DROP_OFF_PICKUP_PENDING",
  CREATE_DROP_OFF_PICKUP_SUCCESS: "CREATE_DROP_OFF_PICKUP_SUCCESS",
  CREATE_DROP_OFF_PICKUP_FAIL: "CREATE_DROP_OFF_PICKUP_FAIL",
  CREATE_CLEAR_DROP_OFF_PICKUP: "CREATE_CLEAR_DROP_OFF_PICKUP",

  UPDATE_DROP_OFF_PICKUP_PENDING: "UPDATE_DROP_OFF_PICKUP_PENDING",
  UPDATE_DROP_OFF_PICKUP_SUCCESS: "UPDATE_DROP_OFF_PICKUP_SUCCESS",
  UPDATE_DROP_OFF_PICKUP_FAIL: "UPDATE_DROP_OFF_PICKUP_FAIL",
  UPDATE_CLEAR_DROP_OFF_PICKUP: "UPDATE_CLEAR_DROP_OFF_PICKUP",

  DELETE_DROP_OFF_PICKUP_PENDING: "DELETE_DROP_OFF_PICKUP_PENDING",
  DELETE_DROP_OFF_PICKUP_SUCCESS: "DELETE_DROP_OFF_PICKUP_SUCCESS",
  DELETE_DROP_OFF_PICKUP_FAIL: "DELETE_DROP_OFF_PICKUP_FAIL",
  DELETE_CLEAR_DROP_OFF_PICKUP: "DELETE_CLEAR_DROP_OFF_PICKUP",

  DROP_POINT_PENDING: "DROP_POINT_PENDING",
  DROP_POINT_SUCCESS: "DROP_POINT_SUCCESS",
  DROP_POINT_FAIL: "DROP_POINT_FAIL",

  PICK_POINT_PENDING: "PICK_POINT_PENDING",
  PICK_POINT_SUCCESS: "PICK_POINT_SUCCESS",
  PICK_POINT_FAIL: "PICK_POINT_FAIL",
};
