import { useState, useEffect } from "react";
import {
  Layout,
  Row,
  Col,
  Button,
  Card,
  Form,
  InputNumber,
  DatePicker,
  Select,
  TimePicker,
  Spin,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getCities } from "../../redux/city/action";
import { createRoute } from "../../redux/route/action";
import { LoadingOutlined } from "@ant-design/icons";
import { getPickUpPoint, getDropPoint } from "../../redux/DropOffPickup/action";

// import momento from "momento";
const { Content } = Layout;
const { RangePicker } = DatePicker;

const { Option } = Select;

const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "white" }} spin />
);
function NewRoute() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [dropPicks, setDropPicks] = useState("");
  const [isPickClick, setPicks] = useState(false);
  const [isDropClick, setDrop] = useState(false);

  const { createRouteLoading, createRouteSuccess } = useSelector(
    (state) => state.route
  );
  const { picks, drops } = useSelector((state) => state.dropOffPickUp);
  const { citys } = useSelector((state) => state.city);
  useEffect(() => {
    dispatch(getCities);
  }, [dispatch]);

  const options = [];

  citys.forEach((city) =>
    options.push({ key: city.id, value: city.id, city: city.name })
  );

  let dateData;
  const onChange = (date, dateString) => {
    dateData = dateString;
  };

  const onChanged = (value) => {
    setPicks(true);
    dispatch(getPickUpPoint(value));
  };

  const onChangedPicks = (value) => {
    setDrop(true);
    dispatch(getDropPoint(value));
  };

  const onFinish = (values) => {
    const { from, to, distance, fare, pickup, dropoff } = values;
    const price = fare;
    const departureTime = dateData[0];
    const arrivalTime = dateData[1];
    const formData = {
      from,
      to,
      distance,
      price,
      departureTime,
      arrivalTime,
      pickup,
      dropoff,
    };
    dispatch(createRoute(formData, history));
    // <Redirect to="/director/routes" />;
  };

  const onFinishFailed = (errorInfo) => {};
  return (
    <div className="tabled">
      <Row gutter={[24, 0]}>
        <Col xs={24} xl={24}>
          <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title="Add main route"
          >
            <div
              style={{
                display: "flex",
                padding: "2em 2em 2em 2em",
                width: "100%",
              }}
            >
              <Content className="p-0">
                <Form
                  name="basic"
                  // initialValues={{ remember: true }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  layout="vertical"
                  className="row-col"
                >
                  <Row>
                    <Col
                      xs={12}
                      xl={8}
                      style={{
                        padding: "1em",
                      }}
                    >
                      <Form.Item
                        className="from"
                        label="Select From City"
                        name="from"
                        rules={[
                          {
                            required: true,
                            message: "Please input the initial city!",
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          placeholder="Choose..."
                          optionFilterProp="children"
                          onChange={onChanged}
                          //   onSearch={onSearch}
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {options.map((option) => (
                            <Option value={option.value} key={option.value}>
                              {option.city.toUpperCase()}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    {isPickClick ? (
                      picks.length === 0 ? (
                        ""
                      ) : (
                        <Col
                          xs={12}
                          xl={8}
                          style={{
                            padding: "1em",
                          }}
                        >
                          <Form.Item
                            className="Pick"
                            label="Select Pickup point"
                            name="pickup"
                            rules={[
                              {
                                required: true,
                                message: "Please input the !",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              placeholder="Choose Pick up Points ..."
                              optionFilterProp="children"
                              mode="multiple"
                              allowClear
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {picks.map((option) => (
                                <Option value={option.id} key={option.id}>
                                  {option.dropingPoint.toUpperCase()}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                      )
                    ) : (
                      ""
                    )}
                    <Col
                      xs={12}
                      xl={8}
                      style={{
                        padding: "1em",
                      }}
                    >
                      <Form.Item
                        className="to"
                        label="Select End Destination"
                        name="to"
                        rules={[
                          {
                            required: true,
                            message: "Please input the End destination!",
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          placeholder="Choose Pick up Points ..."
                          optionFilterProp="children"
                          onChange={onChangedPicks}
                          //   onSearch={onSearch}
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {options.map((option) => (
                            <Option value={option.value} key={option.value}>
                              {option.city.toUpperCase()}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    {isDropClick ? (
                      drops.length === 0 ? (
                        ""
                      ) : (
                        <Col
                          xs={12}
                          xl={8}
                          style={{
                            padding: "1em",
                          }}
                        >
                          <Form.Item
                            className="Pick"
                            label="Select Drop off point"
                            name="dropoff"
                            rules={[
                              {
                                required: true,
                                message: "Please input the !",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              placeholder="Choose..."
                              optionFilterProp="children"
                              mode="multiple"
                              allowClear
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {drops.map((option) => (
                                <Option value={option.id} key={option.id}>
                                  {option.dropingPoint.toUpperCase()}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                      )
                    ) : (
                      ""
                    )}
                  </Row>

                  <Row>
                    <Col
                      xs={24}
                      xl={8}
                      style={{
                        padding: "1em",
                      }}
                    >
                      <Form.Item
                        className="date"
                        label="Departure and Arrival time"
                        name="date"
                        rules={[
                          {
                            required: true,
                            message: "Please input Date!",
                          },
                        ]}
                      >
                        <TimePicker.RangePicker
                          placeholder="AD ..."
                          onChange={onChange}
                          // showTime={{ format: "HH:mm" }}
                          // format="YYYY-MM-DD HH:mm"
                          style={{ width: "100%" }}
                          //   onChange={onChange}
                          //   onOk={onOk}
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      xs={24}
                      xl={8}
                      style={{
                        padding: "1em",
                      }}
                    >
                      <Form.Item
                        className="distance"
                        label="Distance"
                        name="distance"
                        rules={[
                          {
                            required: true,
                            message: "Please input distance!",
                          },
                        ]}
                      >
                        <InputNumber
                          min={0}
                          defaultValue={0}
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      xs={24}
                      xl={8}
                      style={{
                        padding: "1em",
                      }}
                    >
                      <Form.Item
                        className="fare"
                        label="Total Fare"
                        name="fare"
                        rules={[
                          {
                            required: true,
                            message: "Please input Fare for amount!",
                          },
                        ]}
                      >
                        <InputNumber
                          min={0}
                          defaultValue={0}
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Form.Item>
                    <div
                      style={{
                        paddingTop: 10,
                        width: "100%",
                        paddingLeft: "15px",
                      }}
                    >
                      <Button type="primary" htmlType="submit" size="large">
                        {createRouteLoading ? (
                          <Spin indicator={antIcon} />
                        ) : (
                          "Register"
                        )}
                      </Button>
                    </div>
                  </Form.Item>
                </Form>
              </Content>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default NewRoute;
