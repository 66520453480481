import { sample } from "lodash";
import { Badge } from "antd";
// ----------------------------------------------------------------------

const bookingDetails = [...Array(1)].map(() => ({
  id: sample(["1"]),

  seatName: sample(["c1, d2, f3"]),
  adult: sample([1]),
  child: sample(["1"]),
  special: sample(["48"]),
  package: sample(["40"]),
  amount: sample(["1545"]),
  bookedBy: sample(["Abenet Gashaw"]),
  plateNum: sample(["98675."]),
  busStructure: sample(["49"]),

  status: sample([
    <Badge
      className="site-badge-count-109"
      count={"Active"}
      style={{ backgroundColor: "#52c41a" }}
    />,
    <Badge count={"Blocked"} />,
  ]),
}));

export default bookingDetails;
