export const SubRouteTypes = {
  SUB_ROUTE_PENDING: "SUB_ROUTE_PENDING",
  SUB_ROUTE_SUCCESS: "SUB_ROUTE_SUCCESS",
  SUB_ROUTE_FAIL: "SUB_ROUTE_FAIL",
  CLEAR_SUB_ROUTE: "CLEAR_SUB_ROUTE",

  SUB_ROUTES_PENDING: "SUB_ROUTES_PENDING",
  SUB_ROUTES_SUCCESS: "SUB_ROUTES_SUCCESS",
  SUB_ROUTES_FAIL: "SUB_ROUTES_FAIL",
  CLEAR_SUB_ROUTES: "CLEAR_SUB_ROUTES",

  CREATE_SUB_ROUTE_PENDING: "CREATE_SUB_ROUTE_PENDING",
  CREATE_SUB_ROUTE_SUCCESS: "CREATE_SUB_ROUTE_SUCCESS",
  CREATE_SUB_ROUTE_FAIL: "CREATE_SUB_ROUTE_FAIL",
  CREATE_CLEAR_SUB_ROUTE: "CREATE_CLEAR_SUB_ROUTE",

  UPDATE_SUB_ROUTE_PENDING: "UPDATE_SUB_ROUTE_PENDING",
  UPDATE_SUB_ROUTE_SUCCESS: "UPDATE_SUB_ROUTE_SUCCESS",
  UPDATE_SUB_ROUTE_FAIL: "UPDATE_SUB_ROUTE_FAIL",
  UPDATE_CLEAR_SUB_ROUTE: "UPDATE_CLEAR_SUB_ROUTE",

  DELETE_SUB_ROUTE_PENDING: "DELETE_SUB_ROUTE_PENDING",
  DELETE_SUB_ROUTE_SUCCESS: "DELETE_SUB_ROUTE_SUCCESS",
  DELETE_SUB_ROUTE_FAIL: "DELETE_SUB_ROUTE_FAIL",
  DELETE_CLEAR_SUB_ROUTE: "DELETE_CLEAR_SUB_ROUTE",
};
