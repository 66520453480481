import { TrackingTypes } from "./types";

const INITIAL_STATE = {
  error: null,
  locations: [],

  locationsLoading: false,
  locationsSuccess: false,
  locationsFail: false,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TrackingTypes.TRACKING_PENDING:
      return {
        ...state,
        locationsLoading: true,
      };
    case TrackingTypes.TRACKING_SUCCESS:
      return {
        ...state,
        locationsLoading: false,
        locationsSuccess: true,
        locationsFail: false,
        locations: action.payload.locations,
      };
    case TrackingTypes.TRACKING_FAIL:
      return {
        ...state,
        locationsLoading: false,
        locationsFail: true,
        error: action.payload.error,
      };
    default:
      return {
        ...state,
      };
  }
};

export default reducer;
