import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Radio,
  Input,
  Button,
  Space,
  Select,
  Divider,
  DatePicker,
  Tabs,
} from "antd";
import { Table } from "ant-table-extensions";

import Highlighter from "react-highlight-words";
import {
  SearchOutlined,
  MoreOutlined,
  SwapOutlined,
  FileExcelOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  getDashboardReportToday,
  getDashboardReportWeek,
  getDashboardReportMonth,
  getDashboardReportYear,
  clearReports
} from "../../redux/report/action";
import { getRoutes } from "../../redux/route/action";
import { getBuses } from "../../redux/bus/action";

const { Option, OptGroup } = Select;
const { RangePicker } = DatePicker;
const TabPane = Tabs.TabPane;

function MonthReport() {
    const dispatch = useDispatch();
    const [value, setValue] = useState(2);
    // const [radioValue, setRadioValue] = useState(4);
    const [searchText, setSearchText] = useState(false);
    const [searchedColumn, setSearchedColumn] = useState(false);
    const [searchInput, setSearchInput] = useState("");
    const [filteredInfo, setFilteredInfo] = useState({});
    const [sortedInfo, setSortedInfo] = useState({});
    const [reportValue, setReportValue] = useState({});
  
    const { routes } = useSelector((state) => state.route);
    const { buses } = useSelector((state) => state.bus);
    const { reportInterval, reportIntervalLoading } = useSelector(
      (state) => state.report
    );
  
    const onChange = (e) => {
      setValue(e.target.value);
      if (e.target.value === 0) {
        dispatch(getDashboardReportToday);
      }
      if (e.target.value === 1) {
        dispatch(getDashboardReportWeek);
      }
      if (e.target.value === 2) {
        dispatch(getDashboardReportMonth);
      }
      if (e.target.value === 3) {
        dispatch(getDashboardReportYear);
      }
    };
  
    useEffect(() => {
      dispatch(getDashboardReportYear);
      dispatch(getBuses);
      dispatch(getRoutes);
  
      return () => {
        dispatch(clearReports);
      };
    }, [dispatch]);
  
    let getColumnSearchProps = (dataIndex) => ({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              setSearchInput(node);
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
  
            <Button
              onClick={() => handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) =>
        record[dataIndex]
          ? record[dataIndex]
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
          : "",
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => setSearchInput(""), 100);
        }
      },
      render: (text) =>
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ""}
          />
        ) : (
          text
        ),
    });
  
    let handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
    };
  
    let handleReset = (clearFilters) => {
      clearFilters();
      setSearchText("");
      setFilteredInfo(null);
      setSortedInfo(null);
    };
  
    function handleChange(value) {
    }
  
    const columns = [
      {
        title: "Passenger",
        dataIndex: "passenger",
        key: "passenger",
        width: "7%",
      },
      {
        title: "Seat",
        dataIndex: "seat",
        key: "seat",
        width: "17%",
        ...getColumnSearchProps("date"),
      },
      {
        title: "PhoneNumber",
        dataIndex: "phoneNumber",
        key: "phoneNumber",
        width: "22%",
        ...getColumnSearchProps("from"),
        // sorter: (a, b) => a.from.length - b.from.length,
        // sortDirections: ["descend", "ascend"],
      },
      {
        title: "Issued By",
        dataIndex: "issuedBy",
        key: "issuedBy",
        width: "22%",
        ...getColumnSearchProps("to"),
        // sorter: (a, b) => a.to.length - b.to.length,
        // sortDirections: ["descend", "ascend"],
      },
      {
        title: "Bus",
        dataIndex: "bus",
        key: "bus",
        width: "12%",
        ...getColumnSearchProps("bus"),
      },
      {
        title: "Fare",
        dataIndex: "fare",
        key: "fare",
        width: "15%",
      },
      {
        title: "Date",
        dataIndex: "travelDate",
        key: "travelDate",
        width: "15%",
      },
      {
        title: "Action",
        key: "action",
        width: "20%",
        render: (text, record) => (
          <Space size="middle">
            <Button
              style={{ background: "#eba134", borderColor: "#eba134" }}
              type="primary"
              shape="round"
              icon={<MoreOutlined rotate={90} />}
              size="small"
            />
          </Space>
        ),
      },
    ];
    return (
      <div className="tabled">
        <Tabs defaultActiveKey="1" centered>
          <TabPane tab="Daily Report" key="1">
            <Row gutter={[24, 0]}>
              <Col xs={24} xl={24}>
                <Card
                  bordered={false}
                  className="criclebox tablespace mb-24"
                  title="Daily Report"
                >
                  <Row
                    gutter={[24, 0]}
                    style={{
                      paddingTop: "2%",
                      paddingLeft: "2%",
                    }}
                  >
                    <Col
                      xl={7}
                      md={4}
                      style={{
                        alignItems: "left",
                      }}
                    >
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <h5>Date Range:</h5>
                        <DatePicker
                          style={{ width: "80%", marginBottom: ".75rem" }}
                        />
  
                        <Radio.Group
                          onChange={onChange}
                          value={value}
                          defaultValue={value}
                        >
                          <Radio value={0}>Today</Radio>
                          <Radio value={1}>This Week</Radio>
                          <Radio value={2}>This Month</Radio>
                          <Radio value={3}>This Year</Radio>
                        </Radio.Group>
                      </div>
                    </Col>
  
                    <Col
                      xl={5}
                      // md={8}
                      style={{
                        alignItems: "left",
                        paddingLeft: "1px",
                        display: "flex",
                        justifyContent: "space-around",
                      }}
                    >
                      <div>
                        <h5>Route:</h5>
  
                        <Select
                          defaultValue="Addis Ababa"
                          style={{ width: 250 }}
                          onChange={handleChange}
                        >
                          <OptGroup label="Select Route">
                            {routes.map((route) => (
                              <Option value={route.id}>
                                {route.from} - {route.to}
                              </Option>
                            ))}
                          </OptGroup>
                        </Select>
                      </div>
                    </Col>
  
                    <Col
                      xl={4}
                      md={5}
                      style={{
                        alignItems: "left",
                        paddingLeft: "1px",
                      }}
                    >
                      <h5>Bus:</h5>
                      <Select
                        defaultValue="1914"
                        style={{ width: "50%" }}
                        onChange={handleChange}
                      >
                        <OptGroup label="Bus Side No.">
                          {buses.map((bus) => (
                            <Option value={bus.id}>{bus.plateNumber}</Option>
                          ))}
                        </OptGroup>
                      </Select>
                    </Col>
  
                    <Col
                      md={3}
                      style={{
                        alignItems: "left",
                        paddingLeft: "1px",
                        paddingTop: "15px",
                      }}
                    >
                      {/* <Link to="/#"> */}
                      <Button type="primary" icon={<SwapOutlined />} size="large">
                        Generate
                      </Button>
                      {/* </Link> */}
                    </Col>
                  </Row>
                  <Divider />
                  <Table
                    columns={columns}
                    dataSource={reportInterval}
                    loading={reportIntervalLoading}
                    exportableProps={{
                      showColumnPicker: true,
                      btnProps: {
                        type: "primary",
                        style: { marginLeft: "1.75rem" },
                        icon: <FileExcelOutlined />,
                        children: <span>Export to CSV</span>,
                      },
                    }}
                    // searchable={{ fuzzySearch: true }}
                    // loading={reportLoading}
                  />
                </Card>
              </Col>
            </Row>
          </TabPane>
          <TabPane tab="Report With in a Range" key="2">
            <Row gutter={[24, 0]}>
              <Col xs={24} xl={24}>
                <Card
                  bordered={false}
                  className="criclebox tablespace mb-24"
                  title="Report With Range"
                >
                  <Row
                    gutter={[24, 0]}
                    style={{
                      paddingTop: "2%",
                      paddingLeft: "2%",
                    }}
                  >
                    <Col
                      md={4}
                      style={{
                        alignItems: "left",
                      }}
                    >
                      <h5>Date Range:</h5>
                      <RangePicker />
                    </Col>
  
                    <Col
                      xl={5}
                      // md={8}
                      style={{
                        alignItems: "left",
                        paddingLeft: "1px",
                        display: "flex",
                        justifyContent: "space-around",
                      }}
                    >
                      <div>
                        <h5>Route:</h5>
  
                        <Select
                          defaultValue="Addis Ababa"
                          style={{ width: 250 }}
                          onChange={handleChange}
                        >
                          <OptGroup label="Select Route">
                            {routes.map((route) => (
                              <Option value={route.id}>
                                {route.from} - {route.to}
                              </Option>
                            ))}
                          </OptGroup>
                        </Select>
                      </div>
                    </Col>
  
                    <Col
                      md={9}
                      style={{
                        alignItems: "left",
                        paddingLeft: "1px",
                      }}
                    >
                      <h5>Bus:</h5>
                      <Select
                        defaultValue="1914"
                        style={{ width: 150 }}
                        onChange={handleChange}
                      >
                        <OptGroup label="Bus Side No.">
                          {buses.map((bus) => (
                            <Option value={bus.id}>{bus.plateNumber}</Option>
                          ))}
                        </OptGroup>
                      </Select>
                    </Col>
                    <Col
                      md={2}
                      style={{
                        alignItems: "left",
                        paddingLeft: "1px",
                        paddingTop: "15px",
                      }}
                    >
                      {/* <Link to="/#"> */}
                      <Button type="primary" icon={<SwapOutlined />} size="large">
                        Generate
                      </Button>
                      {/* </Link> */}
                    </Col>
                  </Row>
                  <Divider />
                  <Table
                    columns={columns}
                    dataSource={reportInterval}
                    loading={reportIntervalLoading}
                  />
                </Card>
              </Col>
            </Row>
          </TabPane>
        </Tabs>
      </div>
    );
  }

export default MonthReport;
