export const TermsAndConditionsActionTypes = {
  TERMS_CONDITION_PENDING: "TERMS_CONDITION_PENDING",
  TERMS_CONDITION_SUCCESS: "TERMS_CONDITION_SUCCESS",
  TERMS_CONDITION_FAIL: "TERMS_CONDITION_FAIL",
  CLEAR_TERMS_CONDITION: "CLEAR_TERMS_CONDITION",

  TERMS_CONDITIONS_PENDING: "TERMS_CONDITIONS_PENDING",
  TERMS_CONDITIONS_SUCCESS: "TERMS_CONDITIONS_SUCCESS",
  TERMS_CONDITIONS_FAIL: "TERMS_CONDITIONS_FAIL",
  CLEAR_TERMS_CONDITIONS: "CLEAR_TERMS_CONDITIONS",

  CREATE_TERMS_CONDITION_PENDING: "CREATE_TERMS_CONDITION_PENDING",
  CREATE_TERMS_CONDITION_SUCCESS: "CREATE_TERMS_CONDITION_SUCCESS",
  CREATE_TERMS_CONDITION_FAIL: "CREATE_TERMS_CONDITION_FAIL",
  CLEAR_CREATE_TERMS_CONDITION: "CLEAR_CREATE_TERMS_CONDITION",

  UPDATE_TERMS_CONDITION_PENDING: "UPDATE_TERMS_CONDITION_PENDING",
  UPDATE_TERMS_CONDITION_SUCCESS: "UPDATE_TERMS_CONDITION_SUCCESS",
  UPDATE_TERMS_CONDITION_FAIL: "UPDATE_TERMS_CONDITION_FAIL",
  CLEAR_UPDATE_TERMS_CONDITION: "CLEAR_UPDATE_TERMS_CONDITION",

  DELETE_TERMS_CONDITION_PENDING: "DELETE_TERMS_CONDITION_PENDING",
  DELETE_TERMS_CONDITION_SUCCESS: "DELETE_TERMS_CONDITION_SUCCESS",
  DELETE_TERMS_CONDITION_FAIL: "DELETE_TERMS_CONDITION_FAIL",
  CLEAR_DELETE_TERMS_CONDITION: "CLEAR_DELETE_TERMS_CONDITION",
};
