import { TripsTypes } from "./types";

const INITIAL_STATE = {
  error: null,
  trip: {},
  trips: [],
  fromTo: [],

  tripLoading: false,
  tripSuccess: false,
  tripFail: false,

  tripsLoading: false,
  tripsSuccess: false,
  tripsFail: false,

  createTripLoading: false,
  createTripSuccess: false,
  createTripFail: false,

  updateTripLoading: false,
  updateTripSuccess: false,
  updateTripFail: false,

  deleteTripLoading: false,
  deleteTripSuccess: false,
  deleteTripFail: false,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TripsTypes.TRIP_PENDING:
      return {
        ...state,
        tripLoading: true,
      };
    case TripsTypes.TRIP_SUCCESS:
      return {
        ...state,
        tripSuccess: true,
        tripLoading: false,
        trip: {
          trip: action.payload.trip,
        },
      };
    case TripsTypes.TRIP_FAIL:
      return {
        ...state,
        tripLoading: false,
        tripSuccess: false,
        error: action.payload.error,
      };
    case TripsTypes.CLEAR_TRIP:
      return {
        ...state,
        tripLoading: false,
        tripSuccess: false,
        tripsFail: false,
      };

    case TripsTypes.TRIPS_PENDING:
      return {
        ...state,
        tripsLoading: true,
      };
    case TripsTypes.TRIPS_SUCCESS:
      return {
        ...state,
        tripsSuccess: true,
        tripsLoading: false,
        trips: action.payload.trips,
        fromTo: action.payload.fromTo,
      };
    case TripsTypes.TRIPS_FAIL:
      return {
        ...state,
        tripsLoading: false,
        tripsSuccess: false,
        error: action.payload.error,
      };
    case TripsTypes.CLEAR_TRIPS:
      return {
        ...state,
        tripsSuccess: false,
        tripsLoading: false,
        tripsFail: false,
      };

    case TripsTypes.CREATE_TRIP_PENDING:
      return {
        ...state,
        createTripLoading: true,
      };
    case TripsTypes.CREATE_TRIP_SUCCESS:
      return {
        ...state,
        createTripSuccess: true,
        createTripLoading: false,
        trips: [action.payload.trip, ...state.trips],
      };
    case TripsTypes.CREATE_TRIP_FAIL:
      return {
        ...state,
        createTripLoading: false,
        createTripSuccess: false,
        error: action.payload.error,
      };
    case TripsTypes.CREATE_CLEAR_TRIP:
      return {
        ...state,
        createTripSuccess: false,
        createTripLoading: false,
        createTripFail: false,
      };

    case TripsTypes.UPDATE_TRIP_PENDING:
      return {
        ...state,
        updateTripLoading: true,
      };
    case TripsTypes.UPDATE_TRIP_SUCCESS:
      return {
        ...state,
        updateTripSuccess: true,
        updateTripLoading: false,
        trip: {
          ...state.trip,
          [action.payload.id]: action.payload.trip,
        },
      };
    case TripsTypes.UPDATE_TRIP_FAIL:
      return {
        ...state,
        updateTripLoading: false,
        updateTripSuccess: false,
        error: action.payload.error,
      };
    case TripsTypes.UPDATE_CLEAR_TRIP:
      return {
        ...state,
        updateTripSuccess: false,
        updateTripLoading: false,
        updateTripFail: false,
      };

    case TripsTypes.DELETE_TRIP_PENDING:
      return {
        ...state,
        deleteTripLoading: true,
      };
    case TripsTypes.DELETE_TRIP_SUCCESS:
      return {
        ...state,
        deleteTripSuccess: true,
        deleteTripLoading: false,
      };
    case TripsTypes.DELETE_TRIP_FAIL:
      return {
        ...state,
        deleteTripLoading: false,
        deleteTripSuccess: false,
        error: action.payload.error,
      };
    case TripsTypes.DELETE_CLEAR_TRIP:
      return {
        ...state,
        deleteTripSuccess: false,
        deleteTripLoading: false,
        deleteTripFail: false,
      };

    default:
      return state;
  }
};

export default reducer;
