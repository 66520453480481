import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Pie, G2 } from "@ant-design/plots";
import { Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getAllConductors } from "../../redux/conductor/action";
import { getDashboardConductorReport, clearReports } from "../../redux/report/action";

const { Title } = Typography;
const DemoPie = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDashboardConductorReport);

    return () => {
      dispatch(clearReports);
    };
  }, [dispatch]);

  const { reportsConductor } = useSelector((state) => state.report);
  const data = reportsConductor;
  const config = {
    appendPadding: 10,
    data,
    angleField: "ticket",
    colorField: "conductor",
    radius: 0.9,
    label: {
      type: "inner",
      offset: "-30%",
      content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
      style: {
        fontSize: 14,
        textAlign: "center",
      },
    },
    interactions: [
      {
        type: "element-active",
      },
    ],
  };
  return (
    <div className="chart-vistior">
      <Title level={5} style={{ paddingLeft: "1rem" }}>
        Conductors Share
      </Title>
      <Pie {...config} />
    </div>
  );
};

export default DemoPie;
