import { React, useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Table,
  Space,
  Modal,
  Layout,
  Form,
  Input,
  Select,
  notification,
  Spin,
  Badge,
} from "antd";
import { Link } from "react-router-dom";
import Highlighter from "react-highlight-words";
import {
  EditOutlined,
  UserAddOutlined,
  DeleteOutlined,
  SearchOutlined,
  LoadingOutlined,
  RightOutlined
} from "@ant-design/icons";

// import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import {
  getAllConductors,
  getConductor,
  updateConductor,
  deleteConductor,
  deleteConductorClear,
  updateConductorClear,
  resetConductor
} from "../../redux/conductor/action";
import { phonNumberValidate, validateEmail } from "../../validation/Conductor";
// import CONDUCTURSLIST from "../_mocks_/conductors";
const { Content } = Layout;
const { Option } = Select;
const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "white" }} spin />
);
function Conductors() {
  const [isEditModalVisible, setEditModalVisible] = useState(false);
  const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
  const [currConductor, setCurrConductor] = useState({});
  const [conductorEdit, setConductorEdit] = useState({});
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  // conductor reducers
  const {
    conductors,
    conductor,
    error,
    conductorsLoading,
    deleteConductorLoading,
    deleteConductorSuccess,
    deleteConductorFail,
    updateConductorLoading,
    updateConductorSuccess,
    upateConductorFail,
  } = useSelector((state) => state.conductor);

  useEffect(() => {
    dispatch(getAllConductors);
  }, [dispatch, deleteConductorLoading]);

  useEffect(() => {
    if (updateConductorSuccess) {
      dispatch(deleteConductorClear());
    }
  }, [updateConductorSuccess, dispatch]);

  useEffect(() => {
    if (upateConductorFail) {
      dispatch(deleteConductorClear());
    }
  }, [upateConductorFail, dispatch]);

  useEffect(() => {
    if (deleteConductorSuccess) {
      dispatch(updateConductorClear());
    }
  }, [deleteConductorSuccess, dispatch]);
  useEffect(() => {
    if (deleteConductorFail) {
      dispatch(updateConductorClear());
    }
  }, [deleteConductorFail, dispatch]);

  const [rowData, setRowData] = useState({});

  const handleChange = (e) => {
    setRowData({ ...rowData, [e.target.name]: e.target.value });
  };

  const showEditModal = (record) => {
    dispatch(getConductor(record.id));
    setConductorEdit(conductor);
    setEditModalVisible(true);
  };

  const handleEditModalCancel = () => {
    setRowData({});
    setEditModalVisible(false);
  };

  const showDeleteModal = (record) => {
    setCurrConductor(record);
    setDeleteModalVisible(true);
  };

  const handleDeleteModalCancel = () => {
    setDeleteModalVisible(false);
  };

  const handleDelete = (id) => {
    dispatch(deleteConductor(id));

    // dispatch(getAllConductors);
    // dispatch(getAllConductors);
    setDeleteModalVisible(false);
  };

  // Form handle
  const onFinish = (values) => {};

  const onFinishFailed = (errorInfo) => {};
  let statusdata = rowData.status;

  function handleChangeStatus(value) {
    statusdata = value;
  }
  const handleUpdate = (data) => {
    if (data.firstName === "") {
      notification.error({
        message: "First Name is Required",
        placement: "top",
        description: ``,
      });
      dispatch(getAllConductors);
      setEditModalVisible(true);
    } else if (data.lastName === "") {
      notification.error({
        message: "Last Name is Required",
        placement: "topRight",
        description: ``,
      });
      dispatch(getAllConductors);
      setEditModalVisible(true);
    } else if (data.phoneNo === "" || phonNumberValidate(data.phoneNo)) {
      notification.error({
        message: "Phone Number is required",
        placement: "topRight",
        description: ``,
      });
      dispatch(getAllConductors);
      setEditModalVisible(true);
    } else if (
      data.email === "" ||
      data.email.match(
        '/^(([^<>()[]\\.,;:s@"]+(.[^<>()[]\\.,;:s@"]+)*)|(".+"))@(([[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}])|(([a-zA-Z-0-9]+.)+[a-zA-Z]{2,}))$/'
      )
    ) {
      notification.error({
        message: "Email is Required",
        placement: "topRight",
        description: ``,
      });
      dispatch(getAllConductors);
      setEditModalVisible(true);
    } else if (data.address === "") {
      notification.error({
        message: "Address is Required",
        placement: "topRight",
        description: ``,
      });
      dispatch(getAllConductors);
      setEditModalVisible(true);
    } else {
      const { firstName, lastName, phoneNo, email, address } = data;
      const status = statusdata;
      const req = { firstName, lastName, phoneNo, email, address, status };

      dispatch(updateConductor(req, data.id));

      dispatch(getAllConductors);
      dispatch(getAllConductors);
      setEditModalVisible(false);
      setRowData({});
    }
  };

   const handleResetConductor = (record) => {
    dispatch(resetConductor(record.id))
  };

  // TODO searching and sorting table functionality for Conductors page
  const [searchText, setSearchText] = useState(false);
  const [searchedColumn, setSearchedColumn] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [filteredInfo, setFilteredInfo] = useState(null);
  const [sortedInfo, setSortedInfo] = useState(null);
  let getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            setSearchInput(node);
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>

          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => setSearchInput(""), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  let handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  let handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
    setFilteredInfo(null);
    setSortedInfo(null);
  };

  // Columns data
  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "First Name",
      dataIndex: "firstName",
      ...getColumnSearchProps("firstName"),
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      ...getColumnSearchProps("lastName"),
    },
    {
      title: "Phone",
      dataIndex: "phoneNo",
      ...getColumnSearchProps("phoneNo"),
    },
    {
      title: "Position",
      dataIndex: "position",
      ...getColumnSearchProps("position"),
    },
    {
      title: "Email",
      dataIndex: "email",
      ...getColumnSearchProps("email"),
    },
    {
      title: "Address",
      dataIndex: "address",
      ...getColumnSearchProps("address"),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text, record) => {
        const active = (
          <Badge
            className="site-badge-count-109"
            count={"Active"}
            style={{ backgroundColor: "#52c41a" }}
          />
        );

        const inactive = <Badge count={"InActive"} />;

        const show = record.status === "ACTIVE" ? active : inactive;

        return show;
      },
    },
    {
      title: "Action",
      render: (text, record) => {
        return (
          <Space size="middle">
            <Button
              style={{ background: "#eba134", borderColor: "#eba134" }}
              type="primary"
              shape="round"
              icon={<EditOutlined />}
              size="small"
              onClick={() => {
                setRowData(record);
                setCurrConductor(record);
                showEditModal(record);
              }}
            />
            <Button
              style={{ background: "#db0925", borderColor: "#db0925" }}
              shape="round"
              type="primary"
              icon={<DeleteOutlined />}
              size="small"
              onClick={() => {
                showDeleteModal(record);
                setCurrConductor(record);
              }}
            />
            {/* <Button
              style={{ background: "#41b0a5", borderColor: "#2f998f" }}
              shape="round"
              type="primary"
              icon={<RightOutlined />}
              size="small"
              onClick={() => handleResetConductor(record)}
            /> */}
          </Space>
        );
      },
    },
  ];

  return (
    <div className="tabled">
      <Row gutter={[24, 0]}>
        <Col span={24}>
          <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title="All conductors of public bus"
            extra={
              <Link to="new-conductor">
                <Button type="primary" icon={<UserAddOutlined />} size="large">
                  New Conductor
                </Button>
              </Link>
            }
          >
            <div
              style={{
                display: "flex",
                padding: "2em 2em 2em 2em",
                width: "100%",
              }}
            >
              <Table
                style={{ width: "100%" }}
                dataSource={conductors}
                columns={columns}
                loading={conductorsLoading ? true : false}
                scroll={{ x: 400 }}
              />
            </div>
          </Card>
        </Col>
      </Row>

      {/*
        Edit Conductor Modal
      */}
      <Modal
        title="Edit Condactor"
        visible={isEditModalVisible}
        onCancel={handleEditModalCancel}
        footer={[
          <Button key="back" onClick={handleEditModalCancel}>
            cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            form="conductor"
            onClick={() => handleUpdate(rowData)}
          >
            Update
          </Button>,
        ]}
        width={1000}
      >
        <Content className="p-0">
          <Form
            id="conductor"
            form={form}
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
            className="row-col"
          >
            <Row>
              <Col
                xs={8}
                xl={8}
                style={{
                  padding: "1em",
                }}
              >
                <label>First Name</label>
                <Input
                  className="firstName"
                  label="First Name"
                  name="firstName"
                  placeholder={rowData.firstName}
                  value={rowData.firstName}
                  onChange={handleChange}
                />
              </Col>
              <Col
                xs={8}
                xl={8}
                style={{
                  padding: "1em",
                }}
              >
                <label>Last Name</label>
                <Input
                  placeholder={rowData.lastName}
                  value={rowData.lastName}
                  onChange={handleChange}
                  className="lastName"
                  label="Last Name"
                  name="lastName"
                />
              </Col>
              <Col
                xs={8}
                xl={8}
                style={{
                  padding: "1em",
                }}
              >
                <label>Phone Number</label>
                <Input
                  placeholder={rowData.phoneNo}
                  value={rowData.phoneNo}
                  onChange={handleChange}
                  className="phone"
                  label="Phone"
                  name="phoneNo"
                />
              </Col>
            </Row>

            <Row>
              <Col
                xs={8}
                xl={8}
                style={{
                  padding: "1em",
                }}
              >
                <label>Email</label>
                <Input
                  placeholder={rowData.email}
                  value={rowData.email}
                  onChange={handleChange}
                  className="email"
                  label="Email"
                  name="email"
                />
              </Col>
              <Col
                xs={8}
                xl={8}
                style={{
                  padding: "1em",
                }}
              >
                <label>Address</label>
                <Input
                  placeholder={rowData.address}
                  value={rowData.address}
                  onChange={handleChange}
                  className="address"
                  label="Address"
                  name="address"
                />
              </Col>
              <Col
                xs={8}
                xl={8}
                style={{
                  padding: "1em",
                }}
              >
                <label>Status</label>
                <Select
                  className="status"
                  label="Status"
                  onChange={handleChangeStatus}
                  rules={[
                    {
                      required: true,
                      message: "Please input Status!",
                    },
                  ]}
                  style={{ width: "100%" }}
                  size="large"
                  placeholder="Human Resource Status"
                  value={rowData.status}
                >
                  <Option value="ACTIVE">Active</Option>
                  <Option value="INACTIVE">InActive</Option>
                </Select>
              </Col>
            </Row>
          </Form>
        </Content>
      </Modal>

      {/*
        Delete Conductor Modal
      */}
      <Modal
        title="Delete Human Resource"
        visible={isDeleteModalVisible}
        onCancel={handleDeleteModalCancel}
        footer={[
          <Button key="back" onClick={handleDeleteModalCancel}>
            cancel
          </Button>,
          <Button
            key="submit"
            danger
            type="primary"
            onClick={() => {
              handleDelete(currConductor.id);
            }}
          >
            {deleteConductorLoading ? <Spin indicator={antIcon} /> : "Delete"}
          </Button>,
        ]}
      >
        <p>Are you sure?</p>
        <p>you want to delete conductor?</p>
        <p>{`${currConductor.firstName} ${currConductor.lastName}`}</p>
      </Modal>
    </div>
  );
}

export default Conductors;
