import { useState, useEffect } from "react";
import { Typography, Progress } from "antd";
import {
  getDashboardCategoryReport,
  clearReports,
} from "../../redux/report/action";
import { useDispatch, useSelector } from "react-redux";

function EChart() {
  const { Title, Paragraph } = Typography;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDashboardCategoryReport);

    return () => {
      dispatch(clearReports);
    };
  }, [dispatch]);
  const {
    reports,
    reportLoading,
    reportDashboardReportSuccess,
    reportsCatagory,
  } = useSelector((state) => state.report);
  return (
    <>
      <div className="chart-vistior">
        <Title level={5}>The Way Tickets are Booked</Title>
        <Paragraph className="lastweek">
          Booked By Customer
          <Progress
            percent={reportsCatagory.customers}
            showInfo={false}
            status="exception"
          />
        </Paragraph>
        <Paragraph className="lastweek">
          Booked By Bank
          <Progress percent={reportsCatagory.bank} showInfo={false} />
        </Paragraph>
        <Paragraph className="lastweek">
          Booked By Conductors
          <Progress percent={reportsCatagory.conductors} showInfo={false} />
        </Paragraph>
      </div>
    </>
  );
}

export default EChart;
