import { AuthActionTypes } from "./types";
import axios from "axios";
import jwt_decode from "jwt-decode";
import {
  busAdminNameKey,
  busAdminTokenKey,
  BASE_URI,
} from "../../config/key.config";
import setAuthToken from "../../utils/setAuthToken";
import { loginHeader } from "../redux.util";

/**
 * Login Actions
 */

export const loginStart = () => ({
  type: AuthActionTypes.LOGIN_START,
});

export const loginSuccess = (user, token) => ({
  type: AuthActionTypes.LOGIN_SUCCESS,
  payload: {
    user,
    token,
  },
});

export const loginError = (error) => ({
  type: AuthActionTypes.LOGIN_ERROR,
  payload: {
    error,
  },
});

export const clearLoginSuccess = () => ({
  type: AuthActionTypes.CLEAR_LOGIN_SUCCESS,
});

/**
 * Logout actions
 */
export const logOut = () => ({
  type: AuthActionTypes.LOG_OUT,
});

/**
 * Async Action Types
 */
export const loginAsync = (phone, password, history) => {
  return async (dispatch) => {
    try {
      dispatch(loginStart());

      const response = await axios.post(
        `https://publicbus-dev-api.liyubus.com/bus-admins/auth/login`,
        // `${process.env.REACT_APP_API_URL}/auth/login`,
        { phone, password },
        {
          headers: {
            "x-api-key": "zR0ClHzLGi",
          },
        }
      );

      const { data } = response.data;
      const token = data.access_token;

      const userRole = jwt_decode(token);

      localStorage.setItem(busAdminNameKey, data);
      localStorage.setItem(busAdminTokenKey, token);

      dispatch(loginSuccess(data, token));

      // const _data_ = jwt_decode(token);
      history.push("/director/dashboard");
      if (userRole.user.role === "user_director") {
        history.push("/director/dashboard");
      } else if (userRole.user.role === "user_basic") {
        history.push("/basic-access/dashboard");
      } else if (userRole.user.role === "user_finance") {
        history.push("/finance/dashboard");
      } else if (userRole.user.role === "user_oprator") {
        history.push("/operation/dashboard");
      } else if (userRole.user.role === "user_reporter") {
        history.push("/reporter/dashboard");
      } else {
        history.push("/");
      }
      dispatch(setCurrentUser(data));

      // const checkToken = localStorage.getItem(busAdminTokenKey);
      // let userRole;
      // let userData;
      // if (checkToken === null) {
      //   const response = await axios.post(
      //     `http://liyubus-dev-api.liyubus.com:4000/bus-admins/auth/login`,
      //     // `${process.env.REACT_APP_API_URL}/auth/login`,
      //     { phone, password },
      //     {
      //       headers: {
      //         "x-api-key": "zR0ClHzLGi",
      //       },
      //     }
      //   );
      //   const { data } = response.data;
      //   const token = data.access_token;
      //   userData = data;

      //   userRole = jwt_decode(token);

      //   localStorage.setItem(busAdminNameKey, data);
      //   localStorage.setItem(busAdminTokenKey, token);

      //   dispatch(loginSuccess(data, token));
      // } else {
      //   userRole = jwt_decode(localStorage.getItem(busAdminTokenKey));
      // }
    } catch (error) {
      dispatch(loginError(error));
    }
  };
};

// export const loginAsync = (phone, password, dispatch) => {
//   dispatch(loginStart());

//   axios
//     .post(
//       "http://liyubus-dev-api.liyubus.com:4000/bus-admins/auth/login",
//       // `${process.env.REACT_APP_API_URL}/auth/login`,
//       { phone, password },
//       {
//         header: {
//           "x-api-key": "zR0ClHzLGi",
//         },
//       }
//     )
//     .then((res) => {
//     })
//     .catch((err) => {
//     });
// };

//set logged in user
export const setCurrentUser = (data) => {
  const user = jwt_decode(data);
  return {
    type: AuthActionTypes.SET_CURRENT_USER,
    payload: user.user,
  };
};

export const getUserData = () => {
  return {
    name: localStorage.getItem(busAdminTokenKey),
  };
};

// set logged out users
export const logoutUser = (history) => (dispatch) => {
  // remove token from localStorage
  localStorage.clear();
  // remove auth header for future request
  setAuthToken(false);
  history.push("/");
  // Set current use {} whixh set isAUtheticaition to false
  dispatch(setCurrentUser({}));
};
