import axios from "axios";
import { busAdminTokenKey, BASE_URI } from "../../config/key.config";
import { GET_REVIEWS, GET_RATES, ERROR_REVIEW } from "./types";

export const getReviews = () => (dispatch) => {
  return dispatch({ type: GET_REVIEWS });

//   axios
//     .get(`${BASE_URI}/reviews`, {
//       headers: {
//         Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
//       },
//     })
//     .then((res) => dispatch({ type: GET_REVIEWS, payload: res.data }))
//     .catch((err) => dispatch({ type: ERROR_REVIEW }));
};

export const getRates = () => (dispatch) => {
  return dispatch({ type: GET_RATES });

//   axios
//     .get(`${BASE_URI}/reviews/rating`, {
//       headers: {
//         Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
//       },
//     })
//     .then((res) => dispatch({ type: GET_RATES, payload: res.data }))
//     .catch((err) => dispatch({ type: ERROR_REVIEW }));
};
