import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Table,
  Space,
  Modal,
  Input,
  Badge,
} from "antd";
import { Link, useHistory } from "react-router-dom";
import Highlighter from "react-highlight-words";
import {
  UserAddOutlined,
  SearchOutlined,
  RightOutlined,
  RollbackOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  getRefunds,
  acceptRefund,
  rejectRefund,
} from "../../redux/refund/action";

function Refund() {
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    dispatch(getRefunds("WAITING"));
  }, [dispatch]);
  const { refunds, refundsLoading } = useSelector((state) => state.refund);
  const [isAcceptModalVisible, setAcceptModalVisible] = useState(false);
  const [isRejectRefundModalVisible, steRejectRefundMidalVisible] =
    useState(false);
  const [refundData, setRefundData] = useState({});

  // Handle edit modal
  const showAcceptRefundModal = () => {
    setAcceptModalVisible(true);
  };

  const handleAcceptRefundModalCancel = () => {
    setRefundData({});
    setAcceptModalVisible(false);
  };

  const handleAcceptRefundRequest = () => {
    dispatch(acceptRefund(refundData.id));
    dispatch(getRefunds("WAITING"));
    handleAcceptRefundModalCancel();
  };

  // Handle delete modal
  const showRejectRefundModal = () => {
    steRejectRefundMidalVisible(true);
  };

  const handleRejectRefundModalCancel = () => {
    setRefundData({});
    steRejectRefundMidalVisible(false);
  };

  const handleRejectRefundRequest = () => {
    dispatch(rejectRefund(refundData.id));
    dispatch(getRefunds("WAITING"));
    handleRejectRefundModalCancel();
  };

  // Form handle
  const onFinish = (values) => {};

  const onFinishFailed = (errorInfo) => {};

  const modalToggle = (record, rowIndex) => {
    setAcceptModalVisible(true);
  };

  // TODO searching and sorting table functionality for Conductors page
  const [searchText, setSearchText] = useState(false);
  const [searchedColumn, setSearchedColumn] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [filteredInfo, setFilteredInfo] = useState(null);
  const [sortedInfo, setSortedInfo] = useState(null);
  let getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            setSearchInput(node);
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>

          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => setSearchInput(""), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    // {
    //   title: "Passenger ID.",
    //   dataIndex: "passengersId",
    //   key: "passengersId",
    //   ...getColumnSearchProps("passengersId"),
    // },
    {
      title: "Payed Amount",
      dataIndex: "payedAmount",
      key: "payedAmount",
      ...getColumnSearchProps("payedAmount"),
    },
    {
      title: "Canelation Fee Percent",
      dataIndex: "cancelationFeePercent",
      key: "cancelationFeePercent",
      ...getColumnSearchProps("cancelationFeePercent"),
    },
    {
      title: "Cancelation Fee",
      dataIndex: "cancelationFee",
      key: "cancelationFee",
      ...getColumnSearchProps("cancelationFee"),
    },
    {
      title: "Causes",
      dataIndex: "causes",
      key: "causes",
      ...getColumnSearchProps("causes"),
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      ...getColumnSearchProps("createdAt"),
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      key: "updatedAt",
      ...getColumnSearchProps("updatedAt"),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      ...getColumnSearchProps("status"),
      render: (text, record) => {
        const active = (
          <Badge
            className="site-badge-count-109"
            count={"Waiting"}
            style={{ backgroundColor: "#52c41a" }}
          />
        );

        const inactive = <Badge count={""} />;

        const show = record.status === "WAITING" ? active : inactive;

        return show;
      },
    },
    {
      title: "Action",
      key: "action",
      width: "20%",
      render: (text, record) => (
        <>
          <Space size="middle">
            <Button
              style={{ background: "#41b0a5", borderColor: "#2f998f" }}
              type="primary"
              shape="round"
              icon={<RightOutlined />}
              size="small"
              onClick={() => {
                showAcceptRefundModal();
                setRefundData(record);
              }}
            />
            <Button
              style={{ background: "#ff4d4f", borderColor: "#ff4d4f" }}
              type="primary"
              shape="round"
              icon={<RollbackOutlined />}
              size="small"
              onClick={() => {
                showRejectRefundModal();
                setRefundData(record);
              }}
            />
          </Space>
        </>
      ),
    },
  ];

  let handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  let handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
    setFilteredInfo(null);
    setSortedInfo(null);
  };

  return (
    <div className="tabled">
      <Row gutter={[24, 0]}>
        <Col span={24}>
          <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title="Refund Lists"
            extra={
              <Link to="new-refund">
                <Button type="primary" icon={<UserAddOutlined />} size="large">
                  Add Refund
                </Button>
              </Link>
            }
          >
            <div
              style={{
                display: "flex",
                padding: "2em 2em 2em 2em",
                width: "100%",
              }}
            >
              <Table
                style={{ width: "100%" }}
                dataSource={refunds}
                columns={columns}
                loading={refundsLoading}
              />
            </div>
          </Card>
        </Col>
      </Row>

      {/*
        Edit Conductor Modal
      */}
      <Modal
        title="Accept Refund"
        visible={isAcceptModalVisible}
        onCancel={handleAcceptRefundModalCancel}
        footer={[
          <Button key="back" onClick={handleAcceptRefundModalCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleAcceptRefundRequest}
          >
            Accept
          </Button>,
        ]}
      >
        <p>
          <strong>ID</strong> - {refundData.id}
        </p>
        <p>
          <strong>Payed Amount</strong> - {refundData.payedAmount}
        </p>
        <p>
          <strong>Cancelation Fee Percent</strong> -{" "}
          {refundData.cancelationFeePercent}
        </p>
        <p>
          <strong>Cancelation Fee</strong> - {refundData.cancelationFee}
        </p>
        <p>Do You want to Accept this refund request?</p>
      </Modal>

      {/*
        Delete Conductor Modal
      */}
      <Modal
        title="Delete Conductor"
        visible={isRejectRefundModalVisible}
        onCancel={handleRejectRefundModalCancel}
        footer={[
          <Button key="back" onClick={handleRejectRefundModalCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            danger
            type="primary"
            onClick={handleRejectRefundRequest}
          >
            Reject
          </Button>,
        ]}
      >
        <p>
          <strong>ID</strong> - {refundData.id}
        </p>
        <p>
          <strong>Payed Amount</strong> - {refundData.payedAmount}
        </p>
        <p>
          <strong>Cancelation Fee Percent</strong> -{" "}
          {refundData.cancelationFeePercent}
        </p>
        <p>
          <strong>Cancelation Fee</strong> - {refundData.cancelationFee}
        </p>
        <p>Do You want to Reject this refund request?</p>
      </Modal>
    </div>
  );
}

export default Refund;
