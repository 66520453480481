import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Layout,
  Row,
  Col,
  Button,
  Card,
  Form,
  Input,
  Checkbox,
  Spin,
  Select,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { createUser } from "../../redux/user/action";
const { Content } = Layout;
const { Option } = Select;
const CheckboxGroup = Checkbox.Group;

const plainOptions = ["Create", "Read", "Update", "Delete"];
const defaultCheckedList = ["Apple", "Orange"];

const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "white" }} spin />
);

function NewUser() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [checkedList, setCheckedList] = useState(defaultCheckedList);
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(false);
  const { createUserLoading } = useSelector((state) => state.user);

  const onFinish = (values) => {
    const busAssociation = 1;
    const { firstName, lastName, email, phoneNo, role, userName } = values;
    const formData = {
      firstName,
      lastName,
      email,
      phoneNo,
      role,
      userName,
      busAssociation,
    };
    // const formData = { values, busAssociation };
    dispatch(createUser(formData, history));
  };

  const onFinishFailed = (errorInfo) => {};
  const onChange = (list) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < plainOptions.length);
    setCheckAll(list.length === plainOptions.length);
  };
  const onCheckAllChange = (e) => {
    setCheckedList(e.target.checked ? plainOptions : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };
  return (
    <div className="tabled">
      <Row gutter={[24, 0]}>
        <Col xs={24} xl={24}>
          <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title="Add New User"
          >
            <div
              style={{
                display: "flex",
                padding: "2em 2em 2em 2em",
                width: "100%",
              }}
            >
              <Form
                name="basic"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                layout="vertical"
                // className="row-col"
                style={{ width: "100%" }}
              >
                <Card
                  bordered={false}
                  className="criclebox tablespace mb-24"
                  title="User Information"
                  style={{ width: "97%" }}
                >
                  <Content className="p-0">
                    <Row gutter={[24, 0]}>
                      <Col
                        xs="8"
                        xl={8}
                        style={{
                          padding: "1em",
                        }}
                      >
                        <Form.Item
                          className="firstName"
                          label="First Name"
                          name="firstName"
                          rules={[
                            {
                              required: true,
                              message: "Please input your first name!",
                            },
                          ]}
                        >
                          <Input placeholder="First Name..." />
                        </Form.Item>
                      </Col>
                      <Col
                        xs={8}
                        xl={8}
                        style={{
                          padding: "1em",
                        }}
                      >
                        <Form.Item
                          className="lastName"
                          label="Last Name"
                          name="lastName"
                          rules={[
                            {
                              required: true,
                              message: "Please input your last name!",
                            },
                          ]}
                        >
                          <Input placeholder="Last name..." />
                        </Form.Item>
                      </Col>
                      <Col
                        xs={8}
                        xl={8}
                        style={{
                          padding: "1em",
                        }}
                      >
                        <Form.Item
                          className="phoneNo"
                          label="Phone"
                          name="phoneNo"
                          rules={[
                            {
                              required: true,
                              message: "Please input your phone!",
                            },
                          ]}
                        >
                          <Input placeholder="Phone..." />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row gutter={[24, 0]}>
                      <Col
                        xs="8"
                        xl={8}
                        style={{
                          padding: "1em",
                        }}
                      >
                        <Form.Item
                          className="email"
                          label="Email"
                          name="email"
                          rules={[
                            {
                              required: true,
                              message: "Please input your email!",
                            },
                          ]}
                        >
                          <Input placeholder="Email..." />
                        </Form.Item>
                      </Col>
                      <Col
                        xs={8}
                        xl={8}
                        style={{
                          padding: "1em",
                        }}
                      >
                        <Form.Item
                          className="userName"
                          label="User Name"
                          name="userName"
                          rules={[
                            {
                              required: true,
                              message: "Please input your User Name!",
                            },
                          ]}
                        >
                          <Input placeholder="User Name..." />
                        </Form.Item>
                      </Col>
                      <Col
                        xs={8}
                        xl={8}
                        style={{
                          padding: "1em",
                        }}
                      >
                        <Form.Item
                          className="role"
                          label="User Type"
                          name="role"
                          rules={[
                            {
                              required: true,
                              message: "Please input the User Type!",
                            },
                          ]}
                        >
                          <Select
                            placeholder="User Type ..."
                            style={{ width: "100%", borderRadius: "20px" }}
                          >
                            <Option value="user_basic">Basic User</Option>
                            <Option value="user_finance">Finance</Option>
                            <Option value="user_oprator">Operator</Option>
                            <Option value="user_reporter">Reporter</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Content>
                </Card>

                <Content className="mt-5" style={{ paddingTop: 20 }}>
                  <Form.Item>
                    <div
                      style={{
                        paddingTop: 30,
                        width: "95%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        type="primary"
                        htmlType="submit"
                        style={{
                          width: "15%",
                        }}
                      >
                        {createUserLoading ? (
                          <Spin indicator={antIcon} />
                        ) : (
                          "Register"
                        )}
                      </Button>
                    </div>
                  </Form.Item>
                </Content>
              </Form>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default NewUser;
