import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Input,
  Button,
  Space,
  Select,
  Divider,
  DatePicker,
} from "antd";
import {
  getDashboardReportYear,
} from "../../redux/report/action";
import { Table } from "ant-table-extensions";
import Highlighter from "react-highlight-words";
import { useDispatch, useSelector } from "react-redux";
import { SearchOutlined, MoreOutlined, SwapOutlined } from "@ant-design/icons";
import { getTodayReport } from "../../redux/report/action";
import { getRoutes } from "../../redux/route/action";
import { getBuses } from "../../redux/bus/action";

const { Option, OptGroup } = Select;

function OfechoReport() {
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState(false);
  const [searchedColumn, setSearchedColumn] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [filteredInfo, setFilteredInfo] = useState(null);
  const [sortedInfo, setSortedInfo] = useState(null);

  useEffect(() => {
    dispatch(getTodayReport);
    dispatch(getBuses);
    dispatch(getRoutes);
  }, [dispatch]);


  const { routes } = useSelector((state) => state.route);
  const { buses } = useSelector((state) => state.bus);
  const { reportInterval, reportIntervalLoading } = useSelector(
    (state) => state.report
  );
  useEffect(() => {
    dispatch(getDashboardReportYear);
  }, [dispatch]);

  let getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            setSearchInput(node);
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>

          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => setSearchInput(""), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  let handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  let handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
    setFilteredInfo(null);
    setSortedInfo(null);
  };

  function handleChange(value) {
  }

  const columns = [
    {
      title: "Passenger",
      dataIndex: "passenger",
      key: "passenger",
      width: "7%",
    },
    {
      title: "Seat",
      dataIndex: "seat",
      key: "seat",
      width: "17%",
      ...getColumnSearchProps("date"),
    },
    {
      title: "PhoneNumber",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      width: "22%",
      ...getColumnSearchProps("from"),
      // sorter: (a, b) => a.from.length - b.from.length,
      // sortDirections: ["descend", "ascend"],
    },
    {
      title: "Issued By",
      dataIndex: "issuedBy",
      key: "issuedBy",
      width: "22%",
      ...getColumnSearchProps("to"),
      // sorter: (a, b) => a.to.length - b.to.length,
      // sortDirections: ["descend", "ascend"],
    },
    {
      title: "Bus",
      dataIndex: "bus",
      key: "bus",
      width: "12%",
      ...getColumnSearchProps("bus"),
    },
    {
      title: "Fare",
      dataIndex: "fare",
      key: "fare",
      width: "15%",
    },
    {
      title: "Date",
      dataIndex: "travelDate",
      key: "travelDate",
      width: "15%",
    },
    {
      title: "Action",
      key: "action",
      width: "20%",
      render: (text, record) => (
        <Space size="middle">
          <Button
            style={{ background: "#eba134", borderColor: "#eba134" }}
            type="primary"
            shape="round"
            icon={<MoreOutlined rotate={90} />}
            size="small"
          />
        </Space>
      ),
    },
  ];
  return (
    <div className="tabled">
      <Row gutter={[24, 0]}>
        <Col xs={24} xl={24}>
          <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title="Ofecho Report"
          >
            <Row
              gutter={[24, 0]}
              style={{
                paddingTop: "2%",
                paddingLeft: "2%",
              }}
            >
              <Col
                xl={6}
                style={
                  {
                    // alignItems: "left",
                  }
                }
              >
                <h5>Date Range:</h5>
                <DatePicker style={{ width: "100%" }} />
              </Col>

              <Col
                xl={5}
                // md={8}
                style={{
                  alignItems: "left",
                  paddingLeft: "1px",
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <div>
                  <h5>Route:</h5>

                  <Select
                    defaultValue="Addis Ababa"
                    style={{ width: 250 }}
                    onChange={handleChange}
                  >
                    <OptGroup label="Select Route">
                      {routes.map((route) => (
                        <Option value={route.id}>
                          {route.from} - {route.to}
                        </Option>
                      ))}
                    </OptGroup>
                  </Select>
                </div>
              </Col>

              <Col
                md={9}
                style={{
                  alignItems: "left",
                  paddingLeft: "1px",
                }}
              >
                <h5>Bus:</h5>
                <Select
                  defaultValue="1914"
                  style={{ width: 150 }}
                  onChange={handleChange}
                >
                  <OptGroup label="Bus Side No.">
                    {buses.map((bus) => (
                      <Option value={bus.id}>{bus.plateNumber}</Option>
                    ))}
                  </OptGroup>
                </Select>
              </Col>
              <Col
                md={3}
                xl={3}
                style={{
                  alignItems: "left",
                  paddingLeft: "1px",
                  paddingTop: "15px",
                }}
              >
                {/* <Link to="/#"> */}
                <Button type="primary" icon={<SwapOutlined />} size="large">
                  Generate
                </Button>
                {/* </Link> */}
              </Col>
            </Row>
            <Divider />
            <Table
              columns={columns}
              dataSource={reportInterval}
              loading={reportIntervalLoading}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default OfechoReport;
