import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Table,
  Input,
  Button,
  Space,
  Select,
  InputNumber,
  Modal,
  Badge,
  Layout,
  Form,
} from "antd";
import Highlighter from "react-highlight-words";
import { Link } from "react-router-dom";
import {
  SearchOutlined,
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  getCancellations,
  updateCancellation,
  deleteCancellation,
} from "../../redux/cancellation_policy/action";
import { useDispatch, useSelector } from "react-redux";
const { Option } = Select;
const { Content } = Layout;

function CancellationPolicy() {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState(false);
  const [searchedColumn, setSearchedColumn] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [filteredInfo, setFilteredInfo] = useState(null);
  const [sortedInfo, setSortedInfo] = useState(null);

  const [rowData, setRowData] = useState({});
  const [isEditModalVisible, setEditModalVisible] = useState(false);
  const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);

  const {
    cancellations,
    cancellationLoading,
    cancellationsLoading,
    createCancellationLoading,
    updateCancellationLoading,
    deleteCancellationLoading,
  } = useSelector((state) => state.cancellation_policy);
  useEffect(() => {
    dispatch(getCancellations);
  }, [dispatch, deleteCancellationLoading]);

  const showEditModal = (record) => {
    setEditModalVisible(true);
  };

  const showDeleteModal = (record) => {
    setRowData(record);
    setDeleteModalVisible(true);
  };

  const handleEditModalCancel = () => {
    setRowData({});
    setEditModalVisible(false);
    // setCurrId("");
  };
  const handleDeleteModalCancel = () => {
    setRowData({});
    setDeleteModalVisible(false);
    // setCurrId("");
  };
  let chargeData = rowData ? rowData.charge : "";
  function handleChangeCharge(value) {
    chargeData = value.toString();
  }
  let nameData = rowData.name ? rowData.name : "";
  function handleChangeName(value) {
    nameData = value.toString();
  }

  let statusData = rowData ? rowData.status : "ACTIVE";
  function handleChangeStatus(value) {
    statusData = value;
  }

  const handleUpdate = () => {
    const name = nameData;
    const charge = chargeData;
    const status = statusData;
    const req = { name, charge, status };
    dispatch(updateCancellation(rowData.id, req));
    handleEditModalCancel();

    dispatch(getCancellations);
    dispatch(getCancellations);
  };

  const handleDelete = () => {
    dispatch(deleteCancellation(rowData.id));
    handleDeleteModalCancel();
  };
  let getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            setSearchInput(node);
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>

          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => setSearchInput(""), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  let handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  let handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
    setFilteredInfo(null);
    setSortedInfo(null);
  };

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      // width: "7%",
    },
    {
      title: "Name",
      dataIndex: "name",
      ...getColumnSearchProps("name"),
    },
    {
      title: "Charge",
      dataIndex: "charge",
      // width: "32%",
      ...getColumnSearchProps("charge"),
      // sorter: (a, b) => a.from.length - b.from.length,
      // sortDirections: ["descend", "ascend"],
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text, record) => {
        const active = (
          <Badge
            className="site-badge-count-109"
            count={"Active"}
            style={{ backgroundColor: "#52c41a" }}
          />
        );

        const inactive = <Badge count={"Cancelled"} />;

        const show = record.status === "ACTIVE" ? active : inactive;

        return show;
      },
    },
    {
      title: "Action",
      render: (text, record) => (
        <Space size="middle">
          <Button
            style={{ background: "#eba134", borderColor: "#eba134" }}
            type="primary"
            shape="round"
            icon={<EditOutlined />}
            size="small"
            onClick={() => {
              setRowData(record);
              showEditModal(record);
            }}
          />
          <Button
            style={{ background: "#db0925", borderColor: "#db0925" }}
            shape="round"
            type="primary"
            icon={<DeleteOutlined />}
            size="small"
            onClick={() => {
              setRowData(record);
              showDeleteModal(record);
            }}
          />
        </Space>
      ),
    },
  ];
  return (
    <div className="tabled">
      <Row gutter={[24, 0]}>
        <Col xs="24" xl={24}>
          <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title="Informations"
            extra={
              <Link to="new-policy">
                <Button type="primary" icon={<PlusOutlined />} size="large">
                  New Policy
                </Button>
              </Link>
            }
          >
            <Table
              columns={columns}
              dataSource={cancellations}
              loading={cancellationsLoading}
            />
          </Card>
        </Col>
      </Row>

      <Modal
        title="Edit Cancellation Policy"
        visible={isEditModalVisible}
        onCancel={handleEditModalCancel}
        footer={[
          <Button key="back" onClick={handleEditModalCancel}>
            cancel
          </Button>,
          <Button key="submit" type="primary" onClick={() => handleUpdate()}>
            Update
          </Button>,
        ]}
        width={600}
      >
        <Content className="p-0">
          <Form
            name="basic"
            initialValues={{ remember: true }}
            layout="vertical"
            className="row-col"
          >
            <Row>
              <Col
                xs={24}
                xl={24}
                style={{
                  padding: "1em",
                }}
              >
                <label>Name</label>
                <Select
                  value={rowData.name}
                  className="name"
                  label="From"
                  name="name"
                  style={{ width: "100%" }}
                  size="large"
                  onChange={handleChangeName}
                  showSearch
                >
                  <Option value="Before 1 Day">Before 1 Day</Option>
                  <Option value="Before 2 Day">Before 2 Day</Option>
                  <Option value="Before 3 Day">Before 3 Day</Option>
                  <Option value="Before 4 Day">Before 4 Day</Option>
                  <Option value="Before 5 Day">Before 5 Day</Option>
                  <Option value="Before 6 Day">Before 6 Day</Option>
                  <Option value="Before 7 Day">Before 7 Day</Option>
                  <Option value="Before 8 Day">Before 8 Day</Option>
                  <Option value="Before 9 Day">Before 9 Day</Option>
                  <Option value="Before 10 Day">Before 10 Day</Option>
                </Select>
              </Col>
            </Row>
            <Row>
              <Col
                xs={24}
                xl={24}
                style={{
                  padding: "1em",
                }}
              >
                <label>Charge</label>
                <InputNumber
                  className="charge"
                  label="Charge"
                  name="charge"
                  defaultValue={rowData.charge}
                  onChange={handleChangeCharge}
                  min={0}
                  style={{ width: "100%" }}
                  size="large"
                />
              </Col>
            </Row>
            <Row>
              <Col
                xs={24}
                xl={24}
                style={{
                  padding: "1em",
                }}
              >
                <label>Status</label>
                <Select
                  className="status"
                  label="Status"
                  onChange={handleChangeStatus}
                  style={{ width: "100%" }}
                  size="large"
                  placeholder="Conductor Status"
                  value={rowData.status}
                >
                  <Option value="ACTIVE">Active</Option>
                  <Option value="INACTIVE">InActive</Option>
                </Select>
              </Col>
            </Row>
          </Form>
        </Content>
      </Modal>

      <Modal
        title="Delete Route"
        visible={isDeleteModalVisible}
        onCancel={handleDeleteModalCancel}
        footer={[
          <Button key="back" onClick={handleDeleteModalCancel}>
            cancel
          </Button>,
          <Button
            key="submit"
            danger
            type="primary"
            onClick={() => handleDelete()}
          >
            Delete
          </Button>,
        ]}
      >
        <p>Are you sure?</p>
        <p>you want to delete the following Cancellation Policy?</p>
        <p>Day - {rowData.name}</p>
        <p>Charge - {rowData.charge}</p>
      </Modal>
    </div>
  );
}

export default CancellationPolicy;
