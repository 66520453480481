export const ReportTypes = {
  REPORT_BY_ROUTE_DATE_PENDING: "REPORT_BY_ROUTE_DATE_PENDING",
  REPORT_BY_ROUTE_DATE_INTERVAL_PENDING:
    "REPORT_BY_ROUTE_DATE_INTERVAL_PENDING",
  REPORT_BY_DATE_PENDING: "REPORT_BY_DATE_PENDING",
  OFECHO_REPORT_PENDING: "OFECHO_REPORT_PENDING",
  REPORT_TODAY_PENDING: "REPORT_TODAY_PENDING",
  REPORT_WEEK_PENDING: "REPORT_WEEK_PENDING",
  REPORT_MONTH_PENDING: "REPORT_MONTH_PENDING",
  REPORT_YEAR_PENDING: "REPORT_YEAR_PENDING",
  DASHBOARD_REPORT_PENDING: "DASHBOARD_REPORT_PENDING",
  DASHBOARD_CATAGORY_REPORT_PENDING: "DASHBOARD_CATAGORY_REPORT_PENDING",
  DASHBPARD_REPORT_FOR_BANKS_PENDING: "DASHBPARD_REPORT_FOR_BANKS_PENDING",
  DASHBOARD_REPORT_CONDUCTOR_PENDING: "DASHBOARD_REPORT_CONDUCTOR_PENDING",

  REPORT_TODAY: "REPORT_TODAY",
  REPORT_WEEK: "REPORT_WEEK",
  REPORT_MONTH: "REPORT_MONTH",
  REPORT_YEAR: "REPORT_YEAR",
  REPORT_BY_ROUTE_DATE: "REPORT_BY_ROUTE_DATE",
  REPORT_BY_ROUTE_DATE_INTERVAL: "REPORT_BY_ROUTE_DATE_INTERVAL",
  REPORT_BY_DATE: "REPORT_BY_DATE",
  OFECHO_REPORT: "OFECHO_REPORT",
  DASHBOARD_REPORT: "DASHBOARD_REPORT",
  DASHBOARD_CATAGORY_REPORT: "DASHBOARD_CATAGORY_REPORT",
  DASHBPARD_REPORT_FOR_BANKS: "DASHBPARD_REPORT_FOR_BANKS",
  DASHBOARD_REPORT_CONDUCTOR: "DASHBOARD_REPORT_CONDUCTOR",

  REPORT_BY_ROUTE_DATE_FAIL: "REPORT_BY_ROUTE_DATE_FAIL",
  REPORT_BY_ROUTE_DATE_INTERVAL_FAIL: "REPORT_BY_ROUTE_DATE_INTERVAL_FAIL",
  REPORT_BY_DATE_FAIL: "REPORT_BY_DATE_FAIL",
  OFECHO_REPORT_FAIL: "OFECHO_REPORT_FAIL",
  REPORT_TODAY_FAIL: "REPORT_TODAY_FAIL",
  REPORT_WEEK_FAIL: "REPORT_WEEK_FAIL",
  REPORT_MONTH_FAIL: "REPORT_MONTH_FAIL",
  REPORT_YEAR_FAIL: "REPORT_YEAR_FAIL",
  DASHBOARD_REPORT_FAIL: "DASHBOARD_REPORT_FAIL",
  DASHBOARD_CATAGORY_REPORT_FAIL: "DASHBOARD_CATAGORY_REPORT_FAIL",
  DASHBPARD_REPORT_FOR_BANKS_FAIL: "DASHBPARD_REPORT_FOR_BANKS_FAIL",
  DASHBOARD_REPORT_CONDUCTOR_FAIL: "DASHBOARD_REPORT_CONDUCTOR_FAIL",

  REPORT_BY_ROUTE_DATE_CLEAR: "REPORT_BY_ROUTE_DATE_CLEAR",
  REPORT_BY_ROUTE_DATE_INTERVAL_CLEAR: "REPORT_BY_ROUTE_DATE_INTERVAL_CLEAR",
  REPORT_BY_DATE_CLEAR: "REPORT_BY_DATE_CLEAR",
  OFECHO_REPORT_CLEAR: "OFECHO_REPORT_CLEAR",
  REPORT_TODAY_CLEAR: "REPORT_TODAY_CLEAR",
  REPORT_WEEK_CLEAR: "REPORT_WEEK_CLEAR",
  REPORT_MONTH_CLEAR: "REPORT_MONTH_CLEAR",
  REPORT_YEAR_CLEAR: "REPORT_YEAR_CLEAR",
  DASHBOARD_REPORT_CLEAR: "DASHBOARD_REPORT_CLEAR",
  DASHBOARD_CATAGORY_REPORT_CLEAR: "DASHBOARD_CATAGORY_REPORT_CLEAR",
  DASHBPARD_REPORT_FOR_BANKS_CLEAR: "DASHBPARD_REPORT_FOR_BANKS_CLEAR",
  DASHBOARD_REPORT_CONDUCTOR_CLEAR: "DASHBOARD_REPORT_CONDUCTOR_CLEAR",

  REPORT_CLEAR: "REPORT_CLEAR",
};
