import axios from "axios";
import { SubRouteTypes } from "./types";
import { busAdminTokenKey, BASE_URI } from "../../config/key.config";

export const fetchSubRouteStart = () => ({
  type: SubRouteTypes.SUB_ROUTE_PENDING,
});

export const fetchSubRouteSuccess = (id, subRoute) => ({
  type: SubRouteTypes.SUB_ROUTE_SUCCESS,
  payload: {
    id,
    subRoute,
  },
});

export const fetchSubRouteFail = (error) => ({
  type: SubRouteTypes.SUB_ROUTE_FAIL,
  payload: {
    error,
  },
});

export const clearFetchSubRoute = () => ({
  type: SubRouteTypes.CLEAR_SUB_ROUTE,
});

export const fetchSubRoutesStart = () => ({
  type: SubRouteTypes.SUB_ROUTES_PENDING,
});

export const fetchSubRoutesSuccess = (subRoutes) => ({
  type: SubRouteTypes.SUB_ROUTES_SUCCESS,
  payload: {
    subRoutes,
  },
});

export const fetchSubRoutesFail = (error) => ({
  type: SubRouteTypes.SUB_ROUTES_FAIL,
  payload: {
    error,
  },
});

export const clearFetchSubRoutes = () => ({
  type: SubRouteTypes.CLEAR_SUB_ROUTES,
});
//TODO create Route
export const createSubRouteStart = () => ({
  type: SubRouteTypes.CREATE_SUB_ROUTE_PENDING,
});

export const createSubRouteSuccess = (subRoute) => ({
  type: SubRouteTypes.CREATE_SUB_ROUTE_SUCCESS,
  payload: {
    subRoute,
  },
});

export const createSubRouteFail = (error) => ({
  type: SubRouteTypes.CREATE_SUB_ROUTE_FAIL,
  payload: {
    error,
  },
});

export const createCreateSubRoute = () => ({
  type: SubRouteTypes.CREATE_CLEAR_SUB_ROUTE,
});

//TODO Update Route
export const updateSubRouteStart = () => ({
  type: SubRouteTypes.UPDATE_SUB_ROUTE_PENDING,
});

export const updateSubRouteSuccess = (id, subRoute) => ({
  type: SubRouteTypes.UPDATE_SUB_ROUTE_SUCCESS,
  payload: {
    id,
    subRoute,
  },
});

export const updateSubRouteFail = (error) => ({
  type: SubRouteTypes.UPDATE_SUB_ROUTE_FAIL,
  payload: {
    error,
  },
});

export const clearUpdateSubRoute = () => ({
  type: SubRouteTypes.UPDATE_CLEAR_SUB_ROUTE,
});

//TODO delete route
export const deleteSubRouteStart = () => ({
  type: SubRouteTypes.DELETE_SUB_ROUTE_PENDING,
});

export const deleteSubRouteSuccess = (id) => ({
  type: SubRouteTypes.DELETE_SUB_ROUTE_SUCCESS,
  payload: {
    id,
  },
});

export const deleteSubRouteFail = (error) => ({
  type: SubRouteTypes.DELETE_SUB_ROUTE_FAIL,
  payload: {
    error,
  },
});

export const clearDeleteSubRoute = () => ({
  type: SubRouteTypes.DELETE_CLEAR_SUB_ROUTE,
});

export const getSubRoutes = (dispatch) => {
  dispatch(fetchSubRoutesStart());

  axios
    .get(`${BASE_URI}/sub-route`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) => {
      dispatch(fetchSubRoutesSuccess(res.data));
    })
    .catch((err) => {
      dispatch(fetchSubRoutesFail(err));
    });
};

export const getSubRoutesByRoute = (id) => (dispatch) => {
  dispatch(fetchSubRoutesStart());

  axios
    .get(`${BASE_URI}/sub-route`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) => {
      const responses = res.data;
      const responseData = [];
      const response = responses.filter((data) => data.route.id === id);
      response.forEach((response) =>
        responseData.push({
          key: response.id,
          routeId: response.route.id,
          id: response.id,
          departureTime: response.departureTime,
          arrivalTime: response.arrivalTime,
          fare: response.price,
          distance: response.distance,
          status: response.status,
          from: response.from.name,
          to: response.to.name,
          routeStatus: response.route.status,
        })
      );

      dispatch(fetchSubRoutesSuccess(responseData));
    })
    .catch((err) => {
      dispatch(fetchSubRoutesFail(err));
    });
};

export const getSubRoute = (id) => (dispatch) => {
  dispatch(fetchSubRouteStart());

  axios
    .get(`${BASE_URI}/sub-route/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) => {
      dispatch(fetchSubRouteSuccess(id, res.data));
    })
    .catch((err) => {
      dispatch(fetchSubRouteFail(err));
    });
};

export const createSubRoute = (formData) => (dispatch) => {
  dispatch(createSubRouteStart());

  axios
    .post(`${BASE_URI}/sub-route`, formData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) => {
      dispatch(createSubRouteSuccess(res.data));
    })
    .catch((err) => {
      dispatch(createSubRouteFail(err));
    });
};

export const updateSubRoute = (id) => (dispatch) => {
  dispatch(updateSubRouteStart());

  axios
    .put(`${BASE_URI}/sub-route/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) => {
      dispatch(updateSubRouteSuccess(id, res.data));
    })
    .catch((err) => {
      dispatch(updateSubRouteFail(err));
    });
};

export const deleteSubRoute = (id) => (dispatch) => {
  dispatch(deleteSubRouteStart());

  axios
    .delete(`${BASE_URI}/sub-route/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) => {
      dispatch(deleteSubRouteSuccess(id));
    })
    .catch((err) => {
      dispatch(deleteSubRouteFail(err));
    });
};
