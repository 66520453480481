import { AuthActionTypes } from "./types";

const INITIAL_STATE = {
  loginLoading: false,
  loginSuccess: false,
  loginError: false,
  user: {},
  token: null,
  error: "",
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AuthActionTypes.LOGIN_START:
      return {
        ...state,
        loginError: null,
        loginSuccess: false,
        loginLoading: true,
      };

    case AuthActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        loginLoading: false,
        loginSuccess: true,
        loginError: false,
        user: action.payload.user,
        token: action.payload.token,
      };
    case AuthActionTypes.SET_CURRENT_USER:
      return {
        ...state,
        loginLoading: false,
        loginSuccess: true,
        loginError: false,
        user: action.payload,
      };

    case AuthActionTypes.LOGIN_ERROR:
      return {
        ...state,
        loginLoading: false,
        loginSuccess: false,
        loginError: true,
        error: action.payload.error,
      };

    case AuthActionTypes.CLEAR_LOGIN_SUCCESS:
      return {
        ...state,
        loginLoading: false,
        loginSuccess: false,
        // loginError: false,
      };

    case AuthActionTypes.LOG_OUT:
      return {
        ...state,
        user: null,
        token: null,
      };

    default:
      return state;
  }
};

export default reducer;
