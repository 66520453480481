import axios from "axios";
import { TicketTypes } from "./types";
import { busAdminTokenKey, BASE_URI } from "../../config/key.config";

export const fetchTicketStart = () => ({
  type: TicketTypes.TICKET_PENDING,
});

export const fetchTicketSuccess = (id, ticket) => ({
  type: TicketTypes.TICKET_SUCCESS,
  payload: {
    id,
    ticket,
  },
});

export const fetchTicketFail = (error) => ({
  type: TicketTypes.TICKET_FAIL,
  payload: {
    error,
  },
});

export const clearFetchTicket = () => ({
  type: TicketTypes.CLEAR_TICKET,
});

export const fetchTicketsStart = () => ({
  type: TicketTypes.TICKETS_PENDING,
});

export const fetchTicketsSuccess = (tickets) => ({
  type: TicketTypes.TICKETS_SUCCESS,
  payload: {
    tickets,
  },
});

export const fetchTicketsFail = (error) => ({
  type: TicketTypes.TICKETS_FAIL,
  payload: {
    error,
  },
});
export const fetchCancellationTicketsStart = () => ({
  type: TicketTypes.CANCELLATION_TICKETS_PENDING,
});

export const fetchCancellationTicketsSuccess = (tickets) => ({
  type: TicketTypes.CANCELLATION_TICKETS_SUCCESS,
  payload: {
    tickets,
  },
});

export const fetchCancellationTicketsFail = (error) => ({
  type: TicketTypes.CANCELLATION_TICKETS_FAIL,
  payload: {
    error,
  },
});

export const fetchCancellationTicketStart = () => ({
  type: TicketTypes.CANCELLATION_TICKET_PENDING,
});

export const fetchCancellationTicketSuccess = (id, ticket) => ({
  type: TicketTypes.CANCELLATION_TICKET_SUCCESS,
  payload: {
    id,
    ticket,
  },
});

export const fetchCancellationTicketFail = (error) => ({
  type: TicketTypes.CANCELLATION_TICKET_FAIL,
  payload: {
    error,
  },
});

export const clearFetchTickets = () => ({
  type: TicketTypes.CLEAR_TICKETS,
});

export const createCreateTicket = () => ({
  type: TicketTypes.CLEAR_CREATE_ROUTE,
});

export const getTickets = (dispatch) => {
  dispatch(fetchTicketsStart());

  axios
    .get(`${BASE_URI}/tickets`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
      maxBodyLength: 10,
      maxContentLength: 10,
    })
    .then((res) => {
      console.log("ticket", res);
      const response = res.data;
      const responseData = [];
      // const selectedRoute = JSON.parse(response.selectedRoute);
      response.forEach((data) =>
        responseData.push({
          key: data.id,
          id: data.id,
          ticketNo: data.ticketNo,
          bank: data.bank,
          passengers: data.booking.passengers,
          seatNum: data.seat,
          status: data.status,
          refNumber: data.booking.refNumber,
          phoneNumber: data.booking.phoneNumber,
          pickup: data.booking.pickup,
          dropoff: data.booking.dropoff,
          paymentMethod: data.booking.paymentMethod,
          plateNumber: data.booking.bus?.plateNumber,
          sideNumber: data.booking.bus?.sideNumber,
          firstSeatReserved: data.booking.firstSeatReserved?.slice(0, 10),
          from: data.booking.trip?.from,
          to: data.booking.trip?.to,
          route: `${data.booking.trip?.from} ${data.booking.trip?.to}`,
          price: data.booking.trip?.price,
          distance: data.booking.trip?.distance,
          bookedBy: data.booking.conductor
            ? `${data.booking.conductor.firstName} ${data.booking.conductor.lastName}`
            : "unknown",
        })
      );
      dispatch(fetchTicketsSuccess(responseData));
    })
    .catch((err) => {
      console.log("fail", err);
      dispatch(fetchTicketsFail(err));
    });
};

export const getTicket = (id, history) => (dispatch) => {
  dispatch(fetchTicketStart());

  axios
    .get(`${BASE_URI}/tickets/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) => {
      const response = res.data.booking;
      const selectedRoute = JSON.parse(response.selectedRoute);
      const responseData = {
        id: response.id,
        refNumber: response.refNumber,
        phoneNumber: response.phoneNumber,
        passengers: response.passengers,
        seat: response.seat,
        status: res.data.status,
        pickup: response.pickup,
        dropoff: response.dropoff,
        bookedByConductor: response.bookedByConductor,
        bookedByCustomer: response.bookedByCustomer,
        bookedByCallCenter: response.bookedByCallCenter,
        firstSeatReserved: response.firstSeatReserved,
        routeName: `${selectedRoute.from} - ${selectedRoute.to}`,
        routeId: selectedRoute.id,
      };
      dispatch(fetchTicketSuccess(id, responseData));
      {
        responseData && history.push("/director/book-detail");
      }
    })
    .catch((err) => {
      dispatch(fetchTicketFail(err));
    });
};

export const getCancellationTickets = (dispatch) => {
  dispatch(fetchCancellationTicketsStart());

  axios
    .get(`${BASE_URI}/tickets/cancelled-tickets`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) => {
      console.log("canc-tikets", res);

      dispatch(fetchCancellationTicketsSuccess(res));
    })
    .catch((err) => {
      console.log(err);
      dispatch(fetchCancellationTicketsFail(err));
    });
};

export const getCancellationTicket = (id, history) => (dispatch) => {
  dispatch(fetchCancellationTicketStart());

  axios
    .get(`${BASE_URI}/tickets/cancel-ticket/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) => {
      console.log("single-tik", res);
      dispatch(fetchCancellationTicketSuccess(id, res));
    })
    .catch((err) => {
      dispatch(fetchCancellationTicketFail(err));
    });
};
