import { logoutUser } from "../../redux/auth/action";

// import { useState } from "react";
import { Menu } from "antd";
import { NavLink, useLocation } from "react-router-dom";
import PublicBusLogo from "../../assets/images/PublicBusLogo.png";
import { Fragment } from "react";
import { BrowserRouter as Router } from "react-router-dom";

import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import {
  HomeOutlined,
  BookOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
const { SubMenu } = Menu;

function BasicAccessSidenav({ color }) {
  const { pathname } = useLocation();
  const page = pathname.replace("/", "");

  const history = useHistory();
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logoutUser(history));
  };
  return (
    <>
      <div className="brand" style={{ color: "white" }}>
        <img src={PublicBusLogo} alt="" />
        <span style={{ color: "#252525" }}>Public Bus</span>
      </div>
      <hr />
      <Menu theme="light" mode="inline">
        <Menu.Item key="1">
          <NavLink to="dashboard">
            <span
              className="icon"
              style={{
                background: page === "dashboard" ? color : "",
              }}
            >
              <HomeOutlined />
            </span>
            <span className="label">Dashboard</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="9">
          <NavLink to="booking-details">
            <span
              className="icon"
              style={{
                background: page === "structure" ? color : "",
              }}
            >
              <BookOutlined />
            </span>
            <span className="label">Booking Details</span>
          </NavLink>
        </Menu.Item>

        <Menu.Item className="menu-item-header" key="13">
          Report
        </Menu.Item>

        <SubMenu
          key="SubMenu2"
          className="submenu-items"
          icon={
            <span className="icon">
              <ExclamationCircleOutlined />
            </span>
          }
          title="Ticket Report"
        >
          <Menu.Item key="15" className="submenu-item">
            <NavLink to="report">
              <span className="" ellipsis={true}>
                <Fragment>&nbsp;&nbsp;&nbsp;&nbsp;</Fragment>
              </span>
              <span className="label">Report</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="16" className="submenu-item">
            <NavLink to="ofecho-report">
              <span className="">
                <Fragment>&nbsp;&nbsp;&nbsp;&nbsp;</Fragment>
              </span>
              <span className="label">Ofecho Report</span>
            </NavLink>
          </Menu.Item>
        </SubMenu>

        <Menu.Item className="menu-item-header" key="16">
          Account Pages
        </Menu.Item>
        <Menu.Item key="17" onClick={() => handleLogout()}>
          <div>
            <span
              className="icon"
              style={{
                background: page === "signout" ? color : "",
              }}
            >
              <HomeOutlined />
            </span>
            <span className="label">Sign Out</span>
          </div>
        </Menu.Item>
      </Menu>
      <div className="aside-footer">
        <div
          className="footer-box"
          style={{
            background: color,
          }}
        >
          <h6>Need Help?</h6>
          <p>Call: 0982117777 / 9439</p>
        </div>
      </div>
    </>
  );
}

export default BasicAccessSidenav;
