export const ConductorTypes = {
  CONDUCTOR_PENDING: "CONDUCTOR_PENDING",
  CONDUCTOR_SUCCESS: "CONDUCTOR_SUCCESS",
  CONDUCTOR_FAIL: "CONDUCTOR_FAIL",
  CLEAR_CONDUCTOR: "CLEAR_CONDUCTOR",

  CONDUCTORS_PENDING: "CONDUCTORS_PENDING",
  CONDUCTORS_SUCCESS: "CONDUCTORS_SUCCESS",
  CONDUCTORS_FAIL: "CONDUCTORS_FAIL",
  CLEAR_CONDUCTORS: "CLEAR_CONDUCTORS",

  CREATE_CONDUCTOR_PENDING: "CREATE_CONDUCTOR_PENDING",
  CREATE_CONDUCTOR_SUCCESS: "CREATE_CONDUCTOR_SUCCESS",
  CREATE_CONDUCTOR_FAIL: "CREATE_CONDUCTOR_FAIL",
  CLEAR_CREATE_CONDUCTOR: "CLEAR_CREATE_CONDUCTOR",

  UPDATE_CONDUCTOR_PENDING: "UPDATE_CONDUCTOR_PENDING",
  UPDATE_CONDUCTOR_SUCCESS: "UPDATE_CONDUCTOR_SUCCESS",
  UPDATE_CONDUCTOR_FAIL: "UPDATE_CONDUCTOR_FAIL",
  CLEAR_UPDATE_CONDUCTOR: "CLEAR_UPDATE_CONDUCTOR",

  DELETE_CONDUCTOR_PENDING: "DELETE_CONDUCTOR_PENDING",
  DELETE_CONDUCTOR_SUCCESS: "DELETE_CONDUCTOR_SUCCESS",
  DELETE_CONDUCTOR_FAIL: "DELETE_CONDUCTOR_FAIL",
  CLEAR_DELETE_CONDUCTOR: "CLEAR_DELETE_CONDUCTOR",

  RESET_CONDUCTOR_PENDING: "RESET_CONDUCTORS_PENDING",
  RESET_CONDUCTOR_SUCCESS: "RESET_CONDUCTORS_SUCCESS",
  RESET_CONDUCTOR_FAIL: "RESET_CONDUCTORS_FAIL",
};
