import {
  Switch,
  Route,
  BrowserRouter as Router,
  Redirect,
  useHistory,
} from "react-router-dom";

import BasicAccessContainer from "../container/basic-access/BasicAccess.container";
import DirectorContainer from "../container/director/Director.container";
import FinanceContainer from "../container/finance/Finance.container";
import OperationContainer from "../container/operation/Operation.container";
import ReporterContainer from "../container/reporter/Reporter.container";

import SignIn from "../pages/auth/SignIn";
import Home from "../pages/dashboard/Home";
import Conductors from "../pages/conductor/Conductors";
import NewConductor from "../pages/conductor/NewConductor";
import Trips from "../pages/trip/Trips";
import TripDetail from "../pages/trip/TripDetail";
import NewTrip from "../pages/trip/NewTrip";
import Bus from "../pages/bus/Bus";
import NewBus from "../pages/bus/NewBus";
import Tickets from "../pages/ticket/Tickets";
import BusStruture from "../pages/bus_structure/BusStruture";
import BookingDetails from "../pages/booking_details/BookingDetails";
import CitiesAndStops from "../pages/cities_and_stops/CitiesAndStops";
import NewDropingBoardingPoints from "../pages/cities_and_stops/NewDropingBoardingPoints";
import RoutePage from "../pages/route/Route";
import NewRoute from "../pages/route/NewRoute";
import CancellationPolicy from "../pages/cancellation_policy/CancellationPolicy";
import NewPolicy from "../pages/cancellation_policy/NewPolicy";
import TermsAndConditions from "../pages/termsandconditions/TermsAndConditions";
import Services from "../pages/service/Services";
import Report from "../pages/report/Report";
import OfechoReport from "../pages/report/OfechoReport";
import Profile from "../pages/profile/Profile";
import PageNotFound from "../pages/404/404";
import Users from "../pages/users/Users";
import Account from "../pages/account/Account";
import NewAccount from "../pages/account/NewAccount";
import HumanResource from "../pages/human_resource/HumanResource";
import NewHumanResource from "../pages/human_resource/NewHumanResource";
import BookingInformation from "../pages/bus_information/BookingInformation";
import NewBooking from "../pages/booking_details/NewBooking";
import Refund from "../pages/refund/Refund";
import NewRefund from "../pages/refund/NewRefund";
// import Signuppage2 from "../pages/SignUpPage2";
import NewTermsAndConditions from "../pages/termsandconditions/NewTermsAndConditions";
import Customers from "../pages/customer/Customers";
import CustomerDetail from "../pages/customer/Customer";
import BookingDetail from "../pages/booking_details/BookingDetail";
//DirectorUser
import PendingTicketPage from "../pages/booking_details/PendingTicket";
import BookedTicketPage from "../pages/booking_details/BookedTicket";
import RejectedTicketPage from "../pages/booking_details/RejectedTicket";
import RequestCancellation from "../pages/cancellation_request/RequestCancellation";
// import Tracking from "../pages/tracking/Tracking";
import Tracking2 from "../pages/tracking/Tracking2";
import FeedBack from "../pages/review/FeedBack";
import jwt_decode from "jwt-decode";

import NewUser from "../pages/users/NewUser";
import TodayReport from "../pages/report/TodayReport";
import WeekReport from "../pages/report/WeekReport";
import MonthReport from "../pages/report/MonthReport";
import YearReport from "../pages/report/YearReport";

import { busAdminTokenKey } from "../config/key.config";

function Navigation() {
  const tokenCheck = localStorage.getItem(busAdminTokenKey);
  const userRole = tokenCheck ? jwt_decode(tokenCheck) : null;

  return (
    <>
      <Router>
        <div className="App">
          <Switch>
            <Route path="/" exact component={SignIn} />
            {tokenCheck ? (
              <>
                {/* <Route path="/sign-up-2" component={Signuppage2} /> */}
                {userRole.user.role === "user_basic" && (
                  <Switch>
                    <Route
                      path="/basic-access"
                      render={({ match: { url } }) => (
                        <BasicAccessContainer>
                          <Switch>
                            <Route
                              path={`${url}/dashboard`}
                              component={Home}
                              exact
                            />
                            <Route
                              exact
                              path={`${url}/booking-details`}
                              component={BookingDetails}
                            />
                            <Route
                              exact
                              path={`${url}/report`}
                              component={Report}
                            />
                            <Route
                              exact
                              path={`${url}/ofecho-report`}
                              component={OfechoReport}
                            />
                            <Route
                              exact
                              path={`${url}/profile`}
                              component={Profile}
                            />
                            <Route
                              exact
                              path={`${url}/report-today`}
                              component={TodayReport}
                            />
                            <Route
                              exact
                              path={`${url}/report-week`}
                              component={WeekReport}
                            />
                            <Route
                              exact
                              path={`${url}/report-month`}
                              component={MonthReport}
                            />
                            <Route
                              exact
                              path={`${url}/report-year`}
                              component={YearReport}
                            />
                            <Route
                              exact
                              path={`${url}/*`}
                              component={PageNotFound}
                            />
                          </Switch>
                        </BasicAccessContainer>
                      )}
                    />
                    <Route exact path="*" component={PageNotFound} />
                  </Switch>
                )}
                {userRole.user.role === "user_director" && (
                  <Switch>
                    <Route
                      path="/director"
                      render={({ match: { url } }) => (
                        <DirectorContainer>
                          <Switch>
                            <Route
                              path={`${url}/dashboard`}
                              component={Home}
                              exact
                            />                            
                            <Route
                              path={`${url}/users`}
                              component={Users}
                              exact
                            />
                            <Route
                              path={`${url}/customers`}
                              component={Customers}
                              exact
                            />
                            <Route
                              path={`${url}/tracking`}
                              component={Tracking2}
                              exact
                            />
                            <Route
                              path={`${url}/customer/:id`}
                              component={CustomerDetail}
                              exact
                            />

                            <Route
                              path={`${url}/book-detail`}
                              component={BookingDetail}
                              exact
                            />
                            <Route
                              path={`${url}/reviews`}
                              component={FeedBack}
                              exact
                            />

                            <Route
                              path={`${url}/request-cancellation`}
                              component={RequestCancellation}
                              exact
                            />
                            <Route
                              path={`${url}/new-user`}
                              component={NewUser}
                              exact
                            />
                            <Route
                              path={`${url}/account`}
                              component={Account}
                              exact
                            />
                            <Route
                              path={`${url}/new-bank`}
                              component={NewAccount}
                              exact
                            />
                            <Route
                              path={`${url}/new-human-resource`}
                              component={NewHumanResource}
                            />
                            <Route
                              path={`${url}/human-resource`}
                              component={HumanResource}
                            />
                            <Route
                              path={`${url}/conductors`}
                              component={Conductors}
                              exact
                            />
                            <Route
                              path={`${url}/new-conductor`}
                              component={NewConductor}
                              exact
                            />
                            <Route
                              path={`${url}/booking-information`}
                              component={BookingInformation}
                            />
                            <Route
                              exact
                              path={`${url}/refund`}
                              component={Refund}
                            />
                            <Route
                              exact
                              path={`${url}/new-refund`}
                              component={NewRefund}
                            />
                            <Route
                              exact
                              path={`${url}/new-terms`}
                              component={NewTermsAndConditions}
                            />
                            <Route
                              path={`${url}/new-booking`}
                              component={NewBooking}
                            />
                            <Route
                              exact
                              path={`${url}/trips`}
                              component={Trips}
                            />
                            <Route
                              exact
                              path={`${url}/trip-detail`}
                              component={TripDetail}
                            />
                            <Route
                              exact
                              path={`${url}/new-trip`}
                              component={NewTrip}
                            />
                            <Route exact path={`${url}/bus`} component={Bus} />
                            <Route
                              exact
                              path={`${url}/new-bus`}
                              component={NewBus}
                            />
                            <Route
                              exact
                              path={`${url}/ticket`}
                              component={Tickets}
                            />
                            <Route
                              exact
                              path={`${url}/bus-structures`}
                              component={BusStruture}
                            />
                            <Route
                              exact
                              path={`${url}/booking-details`}
                              component={BookingDetails}
                            />
                            <Route
                              exact
                              path={`${url}/pending-tickets`}
                              component={PendingTicketPage}
                            />
                            <Route
                              exact
                              path={`${url}/booked-tickets`}
                              component={BookedTicketPage}
                            />
                            <Route
                              exact
                              path={`${url}/rejected-tickets`}
                              component={RejectedTicketPage}
                            />
                            <Route
                              exact
                              path={`${url}/locations`}
                              component={CitiesAndStops}
                            />
                            <Route
                              exact
                              path={`${url}/new-cities-boarding`}
                              component={NewDropingBoardingPoints}
                            />
                            <Route
                              exact
                              path={`${url}/route`}
                              component={RoutePage}
                            />
                            <Route
                              exact
                              path={`${url}/new-route`}
                              component={NewRoute}
                            />
                            <Route
                              exact
                              path={`${url}/terms-and-conditions`}
                              component={TermsAndConditions}
                            />
                            <Route
                              exact
                              path={`${url}/cancellation-policy`}
                              component={CancellationPolicy}
                            />
                            <Route
                              exact
                              path={`${url}/new-policy`}
                              component={NewPolicy}
                            />
                            <Route
                              exact
                              path={`${url}/services`}
                              component={Services}
                            />
                            <Route
                              exact
                              path={`${url}/report`}
                              component={Report}
                            />
                            <Route
                              exact
                              path={`${url}/ofecho-report`}
                              component={OfechoReport}
                            />
                            <Route
                              exact
                              path={`${url}/profile`}
                              component={Profile}
                            />
                            <Route
                              exact
                              path={`${url}/report-today`}
                              component={TodayReport}
                            />
                            <Route
                              exact
                              path={`${url}/report-week`}
                              component={WeekReport}
                            />
                            <Route
                              exact
                              path={`${url}/report-month`}
                              component={MonthReport}
                            />
                            <Route
                              exact
                              path={`${url}/report-year`}
                              component={YearReport}
                            />
                            <Route
                              exact
                              path={`${url}/*`}
                              component={PageNotFound}
                            />
                          </Switch>
                        </DirectorContainer>
                      )}
                    />
                    <Route exact path="*" component={PageNotFound} />
                  </Switch>
                )}
                {userRole.user.role === "user_finance" && (
                  <Switch>
                    <Route
                      path="/finance"
                      render={({ match: { url } }) => (
                        <FinanceContainer>
                          <Switch>
                            <Route
                              path={`${url}/dashboard`}
                              component={Home}
                              exact
                            />
                            <Route
                              exact
                              path={`${url}/booking-details`}
                              component={BookingDetails}
                            />
                            <Route
                              path={`${url}/booking-information`}
                              component={BookingInformation}
                            />
                            <Route
                              path={`${url}/request-cancellation`}
                              component={RequestCancellation}
                              exact
                            />
                            <Route
                              exact
                              path={`${url}/refund`}
                              component={Refund}
                            />
                            <Route
                              exact
                              path={`${url}/new-refund`}
                              component={NewRefund}
                            />
                            <Route
                              exact
                              path={`${url}/report`}
                              component={Report}
                            />
                            <Route
                              exact
                              path={`${url}/ofecho-report`}
                              component={OfechoReport}
                            />
                            <Route
                              exact
                              path={`${url}/profile`}
                              component={Profile}
                            />
                            <Route
                              exact
                              path={`${url}/report-today`}
                              component={TodayReport}
                            />
                            <Route
                              exact
                              path={`${url}/report-week`}
                              component={WeekReport}
                            />
                            <Route
                              exact
                              path={`${url}/report-month`}
                              component={MonthReport}
                            />
                            <Route
                              exact
                              path={`${url}/report-year`}
                              component={YearReport}
                            />
                            <Route
                              exact
                              path={`${url}/*`}
                              component={PageNotFound}
                            />
                          </Switch>
                        </FinanceContainer>
                      )}
                    />
                    <Route exact path="*" component={PageNotFound} />
                  </Switch>
                )}
                {userRole.user.role === "user_oprator" && (
                  <Switch>
                    <Route
                      path="/operation"
                      render={({ match: { url } }) => (
                        <OperationContainer>
                          <Switch>
                            <Route
                              path={`${url}/dashboard`}
                              component={Home}
                              exact
                            />
                            <Route
                              path={`${url}/conductors`}
                              component={Conductors}
                              exact
                            />
                            <Route
                              path={`${url}/new-conductor`}
                              component={NewConductor}
                              exact
                            />
                            <Route
                              exact
                              path={`${url}/trips`}
                              component={Trips}
                            />
                            <Route
                              exact
                              path={`${url}/new-trip`}
                              component={NewTrip}
                            />
                            <Route
                              path={`${url}/booking-information`}
                              component={BookingInformation}
                            />
                            <Route exact path={`${url}/bus`} component={Bus} />
                            <Route
                              exact
                              path={`${url}/new-bus`}
                              component={NewBus}
                            />
                            <Route
                              exact
                              path={`${url}/refund`}
                              component={Refund}
                            />
                            <Route
                              exact
                              path={`${url}/new-refund`}
                              component={NewRefund}
                            />
                            <Route
                              exact
                              path={`${url}/booking-details`}
                              component={BookingDetails}
                            />
                            <Route
                              exact
                              path={`${url}/ticket`}
                              component={Tickets}
                            />
                            <Route
                              exact
                              path={`${url}/bus-structures`}
                              component={BusStruture}
                            />
                            <Route
                              exact
                              path={`${url}/locations`}
                              component={CitiesAndStops}
                            />
                            <Route
                              exact
                              path={`${url}/new-cities-boarding`}
                              component={NewDropingBoardingPoints}
                            />
                            <Route
                              exact
                              path={`${url}/route`}
                              component={RoutePage}
                            />
                            <Route
                              exact
                              path={`${url}/new-route`}
                              component={NewRoute}
                            />
                            <Route
                              exact
                              path={`${url}/report-today`}
                              component={TodayReport}
                            />
                            <Route
                              exact
                              path={`${url}/report-week`}
                              component={WeekReport}
                            />
                            <Route
                              exact
                              path={`${url}/report-month`}
                              component={MonthReport}
                            />
                            <Route
                              exact
                              path={`${url}/report-year`}
                              component={YearReport}
                            />
                            {/* <Route
                          exact
                          path={`${url}/terms-and-conditions`}
                          component={TermsAndConditions}
                        />
                        <Route
                          exact
                          path={`${url}/cancellation-policy`}
                          component={CancellationPolicy}
                        />
                        <Route
                          exact
                          path={`${url}/new-policy`}
                          component={NewPolicy}
                        />
                        <Route
                          exact
                          path={`${url}/services`}
                          component={Services}
                        /> */}
                            <Route
                              exact
                              path={`${url}/report`}
                              component={Report}
                            />
                            <Route
                              exact
                              path={`${url}/ofecho-report`}
                              component={OfechoReport}
                            />
                            <Route
                              exact
                              path={`${url}/profile`}
                              component={Profile}
                            />
                            <Route
                              exact
                              path={`${url}/*`}
                              component={PageNotFound}
                            />
                          </Switch>
                        </OperationContainer>
                      )}
                    />
                    <Route exact path="*" component={PageNotFound} />
                  </Switch>
                )}
                {userRole.user.role === "user_reporter" && (
                  <Switch>
                    <Route
                      path="/reporter"
                      render={({ match: { url } }) => (
                        <ReporterContainer>
                          <Switch>
                            <Route
                              path={`${url}/dashboard`}
                              component={Home}
                              exact
                            />

                            {/* <Route
                          exact
                          path={`${url}/booking-details`}
                          component={BookingDetails}
                        /> */}

                            <Route
                              path={`${url}/booking-information`}
                              component={BookingInformation}
                            />
                            <Route
                              exact
                              path={`${url}/report`}
                              component={Report}
                            />
                            <Route
                              exact
                              path={`${url}/ofecho-report`}
                              component={OfechoReport}
                            />
                            <Route
                              exact
                              path={`${url}/profile`}
                              component={Profile}
                            />
                            <Route
                              exact
                              path={`${url}/report-today`}
                              component={TodayReport}
                            />
                            <Route
                              exact
                              path={`${url}/report-week`}
                              component={WeekReport}
                            />
                            <Route
                              exact
                              path={`${url}/report-month`}
                              component={MonthReport}
                            />
                            <Route
                              exact
                              path={`${url}/report-year`}
                              component={YearReport}
                            />
                            <Route
                              exact
                              path={`${url}/*`}
                              component={PageNotFound}
                            />
                          </Switch>
                        </ReporterContainer>
                      )}
                    />
                    <Route exact path="*" component={PageNotFound} />
                  </Switch>
                )}
              </>
            ) : (
              <>
                <Redirect to="/" />
              </>
            )}
          </Switch>
        </div>
      </Router>
    </>
  );
}

export default Navigation;
