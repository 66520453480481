import { logoutUser } from "../../redux/auth/action";

import { Menu } from "antd";
import { NavLink, useLocation } from "react-router-dom";
import publicBusLogo from "../../assets/logo/public_bus.png";
import { Fragment } from "react";

import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import {
  HomeOutlined,
  TeamOutlined,
  UserOutlined,
  SwapOutlined,
  CreditCardOutlined,
  BookOutlined,
  EnvironmentOutlined,
  RetweetOutlined,
  CarOutlined,
  MenuOutlined,
  ContainerOutlined,
  MessageOutlined,
} from "@ant-design/icons";
const { SubMenu } = Menu;

function DirectorSidenav({ color }) {
  const { pathname } = useLocation();
  const page = pathname.replace("/", "");

  const history = useHistory();
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logoutUser(history));
  };
  return (
    <>
      <div className="brand" style={{ color: "white" }}>
        <img src={publicBusLogo} alt="" />
        <span style={{ color: "#252525" }}>Public Bus</span>
      </div>
      <hr />
      <Menu theme="light" mode="inline">
        <Menu.Item key="1">
          <NavLink to="dashboard">
            <span
              className="icon"
              style={{
                background: page === "dashboard" ? color : "",
              }}
            >
              <HomeOutlined />
            </span>
            <span className="label">Dashboard</span>
          </NavLink>
        </Menu.Item>

        <Menu.Item key="2">
          <NavLink to="users">
            <span
              className="icon"
              style={{
                background: page === "users" ? color : "",
              }}
            >
              <TeamOutlined />
            </span>
            <span className="label">Users</span>
          </NavLink>
        </Menu.Item>

        <Menu.Item key="2b">
          <NavLink to="customers">
            <span
              className="icon"
              style={{
                background: page === "customers" ? color : "",
              }}
            >
              <TeamOutlined />
            </span>
            <span className="label">Customers</span>
          </NavLink>
        </Menu.Item>

        <Menu.Item key="3">
          <NavLink to="account">
            <span
              className="icon"
              style={{
                background: page === "account" ? color : "",
              }}
            >
              <ContainerOutlined />
            </span>
            <span className="label">Account</span>
          </NavLink>
        </Menu.Item>

        <Menu.Item key="4">
          <NavLink to="human-resource">
            <span
              className="icon"
              style={{
                background: page === "human-resource" ? color : "",
              }}
            >
              <UserOutlined />
            </span>
            <span className="label">Drivers</span>
          </NavLink>
        </Menu.Item>

        <Menu.Item key="4-conductor">
          <NavLink to="conductors">
            <span
              className="icon"
              style={{
                background: page === "conductors" ? color : "",
              }}
            >
              <MenuOutlined />
            </span>
            <span className="label">Conductors</span>
          </NavLink>
        </Menu.Item>

        {/* <Menu.Item key="4a">
          <NavLink to="reviews">
            <span
              className="icon"
              style={{
                background: page === "reviews" ? color : "",
              }}
            >
              <MessageOutlined />
            </span>
            <span className="label">Reviews</span>
          </NavLink>
        </Menu.Item> */}

        <Menu.Item key="5">
          <NavLink to="bus">
            <span
              className="icon"
              style={{
                background: page === "bus" ? color : "",
              }}
            >
              <CarOutlined />
            </span>
            <span className="label">Bus</span>
          </NavLink>
        </Menu.Item>

        <Menu.Item key="6">
          <NavLink to="trips">
            <span
              className="icon"
              style={{
                background: page === "trips" ? color : "",
              }}
            >
              <SwapOutlined />
            </span>
            <span className="label">Trips</span>
          </NavLink>
        </Menu.Item>

        {/* <Menu.Item key="7">
          <NavLink to="human-resource">
            <span
              className="icon"
              style={{
                background: page === "humanresource" ? color : "",
              }}
            >
              <MenuOutlined />
            </span>
            <span className="label">Human Resource</span>
          </NavLink>
        </Menu.Item> */}
        <Menu.Item key="8">
          <NavLink to="bus-structures">
            <span
              className="icon"
              style={{
                background: page === "structure" ? color : "",
              }}
            >
              <MenuOutlined />
            </span>
            <span className="label">Bus Structure</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="9">
          <NavLink to="booking-details">
            <span
              className="icon"
              style={{
                background: page === "structure" ? color : "",
              }}
            >
              <BookOutlined />
            </span>
            <span className="label">Booking Details</span>
          </NavLink>
        </Menu.Item>

        <Menu.Item key="10">
          <NavLink to="locations">
            <span
              className="icon"
              style={{
                background: page === "locations" ? color : "",
              }}
            >
              <EnvironmentOutlined />{" "}
            </span>
            <span className="label">Cities and Stops</span>
          </NavLink>
        </Menu.Item>

        <Menu.Item key="11">
          <NavLink to="route">
            <span
              className="icon"
              style={{
                background: page === "route" ? color : "",
              }}
            >
              <RetweetOutlined />
            </span>
            <span className="label">Route</span>
          </NavLink>
        </Menu.Item>

        <SubMenu
          key="12"
          className="submenu-items"
          icon={
            <span className="icon">
              <CreditCardOutlined />
            </span>
          }
          title="Ticket Managment"
        >
          <Menu.Item key="121" className="submenu-item">
            <NavLink to="booking-information">
              <span className="">
                <Fragment>&nbsp;&nbsp;&nbsp;&nbsp;</Fragment>
              </span>
              <span className="label">Booking Information</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="122" className="submenu-item">
            <NavLink to="request-cancellation">
              <span className="">
                <Fragment>&nbsp;&nbsp;&nbsp;&nbsp;</Fragment>
              </span>
              <span className="label">Request Cancellation</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="123" className="submenu-item">
            <NavLink to="refund">
              <span className="">
                <Fragment>&nbsp;&nbsp;&nbsp;&nbsp;</Fragment>
              </span>
              <span className="label">Refund</span>
            </NavLink>
          </Menu.Item>
        </SubMenu>

        <SubMenu
          key="13"
          className="submenu-items"
          icon={
            <span className="icon">
              <CarOutlined />
            </span>
          }
          title="Bus Information"
        >
          <Menu.Item key="131" className="submenu-item">
            <NavLink to="terms-and-conditions">
              <span className="">
                {/* <HomeOutlined /> */}{" "}
                <Fragment>&nbsp;&nbsp;&nbsp;&nbsp;</Fragment>
              </span>
              <span className="label">Terms and Conditions</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="132" className="submenu-item">
            <NavLink to="cancellation-policy">
              <span className="">
                <Fragment>&nbsp;&nbsp;&nbsp;&nbsp;</Fragment>
                {/* <HomeOutlined /> */}
              </span>
              <span className="label">Cancellation Policy</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="133" className="submenu-item">
            <NavLink to="services">
              <span className="">
                <Fragment>&nbsp;&nbsp;&nbsp;&nbsp;</Fragment>
                {/* <HomeOutlined /> */}
              </span>
              <span className="label">Services</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="134" className="submenu-item">
            <NavLink to="tracking">
              <span className="">
                <Fragment>&nbsp;&nbsp;&nbsp;&nbsp;</Fragment>
                {/* <HomeOutlined /> */}
              </span>
              <span className="label">Tracking</span>
            </NavLink>
          </Menu.Item>
        </SubMenu>
        {/* 3 */}

        {/* <Menu.Item className="menu-item-header" key="14">
          Report
        </Menu.Item> */}

        <SubMenu
          key="155"
          className="submenu-items"
          icon={
            <span className="icon">
              <CarOutlined />
            </span>
          }
          title="Ticket Report"
        >
          <Menu.Item key="155a" className="submenu-item">
            <NavLink to="report">
              <span className="">
                {/* <HomeOutlined /> */}{" "}
                <Fragment>&nbsp;&nbsp;&nbsp;&nbsp;</Fragment>
              </span>
              <span className="label">Report</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="155b" className="submenu-item">
            <NavLink to="ofecho-report">
              <span className="">
                <Fragment>&nbsp;&nbsp;&nbsp;&nbsp;</Fragment>
                {/* <HomeOutlined /> */}
              </span>
              <span className="label">Ofecho Report</span>
            </NavLink>
          </Menu.Item>
        </SubMenu>

        <Menu.Item className="menu-item-header" key="16">
          Account Pages
        </Menu.Item>
        <Menu.Item key="17" onClick={() => handleLogout()}>
          <div>
            <span
              className="icon"
              style={{
                background: page === "signout" ? color : "",
              }}
            >
              <HomeOutlined />
            </span>
            <span className="label">Sign Out</span>
          </div>
        </Menu.Item>
        {/* </Menu.Item> */}
      </Menu>
      <div className="aside-footer">
        <div
          className="footer-box"
          style={{
            background: color,
          }}
        >
          <h6>Need Help?</h6>
          <p>Call: 0982117777 / 9439</p>
        </div>
      </div>
    </>
  );
}

export default DirectorSidenav;
