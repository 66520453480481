import React, { useState, useEffect } from "react";
import { Row, Col, Card, Table, Input, Button, Space, Badge } from "antd";
import Highlighter from "react-highlight-words";
import {
  SearchOutlined,
  DeleteOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { getTickets, getTicket } from "../../redux/ticket/action";
import { useHistory } from "react-router-dom";

function RejectedBooking() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [searchText, setSearchText] = useState(false);
  const [searchedColumn, setSearchedColumn] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [filteredInfo, setFilteredInfo] = useState(null);
  const [sortedInfo, setSortedInfo] = useState(null);
  const [currId, setCurrId] = useState(null);

  useEffect(() => {
    // dispatch(getBookings);
    dispatch(getTickets);
  }, [dispatch]);

  const { tickets, ticketsLoading } = useSelector((state) => state.ticket);

  const rejectedBooking = tickets.filter((res) => res.status === "CANCELLED");

  let getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            setSearchInput(node);
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>

          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => setSearchInput(""), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  let handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  let handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
    setFilteredInfo(null);
    setSortedInfo(null);
  };

  const handleBookDetail = (record) => {
    dispatch(getTicket(currId, history));
  };

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      width: "7%",
    },
    {
      title: "Ticket. No.",
      dataIndex: "ticketNo",
      key: "ticketNo",
      width: "12%",
      ...getColumnSearchProps("ticketNo"),
    },
    {
      title: "Phone No.",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      width: "12%",
      ...getColumnSearchProps("phoneNumber"),
      sorter: (a, b) => a.phoneNum.length - b.phoneNum.length,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Passengers",
      dataIndex: "passengers",
      key: "passengers",
      width: "12%",
      ...getColumnSearchProps("passengers"),
      sorter: (a, b) => a.passengers.length - b.passengers.length,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Seat No.",
      dataIndex: "seatNum",
      key: "seatNum",
      width: "12%",
      ...getColumnSearchProps("seatNum"),
      sorter: (a, b) => a.seatNum.length - b.seatNum.length,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "5%",
      render: (text, record) => {
        const active = (
          <Badge
            className="site-badge-count-109"
            count={"Booked"}
            style={{ backgroundColor: "#52c41a" }}
          />
        );

        const inactive = <Badge count={"CANCELLED"} />;

        const show = record.status === "BOOKED" ? active : inactive;

        return show;
      },
    },
    {
      title: "First Seat Reserved",
      dataIndex: "firstSeatReserved",
      key: "firstSeatReserved",
      width: "15%",
      ...getColumnSearchProps("firstSeatReserved"),
      sorter: (a, b) => a.seatReserved.length - b.seatReserved.length,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Side No.",
      dataIndex: "sideNumber",
      key: "sideNumber",
      width: "12%",
      ...getColumnSearchProps("sideNumber"),
      sorter: (a, b) => a.sideNum.length - b.sideNum.length,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Booked by",
      dataIndex: "bookedBy",
      key: "bookedBy",
      width: "12%",
      ...getColumnSearchProps("bookedBy"),
      sorter: (a, b) => a.bookedBy.length - b.bookedBy.length,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Action",
      key: "action",
      width: "20%",
      render: (text, record) => (
        <Space size="middle">
          <Button
            style={{ background: "#ff4d4f", borderColor: "#ff4d4f" }}
            type="primary"
            shape="round"
            icon={<DeleteOutlined />}
            size="small"
          />
          {/* <Button
            style={{ background: "#41b0a5", borderColor: "#2f998f" }}
            shape="round"
            type="primary"
            icon={<RollbackOutlined />}
            size="small"
            onClick={() => handleRefund(record)}
          /> */}
          <Button
            style={{ background: "#eba134", borderColor: "#eba134" }}
            shape="round"
            type="primary"
            icon={<RightOutlined />}
            size="small"
            onClick={() => {
              setCurrId(record.id);
              handleBookDetail(record);
            }}
          />
        </Space>
      ),
    },
  ];
  return (
    <div className="tabled">
      <Row gutter={[24, 0]}>
        <Col xs="24" xl={24}>
          <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title="Fillter data"
          >
            <Table
              style={{ width: "100%" }}
              columns={columns}
              dataSource={rejectedBooking}
              loading={ticketsLoading}
              scroll={{ x: 400 }}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default RejectedBooking;
