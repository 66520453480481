import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tabs, Row, Col, Avatar, Card, Rate, Typography, Divider } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import { ArrowUpOutlined } from "@ant-design/icons";
import ReviewChart from "./ReviewChart";
import { getReviews, getRates } from "../../redux/review/actions";

const { Title } = Typography;

function FeedBack() {
  const dispatch = useDispatch();
  const { reviews, staffRates, cleanRates, drivingRates, serviceRates } = useSelector((state) => state.review);
  const [tab, setTab] = useState("1");

  useEffect(() => {
    dispatch(getReviews());
    dispatch(getRates())
  }, []);

  return (
    <div>
      <Tabs
        defaultActiveKey="agent"
        tabPosition="top"
        onChange={(e) => setTab(e)}
      >
        <Tabs.TabPane tab="Staff Ethics" key="staff-ethics">
          <ReviewChart rates={staffRates} yearlyReport={staffRates.yearlyReport} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Clean" key="clean">
          <ReviewChart rates={cleanRates} yearlyReport={cleanRates.yearlyReport}/>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Driving Experience" key="driving-experience">
        <ReviewChart rates={drivingRates} yearlyReport={drivingRates.yearlyReport}/>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Onboard Service" key="onboard-service">
        <ReviewChart rates={serviceRates} yearlyReport={serviceRates.yearlyReport}/>
        </Tabs.TabPane>
      </Tabs>

      {reviews.map((res) => (
        <Card
          key={res.id}
          style={{ marginTop: "2.5rem" }}
          className="card-profile-head"
          bodyStyle={{ display: "none" }}
          title={
            <Row justify="space-between" align="middle" gutter={[24, 0]}>
              <Col span={7} md={7} className="col-info">
                <div style={{ display: "flex" }}>
                  <Avatar.Group>
                    <Avatar size={100} shape="square" />

                    <div className="avatar-info"></div>
                  </Avatar.Group>
                  <div>
                    <h2>{res.name}</h2>
                    <Rate allowHalf defaultValue={res.rateValue} />
                  </div>
                </div>
              </Col>
              <Col
                style={{ wordWrap: "break-word" }}
                span={15}
                md={15}
                className="col-info"
              >
                <h3 style={{ whiteSpace: "initial", fontWeight: "500" }}>
                  {res.comment}
                </h3>
              </Col>
              <Col span={2} md={2} className="col-info"></Col>
            </Row>
          }
        ></Card>
      ))}
    </div>
  );
}

export default FeedBack;
