import axios from "axios";
import { RouteTypes } from "./types";
import { busAdminHeader } from "../redux.util";

const INITIAL_STATE = {
  error: null,
  route: {},
  routes: [],
  routesStatus: [],

  routeLoading: false,
  routeSuccess: false,
  routeFail: false,

  routesLoading: false,
  routesSuccess: false,
  routesFail: false,

  routesStatusLoading: false,
  routesStatusSuccess: false,
  routesStatusFail: false,

  createRouteLoading: false,
  createRouteSuccess: false,
  createRouteFail: false,

  updateRouteLoading: false,
  updateRouteSuccess: false,
  updateRouteFail: false,

  deleteRouteLoading: false,
  deleteRouteSuccess: false,
  deleteRouteFail: false,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RouteTypes.ROUTE_PENDING:
      return {
        ...state,
        routeLoading: true,
      };
    case RouteTypes.ROUTE_SUCCESS:
      return {
        ...state,
        routeSuccess: true,
        routeLoading: false,
        route: {
          [action.payload.id]: action.payload.route,
        },
      };
    case RouteTypes.ROUTE_FAIL:
      return {
        ...state,
        routeLoading: false,
        routeSuccess: false,
        error: action.payload.error,
      };
    case RouteTypes.CLEAR_ROUTE:
      return {
        ...state,
        routeLoading: false,
        routeSuccess: false,
        routeFail: false,
      };

    case RouteTypes.ROUTES_PENDING:
      return {
        ...state,
        routesLoading: true,
      };
    case RouteTypes.ROUTES_SUCCESS:
      return {
        ...state,
        routesSuccess: true,
        routesLoading: false,
        routes: action.payload.routes,
      };
    case RouteTypes.ROUTES_FAIL:
      return {
        ...state,
        routesLoading: false,
        routesSuccess: false,
        error: action.payload.error,
      };
    case RouteTypes.CLEAR_ROUTES:
      return {
        ...state,
        routesSuccess: false,
        routesLoading: false,
        routesFail: false,
      };

    case RouteTypes.ROUTES_STATUS_PENDING:
      return {
        ...state,
        routesStatusLoading: true,
      };
    case RouteTypes.ROUTES_STATUS_SUCCESS:
      return {
        ...state,
        routesStatusSuccess: true,
        routesStatusLoading: false,
        routesStatus: action.payload.routes,
      };
    case RouteTypes.ROUTES_STATUS_FAIL:
      return {
        ...state,
        routesStatusLoading: false,
        routesStatusSuccess: false,
        routesStatusFail: true,
        error: action.payload.error,
      };
    case RouteTypes.CLEAR_STATUS_ROUTES:
      return {
        ...state,
        routesStatusSuccess: false,
        routesStatusLoading: false,
        routesStatusFail: false,
      };

    case RouteTypes.CREATE_ROUTE_PENDING:
      return {
        ...state,
        createRouteLoading: true,
      };
    case RouteTypes.CREATE_ROUTE_SUCCESS:
      return {
        ...state,
        createRouteSuccess: true,
        createRouteLoading: false,
        routes: [action.payload.route, ...state.routes],
      };
    case RouteTypes.CREATE_ROUTE_FAIL:
      return {
        ...state,
        createRouteLoading: false,
        createRouteSuccess: false,
        error: action.payload.error,
      };
    case RouteTypes.CLEAR_CREATE_ROUTE:
      return {
        ...state,
        createRouteSuccess: false,
        createRouteLoading: false,
        createRouteFail: false,
      };

    case RouteTypes.UPDATE_ROUTE_PENDING:
      return {
        ...state,
        updateRouteLoading: true,
      };
    case RouteTypes.UPDATE_ROUTE_SUCCESS:
      return {
        ...state,
        updateRouteSuccess: true,
        updateRouteLoading: false,
        route: {
          ...state.route,
          [action.payload.id]: action.payload.route,
        },
      };
    case RouteTypes.UPDATE_ROUTE_FAIL:
      return {
        ...state,
        updateRouteLoading: false,
        updateRouteSuccess: false,
        updateRouteFail: true,
        error: action.payload.error,
      };
    case RouteTypes.CLEAR_UPDATE_ROUTE:
      return {
        ...state,
        updateRouteSuccess: false,
        updateRouteLoading: false,
        updateRouteFail: false,
      };

    case RouteTypes.DELETE_ROUTE_PENDING:
      return {
        ...state,
        deleteRouteLoading: true,
      };
    case RouteTypes.DELETE_ROUTE_SUCCESS:
      return {
        ...state,
        deleteRouteSuccess: true,
        deleteRouteLoading: false,
      };
    case RouteTypes.DELETE_ROUTE_FAIL:
      return {
        ...state,
        deleteRouteLoading: false,
        deleteRouteSuccess: false,
        error: action.payload.error,
      };
    case RouteTypes.CLEAR_DELETE_ROUTE:
      return {
        ...state,
        deleteRouteSuccess: false,
        deleteRouteLoading: false,
        deleteRouteFail: false,
      };

    default:
      return state;
  }
};

export default reducer;
