export const CityTypes = {
  CITY_PENDING: "CITY_PENDING",
  CITY_SUCCESS: "CITY_SUCCESS",
  CITY_FAIL: "CITY_FAIL",
  CLEAR_CITY: "CLEAR_CITY",

  CITYS_PENDING: "CITYS_PENDING",
  CITYS_SUCCESS: "CITYS_SUCCESS",
  CITYS_FAIL: "CITYS_FAIL",
  CLEAR_CITYS: "CLEAR_CITYS",

  CREATE_CITY_PENDING: "CREATE_CITY_PENDING",
  CREATE_CITY_SUCCESS: "CREATE_CITY_SUCCESS",
  CREATE_CITY_FAIL: "CREATE_CITY_FAIL",
  CREATE_CLEAR_CITY: "CREATE_CLEAR_CITY",

  UPDATE_CITY_PENDING: "UPDATE_CITY_PENDING",
  UPDATE_CITY_SUCCESS: "UPDATE_CITY_SUCCESS",
  UPDATE_CITY_FAIL: "UPDATE_CITY_FAIL",
  UPDATE_CLEAR_CITY: "UPDATE_CLEAR_CITY",

  DELETE_CITY_PENDING: "DELETE_CITY_PENDING",
  DELETE_CITY_SUCCESS: "DELETE_CITY_SUCCESS",
  DELETE_CITY_FAIL: "DELETE_CITY_FAIL",
  DELETE_CLEAR_CITY: "DELETE_CLEAR_CITY",
};
