export const TicketTypes = {
  TICKET_PENDING: "TICKET_PENDING",
  TICKET_SUCCESS: "TICKET_SUCCESS",
  TICKET_FAIL: "TICKET_FAIL",
  CLEAR_TICKET: "CLEAR_TICKET",

  TICKETS_PENDING: "TICKETS_PENDING",
  TICKETS_SUCCESS: "TICKETS_SUCCESS",
  TICKETS_FAIL: "TICKETS_FAIL",
  CLEAR_TICKETS: "CLEAR_TICKETS",

  CANCELLATION_TICKETS_PENDING: "CANCELLATION_TICKETS_PENDING",
  CANCELLATION_TICKETS_SUCCESS: "CANCELLATION_TICKETS_SUCCESS",
  CANCELLATION_TICKETS_FAIL: "CANCELLATION_TICKETS_FAIL",

  CANCELLATION_TICKET_PENDING: "CANCELLATION_TICKET_PENDING",
  CANCELLATION_TICKET_SUCCESS: "CANCELLATION_TICKET_SUCCESS",
  CANCELLATION_TICKET_FAIL: "CANCELLATION_TICKET_FAIL",

  // CREATE_BOOKING_PENDING: "CREATE_BOOKING_PENDING",
  // CREATE_BOOKING_SUCCESS: "CREATE_BOOKING_SUCCESS",
  // CREATE_BOOKING_FAIL: "CREATE_BOOKING_FAIL",
  // CLEAR_CREATE_BOOKING: "CLEAR_CREATE_BOOKING",

  // UPDATE_BOOKING_PENDING: "UPDATE_BOOKING_PENDING",
  // UPDATE_BOOKING_SUCCESS: "UPDATE_BOOKING_SUCCESS",
  // UPDATE_BOOKING_FAIL: "UPDATE_BOOKING_FAIL",
  // CLEAR_UPDATE_BOOKING: "CLEAR_UPDATE_BOOKING",

  // DELETE_BOOKING_PENDING: "DELETE_BOOKING_PENDING",
  // DELETE_BOOKING_SUCCESS: "DELETE_BOOKING_SUCCESS",
  // DELETE_BOOKING_FAIL: "DELETE_BOOKING_FAIL",
  // CLEAR_DELETE_BOOKING: "CLEAR_DELETE_BOOKING",
};
