import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Table,
  Space,
  Modal,
  Layout,
  Form,
  Select,
  Input,
  Badge,
  notification,
} from "antd";
import { Link } from "react-router-dom";
import Highlighter from "react-highlight-words";
import {
  EditOutlined,
  PlusOutlined,
  DeleteOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { getBuses, getBus, updateBus, deleteBus } from "../../redux/bus/action";
import { getTrips } from "../../redux/trip/action";
import { useDispatch, useSelector } from "react-redux";
//
const { Option } = Select;

const { Content } = Layout;

function Bus() {
  const [isEditModalVisible, setEditModalVisible] = useState(false);
  const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);

  const [searchText, setSearchText] = useState(false);
  const [searchedColumn, setSearchedColumn] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [filteredInfo, setFilteredInfo] = useState(null);
  const [sortedInfo, setSortedInfo] = useState(null);
  const [rowData, setRowData] = useState({});

  const handleChange = (e) => {
    setRowData({ ...rowData, [e.target.name]: e.target.value });
  };
  const dispatch = useDispatch();

  const { buses, busesLoading, updateBusLoading, deleteBusLoading } =
    useSelector((state) => state.bus);

  const { trips } = useSelector((state) => state.trip);
  useEffect(() => {
    dispatch(getBuses);
    dispatch(getTrips);
  }, [dispatch, updateBusLoading, deleteBusLoading]);

  let getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            setSearchInput(node);
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>

          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => setSearchInput(""), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  let handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  let handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
    setFilteredInfo(null);
    setSortedInfo(null);
  };
  const openNotification = (placement, message) => {
    notification.error({
      message: message,
      description: ``,
      placement,
    });
  };
  let statusData = null;
  function handleChangeStatus(value) {
    statusData = value;
  }
  //Update bus
  const handleUpdate = () => {
    let defaultStatus = rowData.status;

    let status;
    if (statusData !== null) {
      status = statusData;
    } else {
      status = defaultStatus;
    }
    const { plateNumber, sideNumber } = rowData;
    if (plateNumber === "") {
      openNotification("topRight", "Plate Number is required");
    } else if (sideNumber === "") {
      openNotification("topRight", "Side Number is required");
    } else {
      const busStructure = rowData.seaterId;
      const formData = { plateNumber, sideNumber, busStructure, status };
      dispatch(updateBus(formData, rowData.id));

      setEditModalVisible(false);
    }
  };
  // Handle edit modal
  const showEditModal = (record) => {
    dispatch(getBus(record.id));
    setRowData(record);
    setEditModalVisible(true);
  };

  const handleEditModalCancel = () => {
    setEditModalVisible(false);
  };

  // Handle delete modal
  const showDeleteModal = () => {
    setDeleteModalVisible(true);
  };

  const handleDeleteModalCancel = () => {
    setDeleteModalVisible(false);
  };

  const handleDelete = (data) => {
    let checkBus = false;
    for (const obj in trips) {
      if (data.plateNumber === trips[obj].bus) {
        checkBus = true;

        break;
      }
    }
    if (checkBus) {
      notification.error({
        message: "Can not Delete Bus, Bus is assigned to Route",
        placement: "topRight",
        description: ``,
      });
      setDeleteModalVisible(false);
    } else {
      dispatch(deleteBus(data.id));
      setDeleteModalVisible(false);
    }
  };

  // Form handle
  const onFinish = (values) => {};

  const onFinishFailed = (errorInfo) => {};
  // Columns data
  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Side Number",
      dataIndex: "sideNumber",
      ...getColumnSearchProps("sideNumber"),
    },
    {
      title: "Plate Number",
      dataIndex: "plateNumber",
      ...getColumnSearchProps("plateNumber"),
    },
    {
      title: "Bus Structure",
      dataIndex: "seater",
      ...getColumnSearchProps("seater"),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text, record) => {
        const active = (
          <Badge
            className="site-badge-count-109"
            count={"Active"}
            style={{ backgroundColor: "#52c41a" }}
          />
        );

        const inactive = <Badge count={"Cancelled"} />;

        const show = record.status === "ACTIVE" ? active : inactive;

        return show;
      },
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Button
            style={{ background: "#eba134", borderColor: "#eba134" }}
            type="primary"
            shape="round"
            icon={<EditOutlined />}
            size="small"
            onClick={() => {
              showEditModal(record);
            }}
          />
          <Button
            style={{ background: "#db0925", borderColor: "#db0925" }}
            shape="round"
            type="primary"
            icon={<DeleteOutlined />}
            size="small"
            onClick={() => {
              setRowData(record);
              showDeleteModal();
            }}
          />
        </Space>
      ),
    },
  ];

  return (
    <div className="tabled">
      <Row gutter={[24, 0]}>
        <Col xs={24} xl={24}>
          <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title="All Buses of public bus"
            extra={
              <Link to="new-bus">
                <Button type="primary" icon={<PlusOutlined />} size="large">
                  New Bus
                </Button>
              </Link>
            }
          >
            <div
              style={{
                display: "flex",
                padding: "2em 2em 2em 2em",
                width: "100%",
              }}
            >
              <Table
                style={{ width: "100%" }}
                dataSource={buses}
                columns={columns}
                loading={busesLoading ? true : false}
              />
            </div>
          </Card>
        </Col>
      </Row>

      {/*
        Edit Bus Modal
      */}
      <Modal
        title="Edit Bus"
        visible={isEditModalVisible}
        onCancel={handleEditModalCancel}
        footer={[
          <Button key="back" onClick={handleEditModalCancel}>
            cancel
          </Button>,
          <Button key="submit" type="primary" onClick={() => handleUpdate()}>
            Update
          </Button>,
        ]}
        width={1000}
      >
        <Content className="p-0">
          <Form
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
            className="row-col"
          >
            <Row>
              <Col
                xs={8}
                xl={8}
                style={{
                  padding: "1em",
                }}
              >
                <label>Side Number</label>
                <Input
                  style={{ width: "100%" }}
                  className="sideNumber"
                  label="Side Number"
                  name="sideNumber"
                  rules={[
                    {
                      required: true,
                      message: "Please input side number!",
                    },
                  ]}
                  placeholder={rowData.sideNumber}
                  value={rowData.sideNumber}
                  onChange={handleChange}
                />
              </Col>
              <Col
                xs={8}
                xl={8}
                style={{
                  padding: "1em",
                }}
              >
                <label>Plate Number</label>
                <Input
                  style={{ width: "100%" }}
                  className="plateNumber"
                  label="Plate number"
                  name="plateNumber"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Plate number!",
                    },
                  ]}
                  placeholder="plate Number..."
                  value={rowData.plateNumber}
                  onChange={handleChange}
                />
              </Col>
              {/* <Col
                xs={8}
                xl={8}
                style={{
                  padding: "1em",
                }}
              >
                <label>Bus Structure</label>
                <Select
                  style={{ width: "100%" }}
                  className="busStructure"
                  label="Bus structure"
                  name="busStructure"
                  onChange={handleChanges}
                  placeholder="Seat Number..."
                  value={rowData.busStructure}
                >
                  <Option value="2">49 Seats</Option>
                  <Option value="1">51 Seat</Option>
                </Select>
              </Col> */}
              <Col
                xs={8}
                xl={8}
                style={{
                  padding: "1em",
                }}
              >
                <label>Status</label>
                <Select
                  className="status"
                  label="Status"
                  onChange={handleChangeStatus}
                  rules={[
                    {
                      required: true,
                      message: "Please input Status!",
                    },
                  ]}
                  style={{ width: "100%" }}
                  size="large"
                  placeholder="Bus Status"
                  value={rowData.status}
                >
                  <Option value="ACTIVE">Active</Option>
                  <Option value="INACTIVE">InActive</Option>
                </Select>
              </Col>
            </Row>
          </Form>
        </Content>
      </Modal>

      {/*
        Delete Bus Modal
      */}
      <Modal
        title="Delete Bus"
        visible={isDeleteModalVisible}
        onCancel={handleDeleteModalCancel}
        footer={[
          <Button key="back" onClick={handleDeleteModalCancel}>
            cancel
          </Button>,
          <Button
            key="submit"
            danger
            type="primary"
            onClick={() => handleDelete(rowData)}
          >
            Delete
          </Button>,
        ]}
      >
        <p>Are you sure?</p>
        <p>you want to delete Bus with Plate Number ?</p>
        <p>{rowData.plateNumber}</p>
      </Modal>
    </div>
  );
}

export default Bus;
