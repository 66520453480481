import React, { useState, useEffect } from "react";
import { Row, Col, Card, Button, Table, Space, Input, Avatar } from "antd";
import Highlighter from "react-highlight-words";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { SearchOutlined, RightOutlined } from "@ant-design/icons";
import { getCustomers } from "../../redux/customer/action";

const Customers = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState(false);
  const [searchedColumn, setSearchedColumn] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [filteredInfo, setFilteredInfo] = useState(null);
  const [sortedInfo, setSortedInfo] = useState(null);

  useEffect(() => {
    dispatch(getCustomers);
  }, [dispatch]);

  const { customers, customersLoading } = useSelector(
    (state) => state.customer
  );

  let getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            setSearchInput(node);
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>

          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => setSearchInput(""), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  let handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  let handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
    setFilteredInfo(null);
    setSortedInfo(null);
  };

  const handleCustomer = (record) => {
    history.push(`/director/customer/${record.id}`);
  };

  // Columns data
  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Avatar",
      dataIndex: "avatar",
      render: (text, record) => (
        <Space size="middle">
          <Avatar
            src={
              "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIMAAACDCAMAAACZQ1hUAAAAaVBMVEX19fUAAAD5+fnX19dfX1/////8/Pxzc3OKioqOjo7x8fHj4+Pp6emxsbHd3d3u7u7KyspAQEC9vb2cnJxFRUU2NjYXFxcmJiaAgIDR0dGkpKR5eXlnZ2e3t7eUlJRTU1MtLS0QEBAdHR3HtiwFAAAEpklEQVR4nO1a2XKrMAxFApmUHbMvCQn//5FXJu1Mt1xMGqV94Ly0yTjmoNWS7Dg7duzYsWPHjh3PAyqFr1D4K88np+tf/Kksy8k/FFlAT+aBFMYTfETeJ09kgSoZGoBLPhbHrHPdTBd+OQPUY/csFio48GtXvesQXQ1CETlJXPK3Q6SeQYF0DeB3n9WPSEnLLHqSp4AnVv33MkcVspEMjrQ+kPXQOrcEjliwjIRJEFOI/ydt0kxCVB3UAxz/b3WqY0EJksAEoFjbn2KATE4b6MG4vrs6QCVGQWm4RDZvWK1L626U0NuEIOZaRTIMMAOw0zM1EMvES/LhYCdi1UMupAyAzk4OGM4QSrgGq8La3GmCWIRDAaOtgOkErQQHNdh5hQHLrJQwSlXahz8TUEV0AZBY7xtVkAqQQKjtbT0tZRxjS/ALJnAFOAR/gMMmOaQyHBDm0J5DLmIP2/ziDMHjKTgq/wPxwbdPyNhBLhInTzDY5gtO3oMEBzxCaZ2zRihEdBFCY3WaNKiEjtZ8QtOWZxiuMSQYLDWWpUGoAjyZs5w509oZGp2lzrROUIK22ZqjQyOSNRnEIrbZGgd7L94KjKxO1uxAMknzuntrU2+S1aq7OUQWJbXJFZ1gV4pe4LzGgWsLIce8AtMKXlZ6IDHU9jn+HqjjiqBVZNEm+SmJAx+nbr8mBpWkQb6hhPJm6YCOtyGx3Q2MaihvdP9QjbI+8QblXiBPv3uQkQLoJ/RpmUTSwKzpiyioq+CSPYWCUUcOcPgkCuUUNczuU1rmC4lgMI17VHgVBqJSxxLAe07X/pUEZSwKOOgQiUhF+sSf5uNX/ciywGWQc2mqvGpqM8YpgufPtJSTtdW8jJHq85ClTzLGT0DWQqe17lgjvzHXe6Nxxe8R+AWY4ZmB+XNjyTJ0XdYIDPiQdZ/ovvXy5mKc8ttpEnJdwWgqbyiOiXrs0FWp8Og37weqlf4iC1RZ+X7JPMYJPihmoQr0aAg0pd/rzE2SMOOPXua8e09+mOvzmmOYJG6m+2Eyv6i9OHiAwSIFxdm8+CmLOCS+TtiXKWv+0pkoaaDcYlomrG8LiNJs+ao+RT8NHCp4MU87hZ+US+lp0c3Zaw/tWJl/L230IUowkag3NNofsUA8sgxKHXy1LlSp9vP6VfeXfDxG3y1yMj5SzP39E09MR2N9twxLUZBkcd/3sXZTurHoaqlTcqdxEtfZUNyMBcsTOBiQUv+1O6T4sjKYvQnj7N69/D/uFLHLDHc4iGrNYPsxUWYZg3ubSdDw0GsExLXRtJGEkcJDT8jkzuBt+wWfzaxaLvZQLmzqWapM4D6H0rChS4WpyPUBYsO0blOhD5PEXY5lXzsYTYjUKqZUtRxP4gQHmVpF9XBOrShomdmDAZ05+Nusm+AkVSuoGGaLvTGEWa6flFo19Flngm01OtgM49Fbu330E2AH5/VV6YZAcgeiZn17TCC3cp97Ma4nIo7qor09TsirXscm2Uo2U/h0tpqKOGtbXv65k0MM/to70svOYeewc9g5/G0OkrEa7ThUoyeIcp0D5yxpWOREVxoWl5ZQGusUduzYsWPHjh1/Gv8A4rc2sVtzhpkAAAAASUVORK5CYII="
            }
          />
        </Space>
      ),
    },
    {
      title: "First Name",
      dataIndex: "displayName",
      key: "displayName",
      ...getColumnSearchProps("displayName"),
    },
    {
      title: "Phone",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      ...getColumnSearchProps("phoneNumber"),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          {/* <Button
            style={{ background: "#eba134", borderColor: "#eba134" }}
            type="primary"
            shape="round"
            icon={<EditOutlined />}
            size="small"
            // onClick={showEditModal}
          />
          <Button
            style={{ background: "#db0925", borderColor: "#db0925" }}
            shape="round"
            type="primary"
            icon={<DeleteOutlined />}
            size="small"
            // onClick={showDeleteModal}
          /> */}
          <Button
            style={{ background: "#41b0a5", borderColor: "#2f998f" }}
            shape="round"
            type="primary"
            icon={<RightOutlined />}
            size="small"
            onClick={() => handleCustomer(record)}
          />
        </Space>
      ),
    },
  ];

  return (
    <div className="tabled">
      <Row gutter={[24, 0]}>
        <Col span={24}>
          <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title="Customers List"
          >
            <div
              style={{
                display: "flex",
                padding: "2em 2em 2em 2em",
                width: "100%",
              }}
            >
              <Table
                style={{ width: "100%" }}
                dataSource={customers}
                columns={columns}
                loading={customersLoading}
              />
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Customers;
