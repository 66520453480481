import { React, useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Table,
  Space,
  Modal,
  Layout,
  Form,
  Input,
  Select,
  notification,
  Spin,
  Badge,
} from "antd";
import { Link } from "react-router-dom";
import Highlighter from "react-highlight-words";
import {
  EditOutlined,
  UserAddOutlined,
  DeleteOutlined,
  SearchOutlined,
  LoadingOutlined,
} from "@ant-design/icons";

import { useDispatch, useSelector } from "react-redux";

import {
  getHumanResources,
  updateHumanResource,
  deleteHumanResource
} from "../../redux/human_resource/action";
import { getBuses, updateBus } from "../../redux/bus/action";
import { phonNumberValidate } from "../../validation/Conductor";

const { Content } = Layout;
const { Option } = Select;
const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "white" }} spin />
);
function HumanResource() {
  const [isEditModalVisible, setEditModalVisible] = useState(false);
  const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
  const [currConductor, setCurrConductor] = useState({});
  const [conductorEdit, setConductorEdit] = useState({});
  const [status, setStatus] = useState(null);
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const {
    humanResources,
    humanResource,
    humanResourcesLoading,
    updateHumanResourceLoading,
  } = useSelector((state) => state.human_resource);

  useEffect(() => {
    dispatch(getBuses);
    dispatch(getHumanResources);
  }, [dispatch, updateHumanResourceLoading]);

  const [rowData, setRowData] = useState({});
  const { buses } = useSelector((state) => state.bus);
  const handleChange = (e) => {
    setRowData({ ...rowData, [e.target.name]: e.target.value });
  };

  let count = 0;
  const busesData = [];

  buses.forEach((bus) =>
    busesData.push({
      key: count++,
      value: bus.id,
      plateNumber: bus.plateNumber,
    })
  );

  function handleChangeStatus(value) {
    setStatus(value);
  }

  const showEditModal = (record) => {
    // dispatch(getHumanResource(record.id));
    setStatus(record.busStatus);
    setConductorEdit(humanResource.humanResource);
    setEditModalVisible(true);
  };

  const handleEditModalCancel = () => {
    setRowData({});
    setEditModalVisible(false);
  };

  const showDeleteModal = (record) => {
    setCurrConductor(record);
    setDeleteModalVisible(true);
  };

  const handleDeleteModalCancel = () => {
    setDeleteModalVisible(false);
  };

  const handleDelete = (id) => {    
    dispatch(deleteHumanResource(id));
  };

  const openNotification = (placement, message) => {
    notification.error({
      message: message,
      description: ``,
      placement,
    });
  };

  let genderValue = null;
  function handleChangeGender(value) {
    genderValue = value;
  }
  let bloodType = null;
  function handleChangeBloodType(value) {
    bloodType = value;
  }
  let positionValue = null;
  function handleChangeType(value) {
    positionValue = value;
  }
  let busValue = null;
  function handleChangeBus(value) {
    busValue = value;
  }

  let genderDefaultValue = null;
  let bloodTypeDefaultValue = null;
  let positionDefaultValue = null;
  let busDefaultValue = null;

  const updateBusStatus = ({ busId }) => {
    const findBus = buses.find((bus) => bus.id === busId);
    const { plateNumber, sideNumber, seaterId } = findBus;
    const updateData = {
      plateNumber,
      sideNumber,
      busStructure: seaterId,
      status,
    };
    dispatch(updateBus(updateData, busId));
  };
  const handleUpdate = () => {
    genderDefaultValue = rowData.gender;
    bloodTypeDefaultValue = rowData.bloodGroup;
    positionDefaultValue = rowData.type;
    busDefaultValue = rowData.busId;
    const { firstName, lastName, phone, dateOfBirth } = rowData;

    let gender;
    if (genderValue !== null) {
      gender = genderValue;
    } else {
      gender = genderDefaultValue;
    }

    let bloodGroup;
    if (bloodType !== null) {
      bloodGroup = bloodType;
    } else {
      bloodGroup = bloodTypeDefaultValue;
    }

    let type;
    if (positionValue !== null) {
      type = positionValue;
    } else {
      type = positionDefaultValue;
    }

    let bus;
    if (busValue !== null) {
      bus = busValue;
    } else {
      bus = busDefaultValue;
    }

    if (firstName === "") {
      openNotification("topRight", "First Name is Required");
    } else if (lastName === "") {
      openNotification("topRight", "Last Name is Required");
    } else if (phone === "" || phonNumberValidate(phone)) {
      openNotification("topRight", "Phone Number is Required");
    } else {
      const formData = {
        firstName,
        lastName,
        phone,
        dateOfBirth,
        type,
        gender,
        bloodGroup,
        bus,
      };
      updateBusStatus({ busId: bus });
      dispatch(updateHumanResource(rowData.id, formData));
      setEditModalVisible(false);
      setRowData({});
    }
  };

  // TODO searching and sorting table functionality for Conductors page
  const [searchText, setSearchText] = useState(false);
  const [searchedColumn, setSearchedColumn] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [filteredInfo, setFilteredInfo] = useState(null);
  const [sortedInfo, setSortedInfo] = useState(null);
  let getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            setSearchInput(node);
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>

          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => setSearchInput(""), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  let handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  let handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
    setFilteredInfo(null);
    setSortedInfo(null);
  };

  // Columns data
  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "First Name",
      dataIndex: "firstName",
      ...getColumnSearchProps("firstName"),
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      ...getColumnSearchProps("lastName"),
    },
    {
      title: "Phone",
      dataIndex: "phone",
      ...getColumnSearchProps("phone"),
    },
    {
      title: "Type",
      dataIndex: "type",
      ...getColumnSearchProps("type"),
    },
    {
      title: "Gender",
      dataIndex: "gender",
      ...getColumnSearchProps("gender"),
    },
    {
      title: "Date of Birth",
      dataIndex: "dateOfBirth",
      ...getColumnSearchProps("dateOfBirth"),
    },
    {
      title: "Blood G.",
      dataIndex: "bloodGroup",
      ...getColumnSearchProps("bloodGroup"),
    },
    {
      title: "Side No.",
      dataIndex: "sideNumber",
      ...getColumnSearchProps("sideNumber"),
    },
    {
      title: "Plate No.",
      dataIndex: "plateNumber",
      ...getColumnSearchProps("plateNumber"),
    },
    {
      title: "Bus Status",
      dataIndex: "busStatus",
      render: (text, record) => {
        const active = (
          <Badge
            className="site-badge-count-109"
            count={"Active"}
            style={{ backgroundColor: "#52c41a" }}
          />
        );

        const inactive = <Badge count={"InActive"} />;

        const show = record.busStatus === "ACTIVE" ? active : inactive;

        return show;
      },
    },
    {
      title: "Action",
      render: (text, record) => {
        return (
          <Space size="middle">
            <Button
              style={{ background: "#eba134", borderColor: "#eba134" }}
              type="primary"
              shape="round"
              icon={<EditOutlined />}
              size="small"
              onClick={() => {
                setRowData(record);
                setCurrConductor(record);
                showEditModal(record);
              }}
            />
            <Button
              style={{ background: "#db0925", borderColor: "#db0925" }}
              shape="round"
              type="primary"
              icon={<DeleteOutlined />}
              size="small"
              onClick={() => {
                showDeleteModal(record);
                setCurrConductor(record);
              }}
            />
          </Space>
        );
      },
    },
  ];

  return (
    <div className="tabled">
      <Row gutter={[24, 0]}>
        <Col span={24}>
          <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title="All Human Resource of public bus"
            extra={
              <Link to="new-human-resource">
                <Button type="primary" icon={<UserAddOutlined />} size="large">
                  New Human Resource
                </Button>
              </Link>
            }
          >
            <div
              style={{
                display: "flex",
                padding: "2em 2em 2em 2em",
                width: "100%",
              }}
            >
              <Table
                style={{ width: "100%" }}
                dataSource={humanResources}
                columns={columns}
                loading={humanResourcesLoading}
                scroll={{ x: 400 }}
              />
            </div>
          </Card>
        </Col>
      </Row>

      {/*
        Edit Conductor Modal
      */}
      <Modal
        title="Edit Conductor"
        visible={isEditModalVisible}
        onCancel={handleEditModalCancel}
        footer={[
          <Button key="back" onClick={handleEditModalCancel}>
            cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            form="form"
            onClick={() => {
              handleUpdate();
            }}
          >
            Update
          </Button>,
        ]}
        width={1000}
      >
        <Content className="p-0">
          <Row>
            <Col
              xs={8}
              xl={8}
              style={{
                padding: "1em",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <label>First Name</label>
              <Input
                className="firstName"
                label="First Name"
                name="firstName"
                placeholder={rowData.firstName}
                value={rowData.firstName}
                onChange={handleChange}
              />
            </Col>
            <Col
              xs={8}
              xl={8}
              style={{
                padding: "1em",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <label>Last Name</label>
              <Input
                placeholder={rowData.lastName}
                value={rowData.lastName}
                onChange={handleChange}
                className="lastName"
                label="Last Name"
                name="lastName"
              />
            </Col>
            <Col
              xs={8}
              xl={8}
              style={{
                padding: "1em",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <label>Gender</label>
              <Select
                placeholder={rowData.gender}
                size="large"
                // value={rowData.gender}
                onChange={handleChangeGender}
              >
                <Option value="MALE">Male</Option>
                <Option value="FEMALE">Female</Option>
              </Select>
            </Col>
            <Col
              xs={8}
              xl={8}
              style={{
                padding: "1em",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <label>Blood Group</label>
              <Select
                placeholder={rowData.bloodGroup}
                size="large"
                // value={rowData.bloodGroup}
                onChange={handleChangeBloodType}
              >
                <Option value="A_POSITIVE">A_POSITIVE</Option>
                <Option value="A_NEGATIVE">A_NEGATIVE</Option>

                <Option value="B_POSITIVE">B_POSITIVE</Option>
                <Option value="B_NEGATIVE">B_NEGATIVE</Option>

                <Option value="O_POSITIVE">O_POSITIVE</Option>
                <Option value="O_NEGATIVE">O_NEGATIVE</Option>

                <Option value="AB_POSITIVE">AB_POSITIVE</Option>
                <Option value="AB_NEGATIVE">AB_NEGATIVE</Option>
              </Select>
            </Col>
            <Col
              xs={8}
              xl={8}
              style={{
                padding: "1em",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <label>Phone Number</label>
              <Input
                placeholder={rowData.phone}
                value={rowData.phone}
                onChange={handleChange}
                className="phone"
                label="Phone"
                name="phone"
              />
            </Col>

            <Col
              xs={8}
              xl={8}
              style={{
                padding: "1em",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <label>Position</label>
              <Select
                placeholder={rowData.type}
                size="large"
                // value={rowData.type}
                // defaultValue={}
                onChange={handleChangeType}
              >
                <Option value="DRIVER">DRIVER</Option>
                <Option value="CONDACTOR">CONDACTOR</Option>
              </Select>
            </Col>
            <Col
              xs={8}
              xl={8}
              style={{
                padding: "1em",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <label>Bus</label>
              <Select
                size="large"
                showSearch
                placeholder={rowData.sideNumber}
                optionFilterProp="children"
                onChange={handleChangeBus}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                // value={rowData.sideNumber}
              >
                {busesData.map((option, index) => (
                  <Option key={index} value={option.value}>
                    {option.plateNumber}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col
              xs={8}
              xl={8}
              style={{
                padding: "1em",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <label>Bus Status</label>
              <Select
                className="status"
                label="Status"
                onChange={handleChangeStatus}
                rules={[
                  {
                    required: true,
                    message: "Please input Status!",
                  },
                ]}
                style={{ width: "100%" }}
                size="large"
                value={status}
              >
                <Option value="ACTIVE">Active</Option>
                <Option value="INACTIVE">InActive</Option>
              </Select>
            </Col>
          </Row>
        </Content>
      </Modal>

      {/*
        Delete Conductor Modal
      */}
      {/* <Modal
        title="Delete Human Resource"
        visible={isDeleteModalVisible}
        onCancel={handleDeleteModalCancel}
        footer={[
          <Button key="back" onClick={handleDeleteModalCancel}>
            cancel
          </Button>,
          <Button
            key="submit"
            danger
            type="primary"
            onClick={() => handleDelete(currConductor.id)}
          >
            Delete
          </Button>,
        ]}
      >
        <p>Are you sure?</p>
        <p>you want to delete conductor?</p>
        <p>{`${currConductor.firstName} ${currConductor.lastName}`}</p>
      </Modal> */}
    </div>
  );
}

export default HumanResource;
