export const CustomerTypes = {
  CUSTOMER_PENDING: "CUSTOMER_PENDING",
  CUSTOMER_SUCCESS: "CUSTOMER_SUCCESS",
  CUSTOMER_FAIL: "CUSTOMER_FAIL",
  CLEAR_CUSTOMER: "CLEAR_CUSTOMER",

  CUSTOMERS_PENDING: "CUSTOMERS_PENDING",
  CUSTOMERS_SUCCESS: "CUSTOMERS_SUCCESS",
  CUSTOMERS_FAIL: "CUSTOMERS_FAIL",
  CLEAR_CUSTOMERS: "CLEAR_CUSTOMERS",
};
