import { BusStructureType } from "./types";

const INITIAL_STATE = {
  error: null,
  busStructure: null,
  busStructures: [],
  busStructureLoading: false,
  busStructuresLoading: false,
  busStructureSuccess: false,
  busStructuresSuccess: false,
  busStructureFail: false,
  busStructuresFail: false,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case BusStructureType.BUS_STRUCTURE_PENDING:
      return {
        ...state,
        busStructureLoading: true,
      };
    case BusStructureType.BUS_STRUCTURE_SUCCESS:
      return {
        ...state,
        busStructureLoading: false,
        busStructureSuccess: true,
        busStructures: action.payload.busStructure,
      };
    case BusStructureType.BUS_STRUCTURE_FAIL:
      return {
        ...state,
        busStructureSuccess: false,
        error: action.payload.error,
      };
    case BusStructureType.CLEAR_BUS_STRUCTURE_SUCCESS:
      return {
        ...state,
        busStructureSuccess: false,
        busStructureLoading: false,
        busStructureFail: false,
      };
    case BusStructureType.BUS_STRUCTURES_PENDING:
      return {
        ...state,
        busStructuresLoading: true,
      };
    case BusStructureType.BUS_STRUCTURES_SUCCESS:
      return {
        ...state,
        busStructuresLoading: false,
        busStructureSuccess: true,
        busStructures: action.payload.busStructures,
      };
    case BusStructureType.BUS_STRUCTURES_FAIL:
      return {
        ...state,
        busStructuresSuccess: false,
        error: action.payload.error,
      };
    case BusStructureType.CLEAR_BUS_STRUCTURES_SUCCESS:
      return {
        ...state,
        busStructuresSuccess: false,
        busStructuresLoading: false,
        busStructuresFail: false,
      };
    default:
      return state;
  }
};

export default reducer;
