import axios from "axios"
import { busAdminTokenKey, BASE_URI } from "../../config/key.config";
import {    
  GET_ACCOUNTS,
  ADD_ACCOUNT,
  DELETE_ACCOUNT,
  UPDATE_ACCOUNT,
  ERROR_ACCOUNT
} from "./types";

export const getAccounts = () => (dispatch) => {
    return(dispatch({type: GET_ACCOUNTS}))
//   axios
//     .get(`${BASE_URI}/accounts`, {
//       headers: {
//         Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
//       },
//     })
//     .then((res) => dispatch({ type: GET_ACCOUNTS, payload: res.data }))
//     .catch(err => dispatch({type: ERROR_ACCOUNT}))
};

export const addAccount = (formData) => (dispatch) => {    
    return(dispatch({type: ADD_ACCOUNT, payload: formData}))
//   axios
//     .post(`${BASE_URI}/add-account`, formData, {
//       headers: {
//         Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
//       },
//     })
//     .then((res) => dispatch({ type: ADD_ACCOUNT, payload: res.data }))
//     .catch(err => dispatch({type: ERROR_ACCOUNT}))
}

export const updateAccount = (id, formData) => (dispatch) => {       
    return(dispatch({type: UPDATE_ACCOUNT, payload: formData}))
//   axios
//     .put(`${BASE_URI}/add-account/${id}`, formData, {
//       headers: {
//         Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
//       },
//     })
//     .then((res) => dispatch({ type: UPDATE_ACCOUNT, payload: res.data}))
//     .catch(err => dispatch({type: ERROR_ACCOUNT}))
}

export const deleteAccount = (id) => (dispatch) => {        
    return(dispatch({type: DELETE_ACCOUNT, payload: id}))
//   axios
//     .delete(`${BASE_URI}/add-account/${id}`, {
//       headers: {
//         Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
//       },
//     })
//     .then((res) => dispatch({ type: DELETE_ACCOUNT, payload: res.data.id }))
//     .catch(err => dispatch({type: ERROR_ACCOUNT}))
}
