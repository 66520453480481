import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { Pie, G2 } from "@ant-design/plots";
import { Typography } from "antd";
import { getDashboardBankReport, clearReports } from "../../redux/report/action";

const { Title } = Typography;

const DemoPie2 = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDashboardBankReport);
    
    return () => {
      dispatch(clearReports);
    };
  }, [dispatch]);
  
  const { reportsBank } = useSelector((state) => state.report);
  const G = G2.getEngine("canvas");
  const data = reportsBank;
  const cfg = {
    appendPadding: 10,
    data,
    angleField: "tickets",
    colorField: "bank",
    radius: 0.75,
    legend: false,
    label: {
      type: "spider",
      labelHeight: 40,
      formatter: (data, mappingData) => {
        const group = new G.Group({});
        group.addShape({
          type: "circle",
          attrs: {
            x: 0,
            y: 0,
            width: 40,
            height: 50,
            r: 5,
            fill: mappingData.color,
          },
        });
        group.addShape({
          type: "text",
          attrs: {
            x: 10,
            y: 8,
            text: `${data.bank}`,
            fill: mappingData.color,
          },
        });
        group.addShape({
          type: "text",
          attrs: {
            x: 0,
            y: 25,
            text: `${data.tickets}`,
            fill: "rgba(0, 0, 0, 0.65)",
            fontWeight: 700,
          },
        });
        return group;
      },
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
    ],
  };
  const config = cfg;
  return (
    <div className="chart-vistior">
      <Title level={5} style={{ paddingLeft: "1rem" }}>
        Bank Share
      </Title>
      <Pie {...config} />
    </div>
  );
};

export default DemoPie2;
