import { DropOffPickUpTypes } from "./types";

const INITIAL_STATE = {
  error: null,
  drop_pick: {},
  drop_picks: [],
  picks: [],
  drops: [],

  dropPickLoading: false,
  dropPickSuccess: false,
  dropPickFail: false,

  dropPicksLoading: false,
  dropPicksSuccess: false,
  dropPicksFail: false,

  createDropPickLoading: false,
  createDropPickSuccess: false,
  createDropPickFail: false,

  updateDropPickLoading: false,
  updateDropPickSuccess: false,
  upateDropPickFail: false,

  deleteDropPickLoading: false,
  deleteDropPickSuccess: false,
  deleteDropPickFail: false,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DropOffPickUpTypes.DROP_OFF_PICKUP_PENDING:
      return {
        ...state,
        dropPickLoading: true,
      };
    case DropOffPickUpTypes.DROP_OFF_PICKUP_SUCCESS:
      return {
        ...state,
        dropPickSuccess: true,
        dropPickLoading: false,
        drop_pick: {
          ...state.drop_pick,
          [action.payload.id]: action.payload.drop_pick,
        },
      };
    case DropOffPickUpTypes.DROP_OFF_PICKUP_FAIL:
      return {
        ...state,
        dropPickLoading: false,
        dropPickSuccess: false,
        error: action.payload.error,
      };
    case DropOffPickUpTypes.DROP_POINT_SUCCESS:
      return {
        ...state,
        drops: action.payload.drops,
      };
    case DropOffPickUpTypes.PICK_POINT_SUCCESS:
      return {
        ...state,
        picks: action.payload.picks,
      };
    case DropOffPickUpTypes.CLEAR_DROP_OFF_PICKUP:
      return {
        ...state,
        dropPickLoading: false,
        dropPickSuccess: false,
        dropPickFail: false,
      };

    case DropOffPickUpTypes.DROP_OFF_PICKUPS_PENDING:
      return {
        ...state,
        dropPicksLoading: true,
      };
    case DropOffPickUpTypes.DROP_OFF_PICKUPS_SUCCESS:
      return {
        ...state,
        dropPicksSuccess: true,
        dropPicksLoading: false,
        drop_picks: action.payload.drop_picks,
      };
    case DropOffPickUpTypes.DROP_OFF_PICKUPS_FAIL:
      return {
        ...state,
        dropPicksLoading: false,
        dropPicksSuccess: false,
        error: action.payload.error,
      };
    case DropOffPickUpTypes.CLEAR_DROP_OFF_PICKUPS:
      return {
        ...state,
        dropPicksSuccess: false,
        dropPicksLoading: false,
        dropPicksFail: false,
      };

    case DropOffPickUpTypes.CREATE_DROP_OFF_PICKUP_PENDING:
      return {
        ...state,
        createDropPickLoading: true,
      };
    case DropOffPickUpTypes.CREATE_DROP_OFF_PICKUP_SUCCESS:
      return {
        ...state,
        createDropPickSuccess: true,
        createDropPickLoading: false,
        drop_picks: [action.payload.drop_pick, ...state.drop_picks],
      };
    case DropOffPickUpTypes.CREATE_DROP_OFF_PICKUP_FAIL:
      return {
        ...state,
        createDropPickLoading: false,
        createDropPickSuccess: false,
        error: action.payload.error,
      };
    case DropOffPickUpTypes.CREATE_CLEAR_DROP_OFF_PICKUP:
      return {
        ...state,
        createDropPickSuccess: false,
        createDropPickLoading: false,
        createDropPickFail: false,
      };

    case DropOffPickUpTypes.UPDATE_DROP_OFF_PICKUP_PENDING:
      return {
        ...state,
        updateDropPickLoading: true,
      };
    case DropOffPickUpTypes.UPDATE_DROP_OFF_PICKUP_SUCCESS:
      return {
        ...state,
        updateDropPickSuccess: true,
        updateDropPickLoading: false,
        drop_pick: {
          ...state.drop_pick,
          [action.payload.id]: action.payload.drop_pick,
        },
      };
    case DropOffPickUpTypes.UPDATE_DROP_OFF_PICKUP_FAIL:
      return {
        ...state,
        updateDropPickLoading: false,
        updateDropPickSuccess: false,
        error: action.payload.error,
      };
    case DropOffPickUpTypes.UPDATE_CLEAR_DROP_OFF_PICKUP:
      return {
        ...state,
        updateDropPickSuccess: false,
        updateDropPickLoading: false,
        upateDropPickFail: false,
      };

    case DropOffPickUpTypes.DELETE_DROP_OFF_PICKUP_PENDING:
      return {
        ...state,
        deleteDropPickLoading: true,
      };
    case DropOffPickUpTypes.DELETE_DROP_OFF_PICKUP_SUCCESS:
      return {
        ...state,
        deleteDropPickSuccess: true,
        deleteDropPickLoading: false,
      };
    case DropOffPickUpTypes.DELETE_DROP_OFF_PICKUP_FAIL:
      return {
        ...state,
        deleteDropPickLoading: false,
        deleteDropPickSuccess: false,
        error: action.payload.error,
      };
    case DropOffPickUpTypes.DELETE_CLEAR_DROP_OFF_PICKUP:
      return {
        ...state,
        deleteDropPickSuccess: false,
        deleteDropPickLoading: false,
        deleteDropPickFail: false,
      };

    default:
      return state;
  }
};

export default reducer;
