import axios from "axios";
import { ConductorTypes } from "./types";
import { busAdminTokenKey, BASE_URI } from "../../config/key.config";

export const fetchConductorsStart = () => ({
  type: ConductorTypes.CONDUCTORS_PENDING,
});

export const fetchConductorsSuccess = (conductors) => ({
  type: ConductorTypes.CONDUCTORS_SUCCESS,
  payload: {
    conductors,
  },
});

export const fetchConductorsFail = (error) => ({
  type: ConductorTypes.CONDUCTORS_FAIL,
  payload: { error },
});

export const fetchConductorStart = () => ({
  type: ConductorTypes.CONDUCTOR_PENDING,
});

export const fetchConductorSuccess = (id, conductor) => ({
  type: ConductorTypes.CONDUCTOR_SUCCESS,
  payload: {
    id,
    conductor,
  },
});

export const fetchConductorFail = (error) => ({
  type: ConductorTypes.CONDUCTOR_FAIL,
  payload: {
    error,
  },
});

export const createConductorStart = () => ({
  type: ConductorTypes.CREATE_CONDUCTOR_PENDING,
});

export const createConductorSuccess = (conductor) => ({
  type: ConductorTypes.CREATE_CONDUCTOR_SUCCESS,
  payload: {
    conductor,
  },
});

export const createConductorFail = (error) => ({
  type: ConductorTypes.CREATE_CONDUCTOR_FAIL,
  payload: {
    error,
  },
});

export const updateConductorPending = () => ({
  type: ConductorTypes.UPDATE_CONDUCTOR_PENDING,
});

export const updateConductorSuccess = (id, conductor) => ({
  type: ConductorTypes.UPDATE_CONDUCTOR_SUCCESS,
  payload: {
    id,
    conductor,
  },
});

export const updateConductorFail = (error) => ({
  type: ConductorTypes.UPDATE_CONDUCTOR_FAIL,
  payload: {
    error,
  },
});

export const updateConductorClear = () => ({
  type: ConductorTypes.CLEAR_UPDATE_CONDUCTOR,
});

export const deleteConductorPending = () => ({
  type: ConductorTypes.DELETE_CONDUCTOR_PENDING,
});

export const deleteConductorSuccess = (id) => ({
  type: ConductorTypes.DELETE_CONDUCTOR_SUCCESS,
  payload: {
    id,
  },
});

export const deleteConductorFail = (error) => ({
  type: ConductorTypes.DELETE_CONDUCTOR_FAIL,
  payload: {
    error,
  },
});

export const deleteConductorClear = () => ({
  type: ConductorTypes.CLEAR_DELETE_CONDUCTOR,
});

export const resetConductorPending = () => ({
  type: ConductorTypes.RESET_CONDUCTOR_PENDING,
});

export const resetConductorSuccess = (conductor) => ({
  type: ConductorTypes.RESET_CONDUCTOR_SUCCESS,
  payload: {
    conductor,
  },
});

export const resetConductorFail = (error) => ({
  type: ConductorTypes.RESET_CONDUCTOR_FAIL,
  payload: {
    error,
  },
});

export const getAllConductors = (dispatch) => {
  dispatch(fetchConductorsStart());

  axios
    .get(`${BASE_URI}/conductors`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) => {
      const response = res.data;
      const responseData = [];
      response.forEach((res) =>
        responseData.push({
          key: res.id,
          id: res.id,
          name: `${res.firstName} ${res.lastName}`,
          firstName: res.firstName,
          lastName: res.lastName,
          position: "Assistant",
          phoneNo: res.phoneNo,
          email: res.email,
          address: res.address,
          status: res.status,
        })
      );
      dispatch(fetchConductorsSuccess(responseData));
    })
    .catch((err) => {
      dispatch(fetchConductorsFail(err));
    });
};

export const getConductor = (id) => {
  return async (dispatch) => {
    try {
      dispatch(fetchConductorStart());

      const response = await axios.get(`${BASE_URI}/conductors/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
        },
      });
      dispatch(fetchConductorSuccess(id, response.data));
    } catch (err) {
      dispatch(fetchConductorFail(err));
    }
  };
};

export const createConductor = (formData, history) => (dispatch) => {
  dispatch(createConductorStart());
  axios
    .post(`${BASE_URI}/conductors`, formData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) => {
      dispatch(createConductorSuccess(res.data));
      history.push("/director/conductors");
    })
    .catch((err) => {
      dispatch(createConductorFail(err));
    });
};

export const updateConductor = (formData, id) => (dispatch) => {
  dispatch(updateConductorPending());

  axios
    .put(`${BASE_URI}/conductors/${id}`, formData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) => {
      dispatch(updateConductorSuccess(id, res.data));
      // dispatch(updateConductorClear());
    })
    .catch((err) => {
      dispatch(updateConductorFail(err));
      // dispatch(updateConductorClear());
      // dispatch(updateConductorClear());
    });
};

export const deleteConductor = (id) => (dispatch) => {
  dispatch(resetConductorPending());

  axios
    .delete(`${BASE_URI}/conductors/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) => dispatch(deleteConductorSuccess(id)))
    .catch((err) => dispatch(deleteConductorFail(err)));
};

export const resetConductor = (id) => (dispatch) => {
  dispatch(resetConductorPending());

  axios
    .post(`${BASE_URI}/conductors/reset/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) => {
      dispatch(resetConductorSuccess(id));
    })
    .catch((err) => dispatch(resetConductorFail(err)));
};
