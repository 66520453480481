export const phonNumberValidate = (phoneNumber) => {
  const regExp = "/^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$/im";
  const phone = phoneNumber.match(regExp);
//   console.log(phone);
  return phone;
};

export const validateEmail = (email) => {
  return email.match(
    '/^(([^<>()[]\\.,;:s@"]+(.[^<>()[]\\.,;:s@"]+)*)|(".+"))@(([[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}])|(([a-zA-Z-0-9]+.)+[a-zA-Z]{2,}))$/'
  );
};
