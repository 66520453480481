import React, { useState } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Table,
  Space,
  Modal,
  Layout,
  Form,
  Input,
  Select,
  Badge,
  notification,
} from "antd";
import { Link } from "react-router-dom";
import Highlighter from "react-highlight-words";
import {
  EditOutlined,
  UserAddOutlined,
  DeleteOutlined,
  SearchOutlined,
} from "@ant-design/icons";
//
import { useSelector, useDispatch } from "react-redux";
import { deleteUser } from "../../../redux/user/action";

const { Content } = Layout;
const { Option } = Select;

function OperationUser({ operators, handleUserUpdate }) {
  const dispatch = useDispatch();
  const [isEditModalVisible, setEditModalVisible] = useState(false);
  const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
  const [rowData, setRowData] = useState({});
  const [status, setStatus] = useState(null);
  const { usersLoading } = useSelector((state) => state.user);

  const handleChange = (e) => {
    setRowData({ ...rowData, [e.target.name]: e.target.value });
  };

  // Handle edit modal

  let roleData = null;
  function handleChangeRole(value) {
    roleData = value;
  }

  let defaultRoleValue = null;

  function handleChangeStatus(value) {
    setStatus(value)        
  }

  const showEditModal = (record) => {
    setRowData(record);
    setStatus(record.status)
    setEditModalVisible(true);
  };

  const handleEditModalCancel = () => {
    setEditModalVisible(false);
  };

  // Handle delete modal
  const showDeleteModal = (record) => {
    setDeleteModalVisible(true);
    setRowData(record);
  };

  const handleDeleteModalCancel = () => {
    setDeleteModalVisible(false);
  };

  const openNotification = (placement, message) => {
    notification.error({
      message: message,
      description: ``,
      placement,
    });
  };

  const handleUpdate = () => {
    defaultRoleValue = rowData.role;
    const { firstName, lastName, phoneNo, email, userName } = rowData;
    let role;
    if (roleData !== null) {
      role = roleData;
    } else {
      role = defaultRoleValue;
    }

    const busAssociation = 1;

    if (firstName === "") {
      openNotification("topRight", "First Name is Required");
    } else if (lastName === "") {
      openNotification("topRight", "Last Name is Required");
    } else if (phoneNo === "") {
      openNotification("topRight", "Phone Number is Required");
    } else if (email === "") {
      openNotification("topRight", "Email is Required");
    } else if (userName === "") {
      openNotification("topRight", "User Name is Required");
    } else {
      const formData = {
        firstName,
        lastName,
        phoneNo,
        email,
        busAssociation,
        role,
        userName,
        status
      };
      handleUserUpdate(rowData.id, formData);
      setRowData({});
      setEditModalVisible(false);
    }
  };

  const handleDelete = (id) => {
    dispatch(deleteUser(id));
    setRowData({});
    setDeleteModalVisible(false);
  };

  const [searchText, setSearchText] = useState(false);
  const [searchedColumn, setSearchedColumn] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [filteredInfo, setFilteredInfo] = useState(null);
  const [sortedInfo, setSortedInfo] = useState(null);
  let getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            setSearchInput(node);
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>

          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => setSearchInput(""), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  let handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  let handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
    setFilteredInfo(null);
    setSortedInfo(null);
  };

  // Columns data
  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
      ...getColumnSearchProps("firstName"),
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
      ...getColumnSearchProps("lastName"),
    },
    {
      title: "Phone",
      dataIndex: "phoneNo",
      key: "phoneNo",
      ...getColumnSearchProps("phoneNo"),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      ...getColumnSearchProps("email"),
    },
    {
      title: "User Name",
      dataIndex: "userName",
      key: "userName",
      ...getColumnSearchProps("userName"),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => {
        const active = (
          <Badge
            className="site-badge-count-109"
            count={"Active"}
            style={{ backgroundColor: "#52c41a" }}
          />
        );

        const inactive = <Badge count={"Cancelled"} />;

        const show = record.status === "ACTIVE" ? active : inactive;

        return show;
      },
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Button
            style={{ background: "#eba134", borderColor: "#eba134" }}
            type="primary"
            shape="round"
            icon={<EditOutlined />}
            size="small"
            onClick={() => {
              showEditModal(record);
            }}
          />
          <Button
            style={{ background: "#db0925", borderColor: "#db0925" }}
            shape="round"
            type="primary"
            icon={<DeleteOutlined />}
            size="small"
            onClick={() => showDeleteModal(record)}
          />
        </Space>
      ),
    },
  ];

  return (
    <div className="tabled">
      <Row gutter={[24, 0]}>
        <Col span={24}>
          <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title="Basic Access"
            extra={
              <Link to="new-user">
                <Button type="primary" icon={<UserAddOutlined />} size="large">
                  Add Users
                </Button>
              </Link>
            }
          >
            <div
              style={{
                display: "flex",
                padding: "2em 2em 2em 2em",
                width: "100%",
              }}
            >
              <Table
                style={{ width: "100%" }}
                dataSource={operators}
                columns={columns}
                loading={usersLoading}
                scroll={{ x: 400 }}
              />
            </div>
          </Card>
        </Col>
      </Row>

      {/*
        Edit Conductor Modal
      */}
      <Modal
        title="Edit User"
        visible={isEditModalVisible}
        onCancel={handleEditModalCancel}
        footer={[
          <Button key="back" onClick={handleEditModalCancel}>
            cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleUpdate}>
            Update
          </Button>,
        ]}
        width={1000}
      >
        <Content className="p-0">
          <Row>
            <Col
              xs={8}
              xl={8}
              style={{
                padding: "1em",
              }}
            >
              <Input
                className="firstName"
                label="First Name"
                name="firstName"
                placeholder={rowData.firstName}
                value={rowData.firstName}
                onChange={handleChange}
              />
            </Col>

            <Col
              xs={8}
              xl={8}
              style={{
                padding: "1em",
              }}
            >
              <Input
                className="lastName"
                label="Last Name"
                name="lastName"
                placeholder={rowData.lastName}
                value={rowData.lastName}
                onChange={handleChange}
              />
            </Col>

            <Col
              xs={8}
              xl={8}
              style={{
                padding: "1em",
              }}
            >
              <Input
                className="phone"
                label="Phone"
                name="phoneNo"
                placeholder={rowData.phoneNo}
                value={rowData.phoneNo}
                onChange={handleChange}
              />
            </Col>
            <Col
              xs={8}
              xl={8}
              style={{
                padding: "1em",
              }}
            >
              <Input
                className="email"
                label="Email"
                name="email"
                placeholder={rowData.email}
                value={rowData.email}
                onChange={handleChange}
              />
            </Col>
            <Col
              xs={8}
              xl={8}
              style={{
                padding: "1em",
              }}
            >
              <Input
                className="userName"
                label="User Name"
                name="userName"
                placeholder={rowData.userName}
                value={rowData.userName}
                onChange={handleChange}
              />
            </Col>
            <Col
              xs={8}
              xl={8}
              style={{
                padding: "1em",
              }}
            >
              <Select
                className="status"
                label="Status"
                name="status"                
                style={{ width: "100%" }}
                size="medium"
                onChange={handleChangeStatus}
                value={status}
              >
                <Option value="ACTIVE">Active</Option>
                <Option value="INACTIVE">InActive</Option>
              </Select>
            </Col>
            <Col
              xs={8}
              xl={8}
              style={{
                padding: "1em",
              }}
            >
              <Select
                placeholder={
                  rowData.role === "user_basic"
                    ? "Basic Access"
                    : rowData.role === "user_director"
                    ? "Director"
                    : rowData.role === "user_finance"
                    ? "Finance"
                    : rowData.role === "user_reporter"
                    ? "Reporter"
                    : rowData.role === "user_oprator"
                    ? "Operator"
                    : ""
                }
                style={{ width: "100%" }}
                size="medium"
                className="role"
                label="Role"
                name="role"
                onChange={handleChangeRole}
              >
                <Option value="user_basic">Basic Access</Option>                
                <Option value="user_finance">Finance</Option>
                <Option value="user_oprator">Operation</Option>
                <Option value="user_reporter">Reporter</Option>
              </Select>
            </Col>
          </Row>
        </Content>
      </Modal>

      {/*
        Delete Conductor Modal
      */}
      <Modal
        title="Delete Conductor"
        visible={isDeleteModalVisible}
        onCancel={handleDeleteModalCancel}
        footer={[
          <Button key="back" onClick={handleDeleteModalCancel}>
            cancel
          </Button>,
          <Button key="submit" danger type="primary" onClick={() => handleDelete(rowData.id)}>
            Delete
          </Button>,
        ]}
      >
        <p>Are you sure?</p>
        <p>you want to delete the following conductor ?</p>
        <p>{rowData.userName}</p>
      </Modal>
    </div>
  );
}

export default OperationUser;
