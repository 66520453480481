import axios from "axios";
import { HumanResourceTypes } from "./types";
import { busAdminTokenKey, BASE_URI } from "../../config/key.config";

export const fetchHumanResourceStart = () => ({
  type: HumanResourceTypes.HUMAN_RESOURCE_PENDING,
});

export const fetchHumanResourceSuccess = (id, humanResource) => ({
  type: HumanResourceTypes.HUMAN_RESOURCE_SUCCESS,
  payload: {
    id,
    humanResource,
  },
});

export const fetchHumanResourceFail = (error) => ({
  type: HumanResourceTypes.HUMAN_RESOURCE_FAIL,
  payload: {
    error,
  },
});

export const clearFetchHumanResource = () => ({
  type: HumanResourceTypes.CLEAR_HUMAN_RESOURCE,
});

export const fetchHumanResourcesStart = () => ({
  type: HumanResourceTypes.HUMAN_RESOURCES_PENDING,
});

export const fetchHumanResourcesSuccess = (humanResources) => ({
  type: HumanResourceTypes.HUMAN_RESOURCES_SUCCESS,
  payload: {
    humanResources,
  },
});

export const fetchHumanResourcesFail = (error) => ({
  type: HumanResourceTypes.HUMAN_RESOURCES_FAIL,
  payload: {
    error,
  },
});

export const clearFetchHumanResources = () => ({
  type: HumanResourceTypes.CLEAR_HUMAN_RESOURCES,
});
//TODO create Human Resource
export const createHumanResourceStart = () => ({
  type: HumanResourceTypes.CREATE_HUMAN_RESOURCE_PENDING,
});

export const createHumanResourceSuccess = (humanResource) => ({
  type: HumanResourceTypes.CREATE_HUMAN_RESOURCE_SUCCESS,
  payload: {
    humanResource,
  },
});

export const createHumanResourceFail = (error) => ({
  type: HumanResourceTypes.CREATE_HUMAN_RESOURCE_FAIL,
  payload: {
    error,
  },
});

export const createCreateHumanResource = () => ({
  type: HumanResourceTypes.CREATE_CLEAR_HUMAN_RESOURCE,
});

//TODO Update Human Resource
export const updateHumanResourceStart = () => ({
  type: HumanResourceTypes.UPDATE_HUMAN_RESOURCE_PENDING,
});

export const updateHumanResourceSuccess = (id, humanResource) => ({
  type: HumanResourceTypes.UPDATE_HUMAN_RESOURCE_SUCCESS,
  payload: {
    id,
    humanResource,
  },
});

export const updateHumanResourceFail = (error) => ({
  type: HumanResourceTypes.UPDATE_HUMAN_RESOURCE_FAIL,
  payload: {
    error,
  },
});

export const clearUpdateHumanResource = () => ({
  type: HumanResourceTypes.UPDATE_CLEAR_HUMAN_RESOURCE,
});

//TODO Delete Human Resource
export const deleteHumanResourceStart = () => ({
  type:HumanResourceTypes.DELETE_HUMAN_RESOURCE_PENDING,
});

export const deleteHumanResourceSuccess = (id) => ({
  type: HumanResourceTypes.DELETE_HUMAN_RESOURCE_SUCCESS,
  payload: { id },
});

export const deleteHumanResourceFail = (error) => ({
  type: HumanResourceTypes.DELETE_HUMAN_RESOURCE_FAIL,
  payload: { error },
});

export const clearDeleteHumanResource = () => ({
  type: HumanResourceTypes.DELETE_CLEAR_HUMAN_RESOURCE,
});

//TODO assign bus human resource
export const assignBusStart = () => ({
  type: HumanResourceTypes.ASSIGN_BUS_HUMAN_RESOURCE_START,
});

export const assignBusSuccess = (formData) => ({
  type: HumanResourceTypes.ASSIGN_BUS_HUMAN_RESOURCE_SUCCESS,
  payload: {
    formData,
  },
});

export const assignBusFail = (error) => ({
  type: HumanResourceTypes.ASSIGN_BUS_HUMAN_RESOURCE_FAIL,
  payload: {
    error,
  },
});

export const clearAssignBus = () => ({
  type: HumanResourceTypes.CLEAR_ASSIGN_BUS_HUMAN_RESOURCE,
});

export const getHumanResources = (dispatch) => {
  dispatch(fetchHumanResourcesStart());

  axios
    .get(`${BASE_URI}/humanresource`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) => {
      const response = res.data;
      const responseData = [];      

      response.forEach((res) =>
        responseData.push({
          key: res.id,
          id: res.id,
          firstName: res.firstName,
          lastName: res.lastName,
          phone: res.phone,
          dateOfBirth: res.dateOfBirth.slice(0, 10),
          type: res.type,
          bloodGroup: res.bloodGroup,
          gender: res.gender,
          createdAt: res.createdAt,
          updatedAt: res.updatedAt,
          deletedAt: res.deletedAt,
          busId: res.bus?.id,
          sideNumber: res.bus?.sideNumber,
          plateNumber: res.bus?.plateNumber,
          busStatus: res.bus?.status,
        })
      );

      dispatch(fetchHumanResourcesSuccess(responseData));
    })
    .catch((err) => {
      dispatch(fetchHumanResourcesFail(err));
    });
};

export const getHumanResource = (id) => (dispatch) => {
  dispatch(fetchHumanResourceStart());

  axios
    .get(`${BASE_URI}/humanresource/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) => {
      dispatch(fetchHumanResourceSuccess(res.data));
    })
    .catch((err) => {
      dispatch(fetchHumanResourceFail(err));
    });
};

export const createHumanResource = (formData, history) => (dispatch) => {
  dispatch(createHumanResourceStart());

  axios
    .post(`${BASE_URI}/humanresource/add`, formData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) => {
      dispatch(createHumanResourceSuccess(res.data));
      history.push("/director/human-resource");
    })
    .catch((err) => {
      dispatch(createHumanResourceFail(err));
    });
};

export const updateHumanResource = (id, formData) => (dispatch) => {
  dispatch(updateHumanResourceStart());

  axios
    .put(`${BASE_URI}/humanresource/update/${id}`, formData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) => {
      dispatch(updateHumanResourceSuccess(res.data));
    })
    .catch((err) => {
      dispatch(updateHumanResourceFail(err));
    });
};

export const deleteHumanResource = (id) => (dispatch) => {
  dispatch(deleteHumanResourceStart());
  
  // delete route is not found.
  axios
    .delete(`${BASE_URI}/humanresource/delete/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) => dispatch(deleteHumanResourceSuccess(id)))
    .catch((err) => dispatch(deleteHumanResourceFail(err)));
};

export const assignBus = (formData) => (dispatch) => {
  dispatch(assignBusStart());

  axios
    .put(`${BASE_URI}/humanresource/assignbus`, formData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) => {
      dispatch(assignBusSuccess(res.data));
    })
    .catch((err) => {
      dispatch(assignBusFail(err));
    });
};
