import React, { useState, useEffect } from "react";
import { Tabs, Row, Col, Avatar, Card, Rate, Typography, Divider } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import { ArrowUpOutlined } from "@ant-design/icons";
import { Column } from "@ant-design/plots";

const { Title } = Typography;

const ReviewChart = ({ rates, yearlyReport }) => {
  const config = {
    data: yearlyReport,
    isGroup: true,
    xField: "month",
    yField: "value",
    seriesField: "name",
    // columnWidth: 5,
    color: ["#1994d1", "#e3b905"],
    columnStyle: {
      radius: [10, 10, 0, 0],
    },

    label: {
      layout: [
        {
          type: "interval-adjust-position",
        },
        {
          type: "interval-hide-overlap",
        },
        {
          type: "adjust-color",
          color: "red",
        },
      ],
    },
  };
  return (
    <div>
      {rates && (
        <Row justify="space-between" align="middle" gutter={[24, 0]}>
          <Col span={12} md={12} className="col-info">
            <div
              style={{  
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <h1>👍 &nbsp;Positive Review</h1>
              <Title>{rates.totalPositiveRate}</Title>
              <Paragraph className="lastweek" style={{ marginBottom: 24 }}>
                <span className="bnb2">
                  <ArrowUpOutlined />
                  &nbsp;{rates.percentPositive}%
                </span>{" "}
                from last 7 days
              </Paragraph>
            </div>
          </Col>
          <Col
            style={{ wordWrap: "break-word" }}
            span={12}
            md={12}
            className="col-info"
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <h1>👎 &nbsp;Negative Review</h1>
              <Title>{rates.totalNegativeRate}</Title>
              <Paragraph className="lastweek" style={{ marginBottom: 24 }}>
                <span className="bnb2" style={{ color: "red" }}>
                  <ArrowUpOutlined />
                  &nbsp;{rates.percentNegative}%
                </span>{" "}
                from last 7 days
              </Paragraph>
            </div>
          </Col>  
          <Divider plain>
            <h3>Rating Statistics</h3>
          </Divider>

          <Col span={24} md={24} className="col-info">
            <Column {...config} />
          </Col>
        </Row>
      )}
    </div>
  );
};

export default ReviewChart;
