import { logoutUser } from "../../redux/auth/action";

// import { useState } from "react";
import { Menu } from "antd";
import { NavLink, useLocation } from "react-router-dom";
import publicBusLogo from "../../assets/logo/public_bus.png";
import { Fragment } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import {
  HomeOutlined,
  SwapOutlined,
  CreditCardOutlined,
  EnvironmentOutlined,  
  RetweetOutlined,
  ExclamationCircleOutlined,
  MenuOutlined,
  BookOutlined,
} from "@ant-design/icons";
const { SubMenu } = Menu;

function OperationSidenav({ color }) {
  const { pathname } = useLocation();
  const page = pathname.replace("/", "");

  const history = useHistory();
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logoutUser(history));
  };
  return (
    <>
      <div className="brand" style={{ color: "white" }}>
        <img src={publicBusLogo} alt="" />
        <span style={{ color: "#252525" }}>Public Bus</span>
      </div>
      <hr />
      <Menu theme="light" mode="inline">
        <Menu.Item key="1">
          <NavLink to="dashboard">
            <span
              className="icon"
              style={{
                background: page === "dashboard" ? color : "",
              }}
            >
              <HomeOutlined />
            </span>
            <span className="label">Dashboard</span>
          </NavLink>
        </Menu.Item>

        <Menu.Item key="4-conductor">
          <NavLink to="conductors">
            <span
              className="icon"
              style={{
                background: page === "conductors" ? color : "",
              }}
            >
             <MenuOutlined />
            </span>
            <span className="label">Conductors</span>
          </NavLink>
        </Menu.Item>

        <Menu.Item key="2">
          <NavLink to="trips">
            <span
              className="icon"
              style={{
                background: page === "trips" ? color : "",
              }}
            >
              <SwapOutlined />
            </span>
            <span className="label">Trips</span>
          </NavLink>
        </Menu.Item>

        <Menu.Item key="3">
          <NavLink to="bus-structures">
            <span
              className="icon"
              style={{
                background: page === "structure" ? color : "",
              }}
            >
              <MenuOutlined />
            </span>
            <span className="label">Bus Structure</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="4">
          <NavLink to="booking-details">
            <span
              className="icon"
              style={{
                background: page === "structure" ? color : "",
              }}
            >
              <BookOutlined />
            </span>
            <span className="label">Booking Details</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="5">
          <NavLink to="locations">
            <span
              className="icon"
              style={{
                background: page === "locations" ? color : "",
              }}
            >
              <EnvironmentOutlined />{" "}
            </span>
            <span className="label">Cities and Stops</span>
          </NavLink>
        </Menu.Item>

        <Menu.Item key="6">
          <NavLink to="route">
            <span
              className="icon"
              style={{
                background: page === "route" ? color : "",
              }}
            >
              <RetweetOutlined />
            </span>
            <span className="label">Route</span>
          </NavLink>
        </Menu.Item>
        <SubMenu
          key="SubMenu5"
          className="submenu-items"
          // className="submenu-item"
          icon={
            <span className="icon">
              <CreditCardOutlined />
            </span>
          }
          title="Ticket Managment"
        >
          <Menu.Item key="7" className="submenu-item">
            <NavLink to="booking-information">
              <span className="" ellipsis={true}>
                {/* <HomeOutlined /> */}{" "}
                <Fragment>&nbsp;&nbsp;&nbsp;&nbsp;</Fragment>
              </span>
              <span className="label">Booking Information</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="8" className="submenu-item">
            <NavLink to="refund">
              <span className="">
                <Fragment>&nbsp;&nbsp;&nbsp;&nbsp;</Fragment>
                {/* <HomeOutlined /> */}
              </span>
              <span className="label">Refund</span>
            </NavLink>
          </Menu.Item>
        </SubMenu>

        {/* <SubMenu
          key="SubMenu1"
          className="submenu-items"
          icon={
            <span className="icon">
              <CarOutlined />
            </span>
          }
          title="Bus Information"
        >
          <Menu.Item key="9" className="submenu-item">
            <NavLink to="terms-and-conditions">
              <span className="" ellipsis={true}>
                <Fragment>&nbsp;&nbsp;&nbsp;&nbsp;</Fragment>
              </span>
              <span className="label">Terms and Conditions</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="10" className="submenu-item">
            <NavLink to="cancellation-policy">
              <span className="">
                <Fragment>&nbsp;&nbsp;&nbsp;&nbsp;</Fragment>
              </span>
              <span className="label">Cancellation Policy</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="11" className="submenu-item">
            <NavLink to="services">
              <span className="">
                <Fragment>&nbsp;&nbsp;&nbsp;&nbsp;</Fragment>
              </span>
              <span className="label">Services</span>
            </NavLink>
          </Menu.Item>
        </SubMenu> */}
        {/* 3 */}

        <Menu.Item className="menu-item-header" key="13">
          Report
        </Menu.Item>

        <SubMenu
          key="SubMenu2"
          className="submenu-items"
          // className="submenu-item"
          icon={
            <span className="icon">
              <ExclamationCircleOutlined />
            </span>
          }
          title="Ticket Report"
        >
          <Menu.Item key="12" className="submenu-item">
            <NavLink to="report">
              <span className="" ellipsis={true}>
                <Fragment>&nbsp;&nbsp;&nbsp;&nbsp;</Fragment>
              </span>
              <span className="label">Report</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="13" className="submenu-item">
            <NavLink to="ofecho-report">
              <span className="">
                <Fragment>&nbsp;&nbsp;&nbsp;&nbsp;</Fragment>
              </span>
              <span className="label">Ofecho Report</span>
            </NavLink>
          </Menu.Item>
        </SubMenu>

        <Menu.Item className="menu-item-header" key="14">
          Account Pages
        </Menu.Item>
        <Menu.Item key="15" onClick={() => handleLogout()}>
          <div>
            <span
              className="icon"
              style={{
                background: page === "signout" ? color : "",
              }}
            >
              <HomeOutlined />
            </span>
            <span className="label">Sign Out</span>
          </div>
        </Menu.Item>
        {/* </Menu.Item> */}
      </Menu>
      <div className="aside-footer">
        <div
          className="footer-box"
          style={{
            background: color,
          }}
        >
          <h6>Need Help?</h6>
          <p>Call: 0982117777 / 9439</p>
        </div>
      </div>
    </>
  );
}

export default OperationSidenav;
