import axios from "axios";
import { TicketTypes } from "./types";
import { busAdminHeader } from "../redux.util";

const INITIAL_STATE = {
  error: null,
  ticket: {},
  tickets: [],

  ticketLoading: false,
  ticketSuccess: false,
  ticketFail: false,

  ticketsLoading: false,
  ticketsSuccess: false,
  ticketsFail: false,

  cancellationTicketsLoading: false,
  cancellationTicketsSuccess: false,
  cancellationTicketsFail: false,

  cancellationTicketLoading: false,
  cancellationTicketSuccess: false,
  cancellationTicketFail: false,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TicketTypes.TICKET_PENDING:
      return {
        ...state,
        ticketLoading: true,
      };
    case TicketTypes.TICKET_SUCCESS:
      return {
        ...state,
        ticketSuccess: true,
        ticketLoading: false,
        id: action.payload.id,
        ticket: action.payload.ticket,
      };
    case TicketTypes.TICKET_FAIL:
      return {
        ...state,
        ticketLoading: false,
        ticketSuccess: false,
        error: action.payload.error,
      };
    case TicketTypes.CLEAR_TICKET:
      return {
        ...state,
        ticketLoading: false,
        ticketSuccess: false,
        ticketFail: false,
      };

    case TicketTypes.TICKETS_PENDING:
      return {
        ...state,
        ticketsLoading: true,
      };
    case TicketTypes.TICKETS_SUCCESS:
      return {
        ...state,
        ticketsSuccess: true,
        ticketsLoading: false,
        tickets: action.payload.tickets,
      };
    case TicketTypes.TICKETS_FAIL:
      return {
        ...state,
        ticketsLoading: false,
        ticketsSuccess: false,
        error: action.payload.error,
      };
    case TicketTypes.CLEAR_TICKETS:
      return {
        ...state,
        ticketsSuccess: false,
        ticketsLoading: false,
        ticketsFail: false,
      };
    case TicketTypes.CANCELLATION_TICKETS_PENDING:
      return {
        ...state,
        cancellationTicketsLoading: true,
      };
    case TicketTypes.CANCELLATION_TICKETS_SUCCESS:
      return {
        ...state,
        cancellationTicketsSuccess: true,
        cancellationTicketsLoading: false,
        tickets: action.payload.tickets,
      };
    case TicketTypes.CANCELLATION_TICKETS_FAIL:
      return {
        ...state,
        cancellationTicketsLoading: false,
        cancellationTticketsSuccess: false,
        error: action.payload.error,
      };
    case TicketTypes.CANCELLATION_TICKET_PENDING:
      return {
        ...state,
        cancellationTicketLoading: true,
      };
    case TicketTypes.CANCELLATION_TICKET_SUCCESS:
      return {
        ...state,
        cancellatioinTicketSuccess: true,
        cancellatioinTicketLoading: false,
        id: action.payload.id,
        ticket: action.payload.ticket,
      };
    case TicketTypes.CANCELLATION_TICKET_FAIL:
      return {
        ...state,
        cancellationTicketLoading: false,
        cancellationTicketSuccess: false,
        error: action.payload.error,
      };

    default:
      return state;
  }
};

export default reducer;
