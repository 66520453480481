import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Table,
  Input,
  Button,
  Space,
  Select,
  Form,
  Modal,
  Layout,
  notification,
  Badge,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getCities } from "../../redux/city/action";
import {
  getDropOffPickUps,
  updateDropOffPickUp,
  deleteDropOffPickUp,
} from "../../redux/DropOffPickup/action";
import Highlighter from "react-highlight-words";
import { Link } from "react-router-dom";
import {
  SearchOutlined,
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
} from "@ant-design/icons";

const { Content } = Layout;

const { Option } = Select;

function CitiesAndStops() {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [isEditModalVisible, setEditModalVisible] = useState(false);
  const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
  const [currId, setCurrId] = useState(false);
  const [rowData, setRowData] = useState({});

  const [statusData, setStatusData] = useState("");
  const [cityData, setCityData] = useState("");

  const cityValues = useSelector((state) => state.city);
  const dropValues = useSelector((state) => state.dropOffPickUp);

  useEffect(() => {
    dispatch(getDropOffPickUps);
    dispatch(getCities);
  },[]);

  const city_names = [];
  cityValues.citys.forEach((city) =>
    city_names.push({
      key: city.id,
      id: city.id,
      city: city.name,
      status: city.status,
    })
  );

  const handleDelete = (id) => {
    dispatch(deleteDropOffPickUp(id));
    dispatch(getDropOffPickUps);
    dispatch(getDropOffPickUps);
    setDeleteModalVisible(false);
  };

  // Handle edit modal
  const showEditModal = (record) => {
    setEditModalVisible(true);
    setRowData(record);
  };

  const handleEditModalCancel = () => {
    setEditModalVisible(false);
    setCurrId("");
  };

  // Handle delete modal
  const showDeleteModal = () => {
    setDeleteModalVisible(true);
  };

  const handleDeleteModalCancel = () => {
    setDeleteModalVisible(false);
    setCurrId("");
  };

  const [searchText, setSearchText] = useState(false);
  const [searchedColumn, setSearchedColumn] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [filteredInfo, setFilteredInfo] = useState(null);
  const [sortedInfo, setSortedInfo] = useState(null);
  let getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            setSearchInput(node);
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>

          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => setSearchInput(""), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  let handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  let handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
    setFilteredInfo(null);
    setSortedInfo(null);
  };

  const handleChange = (e) => {
    setRowData({ ...rowData, [e.target.name]: e.target.value });
  };
  let statusdata;
  function handleChangeStatus(value) {
    statusdata = value;
  }
  let citydata = rowData ? rowData.city : "";
  function handleChangeCity(value) {
    citydata = value;
  }
  const handleUpdate = (data) => {
    if (data.firstName === "") {
      notification.error({
        message: "Error",
        placement: "topRight",
        description: ``,
      });
      setEditModalVisible(true);
    } else if (data.lastName === "") {
      notification.error({
        message: "Error",
        placement: "topRight",
        description: ``,
      });
      setEditModalVisible(true);
    } else if (data.phoneNo === "") {
      notification.error({
        message: "Error",
        placement: "topRight",
        description: ``,
      });
      setEditModalVisible(true);
    } else if (data.email === "") {
      notification.error({
        message: "Error",
        placement: "topRight",
        description: ``,
      });
      setEditModalVisible(true);
    } else if (data.address === "") {
      notification.error({
        message: "Error",
        placement: "topRight",
        description: ``,
      });
      setEditModalVisible(true);
    } else {
      const city = citydata;
      const status = statusdata;
      const { dropingPoint } = data;
      const name = dropingPoint;
      const req = { name, city, status };
      dispatch(updateDropOffPickUp(req, data.id));

      dispatch(getDropOffPickUps);
      dispatch(getDropOffPickUps);
      setEditModalVisible(false);
    }
  };

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      width: "2%",
    },
    {
      title: "Droping Points",
      dataIndex: "dropingPoint",
      key: "dropingPoint",
      width: "20%",
      ...getColumnSearchProps("dropingPoint"),
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
      width: "22%",
      ...getColumnSearchProps("city"),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "18%",
      render: (text, record) => {
        const active = (
          <Badge
            className="site-badge-count-109"
            count={"Active"}
            style={{ backgroundColor: "#52c41a" }}
          />
        );

        const inactive = <Badge count={"Cancelled"} />;

        const show = record.status === "ACTIVE" ? active : inactive;

        return show;
      },
    },

    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Button
            style={{ background: "#eba134", borderColor: "#eba134" }}
            type="primary"
            shape="round"
            icon={<EditOutlined />}
            size="small"
            onClick={() => {
              // handleModalData(record.id);
              // setCurrId(record.id);
              showEditModal(record);              
            }}
          />
          <Button
            style={{ background: "#db0925", borderColor: "#db0925" }}
            shape="round"
            type="primary"
            icon={<DeleteOutlined />}
            size="small"
            onClick={() => {
              setCurrId(record.id);
              showDeleteModal();
            }}
          />
        </Space>
      ),
    },
  ];
  const columns_2 = [
    {
      title: "City",
      dataIndex: "city",
      key: "city",
      width: "10%",
      ...getColumnSearchProps("city"),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => {
        const active = (
          <Badge
            className="site-badge-count-109"
            count={"Active"}
            style={{ backgroundColor: "#52c41a" }}
          />
        );

        const inactive = <Badge count={"Cancelled"} />;

        const show = record.status === "ACTIVE" ? active : inactive;

        return show;
      },
    },
  ];
  return (
    <div className="tabled">
      <Row gutter={[24, 0]}>
        <Col xs={7} xl={8}>
          <Card
            bordered={false}
            className="criclebox tablespace mb-24 pb-24"
            title="All cities"
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Select
                style={{ width: "80%", padding: "3rem 0" }}
                placeholder="Select a city"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {city_names.map((city) => (
                  <Option value={city.key}>{city.city.toUpperCase()}</Option>
                ))}
              </Select>
            </div>
            <Table
              columns={columns_2}
              dataSource={city_names}
              loading={cityValues.citysLoading}
              scroll={{ x: 400 }}
            />
          </Card>
        </Col>
        <Col xs={17} xl={16}>
          <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title="Citites and Droping"
            extra={
              <Link to="new-cities-boarding">
                <Button type="primary" icon={<PlusOutlined />} size="large">
                  Boarding Dropping
                </Button>
              </Link>
            }
          >
            <Table
              columns={columns}
              dataSource={dropValues.drop_picks}
              scroll={{ x: 400 }}
              // onRow={(record, recordIndex) => ({
              //   onClick: (event) => {
              //     console.log(record.id);
              //   },
              // })}
              loading={dropValues.dropPicksLoading}
            />
          </Card>
        </Col>
      </Row>

      {/* Edit Modal */}
      <Modal
        title="Edit Bus"
        visible={isEditModalVisible}
        onCancel={handleEditModalCancel}
        footer={[
          <Button key="back" onClick={handleEditModalCancel}>
            cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => handleUpdate(rowData)}
          >
            Update
          </Button>,
        ]}
        width={1000}
      >
        <Content className="p-0">
          <Form form={form} layout="vertical" className="row-col">
            <Row>
              <Col
                xs={8}
                xl={10}
                style={{
                  padding: "1em",
                }}
              >
                <Input
                  className="dropingPoint"
                  label="Droping Point"
                  name="dropingPoint"
                  rules={[
                    {
                      required: true,
                      message: "Please input side number!",
                    },
                  ]}
                  placeholder="Boarding or Dropping Point Name"
                  value={rowData.dropingPoint}
                  onChange={handleChange}
                />
              </Col>
              <Col
                xs={8}
                xl={6}
                style={{
                  padding: "1em",
                }}
              >
                <Select
                  className="status"
                  label="Status"
                  onChange={handleChangeStatus}
                  rules={[
                    {
                      required: true,
                      message: "Please input Status!",
                    },
                  ]}
                  style={{ width: 200 }}
                  size="large"
                  placeholder="Status of the Boarding or Droping Point"
                  value={rowData.status}
                >
                  <Option value="ACTIVE">Active</Option>
                  <Option value="INACTIVE">InActive</Option>
                </Select>
              </Col>
              <Col
                xs={8}
                xl={8}
                style={{
                  padding: "1em",
                }}
              >
                <Select
                  size="large"
                  style={{ width: 200 }}
                  placeholder="Select a city"
                  value={rowData.cityId}
                  onChange={handleChangeCity}
                  // filterOption={(input, option) =>
                  //   option.children
                  //     .toLowerCase()
                  //     .indexOf(input.toLowerCase()) >= 0
                  // }
                >
                  {city_names.map((city) => (
                    <Option value={city.key}>{city.city.toUpperCase()}</Option>
                  ))}
                </Select>
              </Col>
            </Row>
          </Form>
        </Content>
      </Modal>

      {/* Delete Modal */}
      <Modal
        title="Delete Bus"
        visible={isDeleteModalVisible}
        onCancel={handleDeleteModalCancel}
        footer={[
          <Button key="back" onClick={handleDeleteModalCancel}>
            cancel
          </Button>,
          <Button
            key="submit"
            danger
            type="primary"
            onClick={() => handleDelete(currId)}
          >
            Delete
          </Button>,
        ]}
      >
        <p>Are you sure?</p>
        <p>you want to delete the following city droping point ?</p>
        <p></p>
      </Modal>
    </div>
  );
}

export default CitiesAndStops;
