import moment from "moment";
import { React, useState, useEffect } from "react";
import {
  Layout,
  Row,
  Col,
  Button,
  Card,
  Form,
  Input,
  Select,
  Space,
  Spin,
} from "antd";
import { PlusOutlined, LoadingOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { createDropOffPickUp } from "../../redux/DropOffPickup/action";
import { getCities } from "../../redux/city/action";
const { Content } = Layout;

const { Option } = Select;

const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "white" }} spin />
);

function NewDropingBoardingPoints() {
  const dispatch = useDispatch();
  const history = useHistory();
  // const [boardingLists, setBoardingList] = useState([{ boardingCity: "" }]);
  const {
    dropPickLoading,
    createDropPickLoading,
    createDropPickSuccess,
    createDropPickFail,
  } = useSelector((state) => state.dropOffPickUp);
  const { citys } = useSelector((state) => state.city);
  useEffect(() => {
    dispatch(getCities);
  }, []);

  const options = [];

  citys.forEach((city) =>
    options.push({ key: city.id, value: city.id, city: city.name })
  );
  const onFinish = (values) => {
    dispatch(createDropOffPickUp(values, history));
  };

  const onFinishFailed = (errorInfo) => {};

  return (
    <div className="tabled">
      <Row gutter={[24, 0]}>
        <Col xs={24} xl={24}>
          <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title="Add new Trip destination"
          >
            <div
              style={{
                display: "flex",
                padding: "2em 2em 2em 2em",
                width: "100%",
              }}
            >
              <Content className="p-0">
                <Form
                  name="basic"
                  initialValues={{ remember: true }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  layout="vertical"
                  className="row-col"
                >
                  <Col
                    xs={24}
                    sm={16}
                    xl={10}
                    style={{
                      padding: "1em",
                    }}
                  >
                    <Form.Item
                      className="city"
                      label="City"
                      name="city"
                      rules={[
                        {
                          required: true,
                          message: "Please input city name!",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder="Select Starting destination"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {options.map((option) => (
                          <Option value={option.value}>{option.city}</Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col
                    xs={24}
                    sm={16}
                    xl={10}
                    style={{
                      padding: "1em",
                    }}
                  >
                    <Form.Item
                      className="name"
                      label="Boarding or Dropping place"
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: "Please input Boarding or Dropping place!",
                        },
                      ]}
                    >
                      <Input placeholder="Boarding or Dropping place" />
                    </Form.Item>
                  </Col>

                  {/* <Form.List
                    name="droppingOrBoarding"
                    label="Boarding or Dropping"
                  >
                    {(fields, { add, remove }) => (
                      <Col
                        xs={24}
                        sm={16}
                        xl={10}
                        style={{
                          padding: "1em",
                        }}
                      >
                        {fields.map((field) => (
                          <Space key={field.key} align="baseline">
                            <Form.Item
                              {...field}
                              label="Boarding or Dropping"
                              name={[field.name, "drop"]}
                              rules={[
                                {
                                  required: true,
                                  message:
                                    "Please Input Boarding or Dropping place",
                                },
                              ]}
                            >
                              <Input placeholder="eer" />
                            </Form.Item>

                            <MinusCircleOutlined
                              onClick={() => remove(field.name)}
                            />
                          </Space>
                        ))}

                        <Form.Item>
                          <Button
                            type="dashed"
                            onClick={() => add()}
                            block
                            icon={<PlusOutlined />}
                          >
                            Add Boarding or Dropping
                          </Button>
                        </Form.Item>
                      </Col>
                    )}
                  </Form.List> */}

                  <Form.Item>
                    <div
                      style={{
                        paddingTop: 30,
                        width: "100%",
                        display: "flex",
                        paddingLeft: "18px",
                        // justifyContent: "center",
                      }}
                    >
                      <Button type="primary" htmlType="submit">
                        {createDropPickLoading ? (
                          <Spin indicator={antIcon} />
                        ) : (
                          "Save"
                        )}
                      </Button>
                    </div>
                  </Form.Item>
                </Form>
              </Content>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default NewDropingBoardingPoints;

// {boardingLists.map((x, i) => {
//   return (
//     <Form.Item
//       className="boardingPoint"
//       label="Boarding or Droping Point"
//       name="boardingPoint"
//       rules={[
//         {
//           required: true,
//           message: "Please input Boarding Point!",
//         },
//       ]}
//     >
//       <div style={{ marginBottom: "1rem" }}>
//         <Row
//           justify="flex-start"
//           align="bottom"
//           gutter={16}
//         >
//           <Col xs={24} sm={16} xl={10}>
//             <Input
//               name="boardingCity"
//               placeholder="Enter Boarding City"
//               value={x.boardingCity}
//               onChange={(e) => handleInputChange(e, i)}
//               // size="medium"
//             />
//           </Col>
//           <Col sm={24} xl={2} xs={24}>
//             {boardingLists.length !== 1 && (
//               <Button
//                 type="primary"
//                 icon={<MinusOutlined />}
//                 className="mr10"
//                 onClick={() => handleRemoveClick(i)}
//                 danger
//               >
//                 Remove
//               </Button>
//             )}
//           </Col>

//           <Col sm={24} xl={2} xs={24}>
//             {boardingLists.length - 1 === i && (
//               <Button
//                 type="primary"
//                 icon={<PlusOutlined />}
//                 onClick={handleAddClick}
//               >
//                 Add
//               </Button>
//             )}
//           </Col>
//         </Row>

//         <span></span>
//       </div>
//     </Form.Item>
//   );
// })}
