import axios from "axios";
import { busAdminHeader } from "../redux.util";
import { BusStructureType } from "./types";
import { busAdminTokenKey, BASE_URI } from "../../config/key.config";

export const fetchBusStructureStart = () => ({
  type: BusStructureType.BUS_STRUCTURES_PENDING,
});

export const fetchBusStructureSuccess = (busStructure) => ({
  type: BusStructureType.BUS_STRUCTURE_SUCCESS,
  payload: {
    busStructure,
  },
});

export const fetchBusStructureFail = (error) => ({
  type: BusStructureType.BUS_STRUCTURES_PENDING,
  payload: {
    error,
  },
});

export const fetchBusStructuresStart = () => ({
  type: BusStructureType.BUS_STRUCTURES_PENDING,
});
export const fetchBusStructuresSuccess = (busStructures) => ({
  type: BusStructureType.BUS_STRUCTURES_SUCCESS,
  payload: {
    busStructures,
  },
});

export const fetchBusStructuresFail = (error) => ({
  type: BusStructureType.BUS_STRUCTURES_FAIL,
  payload: {
    error,
  },
});

export const getBusStructures = (dispatch) => {
  dispatch({
    type: BusStructureType.BUS_STRUCTURE_PENDING,
  });

  axios
    .get(`${BASE_URI}/bus-structure`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) =>
      dispatch({
        type: BusStructureType.BUS_STRUCTURE_SUCCESS,
        payload: res.data,
      })
    )
    .catch((err) =>
      dispatch({
        type: BusStructureType.BUS_STRUCTURE_FAIL,
        payload: err.message,
      })
    );
};

export const getBusStructure = (id, dispatch) => {
  dispatch({
    type: BusStructureType.BUS_STRUCTURE_PENDING,
  });

  axios
    .get(`${BASE_URI}/bus-structure/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) =>    
      dispatch({
        type: BusStructureType.BUS_STRUCTURE_SUCCESS,
        payload: res.data,
      })
    )
    .catch((err) =>
      dispatch({
        type: BusStructureType.BUS_STRUCTURE_FAIL,
        payload: err.message,
      })
    );
};

// export const updateBusStatus = (id, formData, dispatch) => {
//   dispatch({
//     type: BusStructureType.BUS_STRUCTURE_PENDING,
//   });

//   axios
//     .post(
//       `http://192.168.0.6:4000/bus-structure/${id}/update-status/INACTIVE`,
//       formData,
//       busAdminHeader()
//     )
//     .then((res) =>
//       dispatch({
//         type: BusStructureType.BUS_STRUCTURE_SUCCESS,
//         payload: res.data,
//       })
//     )
//     .catch((err) =>
//       dispatch({
//         type: BusStructureType.BUS_STRUCTURE_FAIL,
//         payload: err.message,
//       })
//     );
// };

// export const createBusStructure = (formData, dispatch) => {
//   dispatch({
//     type: BusStructureType.BUS_STRUCTURE_PENDING,
//   });

//   axios
//     .get(`http://192.168.0.6:4000/bus-structure`, formData, busAdminHeader())
//     .then((res) =>
//       dispatch({
//         type: BusStructureType.BUS_STRUCTURE_SUCCESS,
//         payload: res.data,
//       })
//     )
//     .catch((err) =>
//       dispatch({
//         type: BusStructureType.BUS_STRUCTURE_FAIL,
//         payload: err.message,
//       })
//     );
// };

// export const updateBusStructure = (id, formData, dispatch) => {
//   dispatch({
//     type: BusStructureType.BUS_STRUCTURE_PENDING,
//   });

//   axios
//     .put(
//       `http://localhost:3000/bus-structure/${id}`,
//       formData,
//       busAdminHeader()
//     )
//     .then((res) =>
//       dispatch({
//         type: BusStructureType.BUS_STRUCTURE_SUCCESS,
//         payload: res.data,
//       })
//     )
//     .catch((err) =>
//       dispatch({
//         type: BusStructureType.BUS_STRUCTURE_FAIL,
//         payload: err.message,
//       })
//     );
// };
// export const deleteBusStructure = (dispatch) => {
//   dispatch({
//     type: BusStructureType.BUS_STRUCTURE_PENDING,
//   });
//   axios
//     .delete(`http://192.168.0.6:4000/bus-structure`, busAdminHeader())
//     .then((res) =>
//       dispatch({
//         type: BusStructureType.BUS_STRUCTURE_DELETE,
//         payload: {},
//       })
//     )
//     .catch((err) =>
//       dispatch({
//         type: BusStructureType.BUS_STRUCTURE_FAIL,
//         payload: err.message,
//       })
//     );
// };
