import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createBus } from "../../redux/bus/action";
import { useHistory } from "react-router-dom";
import {
  Layout,
  Row,
  Col,
  Button,
  Card,
  Form,
  Input,
  Select,
  Spin,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const { Content } = Layout;
const { Option } = Select;
const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "white" }} spin />
);
function handleChange(value) {}
function NewBus() {
  const dispatch = useDispatch();
  const history = useHistory();        

  const { createBusLoading, createBusSuccess, error } = useSelector(
    (state) => state.bus
  );

  const onFinish = (values) => {
    dispatch(createBus(values, history));
  };

  const onFinishFailed = (errorInfo) => {};
  return (
    <div className="tabled">
      <Row gutter={[24, 0]}>
        <Col xs="24" xl={24}>
          <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title="Register new bus for public bus"
          >
            <div
              style={{
                display: "flex",
                padding: "2em 2em 2em 2em",
                width: "100%",
              }}
            >
              <Content className="p-0">
                <Form
                  name="basic"
                  initialValues={{ remember: true }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  layout="vertical"
                  className="row-col"
                >
                  <Row>
                    <Col
                      xs="8"
                      xl={8}
                      style={{
                        padding: "1em",
                      }}
                    >
                      <Form.Item
                        className="sideNumber"
                        label="Side Number"
                        name="sideNumber"
                        rules={[
                          {
                            required: true,
                            message: "Please input side number!",
                          },
                        ]}
                      >
                        <Input placeholder="Side number..." />
                      </Form.Item>
                    </Col>
                    <Col
                      xs="8"
                      xl={8}
                      style={{
                        padding: "1em",
                      }}
                    >
                      <Form.Item
                        className="plateNumber"
                        label="Plate number"
                        name="plateNumber"
                        rules={[
                          {
                            required: true,
                            message: "Please input the Plate number!",
                          },
                        ]}
                      >
                        <Input placeholder="Plate number..." />
                      </Form.Item>
                    </Col>
                    <Col
                      xs="8"
                      xl={8}
                      style={{
                        padding: "1em",
                      }}
                    >
                      <Form.Item
                        className="busStructure"
                        label="Bus structure"
                        name="busStructure"
                        rules={[
                          {
                            required: true,
                            message: "Please input your Bus structure!",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Select Number of Seats"
                          defaultValue="49 Seats"
                          style={{ width: "100%", borderRadius: "50%" }}
                          onChange={handleChange}
                          size="large"
                        >
                          <Option value="1">49 Seats</Option>
                          <Option value="2">51 Seats</Option>                          
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Form.Item>
                    <div
                      style={{
                        paddingTop: 30,
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        type="primary"
                        htmlType="submit"
                        style={{
                          width: "20%",
                        }}
                      >
                        {createBusLoading ? (
                          <Spin indicator={antIcon} />
                        ) : (
                          "Register"
                        )}
                      </Button>
                    </div>
                  </Form.Item>
                </Form>
              </Content>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default NewBus;
