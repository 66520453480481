import axios from "axios";
import { RouteTypes } from "./types";
import { busAdminTokenKey, BASE_URI } from "../../config/key.config";

export const fetchRouteStart = () => ({
  type: RouteTypes.ROUTE_PENDING,
});

export const fetchRouteSuccess = (id, route) => ({
  type: RouteTypes.ROUTE_SUCCESS,
  payload: {
    id,
    route,
  },
});

export const fetchRouteFail = (error) => ({
  type: RouteTypes.ROUTE_FAIL,
  payload: {
    error,
  },
});

export const clearFetchRoute = () => ({
  type: RouteTypes.CLEAR_ROUTE,
});

export const fetchRoutesStart = () => ({
  type: RouteTypes.ROUTES_PENDING,
});

export const fetchRoutesSuccess = (routes) => ({
  type: RouteTypes.ROUTES_SUCCESS,
  payload: {
    routes,
  },
});

export const fetchRoutesFail = (error) => ({
  type: RouteTypes.ROUTES_FAIL,
  payload: {
    error,
  },
});

export const clearFetchRoutes = () => ({
  type: RouteTypes.CLEAR_ROUTES,
});

//Routes by status

export const fetchRoutesStatusStart = () => ({
  type: RouteTypes.ROUTES_STATUS_PENDING,
});

export const fetchRoutesStatusSuccess = (routes) => ({
  type: RouteTypes.ROUTES_STATUS_SUCCESS,
  payload: {
    routes,
  },
});

export const fetchRoutesStatusFail = (error) => ({
  type: RouteTypes.ROUTES_STATUS_FAIL,
  payload: {
    error,
  },
});

export const clearFetchRoutesStatus = () => ({
  type: RouteTypes.CLEAR_STATUS_ROUTES,
});
//TODO create Route
export const createRouteStart = () => ({
  type: RouteTypes.CREATE_ROUTE_PENDING,
});

export const createRouteSuccess = (route) => ({
  type: RouteTypes.CREATE_ROUTE_SUCCESS,
  payload: {
    route,
  },
});

export const createRouteFail = (error) => ({
  type: RouteTypes.CREATE_ROUTE_FAIL,
  payload: {
    error,
  },
});

export const createCreateRoute = () => ({
  type: RouteTypes.CLEAR_CREATE_ROUTE,
});

//TODO Update Route
export const updateRouteStart = () => ({
  type: RouteTypes.UPDATE_ROUTE_PENDING,
});

export const updateRouteSuccess = (id, route) => ({
  type: RouteTypes.UPDATE_ROUTE_SUCCESS,
  payload: {
    id,
    route,
  },
});

export const updateRouteFail = (error) => ({
  type: RouteTypes.UPDATE_ROUTE_FAIL,
  payload: {
    error,
  },
});

export const clearUpdateRoute = () => ({
  type: RouteTypes.CLEAR_UPDATE_ROUTE,
});

//TODO delete route
export const deleteRouteStart = () => ({
  type: RouteTypes.DELETE_ROUTE_PENDING,
});

export const deleteRouteSuccess = (id) => ({
  type: RouteTypes.DELETE_ROUTE_SUCCESS,
  payload: {
    id,
  },
});

export const deleteRouteFail = (error) => ({
  type: RouteTypes.DELETE_ROUTE_FAIL,
  payload: {
    error,
  },
});

export const clearDeleteRoute = () => ({
  type: RouteTypes.CLEAR_DELETE_ROUTE,
});

export const getRoutes = (dispatch) => {
  dispatch(fetchRoutesStart());

  axios
    .get(`${BASE_URI}/main-routes`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) => {
      const response = res.data;
      const responseData = [];
      response.forEach((route) =>
        responseData.push({
          key: route.id,
          id: route.id,
          from: route.from.name,
          fromId: route.from.id,
          to: route.to.name,
          toId: route.to.id,
          fare: route.price,
          distance: route.distance,
          arrivalTime: route.arrivalTime,
          departureTime: route.departureTime,
          status: route.status,
          subRoute: route.subRoute,
        })
      );

      dispatch(fetchRoutesSuccess(responseData));
    })
    .catch((err) => {
      dispatch(fetchRoutesFail(err));
    });
};

export const getRoutesByStatus = (status) => (dispatch) => {
  dispatch(fetchRoutesStatusStart());

  axios
    .get(`${BASE_URI}/main-routes/bus-association/status/${status}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) => {
      const response = res.data;
      const responseData = [];
      response.forEach((route) =>
        responseData.push({
          key: route.id,
          id: route.id,
          from: route.from.name,
          fromId: route.from.id,
          to: route.to.name,
          toId: route.to.id,
          fare: route.price,
          distance: route.distance,
          arrivalTime: route.arrivalTime,
          departureTime: route.departureTime,
          status: route.status,
          subRoute: route.subRoute,
        })
      );

      dispatch(fetchRoutesStatusSuccess(responseData));
    })
    .catch((err) => {
      dispatch(fetchRoutesStatusFail(err));
    });
};

export const getRoutesDashboard = (dispatch) => {
  dispatch(fetchRoutesStart());

  axios
    .get(`${BASE_URI}/main-routes`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) => {
      const response = res.data;
      let prevRoute = response[0];
      const responseData = [];
      response.forEach((route) =>
        responseData.push({
          key: route.id,
          id: route.id,
          from: route.from.name,
          fromId: route.from.id,
          to: route.to.name,
          toId: route.to.id,
          fare: route.price,
          distance: route.distance,
          arrivalTime: route.arrivalTime,
          departureTime: route.departureTime,
          status: route.status,
          subRoute: route.subRoute,
        })
      );

      dispatch(fetchRoutesSuccess(responseData));
    })
    .catch((err) => {
      dispatch(fetchRoutesFail(err));
    });
};

export const getRoute = (id) => (dispatch) => {
  dispatch(fetchRouteStart());

  axios
    .get(`${BASE_URI}/main-routes/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) => {
      const response = res.data.subRoute;
      const routeStatus = res.data.status;
      const responseData = [];
      response.forEach((sub) =>
        responseData.push({
          key: sub.id,
          id: sub.id,
          from: sub.from.name,
          fromId: sub.from.id,
          to: sub.to.name,
          toId: sub.to.id,
          fare: sub.price,
          distance: sub.distance,
          departureTime: sub.departureTime,
          status: sub.status,
          arrivalTime: sub.arrivalTime,
          route: routeStatus,
        })
      );
      dispatch(fetchRouteSuccess(id, res.data));
    })
    .catch((err) => {
      dispatch(fetchRouteFail(err));
    });
};

export const createRoute = (formData, history) => (dispatch) => {
  dispatch(createRouteStart());

  axios
    .post(`${BASE_URI}/main-routes`, formData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) => {
      dispatch(createRouteSuccess(res.data));
      // window.location.href = "http://localhost:3000/director/route";
      history.push("/director/route");
    })
    .catch((err) => {
      dispatch(createRouteFail(err));
    });
};

export const updateRoute = (formData, id) => (dispatch) => {
  dispatch(updateRouteStart());

  axios
    .put(`${BASE_URI}/main-routes/${id}`, formData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) => {
      dispatch(updateRouteSuccess(id, res.data));
    })
    .catch((err) => {
      dispatch(updateRouteFail(err));
    });
};

export const deleteRoute = (id) => (dispatch) => {
  dispatch(deleteRouteStart());

  axios
    .delete(`${BASE_URI}/main-routes/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) => {
      dispatch(deleteRouteSuccess(id));
    })
    .catch((err) => {
      dispatch(deleteRouteFail(err));
    });
};
