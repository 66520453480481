import { combineReducers } from "redux";

import AuthReducer from "./auth/reducer";
import AccountReducer from "./account/reducer";
import BusReducer from "./bus/reducer";
import BookingReducer from "./booking/reducer";
import BusStructureReducer from "./busStructure/reducer";
import CityReducer from "./city/reducer";
import ConductorReducer from "./conductor/reducer";
import RouteReducer from "./route/reducer";
import SubRouteReducer from "./subroute/reducer";
import UserReducer from "./user/reducer";
import DropOffPickupReducer from "./DropOffPickup/reducer";
import TripReducer from "./trip/reducer";
import TicketReducer from "./ticket/reducer";
import TermCondReducer from "./terms_and_conditions/reducer";
import AmenityReducer from "./amenties/reducer";
import CancellationPolicy from "./cancellation_policy/reducer";
import ReviewReducer from "./review/reducer";
import ReportReducer from "./report/reducer";
import TrackReducer from "./tracking/reducer";
import RefundReducer from "./refund/reducer";
import CustomerReducer from "./customer/reducer";
import HumanResourceReducer from "./human_resource/reducer";

export const rootReducer = combineReducers({
  auth: AuthReducer,
  account: AccountReducer,
  bus: BusReducer,
  booking: BookingReducer,
  conductor: ConductorReducer,
  city: CityReducer,
  dropOffPickUp: DropOffPickupReducer,
  busStructure: BusStructureReducer,
  route: RouteReducer,
  subRoute: SubRouteReducer,
  user: UserReducer,
  trip: TripReducer,
  ticket: TicketReducer,
  terms_conditions: TermCondReducer,
  amenity: AmenityReducer,
  cancellation_policy: CancellationPolicy,
  review: ReviewReducer,
  report: ReportReducer,
  tracking: TrackReducer,
  refund: RefundReducer,
  customer: CustomerReducer,
  human_resource: HumanResourceReducer,
});
