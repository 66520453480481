export const TripsTypes = {
    TRIP_PENDING: "TRIP_PENDING",
    TRIP_SUCCESS: "TRIP_SUCCESS",
    TRIP_FAIL: "TRIP_FAIL",
    CLEAR_TRIP: "CLEAR_TRIP",
  
    TRIPS_PENDING: "TRIPS_PENDING",
    TRIPS_SUCCESS: "TRIPS_SUCCESS",
    TRIPS_FAIL: "TRIPS_FAIL",
    CLEAR_TRIPS: "CLEAR_TRIPS",
  
    CREATE_TRIP_PENDING: "CREATE_TRIP_PENDING",
    CREATE_TRIP_SUCCESS: "CREATE_TRIP_SUCCESS",
    CREATE_TRIP_FAIL: "CREATE_TRIP_FAIL",
    CREATE_CLEAR_TRIP: "CREATE_CLEAR_TRIP",
  
    UPDATE_TRIP_PENDING: "UPDATE_TRIP_PENDING",
    UPDATE_TRIP_SUCCESS: "UPDATE_TRIP_SUCCESS",
    UPDATE_TRIP_FAIL: "UPDATE_TRIP_FAIL",
    UPDATE_CLEAR_TRIP: "UPDATE_CLEAR_TRIP",
  
    DELETE_TRIP_PENDING: "DELETE_TRIP_PENDING",
    DELETE_TRIP_SUCCESS: "DELETE_TRIP_SUCCESS",
    DELETE_TRIP_FAIL: "DELETE_TRIP_FAIL",
    DELETE_CLEAR_TRIP: "DELETE_CLEAR_TRIP",
  };
  