import { CustomerTypes } from "./types";

const INITIAL_STATE = {
  error: null,
  customers: [],
  customer: {},

  customerLoading: false,
  customerSuccess: false,
  customerFail: false,

  customersLoading: false,
  customersSuccess: false,
  customersFail: false,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CustomerTypes.CUSTOMER_PENDING:
      return {
        ...state,
        customerLoading: true,
      };
    case CustomerTypes.CUSTOMER_SUCCESS:
      return {
        ...state,
        customerSuccess: true,
        customerLoading: false,
        customer: action.payload.customer,
      };
    case CustomerTypes.CUSTOMER_FAIL:
      return {
        ...state,
        customerLoading: false,
        customerFail: true,
        customerSuccess: false,
        error: action.payload.error,
      };

    case CustomerTypes.CUSTOMERS_PENDING:
      return {
        ...state,
        customersLoading: true,
      };
    case CustomerTypes.CUSTOMERS_SUCCESS:
      return {
        ...state,
        customersSuccess: true,
        customersLoading: false,
        customers: action.payload.customers,
      };
    case CustomerTypes.CUSTOMERS_FAIL:
      return {
        ...state,
        customersLoading: false,
        customersFail: true,
        customersSuccess: false,
        error: action.payload.error,
      };

    default:
      return state;
  }
};

export default reducer;
