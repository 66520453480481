import axios from "axios";
import { busAdminTokenKey, BASE_URI } from "../../config/key.config";
import { UserTypes } from "./types";

export const fetchUserStart = () => ({
  type: UserTypes.USER_PENDING,
});

export const fetchUserSuccess = (id, user) => ({
  type: UserTypes.USER_SUCCESS,
  payload: {
    id,
    user,
  },
});

export const fetchUserFail = (error) => ({
  type: UserTypes.USER_FAIL,
  payload: {
    error,
  },
});

export const fetchUsersStart = () => ({
  type: UserTypes.USERS_PENDING,
});

export const fetchUsersSuccess = (users) => ({
  type: UserTypes.USERS_SUCCESS,
  payload: {
    users,
  },
});

export const fetchUsersFail = (error) => ({
  type: UserTypes.USERS_FAIL,
  payload: {
    error,
  },
});

export const createUserStart = () => ({
  type: UserTypes.CREATE_USER_PENDING,
});

export const createUserSuccess = (user) => ({
  type: UserTypes.CREATE_USER_SUCCESS,
  payload: {
    user,
  },
});

export const createUserFail = (error) => ({
  type: UserTypes.CREATE_USER_FAIL,
  payload: {
    error,
  },
});

export const updateUserStart = () => ({
  type: UserTypes.UPDATE_USER_PENDING,
});

export const updateUserSuccess = (id, user) => ({
  type: UserTypes.UPDATE_USER_SUCCESS,
  payload: {
    id,
    user,
  },
});

export const updateUserFail = (error) => ({
  type: UserTypes.UPDATE_USER_FAIL,
  payload: {
    error,
  },
});

export const deleteUserStart = () => ({
  type: UserTypes.UPDATE_USER_PENDING,
});

export const deleteUserSuccess = (id) => ({
  type: UserTypes.UPDATE_USER_SUCCESS,
  payload: {
    id,
  },
});

export const deleteUserFail = (error) => ({
  type: UserTypes.UPDATE_USER_FAIL,
  payload: {
    error,
  },
});

export const getAllUsers = (dispatch) => {
  dispatch({
    type: UserTypes.USERS_PENDING,
  });

  axios
    .get(`${BASE_URI}/bus-admins`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) => {
      const response = res.data;
      const responseData = [];

      response.forEach((res) =>
        responseData.push({
          key: res.id,
          id: res.id,
          firstName: res.firstName,
          lastName: res.lastName,
          phoneNo: res.phoneNo,
          email: res.email,
          userName: res.userName,
          role: res.role,
          status: res.status,
        })
      );

      dispatch(fetchUsersSuccess(responseData));
    })
    .catch((err) =>
      dispatch({
        type: UserTypes.USERS_FAIL,
        error: err.message,
      })
    );
};

export const getUser = (id, dispatch) => {
  dispatch({
    type: UserTypes.USER_PENDING,
  });

  axios
    .get(`${BASE_URI}/user/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) =>
      dispatch({
        type: UserTypes.USER_SUCCESS,
        payload: res.data,
      })
    )
    .catch((err) =>
      dispatch({
        type: UserTypes.USER_FAIL,
        error: err.message,
      })
    );
};

export const createUser = (formData, history) => (dispatch) => {
  dispatch(createUserStart());
  axios
    .post(`${BASE_URI}/bus-admins`, formData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) => {
      dispatch(createUserSuccess(res.data));
      history.push("/director/users");
    })
    .catch((err) => {
      dispatch(createUserFail(err));
    });
};

export const updateUser = (id, formData) => (dispatch) => {
  dispatch(updateUserStart());

  console.log(formData);

  axios
    .put(`${BASE_URI}/bus-admins/${id}`, formData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) => {
      dispatch(updateUserSuccess(id, res.data));
    })
    .catch((err) => {
      dispatch(updateUserFail(err));
    });
};

export const deleteUser = (id) => (dispatch) => {
  dispatch(deleteUserStart());

  axios
    .delete(`${BASE_URI}/bus-admins/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) => res.dispatch(deleteUserSuccess(id)))
    .catch((err) => dispatch(deleteUserFail(err)));
};
