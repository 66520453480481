import React, { useState, useEffect } from "react";
import {
  Layout,
  Row,
  Col,
  Button,
  Card,
  Form,
  InputNumber,
  Select,
  Spin,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { createCancellation } from "../../redux/cancellation_policy/action";
import { LoadingOutlined } from "@ant-design/icons";

const { Content } = Layout;

const { Option } = Select;

const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "white" }} spin />
);

function NewPolicy() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { createCancellationLoading } = useSelector(
    (state) => state.cancellation_policy
  );
  const onFinish = (values) => {
    const { name, chargeFee } = values;
    const charge = chargeFee.toString();
    const busAssociation = "1";
    const req = { name, charge, busAssociation };
    console.log("Success:", values);
    dispatch(createCancellation(req, history));
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <div className="tabled">
      <Row gutter={[24, 0]}>
        <Col xs={24} xl={24} sm={24}>
          <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title="Add new Trip destination"
          >
            <div
              style={{
                display: "flex",
                padding: "2em 2em 2em 2em",
                width: "100%",
              }}
            >
              <Content className="p-0">
                <Form
                  name="basic"
                  initialValues={{ remember: true }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  layout="vertical"
                  className="row-col"
                >
                  <Row>
                    <Col
                      xs={24}
                      sm={16}
                      xl={12}
                      style={{
                        padding: "1em",
                      }}
                    >
                      <Form.Item
                        className="name"
                        label="Cancellation Policy (In days)"
                        name="name"
                        rules={[
                          {
                            required: true,
                            message: "Please select the cancellation policy!",
                          },
                        ]}
                      >
                        <Select
                          size="medium"
                          style={{ width: "80%" }}
                          showSearch
                          placeholder="Choose..."
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          <Option value="1">Before 1 Day</Option>
                          <Option value="2">Before 2 Day</Option>
                          <Option value="3">Before 3 Day</Option>
                          <Option value="4">Before 4 Day</Option>
                          <Option value="5">Before 5 Day</Option>
                          <Option value="6">Before 6 Day</Option>
                          <Option value="7">Before 7 Day</Option>
                          <Option value="8">Before 8 Day</Option>
                          <Option value="9">Before 9 Day</Option>
                          <Option value="10">Before 10 Day</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col
                      xs={24}
                      sm={16}
                      xl={12}
                      style={{
                        padding: "1em",
                      }}
                    >
                      <Form.Item
                        className="chargeFee"
                        label="Cancellation Charge (%)"
                        name="chargeFee"
                        rules={[
                          {
                            required: true,
                            message: "Please input the cancellation charge!",
                          },
                        ]}
                      >
                        <InputNumber
                          min={0}
                          defaultValue={0}
                          style={{ width: "80%" }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Form.Item>
                    <div
                      style={{
                        paddingTop: 30,
                        width: "100%",
                        display: "flex",
                        paddingLeft: "18px",
                        // justifyContent: "center",
                      }}
                    >
                      <Button type="primary" htmlType="submit">
                        {createCancellationLoading ? (
                          <Spin indicator={antIcon} />
                        ) : (
                          "Save"
                        )}
                      </Button>
                    </div>
                  </Form.Item>
                </Form>
              </Content>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default NewPolicy;
