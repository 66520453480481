export const CancellationPolicyActionTypes = {
  CANCELLATION_PENDING: "CANCELLATION_PENDING",
  CANCELLATION_SUCCESS: "CANCELLATION_SUCCESS",
  CANCELLATION_FAIL: "CANCELLATION_FAIL",
  CLEAR_CANCELLATION: "CLEAR_CANCELLATION",

  CANCELLATIONS_PENDING: "CANCELLATIONS_PENDING",
  CANCELLATIONS_SUCCESS: "CANCELLATIONS_SUCCESS",
  CANCELLATIONS_FAIL: "CANCELLATIONS_FAIL",
  CLEAR_CANCELLATIONS: "CLEAR_CANCELLATIONS",

  CREATE_CANCELLATION_PENDING: "CREATE_CANCELLATION_PENDING",
  CREATE_CANCELLATION_SUCCESS: "CREATE_CANCELLATION_SUCCESS",
  CREATE_CANCELLATION_FAIL: "CREATE_CANCELLATION_FAIL",
  CLEAR_CREATE_CANCELLATION: "CLEAR_CREATE_CANCELLATION",

  UPDATE_CANCELLATION_PENDING: "UPDATE_CANCELLATION_PENDING",
  UPDATE_CANCELLATION_SUCCESS: "UPDATE_CANCELLATION_SUCCESS",
  UPDATE_CANCELLATION_FAIL: "UPDATE_CANCELLATION_FAIL",
  CLEAR_UPDATE_CANCELLATION: "CLEAR_UPDATE_CANCELLATION",

  DELETE_CANCELLATION_PENDING: "DELETE_CANCELLATION_PENDING",
  DELETE_CANCELLATION_SUCCESS: "DELETE_CANCELLATION_SUCCESS",
  DELETE_CANCELLATION_FAIL: "DELETE_CANCELLATION_FAIL",
  CLEAR_DELETE_CANCELLATION: "CLEAR_DELETE_CANCELLATION",
};
