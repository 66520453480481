import moment from "moment";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Layout,
  Row,
  Col,
  Button,
  Card,
  Form,
  DatePicker,
  Select,
  Spin,
} from "antd";
import { createTrip } from "../../redux/trip/action";
import { useHistory } from "react-router-dom";
import { getRoutes, getRoutesByStatus } from "../../redux/route/action";
import { LoadingOutlined } from "@ant-design/icons";
import { getActiveBuses } from "../../redux/bus/action";

const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "white" }} spin />
);
const { Content } = Layout;

const { Option } = Select;

function disabledDate(current) {
  // Can not select days before today and today
  return current && current < moment().endOf("day");
}

function NewTrip() {
  const history = useHistory();
  const dispatch = useDispatch();
  let dateData;
  const onChange = (date, dateString) => {
    dateData = dateString.split(" ");
    dateData = dateData[0];
  };
  const onFinish = (values) => {
    // const data = dateData[0];
    const { bus, route } = values;
    // const formData = { bus, route, dateData };
    dispatch(createTrip(bus, route, dateData, history));
  };

  const onFinishFailed = (errorInfo) => {};

  function handleChange(value) {}

  useEffect(() => {
    dispatch(getRoutesByStatus("ACTIVE"));
    dispatch(getActiveBuses);
  }, [dispatch]);

  const { createTripLoading } = useSelector((state) => state.trip);
  const { routes, routesStatus } = useSelector((state) => state.route);
  const { buses } = useSelector((state) => state.bus);
  const routesData = [];
  const busesData = [];
  let count = 0;
  routesStatus.forEach((route) =>
    routesData.push({
      key: count++,
      value: route.id,
      routeName: `${route.from} - ${route.to}`,
    })
  );

  buses.forEach((bus) =>
    busesData.push({
      key: count++,
      value: bus.id,
      status: bus.status,
      plateNumber: bus.plateNumber,
    })
  );
  buses.filter((res) => res.status !== "INACTIVE");

  return (
    <div className="tabled">
      <Row gutter={[24, 0]}>
        <Col xs={24} xl={24}>
          <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title="Add new Trip destination"
          >
            <div
              style={{
                display: "flex",
                padding: "2em 2em 2em 2em",
                width: "100%",
              }}
            >
              <Content className="p-0">
                <Form
                  name="basic"
                  initialValues={{ remember: true }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  layout="vertical"
                  className="row-col"
                >
                  <Row>
                    <Col
                      xs={24}
                      sm={16}
                      xl={8}
                      style={{
                        padding: "1em",
                      }}
                    >
                      <Form.Item
                        className="arriveTime"
                        label="Date"
                        name="arriveTime"
                        rules={[
                          {
                            required: true,
                            message: "Please input the initial time!",
                          },
                        ]}
                      >
                        <DatePicker
                          format="YYYY-MM-DD"
                          disabledDate={disabledDate}
                          onChange={onChange}
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      xs={24}
                      sm={16}
                      xl={8}
                      style={{
                        padding: "1em",
                      }}
                    >
                      <Form.Item
                        className="route"
                        label="Route"
                        name="route"
                        rules={[
                          {
                            required: true,
                            message: "Please input the starting destination!",
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          placeholder="Select route"
                          optionFilterProp="children"
                          onChange={handleChange}
                          //   onChange={onChange}
                          //   onSearch={onSearch}
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {routesData.map((option) => (
                            <Option value={option.value}>
                              {option.routeName}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col
                      xs={24}
                      sm={16}
                      xl={8}
                      style={{
                        padding: "1em",
                      }}
                    >
                      <Form.Item
                        className="bus"
                        label="Select Bus"
                        name="bus"
                        rules={[
                          {
                            required: true,
                            message: "Please input the bus plate!",
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          placeholder="Select Bus"
                          optionFilterProp="children"
                          onChange={handleChange}
                          placement="topLeft"
                          dropdownMatchSelectWidth={false}
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {busesData.map((option) => (
                            <Option value={option.value}>
                              {option.plateNumber}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Form.Item>
                    <div
                      style={{
                        paddingTop: 30,
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        type="primary"
                        htmlType="submit"
                        style={{
                          width: "20%",
                          // display: "flex",
                          // justifyContent: "center",
                        }}
                      >
                        {createTripLoading ? (
                          <Spin indicator={antIcon} />
                        ) : (
                          "Register"
                        )}
                      </Button>
                    </div>
                  </Form.Item>
                </Form>
              </Content>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default NewTrip;
