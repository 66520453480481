import { RefundTypes } from "./types";

const INITIAL_STATE = {
  error: null,
  refund: {},
  refunds: [],

  refundLoading: false,
  refundSuccess: false,
  refundFail: false,

  refundsLoading: false,
  refundsSuccess: false,
  refundsFail: false,

  createRefundLoading: false,
  createRefundSuccess: false,
  createRefundSuccessfully: false,
  createRefundSuccessFailed: false,
  createRefundFail: false,

  acceptRefundLoading: false,
  acceptRefundSuccess: false,
  acceptRefundFail: false,

  rejectRefundLoading: false,
  rejectRefundSuccess: false,
  rejectRefundFail: false,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RefundTypes.REFUND_PENDING:
      return {
        ...state,
        refundLoading: true,
      };
    case RefundTypes.REFUND_SUCCESS:
      return {
        ...state,
        refundSuccess: true,
        refundLoading: false,
        refund: {
          ...state.refund,
          [action.payload.id]: action.payload.refund,
        },
      };
    case RefundTypes.REFUND_FAIL:
      return {
        ...state,
        refundLoading: false,
        refundFail: true,
        refundSuccess: false,
        error: action.payload.error,
      };
    // case RefundTypes.CLEAR_REFUND:
    //   return {
    //     ...state,
    //     refundLoading: false,
    //     refundSuccess: false,
    //     refundFail: false,
    //   };

    case RefundTypes.REFUNDS_PENDING:
      return {
        ...state,
        refundsLoading: true,
      };
    case RefundTypes.REFUNDS_SUCCESS:
      return {
        ...state,
        refundsSuccess: true,
        refundsLoading: false,
        refunds: action.payload.refunds,
      };
    case RefundTypes.REFUNDS_FAIL:
      return {
        ...state,
        refundsLoading: false,
        refundsFail: true,
        refundsSuccess: false,
        error: action.payload.error,
      };
    // case RefundTypes.CLEAR_REFUNDS:
    //   return {
    //     ...state,
    //     refundsSuccess: false,
    //     refundsLoading: false,
    //     refundsFail: false,
    //   };

    case RefundTypes.CREATE_REFUND_PENDING:
      return {
        ...state,
        createRefundLoading: true,
      };
    case RefundTypes.CREATE_REFUND_SUCCESS:
      return {
        ...state,
        createRefundSuccess: true,
        createRefundLoading: false,
        // createRefundSuccessFailed: false,
        refunds: [action.payload.refund, ...state.refunds],
      };
    case RefundTypes.CREATE_REFUND_SUCCESS_FAILED:
      return {
        ...state,
        createRefundSuccessFailed: true,
        // refunds: [action.payload.refund, ...state.refunds],
      };
    case RefundTypes.CREATE_REFUND_FAIL:
      return {
        ...state,
        createRefundLoading: false,
        createRefundFail: true,
        createRefundSuccess: false,
        error: action.payload.error,
      };
    // case RefundTypes.CLEAR_CREATE_REFUND:
    //   return {
    //     ...state,
    //     createRefundSuccess: false,
    //     createRefundLoading: false,
    //     createRefundFail: false,
    //   };

    case RefundTypes.REFUND_ACCEPT_PENDING:
      return {
        ...state,
        acceptRefundLoading: true,
      };
    case RefundTypes.REFUND_ACCEPT_SUCCESS:
      return {
        ...state,
        acceptRefundSuccess: true,
        acceptRefundLoading: false,
        refund: {
          ...state.refund,
          [action.payload.id]: action.payload.refund,
        },
      };
    case RefundTypes.REFUND_ACCEPT_FAIL:
      return {
        ...state,
        acceptRefundLoading: false,
        acceptRefundFail: true,
        acceptRefundSuccess: false,
        error: action.payload.error,
      };
    // case RefundTypes.CLEAR_REFUND_ACCEPT:
    //   return {
    //     ...state,
    //     acceptRefundSuccess: false,
    //     acceptRefundLoading: false,
    //     acceptRefundFail: false,
    //   };

    case RefundTypes.REFUND_REJECT_PENDING:
      return {
        ...state,
        rejectRefundLoading: true,
      };
    case RefundTypes.REFUND_REJECT_SUCCESS:
      return {
        ...state,
        rejectRefundSuccess: true,
        rejectRefundLoading: false,
        refund: {
          ...state.refund,
          [action.payload.id]: action.payload.refund,
        },
      };
    case RefundTypes.REFUND_REJECT_FAIL:
      return {
        ...state,
        rejectRefundLoading: false,
        rejectRefundSuccess: false,
        rejectRefundFail: true,
        error: action.payload.error,
      };
    // case RefundTypes.REFUND_REJECT_CLEAR:
    //   return {
    //     ...state,
    //     rejectRefundSuccess: false,
    //     rejectRefundLoading: false,
    //     rejectRefundFail: false,
    //   };

    default:
      return state;
  }
};

export default reducer;
