import React, { useEffect } from "react";
import { Button, Form, Input, Spin, Typography, Row, Col } from "antd";
import PublicBusLogo from "../../assets/images/PublicBusLogo.png";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";
import { loginAsync, clearLoginSuccess } from "../../redux/auth/action";

const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "white" }} spin />
);

function SignIn() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { Title } = Typography;

  const { loginLoading, loginError } = useSelector((state) => state.auth);

  const handleSubmit = (values) => {
    const { phone, password } = values;
    dispatch(loginAsync(phone, password, history));
  };

  useEffect(() => {
    if (loginError) {
      dispatch(clearLoginSuccess());
    }
  }, [loginError, dispatch]);

  const onFinishFailed = (errorInfo) => {};

  return (
    <>
      {loginError && loginError.message ? "" : null}
      <div className="overlay-sign-up">
        <div className="sign-up-2" style={{}}>
          <Row
            style={{
              display: "flex",
              width: "80%",
              justifyContent: "center",
              paddingBottom: "5rem",
            }}
          >
            <Col
              xs={24}
              xl={12}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                // justifyContent: "flex-end",
              }}
            >
              <Title style={{ color: "#fff" }}>Sign In</Title>
              <Title level={4} style={{ color: "#fff" }}>
                To Public Bus Account
              </Title>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-around",
                  height: "22rem",
                }}
              >
                <Form
                  name="basic"
                  onFinishFailed={onFinishFailed}
                  className="row-col"
                  initialValues={{}}
                  onFinish={handleSubmit}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-around",
                    height: "20rem",
                  }}
                >
                  <Form.Item
                    name="phone"
                    rules={[
                      { required: true, message: "Please input your phone!" },
                    ]}
                  >
                    <Input
                      placeholder="Phone Number"
                      className="sign-up-fields"
                    />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                    ]}
                  >
                    <Input
                      type="password"
                      placeholder="Password"
                      className="sign-up-fields"
                      size="small"
                    />
                  </Form.Item>
                  {loginError ? (
                    <div
                      style={{
                        width: "100%",
                        textAlign: "center",
                        color: "red",
                      }}
                    >
                      Phone Number or Password not correct
                    </div>
                  ) : (
                    ""
                  )}

                  <Form.Item>
                    <Button
                      style={{ width: "100%", fontSize: "1.2rem" }}
                      type="primary"
                      htmlType="submit"
                    >
                      {loginLoading ? <Spin indicator={antIcon} /> : "SIGN IN"}
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </Col>
            <Col
              xs={24}
              xl={12}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                // justifyContent: "flex-end",
              }}
            >
              <img src={PublicBusLogo} className="iconDetails mb-5" alt="" />

              {/* <Title level={4} style={{ color: "#fff" }}>
              To Public Bus Account
            </Title> */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-around",
                  height: "10rem",
                  alignItems: "center",
                }}
              >
                <Title level={2} style={{ color: "#fff" }}>
                  Public Bus
                </Title>
                <Title level={4} style={{ fontSize: "1.1rem", color: "white" }}>
                  From now you can access favorite apps like Bus configuration,
                  Managing employees, agents and accounting from a single log
                  in. We have a dedicated and expert support team for you, We
                  communicate and help you with Livechat, Skype, Whatsapp and
                  ticket system in real-time 24/7. Call us 9439
                </Title>
              </div>
            </Col>
          </Row>
          <div xs={24} xl={24}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: "",
              }}
            >
              <Title style={{ color: "white", fontSize: "1.2rem" }}>
                Copyright © 2022 Engida Travel Technology
              </Title>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignIn;
