import { useState, useEffect } from "react";
import Highlighter from "react-highlight-words";
import {
  Card,
  Col,
  Row,
  Typography,
  Button,
  Timeline,
  Table,
  Input,
  Space,
  Modal,
  Form,
  Layout,
  Select,
} from "antd";
import {
  MenuUnfoldOutlined,
  RightOutlined,
  SearchOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Paragraph from "antd/lib/typography/Paragraph";
import Echart from "../../components/chart/EChart";
import Echart3 from "../../components/chart/EChart3";
import DemoPie from "../../components/chart/DemoPie";
import DemoPie2 from "../../components/chart/DemoPie2";
import { getRoutesDashboard } from "../../redux/route/action";
import { getBookings } from "../../redux/booking/action";
import { getTripsDashboard } from "../../redux/trip/action";
import {
  getDashboardReport,
  getDashboardCategoryReport,
  clearReports,
} from "../../redux/report/action";

const { Option } = Select;
const { Content } = Layout;

function Home() {
  const dispatch = useDispatch();
  const { Title, Text } = Typography;
  const [isEditModalVisible, setEditModalVisible] = useState(false);
  const [searchText, setSearchText] = useState(false);
  const [searchedColumn, setSearchedColumn] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [filteredInfo, setFilteredInfo] = useState(null);
  const [sortedInfo, setSortedInfo] = useState(null);
  const { bookings, bookingsLoading } = useSelector((state) => state.booking);

  const { trips, tripsSuccess } = useSelector((state) => state.trip);
  const { reports, reportDashboardReportSuccess } = useSelector(
    (state) => state.report
  );

  useEffect(() => {
    dispatch(getRoutesDashboard);
    // dispatch(getBookings);
    dispatch(getDashboardReport);
    dispatch(getDashboardCategoryReport);
    dispatch(getTripsDashboard);

    return () => {
      dispatch(clearReports);
    };
  }, [dispatch]);

  let getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            setSearchInput(node);
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>

          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            ?.toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => setSearchInput(""), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text?.toString() : ""}
        />
      ) : (
        text
      ),
  });

  let handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleEditModalCancel = () => {
    setEditModalVisible(false);
  };

  const showEditModal = () => {
    setEditModalVisible(true);
  };

  let handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
    setFilteredInfo(null);
    setSortedInfo(null);
  };

  // Form handle
  const onFinish = (values) => {};

  const onFinishFailed = (errorInfo) => {};

  // Columns data
  const columns = [
    {
      title: "Booked By",
      dataIndex: "bookedBy",
      key: "bookedBy",
      // sortOrder: "ascend",
      sorter: (a, b) => a.bookedBy - b.bookedBy,
      ...getColumnSearchProps("firstName"),
    },

    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      ...getColumnSearchProps("phone"),
    },
    {
      title: "Ref. No",
      dataIndex: "refNumber",
      key: "refNumber",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    // {
    //   title: "Action",
    //   key: "action",
    //   render: (text, record) => (
    //     <Space size="middle">
    //       <Button
    //         style={{ background: "#eba134", borderColor: "#eba134" }}
    //         type="primary"
    //         shape="round"
    //         icon={<EditOutlined />}
    //         size="small"
    //         onClick={showEditModal}
    //       />
    //     </Space>
    //   ),
    // },
  ];

  const count = [
    {
      today: "Today",
      salePercentage: reportDashboardReportSuccess
        ? `${reports.todayPercent}%`
        : "Loading...",
      sales: "Total Sales",
      salesNo: reportDashboardReportSuccess
        ? `${reports.todayTotal}`
        : "Loading...",
      title: "$53,000",
      persent: "+30%",
      bnb: "bnb2",
      backgroundColor: "#418EF2",
      color: "#fff",
      colorSilent: "#e3e6e8",
      detail: "report-today",
    },
    {
      today: "This Week",
      sales: "Total Sales",
      salePercentage: reportDashboardReportSuccess
        ? `${reports.weekPercent}%`
        : "Loading...",
      salesNo: reportDashboardReportSuccess
        ? `${reports.weekTotal}`
        : "Loading...",
      title: "3,200",
      persent: "+20%",
      bnb: "bnb2",
      backgroundColor: "#77C3F2",
      color: "#fff",
      colorSilent: "#e3e6e8",
      detail: "report-week",
    },
    {
      today: "This Month",
      sales: "Total Sales",
      salePercentage: reportDashboardReportSuccess
        ? `${reports.monthPercent}%`
        : "Loading...",
      salesNo: reportDashboardReportSuccess
        ? `${reports.monthTotal}`
        : "Loading...",
      title: "+1,200",
      persent: "-20%",
      bnb: "redtext",
      backgroundColor: "#49B1F2",
      color: "#fff",
      colorSilent: "#e3e6e8",
      detail: "report-month",
    },
    {
      today: "This Year",
      sales: "Total Sales",
      salePercentage: reportDashboardReportSuccess
        ? `${reports.yearPercent}%`
        : "Loading...",
      salesNo: reportDashboardReportSuccess
        ? `${reports.yearTotal}`
        : "Loading...",
      title: "$13,200",
      persent: "10%",
      bnb: "bnb2",
      backgroundColor: "#3D9DF2",
      color: "#fff",
      colorSilent: "#e3e6e8",
      detail: "report-year",
    },
  ];

  let timelineList = [];

  if (tripsSuccess && trips.length >= 7) {
    timelineList = [
      {
        title: tripsSuccess ? trips[trips.length - 1].destination : "",
        time: tripsSuccess ? trips[trips.length - 1].date : "",
        color: "green",
      },
      {
        title: tripsSuccess ? trips[trips.length - 2].destination : "",
        time: tripsSuccess ? trips[trips.length - 2].date : "",
        color: "green",
      },
      {
        title: tripsSuccess ? trips[trips.length - 3].destination : "",
        time: tripsSuccess ? trips[trips.length - 3].date : "",
      },
      {
        title: tripsSuccess ? trips[trips.length - 4].destination : "",
        time: tripsSuccess ? trips[trips.length - 4].date : "",
      },
      {
        title: tripsSuccess ? trips[trips.length - 4].destination : "",
        time: tripsSuccess ? trips[trips.length - 4].date : "",
      },
      {
        title: tripsSuccess ? trips[trips.length - 5].destination : "",
        time: tripsSuccess ? trips[trips.length - 5].date : "",
        color: "gray",
      },
      {
        title: tripsSuccess ? trips[trips.length - 6].destination : "",
        time: tripsSuccess ? trips[trips.length - 6].date : "",
        color: "black",
      },
      {
        title: tripsSuccess ? trips[trips.length - 7].destination : "",
        time: tripsSuccess ? trips[trips.length - 7].date : "",
        color: "black",
      },
      {
        title: "...",
        time: "",
        color: "black",
      },
    ];
  } else {
    trips.forEach((res) => {
      timelineList.push({
        title: tripsSuccess ? res.destination : "",
        time: tripsSuccess ? res.date : "",
        color: "green",
      });
    });
  }

  return (
    <>
      <div className="layout-content">
        <Row className="rowgap-vbox" gutter={[24, 0]}>
          {count.map((c, index) => (
            <Col key={index} xs={24} sm={24} md={12} xl={6} className="mb-24">
              <Link to={`${c.detail}`}>
                <Card
                  bordered={false}
                  className="criclebox"
                  style={{ backgroundColor: c.backgroundColor }}
                >
                  <div
                    className="number"
                    style={{
                      width: "100%",
                      height: "8rem",
                      display: "flex",
                    }}
                  >
                    <Row
                      align="middle"
                      gutter={[24, 0]}
                      style={{
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Col xs={11} offset={2}>
                        <Title
                          level={4}
                          style={{ fontWeight: "700", color: c.color }}
                        >
                          {c.salePercentage}
                          {/* <small className={c.bnb}>{c.persent}</small> */}
                        </Title>
                        <span
                          style={{ color: c.colorSilent, fontSize: "1rem" }}
                        >
                          {c.today}
                        </span>
                      </Col>
                      <Col xs={11}>
                        <Title
                          level={4}
                          style={{ fontWeight: "700", color: c.color }}
                        >
                          {c.salesNo}
                          {/* <small className={c.bnb}>{c.persent}</small> */}
                        </Title>
                        <span
                          style={{ color: c.colorSilent, fontSize: "1rem" }}
                        >
                          {c.sales}
                        </span>
                      </Col>
                    </Row>
                  </div>
                </Card>
              </Link>
            </Col>
          ))}
        </Row>

        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={18} className="mb-24">
            <Card bordered={false} className="criclebox h-fulll">
              <Echart />
            </Card>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={6} className="mb-24">
            <Card bordered={false} className="criclebox h-fulll">
              <Echart3 />
            </Card>
          </Col>
        </Row>

        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} className="mb-24">
            <Card bordered={false} className="criclebox cardbody h-fulll">
              <div className="ant-list-box table-responsive">
                <DemoPie />
              </div>
            </Card>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} className="mb-24">
            <Card bordered={false} className="criclebox cardbody h-fulll">
              <div className="ant-list-box table-responsive">
                <DemoPie2 />
              </div>
            </Card>
          </Col>
        </Row>

        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={16} className="mb-24">
            <Card bordered={false} className="criclebox cardbody h-fulll">
              <div className="ant-list-box table-responsive">
                <Table
                  style={{ width: "100%" }}
                  dataSource={bookings}
                  columns={columns}
                  loading={bookingsLoading}
                  scroll={{ x: 400 }}
                  sortDirections={["descend", "ascend"]}
                />
              </div>
            </Card>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={8} className="mb-24">
            <Card bordered={false} className="criclebox h-fulll">
              <div className="timeline-box">
                <Title level={5} style={{ marginBottom: 24 }}>
                  Active Trips
                </Title>
                <Timeline className="timelinelist">
                  {timelineList.map((t, index) => (
                    <Timeline.Item color={t.color} key={index}>
                      <Title level={5}>{t.title}</Title>
                      <Text>{t.time}</Text>
                    </Timeline.Item>
                  ))}
                </Timeline>
                <Button type="primary" className="width-100">
                  <Link to="trips">{<MenuUnfoldOutlined />} Show More</Link>
                </Button>
              </div>
            </Card>
          </Col>
        </Row>

        <Row gutter={[24, 0]}>
          <Col xs={24} md={12} sm={24} lg={12} xl={14} className="mb-24">
            <Card bordered={false} className="criclebox h-fulll">
              <Row gutter>
                <Col
                  xs={24}
                  md={12}
                  sm={24}
                  lg={12}
                  xl={14}
                  className="mobile-24"
                >
                  <div className="h-fulll col-content p-20">
                    <div className="ant-muse">
                      <Text></Text>
                      <Title level={5}>Public Bus</Title>
                      <Paragraph className="lastweek mb-36">
                        We provide best and quality service.
                      </Paragraph>
                    </div>
                    <div className="card-footer">
                      <a className="icon-move-right" href="#pablo">
                        Read More User Manual
                        {<RightOutlined />}
                      </a>
                    </div>
                  </div>
                </Col>
                <Col
                  xs={24}
                  md={12}
                  sm={24}
                  lg={12}
                  xl={10}
                  className="col-img"
                >
                  <div className="ant-cret text-right">
                    <img
                      src={
                        "https://media.istockphoto.com/photos/white-coach-buses-in-a-row-picture-id1127400268?b=1&k=20&m=1127400268&s=170667a&w=0&h=hFEuDFA6IZRbKzuviK0CkGp-DN6DaAqUGALOhYiXIBs="
                      }
                      alt=""
                      className="border10"
                    />
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>

          <Col xs={24} md={12} sm={24} lg={12} xl={10} className="mb-24">
            <Card bordered={false} className="criclebox card-info-2 h-fulll">
              <div className="gradent h-fulll col-content">
                <div className="card-content">
                  <Title level={5}>Our Company Policy</Title>
                  <p></p>
                </div>
                <div className="card-footer">
                  <a className="icon-move-right" href="#pablo">
                    Read More About Company Policy
                    <RightOutlined />
                  </a>
                </div>
              </div>
            </Card>
          </Col>
        </Row>
      </div>

      <Modal
        title="Edit"
        visible={isEditModalVisible}
        onCancel={handleEditModalCancel}
        footer={[
          <Button key="back" onClick={handleEditModalCancel}>
            cancel
          </Button>,
          <Button key="submit" type="primary">
            Update
          </Button>,
        ]}
        width={1000}
      >
        <Content className="p-0">
          <Form
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
            className="row-col"
          >
            <Row>
              <Col
                xs={8}
                xl={8}
                style={{
                  padding: "1em",
                }}
              >
                <Form.Item
                  className="firstName"
                  label="First Name"
                  name="firstName"
                  rules={[
                    {
                      required: true,
                      message: "Please input your first name!",
                    },
                  ]}
                >
                  <Input placeholder="First Name..." />
                </Form.Item>
              </Col>
              <Col
                xs={8}
                xl={8}
                style={{
                  padding: "1em",
                }}
              >
                <Form.Item
                  className="lastName"
                  label="Last Name"
                  name="lastName"
                  rules={[
                    {
                      required: true,
                      message: "Please input your last name!",
                    },
                  ]}
                >
                  <Input placeholder="Last name..." />
                </Form.Item>
              </Col>
              <Col
                xs={8}
                xl={8}
                style={{
                  padding: "1em",
                }}
              >
                <Form.Item
                  className="phone"
                  label="Phone"
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: "Please input your phone!",
                    },
                  ]}
                >
                  <Input placeholder="Phone..." />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col
                xs={8}
                xl={8}
                style={{
                  padding: "1em",
                }}
              >
                <Form.Item className="role" label="Status" name="status">
                  <Select
                    defaultValue=""
                    style={{ width: "100%" }}
                    size="medium"
                  >
                    <Option value="booked">BOOKED</Option>
                    <Option value="active">ACTIVE</Option>
                    <Option value="canceled">CANCELED</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Content>
      </Modal>
    </>
  );
}

export default Home;
