import {
  Layout,
  Row,
  Col,
  Button,
  Card,
  Form,
  Input,
  notification,
  Spin,
  Select,
} from "antd";
import React, { useState, useEffect } from "react";
import { getBuses } from "../../redux/bus/action";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { createConductor } from "../../redux/conductor/action";
import { LoadingOutlined } from "@ant-design/icons";

const { Option } = Select;
const { Content } = Layout;
const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "white" }} spin />
);

function NewConductor() {
  const [form] = Form.useForm();
  const [formData, setFormData] = useState({});
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getBuses);
  }, [dispatch]);

  const { buses, bus } = useSelector((state) => state.bus);
  let count = 0;
  const busesData = [];

  buses.forEach((bus) =>
    busesData.push({
      key: count++,
      value: bus.id,
      plateNumber: bus.plateNumber,
    })
  );

  const { createConductorLoading, error } = useSelector(
    (state) => state.conductor
  );

  const onSubmit = (values) => {
    setFormData(values);
    dispatch(createConductor(values, history));

    setFormData({});
  };

  const onFinishFailed = (errorInfo) => {};

  function handleChange(value) {}

  return (
    <>
      {error && error.message
        ? notification.error({
            message: "Error",
            placement: "top",
            description: `${error.message}`,
          })
        : null}
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs={24} xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="Register New Conductor"
            >
              <div
                style={{
                  display: "flex",
                  padding: "2em 2em 2em 2em",
                  width: "100%",
                }}
              >
                <Content className="p-0">
                  <Form
                    name="basic"
                    initialValues={{ remember: true }}
                    onFinish={onSubmit}
                    form={form}
                    onFinishFailed={onFinishFailed}
                    layout="vertical"
                    className="row-col"
                  >
                    <Row>
                      <Col
                        xs={8}
                        xl={8}
                        style={{
                          padding: "1em",
                        }}
                      >
                        <Form.Item
                          className="firstName"
                          label="First Name"
                          name="firstName"
                          rules={[
                            {
                              required: true,
                              message: "Please input your first name!",
                            },
                          ]}
                        >
                          <Input placeholder="First Name..." />
                        </Form.Item>
                      </Col>

                      <Col
                        xs={8}
                        xl={8}
                        style={{
                          padding: "1em",
                        }}
                      >
                        <Form.Item
                          className="lastName"
                          label="Last Name"
                          name="lastName"
                          rules={[
                            {
                              required: true,
                              message: "Please input your last name!",
                            },
                          ]}
                        >
                          <Input placeholder="Last name..." />
                        </Form.Item>
                      </Col>

                      <Col
                        xs={8}
                        xl={8}
                        style={{
                          padding: "1em",
                        }}
                      >
                        <Form.Item
                          className="phoneNo"
                          label="Phone Number"
                          name="phoneNo"
                          rules={[
                            {
                              required: true,
                              message: "Please input your Phone Number!",
                            },
                          ]}
                        >
                          <Input placeholder="Phone Number..." />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row>
                      <Col
                        xs={8}
                        xl={8}
                        style={{
                          padding: "1em",
                        }}
                      >
                        <Form.Item
                          className="position"
                          label="Position"
                          name="position"
                          rules={[
                            {
                              required: true,
                              message: "Please input the position of employee!",
                            },
                          ]}
                        >
                          <Select placeholder="select position..." size="large">                            
                            <Option value="inactive">Assistant</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col
                        xs={8}
                        xl={8}
                        style={{
                          padding: "1em",
                        }}
                      >
                        <Form.Item
                          className="bus"
                          label="Select Bus"
                          name="bus"
                          rules={[
                            {
                              required: true,
                              message: "Please input the bus plate!",
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            placeholder="Select Bus"
                            optionFilterProp="children"
                            size="large"
                            onChange={handleChange}
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {busesData.map((option) => (
                              <Option value={option.value}>
                                {option.plateNumber}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col
                        xs={8}
                        xl={8}
                        style={{
                          padding: "1em",
                        }}
                      >
                        <Form.Item
                          className="email"
                          label="Email"
                          name="email"
                          rules={[
                            {
                              required: true,
                              message: "Please input your email!",
                            },
                          ]}
                        >
                          <Input placeholder="Email..." />
                        </Form.Item>
                      </Col>
                      <Col
                        xs={8}
                        xl={8}
                        style={{
                          padding: "1em",
                        }}
                      >
                        <Form.Item
                          className="address"
                          label="Address"
                          name="address"
                          rules={[
                            {
                              required: true,
                              message: "Please input your address!",
                            },
                          ]}
                        >
                          <Input placeholder="Address..." />
                        </Form.Item>
                      </Col>
                      {/* <Col
                        xs="8"
                        xl={8}
                        style={{
                          padding: "1em",
                        }}
                      >
                        <Form.Item
                          className="password"
                          label="Password"
                          name="password"
                          rules={[
                            {
                              required: true,
                              message: "Please input Password!",
                            },
                          ]}
                        >
                          <Input.Password placeholder="Password..." />
                        </Form.Item>
                      </Col> */}
                    </Row>

                    <Form.Item>
                      <div
                        style={{
                          paddingTop: 30,
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Button
                          type="primary"
                          htmlType="submit"
                          style={{
                            width: "20%",
                          }}
                        >
                          {createConductorLoading ? (
                            <Spin indicator={antIcon} />
                          ) : (
                            "Register"
                          )}
                          {/* Register */}
                        </Button>
                      </div>
                    </Form.Item>
                  </Form>
                </Content>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default NewConductor;
