import { TermsAndConditionsActionTypes } from "./types";

const InitialState = {
  error: null,
  term_condition: {},
  term_conditions: [],

  termLoading: false,
  termSuccess: false,
  termFail: false,

  termsLoading: false,
  termsSuccess: false,
  termsFail: false,

  createTermLoading: false,
  createTermSuccess: false,
  createTermFail: false,

  updateTermLoading: false,
  updateTermSuccess: false,
  updateTermFail: false,

  deleteTermLoading: false,
  deleteTermSuccess: false,
  deleteTermFail: false,
};

const reducer = (state = InitialState, action) => {
  switch (action.type) {
    case TermsAndConditionsActionTypes.TERMS_CONDITION_PENDING:
      return {
        ...state,
        termLoading: true,
      };
    case TermsAndConditionsActionTypes.TERMS_CONDITION_FAIL:
      return {
        ...state,
        termLoading: false,
        termSuccess: false,
        error: action.payload.error,
      };
    case TermsAndConditionsActionTypes.TERMS_CONDITION_SUCCESS:
      return {
        ...state,
        termLoading: false,
        termSuccess: true,
        term_condition: {
          ...state.term_condition,
          [action.payload.id]: action.payload.term_condition,
        },
      };
    case TermsAndConditionsActionTypes.CLEAR_TERMS_CONDITION:
      return {
        ...state,
        termLoading: false,
        termSuccess: false,
        termFail: false,
      };
    case TermsAndConditionsActionTypes.TERMS_CONDITIONS_PENDING:
      return {
        ...state,
        termsLoading: true,
      };
    case TermsAndConditionsActionTypes.TERMS_CONDITIONS_SUCCESS:
      return {
        ...state,
        termsLoading: false,
        termsSuccess: true,
        term_conditions: action.payload.term_conditions,
        desc: action.payload.desc,
      };
    case TermsAndConditionsActionTypes.TERMS_CONDITIONS_FAIL:
      return {
        ...state,
        termsLoading: false,
        termsSuccess: false,
        error: action.payload.error,
      };
    case TermsAndConditionsActionTypes.CLEAR_TERMS_CONDITIONS:
      return {
        ...state,
        termsLoading: false,
        termsSuccess: false,
        termsFail: false,
      };
    case TermsAndConditionsActionTypes.CREATE_TERMS_CONDITION_PENDING:
      return {
        ...state,
        createTermLoading: true,
      };
    case TermsAndConditionsActionTypes.CREATE_TERMS_CONDITION_SUCCESS:
      return {
        ...state,
        createTermLoading: false,
        createTermSuccess: true,
        term_conditions: [
          action.payload.term_condition,
          ...state.term_conditions,
        ],
      };
    case TermsAndConditionsActionTypes.CREATE_TERMS_CONDITION_FAIL:
      return {
        ...state,
        createTermLoading: false,
        createTermSuccess: false,
        error: action.payload.error,
      };
    case TermsAndConditionsActionTypes.CLEAR_CREATE_TERMS_CONDITION:
      return {
        ...state,
        createTermLoading: false,
        createTermSuccess: false,
        createTermFail: false,
      };
    case TermsAndConditionsActionTypes.UPDATE_TERMS_CONDITION_PENDING:
      return {
        ...state,
        updateTermLoading: false,
      };
    case TermsAndConditionsActionTypes.UPDATE_TERMS_CONDITION_SUCCESS:
      return {
        ...state,
        updateTermLoading: false,
        updateTermSuccess: true,
        term_condition: {
          ...state.term_condition,
          [action.payload.id]: action.payload.term_condition,
        },
      };
    case TermsAndConditionsActionTypes.UPDATE_TERMS_CONDITION_FAIL:
      return {
        ...state,
        updateTermLoading: false,
        updateTermSuccess: false,
        error: action.payload.error,
      };
    case TermsAndConditionsActionTypes.CLEAR_UPDATE_TERMS_CONDITION:
      return {
        ...state,
        updateTermLoading: false,
        updateTermSuccess: false,
        updateTermFail: false,
      };
    case TermsAndConditionsActionTypes.DELETE_TERMS_CONDITION_PENDING:
      return {
        ...state,
        deleteTermLoading: false,
      };
    case TermsAndConditionsActionTypes.DELETE_TERMS_CONDITION_SUCCESS:
      return {
        ...state,
        deleteTermLoading: false,
        deleteTermSuccess: true,
        term_condition: {},
      };
    case TermsAndConditionsActionTypes.DELETE_TERMS_CONDITION_FAIL:
      return {
        ...state,
        deleteTermLoading: false,
        deleteTermSuccess: false,
        error: action.payload.error,
      };
    case TermsAndConditionsActionTypes.CLEAR_DELETE_TERMS_CONDITION:
      return {
        ...state,
        deleteTermLoading: false,
        deleteTermSuccess: false,
        deleteTermFail: false,
      };
    default:
      return state;
  }
};

export default reducer;
