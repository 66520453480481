import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../redux/auth/action";
import { Link, useHistory } from "react-router-dom";
import {
  Row,
  Col,
  Button,
  Drawer,
  Typography,
  Popover,
  message,
  Divider,
  Badge,
  Select,
} from "antd";
import { SettingOutlined, UserOutlined, BellOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { setCurrentUser } from "../../redux/auth/action";
import { busAdminTokenKey } from "../../config/key.config";

const { Option } = Select;

const ButtonContainer = styled.div`
  .ant-btn-primary {
    background-color: #1890ff;
  }
  .ant-btn-success {
    background-color: #52c41a;
  }
  .ant-btn-yellow {
    background-color: #fadb14;
  }
  .ant-btn-black {
    background-color: #262626;
    color: #fff;
    border: 0px;
    border-radius: 5px;
  }
  .ant-switch-active {
    background-color: #1890ff;
  }
`;

function Header({
  placement,
  name,
  subName,
  onPress,
  handleSidenavColor,
  handleSidenavType,
  handleFixedNavbar,
}) {
  const { loginSuccess, user, token } = useSelector((state) => state.auth);
  useEffect(() => {
    dispatch(setCurrentUser(localStorage.getItem(busAdminTokenKey)));
    console.log("Here");
  }, []);
  const { Title, Text } = Typography;
  const history = useHistory();

  const [visible, setVisible] = useState(false);
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [sidenavType, setSidenavType] = useState("transparent");

  useEffect(() => window.scrollTo(0, 0));

  const showDrawer = () => setVisible(true);
  const hideDrawer = () => setVisible(false);

  const dispatch = useDispatch();

  const text = <span>Title</span>;
  const content = (
    <div>
      <Link to="profile">
        {/* <ButtonContainer> */}
        <p size="large">View Profile</p>
        {/* </ButtonContainer> */}
      </Link>
      <Divider
        style={{
          padding: "0 !important",
          margin: "0 !important",
        }}
      />
      <p onClick={() => handleLogout()} style={{ cursor: "pointer" }}>
        <p size="large">Log Out</p>
      </p>
    </div>
  );

  const handleLogout = () => {
    console.log("logging out user...");
    message.warning("Logged Out");
    dispatch(logoutUser(history));
  };

  return (
    <>
      <Row gutter={[24, 0]}>
        <Col span={24} md={6}>
          <div className="ant-page-header-heading">
            <span
              className="ant-page-header-heading-title"
              style={{ textTransform: "capitalize" }}
            >
              {subName.replace("/", " ")}
            </span>
          </div>
        </Col>
        <Col span={4} md={18} className="header-control">
          <Button type="link" onClick={showDrawer}>
            {<SettingOutlined />}
          </Button>
          <Drawer
            className="settings-drawer"
            mask={true}
            width={360}
            onClose={hideDrawer}
            placement={placement}
            visible={visible}
          >
            <div layout="vertical">
              <div className="header-top">
                <Title level={4}>
                  Public Bus
                  <Text className="subtitle">{user.firstName}</Text>
                </Title>
              </div>
              <div className="sidebar-color">
                <div className="ant-docment">
                  <Select
                    style={{ width: "100%" }}
                    // showSearch
                    placeholder="Select language"
                    optionFilterProp="children"
                    // onChange={onChange}
                    // onSearch={onSearch}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <Option value="en">English</Option>
                    <Option value="am">Amharic</Option>
                  </Select>
                </div>
              </div>
            </div>
          </Drawer>
          <span style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
            <Popover placement="bottom" content={content} trigger="click">
              {<UserOutlined />}
            </Popover>
          </span>
          <span style={{ paddingLeft: "1rem", cursor: "pointer" }}>
            <Badge count={1}>
              {
                <Link to="reviews">
                  <BellOutlined />
                </Link>
              }
            </Badge>
          </span>
        </Col>
      </Row>
    </>
  );
}

export default Header;
