export const BusActionTypes = {
  BUS_PENDING: "BUS_PENDING",
  BUS_SUCCESS: "BUS_SUCCESS",
  BUS_FAIL: "BUS_FAIL",
  CLEAR_BUS: "CLEAR_BUS",

  BUSES_PENDING: "BUSES_PENDING",
  BUSES_SUCCESS: "BUSES_SUCCESS",
  BUSES_FAIL: "BUSES_FAIL",
  CLEAR_BUSES: "CLEAR_BUSES",

  CREATE_BUS_PENDING: "CREATE_BUS_PENDING",
  CREATE_BUS_SUCCESS: "CREATE_BUS_SUCCESS",
  CREATE_BUS_FAIL: "CREATE_BUS_FAIL",
  CLEAR_CREATE_BUS: "CLEAR_CREATE_BUS",

  UPDATE_BUS_PENDING: "UPDATE_BUS_PENDING",
  UPDATE_BUS_SUCCESS: "UPDATE_BUS_SUCCESS",
  UPDATE_BUS_FAIL: "UPDATE_BUS_FAIL",
  CLEAR_UPDATE_BUS: "CLEAR_UPDATE_BUS",

  DELETE_BUS_PENDING: "DELETE_BUS_PENDING",
  DELETE_BUS_SUCCESS: "DELETE_BUS_SUCCESS",
  DELETE_BUS_FAIL: "DELETE_BUS_FAIL",
  CLEAR_DELETE_BUS: "CLEAR_DELETE_BUS",

};