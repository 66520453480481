export const BusStructureType = {
  BUS_STRUCTURE_PENDING: "BUS_STRUCTURE_PENDING",
  BUS_STRUCTURE_SUCCESS: "BUS_STRUCTURE_SUCCESS",
  BUS_STRUCTURE_FAIL: "BUS_STRUCTURE_ERROR",
  CLEAR_BUS_STRUCTURE_SUCCESS: "CLEAR_BUS_STRUCTURE_SUCCESS",

  BUS_STRUCTURES_PENDING: "BUS_STRUCTURES_PENDING",
  BUS_STRUCTURES_SUCCESS: "BUS_STRUCTURES_SUCCESS",
  BUS_STRUCTURES_FAIL: "BUS_STRUCTURES_FAIL",
  CLEAR_BUS_STRUCTURES_SUCCESS: "CLEAR_BUS_STRUCTURES_SUCCESS",

  // UPDATE_BU_STRUCTURE_STATUS_PEDING: "UPDATE_BU_STRUCTURE_STATUS_PEDING",
  // UPDATE_BU_STRUCTURE_STATUS_SUCCESS: "UPDATE_BU_STRUCTURE_STATUS_SUCCESS",
  // UPDATE_BU_STRUCTURE_STATUS_FAIL: "UPDATE_BU_STRUCTURE_STATUS_FAIL",
  // CLEAR_UPDATE_BUS_STRUCTURE_STATUS_SUCCESS:
  //   "CLEAR_UPDATE_BUS_STATUS_STRUCTURE",

  // CREATE_BUS_STRUCTURE_PENDING: "CREATE_BUS_STRUCTURE_PENDING",
  // CREATE_BUS_STRUCTURE_SUCCESS: "CREATE_BUS_STRUCTURE_SUCCESS",
  // CREATE_BUS_STRUCTURE_FAIL: "CREATE_BUS_STRUCTURE_FAIL",
  // CLEAR_CREATE_BUS_STRUCTURE_SUCCESS: "CLEAR_CREATE_BUS_STRUCTURE_SUCCESS",

  // UPDATE_BU_STRUCTURE_PEDING: "UPDATE_BU_STRUCTURE_PEDING",
  // UPDATE_BU_STRUCTURE_SUCCESS: "UPDATE_BU_STRUCTURE_SUCCESS",
  // UPDATE_BU_STRUCTURE_FAIL: "UPDATE_BU_STRUCTURE_FAIL",
  // CLEAR_UPDATE_BUS_STRUCTURE_SUCCESS: "CLEAR_UPDATE_BUS_STRUCTURE",
};
