import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Row, Col, Card, Avatar } from "antd";
import { busAdminTokenKey } from "../../config/key.config";

import { setCurrentUser } from "../../redux/auth/action";

function Profile() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(setCurrentUser(localStorage.getItem(busAdminTokenKey)));
  }, [dispatch]);

  return (
    <>
      <div className="profile-nav-bg"></div>

      <Card
        className="card-profile-head"
        bodyStyle={{ display: "none" }}
        title={
          <Row justify="space-between" align="middle" gutter={[24, 0]}>
            <Col span={24} md={12} className="col-info">
              <Avatar.Group>
                <Avatar size={74} shape="square" />

                <div className="avatar-info">
                  <h4 className="font-semibold m-0">
                    {user.firstName} {user.lastName}
                  </h4>
                  <p>{user.role}</p>
                </div>
              </Avatar.Group>
            </Col>
          </Row>
        }
      ></Card>
      <Card
        style={{ marginTop: "3rem" }}
        className="card-profile-head"
        bodyStyle={{ display: "none" }}
        title={
          <Row justify="space-between" align="middle" gutter={[24, 0]}>
            <Col span={24} md={24} className="col-info">
              <Avatar.Group>
                <Avatar size={50} shape="square" />

                <div className="avatar-info">
                  <p className="m-0">Phone Number - {user.phoneNo}</p>
                </div>
              </Avatar.Group>
            </Col>
            <Col span={24} md={24} className="col-info">
              <Avatar.Group>
                <Avatar size={50} shape="square" />

                <div className="avatar-info">
                  <p className="font-semibold m-0">Email - {user.email}</p>
                </div>
              </Avatar.Group>
            </Col>
            <Col span={24} md={12} className="col-info">
              <Avatar.Group>
                <Avatar size={50} shape="square" />

                <div className="avatar-info">
                  <p className="font-semibold m-0">Status - {user.status}</p>
                </div>
              </Avatar.Group>
            </Col>
          </Row>
        }
      ></Card>
    </>
  );
}

export default Profile;
