import { useEffect } from "react";
import {
  Layout,
  Row,
  Col,
  Button,
  Card,
  Form,
  Input,
  Select,
  Spin,
  DatePicker,
} from "antd";
import moment from "moment";
import { getBuses } from "../../redux/bus/action";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { createHumanResource } from "../../redux/human_resource/action";
const { Content } = Layout;
const { Option } = Select;

const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "white" }} spin />
);

function disabledDate(current) {
  return current && current > moment().endOf("day");
}

function NewHumanResource() {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getBuses);
  }, [dispatch]);

  const { createHumanResourceLoading } = useSelector(
    (state) => state.human_resource
  );
  const { buses } = useSelector((state) => state.bus);

  let dateData;
  const onChange = (date, dateString) => {
    dateData = dateString.split(" ");
    dateData = dateData[0];
  };

  let count = 0;
  const busesData = [];

  buses.forEach((bus) =>
    busesData.push({
      key: count++,
      value: bus.id,
      plateNumber: bus.plateNumber,
    })
  );

  const onFinish = (values) => {
    const formData = values;
    formData.dateOfBirth = dateData;
    dispatch(createHumanResource(formData, history));
  };

  const onFinishFailed = (errorInfo) => {};

  return (
    <div className="tabled">
      <Row gutter={[24, 0]}>
        <Col xs="24" xl={24}>
          <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title="Register new Human Resource"
          >
            <div
              style={{
                display: "flex",
                padding: "2em 2em 2em 2em",
                width: "100%",
              }}
            >
              <Content className="p-0">
                <Form
                  name="basic"
                  initialValues={{ remember: true }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  layout="vertical"
                  className="row-col"
                >
                  <Row>
                    <Col
                      xs={8}
                      xl={8}
                      style={{
                        padding: "1em",
                      }}
                    >
                      <Form.Item
                        className="firstName"
                        label="First Name"
                        name="firstName"
                        rules={[
                          {
                            required: true,
                            message: "Please input your first name!",
                          },
                        ]}
                      >
                        <Input placeholder="First Name..." />
                      </Form.Item>
                    </Col>
                    <Col
                      xs={8}
                      xl={8}
                      style={{
                        padding: "1em",
                      }}
                    >
                      <Form.Item
                        className="lastName"
                        label="Last Name"
                        name="lastName"
                        rules={[
                          {
                            required: true,
                            message: "Please input your last name!",
                          },
                        ]}
                      >
                        <Input placeholder="Last name..." />
                      </Form.Item>
                    </Col>
                    <Col
                      xs={8}
                      xl={8}
                      style={{
                        padding: "1em",
                      }}
                    >
                      <Form.Item
                        className="gender"
                        label="Gender"
                        name="gender"
                        rules={[
                          {
                            required: true,
                            message: "Please input the gender of employee!",
                          },
                        ]}
                      >
                        <Select placeholder="select gender..." size="large">
                          <Option value="MALE">Male</Option>
                          <Option value="FEMALE">Female</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col
                      xs={8}
                      xl={8}
                      style={{
                        padding: "1em",
                      }}
                    >
                      <Form.Item
                        className="bloodGroup"
                        label="Blood Type"
                        name="bloodGroup"
                        rules={[
                          {
                            required: true,
                            message:
                              "Please input the blood group of employee!",
                          },
                        ]}
                      >
                        <Select placeholder="select blood type..." size="large">
                          <Option value="A_NEGATIVE">A</Option>
                          <Option value="B_NEGATIVE">B</Option>
                          <Option value="AB_POSITIVE">AB</Option>
                          <Option value="O_NEGATIVE">O</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col
                      xs={8}
                      xl={8}
                      style={{
                        padding: "1em",
                      }}
                    >
                      <Form.Item
                        className="dateOfBirth"
                        label="Date of Birth"
                        name="dateOfBirth"
                        rules={[
                          {
                            required: true,
                            message:
                              "Please input the Date of Birth of employee!",
                          },
                        ]}
                      >
                        <DatePicker
                          size="large"
                          format="DD:MM:YYYY"
                          disabledDate={disabledDate}
                          onChange={onChange}
                          style={{ width: "100%" }}
                          //   disabledTime={disabledDateTime}
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      xs={8}
                      xl={8}
                      style={{
                        padding: "1em",
                      }}
                    >
                      <Form.Item
                        className="phone"
                        label="Phone"
                        name="phone"
                        rules={[
                          {
                            required: true,
                            message: "Please input your phone!",
                          },
                        ]}
                      >
                        <Input placeholder="Phone..." />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col
                      xs={8}
                      xl={8}
                      style={{
                        padding: "1em",
                      }}
                    >
                      <Form.Item
                        className="type"
                        label="Position"
                        name="type"
                        rules={[
                          {
                            required: true,
                            message: "Please input the position of employee!",
                          },
                        ]}
                      >
                        <Select placeholder="select position..." size="large">
                          <Option value="DRIVER">Driver</Option>                          
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col
                      xs={24}
                      sm={16}
                      xl={8}
                      style={{
                        padding: "1em",
                      }}
                    >
                      <Form.Item
                        className="bus"
                        label="Select Bus"
                        name="bus"
                        rules={[
                          {
                            required: true,
                            message: "Please input the bus plate!",
                          },
                        ]}
                      >
                        <Select
                          size="large"
                          showSearch
                          placeholder="Select Bus"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {busesData.map((option, index) => (
                            <Option key={index} value={option.value}>
                              {option.plateNumber}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Form.Item>
                    <div
                      style={{
                        paddingTop: 30,
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        type="primary"
                        htmlType="submit"
                        style={{
                          width: "20%",
                        }}
                      >
                        {createHumanResourceLoading ? (
                          <Spin indicator={antIcon} />
                        ) : (
                          "Register"
                        )}
                      </Button>
                    </div>
                  </Form.Item>
                </Form>
              </Content>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default NewHumanResource;
