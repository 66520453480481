import { ReportTypes } from "./types";
import axios from "axios";

import { busAdminTokenKey, BASE_URI } from "../../config/key.config";

export const fetchTodayReportStart = () => ({
  type: ReportTypes.REPORT_TODAY_PENDING,
});

export const fetchTodayReportSuccess = (reportInterval) => ({
  type: ReportTypes.REPORT_TODAY,
  payload: {
    reportInterval,
  },
});

export const fetchTodayReportFail = (error) => ({
  type: ReportTypes.REPORT_TODAY_FAIL,
  payload: {
    error,
  },
});

export const fetchWeekReportStart = () => ({
  type: ReportTypes.REPORT_WEEK_PENDING,
});

export const fetchWeekReportSuccess = (reportInterval) => ({
  type: ReportTypes.REPORT_WEEK,
  payload: {
    reportInterval,
  },
});

export const fetchWeekReportFail = (error) => ({
  type: ReportTypes.REPORT_WEEK_FAIL,
  payload: {
    error,
  },
});

export const fetchMonthReportStart = () => ({
  type: ReportTypes.REPORT_MONTH_PENDING,
});

export const fetchMonthReportSuccess = (reportInterval) => ({
  type: ReportTypes.REPORT_MONTH,
  payload: {
    reportInterval,
  },
});

export const fetchMonthReportFail = (error) => ({
  type: ReportTypes.REPORT_MONTH_FAIL,
  payload: {
    error,
  },
});

export const fetchYearReportStart = () => ({
  type: ReportTypes.REPORT_YEAR_PENDING,
});

export const fetchYearReportSuccess = (reportInterval) => ({
  type: ReportTypes.REPORT_YEAR,
  payload: {
    reportInterval,
  },
});

export const fetchYearReportFail = (error) => ({
  type: ReportTypes.REPORT_YEAR_FAIL,
  payload: {
    error,
  },
});

export const fetchReportByDateStart = () => ({
  type: ReportTypes.REPORT_BY_DATE_PENDING,
});

export const fetchReportByDateSuccess = (dateReport) => ({
  type: ReportTypes.REPORT_BY_DATE,
  payload: {
    dateReport,
  },
});

export const fetchReportByDateFail = (error) => ({
  type: ReportTypes.REPORT_BY_DATE_FAIL,
  payload: {
    error,
  },
});

export const fetchReportByRouteDateStart = () => ({
  type: ReportTypes.REPORT_BY_ROUTE_DATE_PENDING,
});

export const fetchReportByRouteDateSuccess = (routeDateReport) => ({
  type: ReportTypes.REPORT_BY_ROUTE_DATE,
  payload: {
    routeDateReport,
  },
});

export const fetchReportByRouteDateFail = (error) => ({
  type: ReportTypes.REPORT_BY_ROUTE_DATE_FAIL,
  payload: {
    error,
  },
});

export const fetchReportByRouteDateIntervalStart = () => ({
  type: ReportTypes.REPORT_BY_ROUTE_DATE_INTERVAL_PENDING,
});

export const fetchReportByRouteDateIntervalSuccess = (intervalReport) => ({
  type: ReportTypes.REPORT_BY_ROUTE_DATE_INTERVAL,
  payload: {
    intervalReport,
  },
});

export const fetchReportByRouteDateIntervalFail = (error) => ({
  type: ReportTypes.REPORT_BY_ROUTE_DATE_INTERVAL_FAIL,
  payload: {
    error,
  },
});

export const fetchOfechoReportStart = (reports) => ({
  type: ReportTypes.OFECHO_REPORT_PENDING,
  payload: {
    reports,
  },
});

export const fetchOfechoReportSuccess = (ofechoReport) => ({
  type: ReportTypes.OFECHO_REPORT,
  payload: {
    ofechoReport,
  },
});

export const fetchOfechoReportFail = (error) => ({
  type: ReportTypes.OFECHO_REPORT_FAIL,
  payload: {
    error,
  },
});

export const fetchDashboardReportStart = () => ({
  type: ReportTypes.DASHBOARD_REPORT_PENDING,
});

export const fetchDashboardReportSuccess = (reports) => ({
  type: ReportTypes.DASHBOARD_REPORT,
  payload: {
    reports,
  },
});

export const fetchDashboardReportFail = (error) => ({
  type: ReportTypes.DASHBOARD_REPORT_FAIL,
  payload: {
    error,
  },
});

export const fetchDashboardCatagoryReportStart = () => ({
  type: ReportTypes.DASHBOARD_CATAGORY_REPORT_PENDING,
});

export const fetchDashboardCatagoryReportSuccess = (reportsCatagory) => ({
  type: ReportTypes.DASHBOARD_CATAGORY_REPORT,
  payload: {
    reportsCatagory,
  },
});

export const fetchDashboardCatagoryReportFail = (error) => ({
  type: ReportTypes.DASHBOARD_CATAGORY_REPORT_FAIL,
  payload: {
    error,
  },
});

export const fetchDashboardBankReportStart = () => ({
  type: ReportTypes.DASHBPARD_REPORT_FOR_BANKS_PENDING,
});

export const fetchDashboardBankReportSuccess = (reportsBank) => ({
  type: ReportTypes.DASHBPARD_REPORT_FOR_BANKS,
  payload: {
    reportsBank,
  },
});

export const fetchDashboardBankReportFail = (error) => ({
  type: ReportTypes.DASHBPARD_REPORT_FOR_BANKS_FAIL,
  payload: {
    error,
  },
});

export const fetchDashboardConductorReportStart = () => ({
  type: ReportTypes.DASHBOARD_REPORT_CONDUCTOR_PENDING,
});

export const fetchDashboardConductorReportSuccess = (reportsConductor) => ({
  type: ReportTypes.DASHBOARD_REPORT_CONDUCTOR,
  payload: {
    reportsConductor,
  },
});

export const fetchDashboardConductorReportFail = (error) => ({
  type: ReportTypes.DASHBOARD_REPORT_CONDUCTOR_FAIL,
  payload: {
    error,
  },
});

export const fetchReportFail = (error) => ({
  type: ReportTypes.REPORT_FAIL,
  payload: {
    error,
  },
});
export const clearReport = () => ({
  type: ReportTypes.REPORT_CLEAR,
});

export const clearReports = (dispatch) => {
  dispatch(clearReport);
};

export const getTodayReport = (dispatch) => {
  dispatch(fetchTodayReportStart());

  axios
    .get(`${BASE_URI}/report/dashboard`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) => {
      const responseToday = res.data.today;
      const responseData = responseToday.report;
      dispatch(fetchTodayReportSuccess(responseData));
    })
    .catch((err) => {
      dispatch(fetchTodayReportFail(err));
    });
};

export const getWeekReport = (dispatch) => {
  dispatch(fetchWeekReportStart());

  axios
    .get(`${BASE_URI}/report/dashboard`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) => {
      const responseWeek = res.data.week;

      const responseData = responseWeek.report;
      dispatch(fetchWeekReportSuccess(responseData));
    })
    .catch((err) => {
      dispatch(fetchWeekReportFail(err));
    });
};

export const getMonthReport = (dispatch) => {
  dispatch(fetchMonthReportStart());

  axios
    .get(`${BASE_URI}/report/dashboard`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) => {
      const responseMonth = res.data.month;

      const responseData = responseMonth.report;
      dispatch(fetchMonthReportSuccess(responseData));
    })
    .catch((err) => {
      dispatch(fetchMonthReportFail(err));
    });
};

export const getYearReport = (dispatch) => {
  dispatch(fetchYearReportStart());

  axios
    .get(`${BASE_URI}/report/dashboard`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) => {
      const responseYear = res.data.year;

      const responseData = responseYear.report;

      dispatch(fetchYearReportSuccess(responseData));
    })
    .catch((err) => {
      dispatch(fetchYearReportFail(err));
    });
};

export const getReportByDate = (dispatch) => {
  dispatch(fetchReportByDateStart());

  axios
    .get(`${BASE_URI}/report/ticket/day/2021-03-24`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) => {
      dispatch(fetchReportByDateSuccess(res.data));
    })
    .catch((err) => {
      dispatch(fetchReportByDateFail(err));
    });
};

export const getReportByRouteAndDate = (dispatch) => {
  dispatch(fetchReportByRouteDateStart());

  axios
    .get(
      `${BASE_URI}/report/main-report/ticket/bus/1/route/addis ababa-gondar?date=12/07/2021`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
        },
      }
    )
    .then((res) => {
      dispatch(fetchReportByRouteDateSuccess(res.data));
    })
    .catch((err) => {
      dispatch(fetchReportByRouteDateFail(err));
    });
};

export const getReportByRouteAndDateInterval = (dispatch) => {
  dispatch(fetchReportByRouteDateIntervalStart());

  axios
    .get(
      `${BASE_URI}/report/main-report/ticket/bus/1/route/addis ababa-gondar?date=12/07/2021report/main-report-interval/ticket/bus/1/route/adama-addis ababa?initial=14/02/2021&final=11/10/2021`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
        },
      }
    )
    .then((res) => {
      dispatch(fetchReportByRouteDateIntervalSuccess(res.data));
    })
    .catch((err) => {
      dispatch(fetchReportByRouteDateIntervalFail(err));
    });
};

export const getOfechoReport = (dispatch) => {
  dispatch(fetchOfechoReportStart());

  axios
    .get(
      `${BASE_URI}/report/ofecho-report/bus/1/route/addis ababa-gondar?travelDate=06/07/2021`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
        },
      }
    )
    .then((res) => {
      dispatch(fetchOfechoReportSuccess(res.data));
    })
    .catch((err) => {
      dispatch(fetchOfechoReportFail(err));
    });
};

export const getDashboardReport = (dispatch) => {
  dispatch(fetchDashboardReportStart());

  axios
    .get(`${BASE_URI}/report/dashboard`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) => {
      const responseToday = res.data.today;
      const responseWeek = res.data.week;
      const responseMonth = res.data.month;
      const responseYear = res.data.year;

      const responseData = {
        todayPercent: responseToday.percent,
        todayAllCount: responseToday.allCount,
        todayCount: responseToday.count,
        todayTotal: responseToday.total,

        weekPercent: responseWeek.percent,
        weekAllCount: responseWeek.allCount,
        weekCount: responseWeek.count,
        weekTotal: responseWeek.total,

        monthPercent: responseMonth.percent,
        monthAllCount: responseMonth.allCount,
        monthCount: responseMonth.count,
        monthTotal: responseMonth.total,

        yearPercent: responseYear.percent,
        yearAllCount: responseYear.allCount,
        yearCount: responseYear.count,
        yearTotal: responseYear.total,
      };

      dispatch(fetchDashboardReportSuccess(responseData));
    })
    .catch((err) => {
      dispatch(fetchDashboardReportFail(err));
    });
};

export const getDashboardReportToday = (dispatch) => {
  dispatch(fetchTodayReportStart());

  axios
    .get(`${BASE_URI}/report/dashboard`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) => {
      const response = res.data.today;
      let responseData = [];

      const total = response.total;
      const percent = response.percent;
      const count = response.allCount;

      response.report.forEach((res) =>
        responseData.push({
          total: total,
          percent: percent,
          count: count,
          key: res.ticketNo,
          passenger: res.passenger,
          seat: res.seat,
          phoneNumber: res.phoneNumber,
          issuedBy: res.issuedBy,
          travelDate: res.travelDate,
          fare: res.fare,
          bus: res.bus,
        })
      );
      dispatch(clearReport);
      dispatch(fetchTodayReportSuccess(responseData));
    })
    .catch((err) => {
      dispatch(fetchTodayReportFail(err));
    });
};

export const getDashboardReportWeek = (dispatch) => {
  dispatch(fetchWeekReportStart());

  axios
    .get(`${BASE_URI}/report/dashboard`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) => {
      const response = res.data.week;
      let responseData = [];

      const total = response.total;
      const percent = response.percent;
      const count = response.allCount;

      response.report.forEach((res) =>
        responseData.push({
          total: total,
          percent: percent,
          count: count,
          key: res.ticketNo,
          passenger: res.passenger,
          seat: res.seat,
          phoneNumber: res.phoneNumber,
          issuedBy: res.issuedBy,
          travelDate: res.travelDate,
          fare: res.fare,
          bus: res.bus,
        })
      );
      dispatch(clearReport);
      dispatch(fetchWeekReportSuccess(responseData));
    })
    .catch((err) => {
      dispatch(fetchWeekReportFail(err));
    });
};

export const getDashboardReportMonth = (dispatch) => {
  dispatch(fetchMonthReportStart());

  axios
    .get(`${BASE_URI}/report/dashboard`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) => {
      const response = res.data.month;
      let responseData = [];

      const total = response.total;
      const percent = response.percent;
      const count = response.allCount;

      response.report.forEach((res) =>
        responseData.push({
          total: total,
          percent: percent,
          count: count,
          key: res.ticketNo,
          passenger: res.passenger,
          seat: res.seat,
          phoneNumber: res.phoneNumber,
          issuedBy: res.issuedBy,
          travelDate: res.travelDate,
          fare: res.fare,
          bus: res.bus,
        })
      );
      dispatch(clearReport);
      dispatch(fetchMonthReportSuccess(responseData));
    })
    .catch((err) => {
      dispatch(fetchMonthReportFail(err));
    });
};

export const getDashboardReportYear = (dispatch) => {
  dispatch(fetchYearReportStart());

  axios
    .get(`${BASE_URI}/report/dashboard`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) => {
      const response = res.data.year;
      let responseData = [];

      const total = response.total;
      const percent = response.percent;
      const count = response.allCount;

      response.report.forEach((res) =>
        responseData.push({
          total: total,
          percent: percent,
          count: count,
          key: res.ticketNo,
          passenger: res.passenger,
          seat: res.seat,
          phoneNumber: res.phoneNumber,
          issuedBy: res.issuedBy,
          travelDate: res.travelDate,
          fare: res.fare,
          bus: res.bus,
        })
      );
      dispatch(clearReport);
      dispatch(fetchYearReportSuccess(responseData));
    })
    .catch((err) => {
      dispatch(fetchYearReportFail(err));
    });
};

export const getDashboardCategoryReport = (dispatch) => {
  dispatch(fetchDashboardCatagoryReportStart());

  axios
    .get(`${BASE_URI}/report/dashboard/catagory`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) => {      
      const response = res.data;
      const responseData = {
        conductors: response.conductors.percent ? response.conductors.percent : 0,
        customers: response.customer.percent ? response.customer.percent : 0,
        bank: response.bank.percent ? response.bank.percent : 0,
      };
      dispatch(fetchDashboardCatagoryReportSuccess(responseData));
    })
    .catch((err) => {
      dispatch(fetchDashboardCatagoryReportFail(err));
    });
};

export const getDashboardBankReport = (dispatch) => {
  dispatch(fetchDashboardBankReportStart());

  axios
    .get(`${BASE_URI}/report/dashboard/banks`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) => {
      dispatch(fetchDashboardBankReportSuccess(res.data));
    })
    .catch((err) => {
      dispatch(fetchDashboardBankReportFail(err));
    });
};

export const getDashboardConductorReport = (dispatch) => {
  dispatch(fetchDashboardConductorReportStart());

  axios
    .get(`${BASE_URI}/report/dashboard/conductors`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(busAdminTokenKey)}`,
      },
    })
    .then((res) => {
      const response = res.data;
      const responseDatas = [];
      response.report.forEach((res) =>
        responseDatas.push({ conductor: res.conductor, ticket: res.tickets })
      );
      dispatch(fetchDashboardConductorReportSuccess(responseDatas));
    })
    .catch((err) => {
      dispatch(fetchDashboardConductorReportFail(err));
    });
};
