import { useState, useEffect } from "react";
import { Tabs, Skeleton } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers } from "../../redux/user/action";
import BasicAccessUser from "./basic-access/BasicAccess.user";
import FinanceUser from "./finance/Finance.user";
import OperationUser from "./operation/Operation.user";
import ReporterUser from "./reporter/Reporter.user";
import { updateUser } from "../../redux/user/action";

const Users = () => {
  const dispatch = useDispatch();
  const [tab, setTab] = useState("1");
  const {
    error,
    user,
    users,
    userLoading,
    usersLoading,
    usersSuccess,
    updateUserLoading,
  } = useSelector((state) => state.user);

  useEffect(() => dispatch(getAllUsers), [dispatch, updateUserLoading]);

  const basic_access = users.filter(
    (user_basic) => user_basic.role === "user_basic"
  );

  const finances = users.filter((finance) => finance.role === "user_finance");

  const operators = users.filter(
    (operator) => operator.role === "user_oprator"
  );

  const reporters = users.filter(
    (reporter) => reporter.role === "user_reporter"
  );

  const handleUserUpdate = (id, formData) => {    
    dispatch(updateUser(id, formData));
  };

  return (
    <>
      {usersSuccess ? (
        <div className="row">
          <div className="col-md-12">
            <Tabs
              defaultActiveKey="agent"
              tabPosition="top"
              onChange={(e) => setTab(e)}
            >
              <Tabs.TabPane tab="Basic-access" key="basic-access">
                <BasicAccessUser
                  basic_access={basic_access}
                  handleUserUpdate={handleUserUpdate}
                />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Finance" key="finance">
                <FinanceUser
                  finances={finances}
                  handleUserUpdate={handleUserUpdate}
                />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Operation" key="operation">
                <OperationUser
                  operators={operators}
                  handleUserUpdate={handleUserUpdate}
                />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Reporter" key="reporter">
                <ReporterUser
                  reporters={reporters}
                  handleUserUpdate={handleUserUpdate}
                />
              </Tabs.TabPane>
            </Tabs>
          </div>
        </div>
      ) : (
        <Skeleton active />
      )}
    </>
  );
};

export default Users;
