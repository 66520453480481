import { ReportTypes } from "./types";

const INITIAL_STATE = {
  error: null,
  reports: [],
  dateReport: [],
  routeDateReport: [],
  intervalReport: [],
  ofechoReport: [],
  reportsCatagory: [],
  reportsConductor: [],
  reportsBank: [],

  reportTodayStart: false,
  reportIntervalLoading: false,
  reportTodaySuccess: false,
  reportTodayFail: false,

  reportWeekStart: false,
  reportWeekSuccess: false,
  reportWeekFail: false,

  reportMonthStart: false,
  reportMonthSuccess: false,
  reportMonthFail: false,

  reportYearStart: false,
  reportYearSuccess: false,
  reportYearFail: false,

  reportByDateStart: false,
  reportByDateSuccess: false,
  reportByDateFail: false,

  reportByRouteAndDateStart: false,
  reportByRouteAndDateSuccess: false,
  reportByRouteAndDateFail: false,

  reportByRouteAndDateIntervalStart: false,
  reportByRouteAndDateIntervalSuccess: false,
  reportByRouteAndDateIntervalFail: false,

  reportOfechoStart: false,
  reportOfechoSuccess: false,
  reportOfechoFail: false,

  reportDashboardReportLoading: false,
  reportDashboardReportSuccess: false,
  reportDashboardReportFail: false,

  reportDashboardCatagoryLoading: false,
  reportDashboardCatagorySuccess: false,
  reportDashboardCatagoryFail: false,

  reportDashboardBankLoading: false,
  reportDashboardBankSuccess: false,
  reportDashboardBankFail: false,

  reportDashboardConductorLoading: false,
  reportDashboardConductorSuccess: false,
  reportDashboardConductorFail: false,
  reportFail: false,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ReportTypes.REPORT_CLEAR:
      return {
        ...state,
        error: null,
        reports: [],
        reportInterval: [],
        todayReport: [],
        weekReport: [],
        monthReport: [],
        yearReport: [],
        dateReport: [],
        routeDateReport: [],
        intervalReport: [],
        ofechoReport: [],
        reportsCatagory: [],
        reportsConductor: [],
        reportsBank: [],

        reportTodayStart: false,
        reportTodaySuccess: false,
        reportTodayFail: false,

        reportWeekStart: false,
        reportWeekSuccess: false,
        reportWeekFail: false,

        reportMonthStart: false,
        reportMonthSuccess: false,
        reportMonthFail: false,

        reportYearStart: false,
        reportYearSuccess: false,
        reportYearFail: false,

        reportByDateStart: false,
        reportByDateSuccess: false,
        reportByDateFail: false,

        reportByRouteAndDateStart: false,
        reportByRouteAndDateSuccess: false,
        reportByRouteAndDateFail: false,

        reportByRouteAndDateIntervalStart: false,
        reportByRouteAndDateIntervalSuccess: false,
        reportByRouteAndDateIntervalFail: false,

        reportOfechoStart: false,
        reportOfechoSuccess: false,
        reportOfechoFail: false,

        reportDashboardReportLoading: false,
        reportDashboardReportSuccess: false,
        reportDashboardReportFail: false,

        reportDashboardCatagoryLoading: false,
        reportDashboardCatagorySuccess: false,
        reportDashboardCatagoryFail: false,

        reportDashboardBankLoading: false,
        reportDashboardBankSuccess: false,
        reportDashboardBankFail: false,

        reportDashboardConductorLoading: false,
        reportDashboardConductorSuccess: false,
        reportDashboardConductorFail: false,
        reportFail: false,
      };
    case ReportTypes.REPORT_TODAY_PENDING:
      return {
        ...state,
        reportTodayStart: true,
        reportIntervalLoading: true,
      };
    case ReportTypes.REPORT_TODAY:
      return {
        ...state,
        reportTodayStart: false,
        reportTodaySuccess: true,
        reportIntervalLoading: false,
        reportTodayFail: false,
        reportInterval: action.payload.reportInterval,
      };
    case ReportTypes.REPORT_TODAY_FAIL:
      return {
        ...state,
        reportTodayStart: false,
        reportTodaySuccess: false,
        reportIntervalLoading: false,
        reportTodayFail: true,
        error: action.payload.error,
      };
    case ReportTypes.REPORT_WEEK_PENDING:
      return {
        ...state,
        reportWeekStart: true,
        reportIntervalLoading: true,
      };
    case ReportTypes.REPORT_WEEK:
      return {
        ...state,
        reportWeekStart: false,
        reportWeekSuccess: true,
        reportIntervalLoading: false,
        reportWeekFail: false,
        reportInterval: action.payload.reportInterval,
      };
    case ReportTypes.REPORT_WEEK_FAIL:
      return {
        ...state,
        reportWeekStart: false,
        reportWeekSuccess: false,
        reportIntervalLoading: false,
        reportWeekFail: true,
        reportInterval: action.payload.reportInterval,
      };
    case ReportTypes.REPORT_MONTH_PENDING:
      return {
        ...state,
        reportMonthStart: true,
        reportIntervalLoading: true,
      };
    case ReportTypes.REPORT_MONTH:
      return {
        ...state,
        reportMonthStart: false,
        reportMonthSuccess: true,
        reportIntervalLoading: false,
        reportMonthFail: false,
        reportInterval: action.payload.reportInterval,
      };
    case ReportTypes.REPORT_MONTH_FAIL:
      return {
        ...state,
        reportMonthStart: false,
        reportMonthSuccess: false,
        reportIntervalLoading: false,
        reportMonthFail: true,
        error: action.payload.error,
      };

    case ReportTypes.REPORT_YEAR_PENDING:
      return {
        ...state,
        reportYearStart: true,
        reportIntervalLoading: true,
      };
    case ReportTypes.REPORT_YEAR:
      return {
        ...state,
        reportYearStart: false,
        reportYearSuccess: true,
        reportIntervalLoading: false,
        reportYearFail: false,
        reportInterval: action.payload.reportInterval,
      };
    case ReportTypes.REPORT_YEAR_FAIL:
      return {
        ...state,
        reportYearStart: false,
        reportYearSuccess: false,
        reportIntervalLoading: false,
        reportYearFail: true,
        error: action.payload.error,
      };
    case ReportTypes.REPORT_BY_DATE_PENDING:
      return {
        ...state,
        reportByDateStart: true,
      };
    case ReportTypes.REPORT_BY_DATE:
      return {
        ...state,
        reportByDateStart: false,
        reportByDateSuccess: true,
        reportByDateFail: false,
        dateReport: action.payload.dateReport,
      };
    case ReportTypes.REPORT_BY_DATE_FAIL:
      return {
        ...state,
        reportByDateStart: false,
        reportByDateSuccess: false,
        reportByDateFail: true,
        error: action.payload.error,
      };
    case ReportTypes.REPORT_BY_ROUTE_DATE_PENDING:
      return {
        ...state,
        reportByRouteAndDateStart: true,
      };
    case ReportTypes.REPORT_BY_ROUTE_DATE:
      return {
        ...state,
        reportByRouteAndDateStart: false,
        reportByRouteAndDateSuccess: true,
        reportByRouteAndDateFail: false,
        routeDateReport: action.payload.routeDateReport,
      };
    case ReportTypes.REPORT_BY_ROUTE_DATE_FAIL:
      return {
        ...state,
        reportByRouteAndDateStart: false,
        reportByRouteAndDateSuccess: false,
        reportByRouteAndDateFail: true,
        error: action.payload.error,
      };
    case ReportTypes.REPORT_BY_ROUTE_DATE_INTERVAL_PENDING:
      return {
        ...state,
        reportByRouteAndDateIntervalStart: true,
      };
    case ReportTypes.REPORT_BY_ROUTE_DATE_INTERVAL:
      return {
        ...state,
        reportByRouteAndDateIntervalStart: false,
        reportByRouteAndDateIntervalSuccess: true,
        reportByRouteAndDateIntervalFail: false,
        intervalReport: action.payload.intervalReport,
      };
    case ReportTypes.REPORT_BY_ROUTE_DATE_INTERVAL_FAIL:
      return {
        ...state,
        reportByRouteAndDateIntervalStart: false,
        reportByRouteAndDateIntervalSuccess: false,
        reportByRouteAndDateIntervalFail: true,
        error: action.payload.error,
      };

    case ReportTypes.OFECHO_REPORT_PENDING:
      return {
        ...state,
        reportOfechoStart: true,
      };
    case ReportTypes.OFECHO_REPORT:
      return {
        ...state,
        reportOfechoStart: false,
        reportOfechoSuccess: true,
        reportOfechoFail: false,
        ofechoReport: action.payload.ofechoReport,
      };
    case ReportTypes.OFECHO_REPORT_FAIL:
      return {
        ...state,
        reportOfechoStart: false,
        reportOfechoSuccess: false,
        reportOfechoFail: true,
        reports: action.payload.reports,
      };
    case ReportTypes.DASHBOARD_REPORT_PENDING:
      return {
        ...state,
        reportDashboardReportLoading: true,
      };
    case ReportTypes.DASHBOARD_REPORT:
      return {
        ...state,
        reportDashboardReportLoading: false,
        reportDashboardReportSuccess: true,
        reportDashboardReportFail: false,
        reports: action.payload.reports,
      };
    case ReportTypes.DASHBOARD_REPORT_FAIL:
      return {
        ...state,
        reportDashboardReportLoading: false,
        reportDashboardReportSuccess: false,
        reportDashboardReportFail: true,
        error: action.payload.error,
      };
    case ReportTypes.DASHBOARD_CATAGORY_REPORT_PENDING:
      return {
        ...state,
        reportDashboardCatagoryLoading: true,
      };
    case ReportTypes.DASHBOARD_CATAGORY_REPORT:
      return {
        ...state,
        reportDashboardCatagoryLoading: false,
        reportDashboardCatagorySuccess: true,
        reportDashboardCatagoryFail: false,
        reportsCatagory: action.payload.reportsCatagory,
      };
    case ReportTypes.DASHBOARD_CATAGORY_REPORT_FAIL:
      return {
        ...state,
        reportDashboardCatagoryLoading: false,
        reportDashboardCatagorySuccess: false,
        reportDashboardCatagoryFail: true,
        error: action.payload.error,
      };
    case ReportTypes.DASHBPARD_REPORT_FOR_BANKS_PENDING:
      return {
        ...state,
        reportDashboardBankLoading: true,
      };
    case ReportTypes.DASHBPARD_REPORT_FOR_BANKS:
      return {
        ...state,
        reportDashboardBankLoading: false,
        reportDashboardBankSuccess: true,
        reportDashboardBankFail: false,
        reportsBank: action.payload.reportsBank,
      };
    case ReportTypes.DASHBPARD_REPORT_FOR_BANKS_FAIL:
      return {
        ...state,
        reportDashboardBankLoading: false,
        reportDashboardBankSuccess: false,
        reportDashboardBankFail: true,
        error: action.payload.error,
      };
    case ReportTypes.DASHBOARD_REPORT_CONDUCTOR_PENDING:
      return {
        ...state,
        reportDashboardConductorLoading: true,
      };
    case ReportTypes.DASHBOARD_REPORT_CONDUCTOR:
      return {
        ...state,
        reportDashboardConductorLoading: false,
        reportDashboardConductorSuccess: true,
        reportDashboardConductorFail: false,
        reportsConductor: action.payload.reportsConductor,
      };
    case ReportTypes.DASHBOARD_REPORT_CONDUCTOR_FAIL:
      return {
        ...state,
        reportDashboardConductorLoading: false,
        reportDashboardConductorSuccess: false,
        reportDashboardConductorFail: true,
        error: action.payload.error,
      };
    // case ReportTypes.REPORT_CLEAR:
    //   return {
    //     ...state,
    //     reportLoading: false,
    //     reportTodaySuccess: false,
    //     reportWeekSuccess: false,
    //     reportMonthSuccess: false,
    //     reportYearSuccess: false,
    //     reportByDateSuccess: false,
    //     reportByRouteAndDateSuccess: false,
    //     reportByRouteAndDateIntervalSuccess: false,
    //     reportOfechoSuccess: false,
    //     reportFail: false,
    //     reports: {},
    //     error: {},
    //   };

    default:
      return state;
  }
};

export default reducer;
