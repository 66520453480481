import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Table,
  Input,
  Button,
  Space,
  Select,
  InputNumber,
  Modal,
  Badge,
  Form,
  Layout,
  TimePicker,
  Spin,
} from "antd";
import Highlighter from "react-highlight-words";
import { Link } from "react-router-dom";
import {
  SearchOutlined,
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  CheckCircleFilled,
  LoadingOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";

import { getRoutes, updateRoute, deleteRoute } from "../../redux/route/action";
import {
  createSubRoute,
  deleteSubRoute,
  getSubRoutesByRoute,
} from "../../redux/subroute/action";
import { getCities } from "../../redux/city/action";

const { Option } = Select;
const { Content } = Layout;
// const { RangePicker } = DatePicker;
const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "white" }} spin />
);

function RoutePage() {
  // const [addNewSubRoute, setAddNewSubRoute] = useState(false);
  const dispatch = useDispatch();
  const [isSubRoute, setSubRouteModal] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [isEditModalVisible, setEditModalVisible] = useState(false);
  const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
  const [currId, setCurrId] = useState("");
  const [rowData, setRowData] = useState({});
  const [subRowData, setSubRowData] = useState({});
  const [subData, setSubData] = useState([]);
  const [currRouteId, setCurrRouteId] = useState("");
  const [rowId, setRowId] = useState("");
  const [subRowId, setSubRowId] = useState("");
  const [isSubRouteDeleteModalVisible, setSubRouteDeleteModal] =
    useState(false);

  const {
    routes,
    route,
    routeLoading,
    routesLoading,
    routeSuccess,
    routesSuccess,
    updateRouteLoading,
    deleteRouteLoading,
  } = useSelector((state) => state.route);
  const {
    error,
    subRoute,
    subRoutes,
    subRoutesLoading,
    subRouteLoading,
    subRoutesSuccess,
    createSubRouteLoading,
  } = useSelector((state) => state.subRoute);
  const { citys } = useSelector((state) => state.city);

  useEffect(() => {
    dispatch(getRoutes);
    dispatch(getCities);
  }, [dispatch, updateRouteLoading, deleteRouteLoading]);

  const options = [];

  citys.forEach((city) =>
    options.push({ key: city.id, value: city.id, city: city.name })
  );

  const showSubRouteModal = (record) => {
    dispatch(getSubRoutesByRoute(record.id));
    setSubRouteModal(true);
  };

  const handleSubRouteModal = () => {
    setSubRouteModal(false);
    setCurrId("");
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    setCurrId("");
    setRowData({});
  };

  const handleCancel = () => {
    setRowData({});
    setIsModalVisible(false);
    setCurrId("");
  };

  const showEditModal = (record) => {
    setEditModalVisible(true);
  };

  const handleEditModalCancel = () => {
    setRowData({});
    setEditModalVisible(false);
    setCurrId("");
  };

  // Handle delete modal
  const showDeleteModal = (record) => {
    setRowData(record);
    setDeleteModalVisible(true);
  };

  const handleDeleteModalCancel = () => {
    setRowData({});

    setDeleteModalVisible(false);
    setCurrId("");
  };

  const handleDelete = (id) => {
    dispatch(deleteRoute(id));
    // dispatch(getRoutes);

    // dispatch(getRoutes);
    setDeleteModalVisible(false);
  };

  const handleSubRouteDelete = (id) => {
    dispatch(deleteSubRoute(id));
    dispatch(getSubRoutesByRoute(rowId));
    dispatch(getSubRoutesByRoute(rowId));

    setSubRouteDeleteModal(false);
  };

  const showSubRowDeleteModal = (record) => {
    setRowData(record);
    setSubRouteDeleteModal(true);
  };

  const handleSubRouteDeleteModal = () => {
    setRowData({});
    setSubRowId("");
    setSubRouteDeleteModal(false);
    setCurrId("");
  };

  let cityFrom = rowData ? rowData.from : "";
  function handleChangeFrom(value) {
    cityFrom = value;
  }
  let cityTo = rowData ? rowData.to : "";
  function handleChangeTo(value) {
    cityTo = value;
  }
  let fareData = rowData ? rowData.fare : "";
  function handleChangeFare(value) {
    fareData = value;
  }
  let distanceData = rowData ? rowData.distance : "";
  function handleChangeDistance(value) {
    distanceData = value;
  }
  let statusdata = rowData ? rowData.status : "ACTIVE";
  function handleChangeStatus(value) {
    statusdata = value;
  }

  const handleUpdate = (data) => {
    const status = statusdata;
    const distance = distanceData;
    const price = fareData;
    const from = cityFrom;
    const to = cityTo;
    const req = { status, price, from, to, distance };
    dispatch(updateRoute(req, data.id));

    dispatch(getRoutes);
    dispatch(getRoutes);
    setEditModalVisible(false);
  };

  const [searchText, setSearchText] = useState(false);
  const [searchedColumn, setSearchedColumn] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [filteredInfo, setFilteredInfo] = useState(null);
  const [sortedInfo, setSortedInfo] = useState(null);
  let getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            setSearchInput(node);
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>

          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => setSearchInput(""), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  let dateData;
  const onChange = (date, dateString) => {
    dateData = dateString;
    dateString = null;
  };
  let citySubFrom;
  function handleChangeSubFrom(value) {
    citySubFrom = value;
    value = null;
  }
  let citySubTo = 1;
  function handleChangeSubTo(value) {
    citySubTo = value;
    value = null;
  }
  let fareSubData;
  function handleChangeSubFare(value) {
    fareSubData = value;
    value = null;
  }
  const onFinish = () => {
    const price = fareSubData;
    const departureTime = dateData[0];
    const arrivalTime = dateData[1];
    const from = citySubFrom;
    const to = citySubTo;
    const route = rowId;
    const req = { price, departureTime, arrivalTime, from, to, route };
    dispatch(createSubRoute(req));

    dateData = null;
    citySubFrom = null;
    citySubTo = null;
    fareSubData = null;

    dispatch(getSubRoutesByRoute(rowId));
    handleCancel();
  };

  const onFinishFailed = (errorInfo) => {};
  let handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  let handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
    setFilteredInfo(null);
    setSortedInfo(null);
  };

  const handleChange = (e) => {
    setRowData({ ...rowData, [e.target.name]: e.target.value });
  };

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      width: "2%",
    },
    {
      title: "From",
      dataIndex: "from",
      width: "15%",
      ...getColumnSearchProps("from"),
    },
    {
      title: "To",
      dataIndex: "to",
      width: "15%",
      ...getColumnSearchProps("to"),
      // sorter: (a, b) => a.from.length - b.from.length,
      // sortDirections: ["descend", "ascend"],
    },
    {
      title: "Fare",
      dataIndex: "fare",
      width: "2%",
      ...getColumnSearchProps("fare"),
      sorter: (a, b) => a.to.length - b.to.length,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Distance",
      dataIndex: "distance",
      width: "2%",
      ...getColumnSearchProps("distance"),
      sorter: (a, b) => a.to.length - b.to.length,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Departure Time",
      dataIndex: "departureTime",
      width: "2%",
      ...getColumnSearchProps("departureTime"),
      sorter: (a, b) => a.to.length - b.to.length,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Arrival Time",
      dataIndex: "arrivalTime",
      width: "2%",
      ...getColumnSearchProps("arrivalTime"),
      sorter: (a, b) => a.to.length - b.to.length,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Status",
      dataIndex: "status",
      width: "8%",
      render: (text, record) => {
        const active = (
          <Badge
            className="site-badge-count-109"
            count={"Active"}
            style={{ backgroundColor: "#52c41a" }}
          />
        );

        const inactive = <Badge count={"Cancelled"} />;

        const show = record.status === "ACTIVE" ? active : inactive;

        return show;
      },
    },
    {
      title: "Common Sub Route",
      render: (text, record) => (
        <Button
          style={{
            background: "#eba134",
            borderColor: "#eba134",
          }}
          type="primary"
          shape="round"
          icon={<CheckCircleFilled />}
          onClick={() => {
            setRowData(record);
            setCurrId(record.id);
            setRowId(record.id);
            showSubRouteModal(record);
          }}
        >
          Sub routes
        </Button>
      ),
    },
    {
      title: "Action",
      render: (text, record) => (
        <Space size="middle">
          <Button
            style={{ background: "#eba134", borderColor: "#eba134" }}
            type="primary"
            shape="round"
            icon={<EditOutlined />}
            size="small"
            onClick={() => {
              setRowData(record);
              setCurrId(record.id);
              showEditModal(record);
            }}
          />
          <Button
            style={{ background: "#db0925", borderColor: "#db0925" }}
            shape="round"
            type="primary"
            icon={<DeleteOutlined />}
            size="small"
            onClick={() => {
              setCurrId(record.id);
              showDeleteModal(record);
            }}
          />
        </Space>
      ),
    },
  ];

  const columnsSub = [
    {
      title: "From",
      dataIndex: "from",
      key: "from",
      width: "15%",
      ...getColumnSearchProps("from"),
    },
    {
      title: "To",
      dataIndex: "to",
      key: "to",
      width: "15%",
      ...getColumnSearchProps("to"),
    },
    {
      title: "Fare",
      dataIndex: "fare",
      key: "fare",
      width: "12%",
      ...getColumnSearchProps("fare"),
      sorter: (a, b) => a.to.length - b.to.length,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Distance",
      dataIndex: "distance",
      key: "distance",
      width: "12%",
      ...getColumnSearchProps("distance"),
      sorter: (a, b) => a.to.length - b.to.length,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Departure Time",
      dataIndex: "departureTime",
      key: "departureTime",
      width: "12%",
      ...getColumnSearchProps("departureTime"),
      sorter: (a, b) => a.to.length - b.to.length,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Arrival Time",
      dataIndex: "arrivalTime",
      key: "arrivalTime",
      width: "12%",
      ...getColumnSearchProps("arrivalTime"),
      sorter: (a, b) => a.to.length - b.to.length,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "8%",
      render: (text, record) => {
        const active = (
          <Badge
            className="site-badge-count-109"
            count={"Active"}
            style={{ backgroundColor: "#52c41a" }}
          />
        );

        const inactive = <Badge count={"Cancelled"} />;

        const show = record.status === "ACTIVE" ? active : inactive;

        return show;
      },
    },
    {
      title: "Route Status",
      dataIndex: "routeStatus",
      key: "routeStatus",
      width: "12%",
      render: (text, record) => {
        const active = (
          <Badge
            className="site-badge-count-109"
            count={"Active"}
            style={{ backgroundColor: "#52c41a" }}
          />
        );

        const inactive = <Badge count={"Cancelled"} />;

        const show = record.status === "ACTIVE" ? active : inactive;

        return show;
      },
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Button
            style={{ background: "#eba134", borderColor: "#eba134" }}
            type="primary"
            shape="round"
            icon={<EditOutlined />}
            size="small"
            onClick={() => {
              setSubRowData(record);
              showEditModal(record);
            }}
          />
          <Button
            style={{ background: "#db0925", borderColor: "#db0925" }}
            shape="round"
            type="primary"
            icon={<DeleteOutlined />}
            size="small"
            onClick={() => {
              showSubRowDeleteModal(record);
              setSubRowId(record.id);
            }}
          />
        </Space>
      ),
    },
  ];

  return (
    <div className="tabled">
      <Row gutter={[24, 0]}>
        <Col xs={24} xl={24} md={24}>
          <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title="Fillter data"
            extra={
              <Link to="new-route">
                <Button type="primary" icon={<PlusOutlined />} size="large">
                  Add main route
                </Button>
              </Link>
            }
          >
            <Table
              columns={columns}
              dataSource={routes}
              loading={routesLoading}
              scroll={{ x: 600 }}
            />
          </Card>
        </Col>
      </Row>
      {/* Modals */}
      <Modal
        title={rowData ? `${rowData.from} - ${rowData.to}` : ""}
        visible={isSubRoute}
        onCancel={handleSubRouteModal}
        footer={[
          <Button key="back" onClick={handleSubRouteModal}>
            cancel
          </Button>,
        ]}
        width={1500}
      >
        <Row gutter={[24, 0]}>
          <Col xs={24} xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="Sub routes"
              extra={
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  size="large"
                  onClick={showModal}
                >
                  Add Sub Route
                </Button>
              }
            >
              <Table
                columns={columnsSub}
                dataSource={subRoutes}
                loading={subRoutesLoading}
                scroll={{ x: 400 }}
              />
            </Card>
          </Col>
        </Row>
      </Modal>
      {/* SUB MODAL CREATE */}
      <Modal
        title="routes.add_sub_routes"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button
            key="back"
            onClick={() => {
              handleCancel();
              setCurrId("");
              setRowData({});
              setSubRowData({});
            }}
          >
            cancel
          </Button>,
        ]}
        width={600}
      >
        <Content className="p-0">
          <Form
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
            className="row-col"
          >
            <Row>
              <Col
                xs={24}
                xl={20}
                style={{
                  padding: "1em",
                }}
              >
                <label>From</label>
                <Select
                  className="to"
                  label="To"
                  name="to"
                  rules={[
                    {
                      required: true,
                      message: "Please the starting destination!",
                    },
                  ]}
                  // defaultValue="From"
                  style={{ width: "100%" }}
                  size="large"
                  defaultValue=""
                  onChange={handleChangeSubFrom}
                  showSearch
                >
                  {options.map((option) => (
                    <Option value={option.value}>
                      {option.city.toUpperCase()}
                    </Option>
                  ))}
                </Select>
              </Col>
              <Col
                xs={24}
                xl={20}
                style={{
                  padding: "1em",
                }}
              >
                <label>To</label>
                <Select
                  // defaultValue={subRowData.to}
                  className="to"
                  label="To"
                  name="to"
                  defaultValue=""
                  rules={[
                    {
                      required: true,
                      message: "Please Enter the Ending destination!",
                    },
                  ]}
                  // defaultValue="From"
                  style={{ width: "100%" }}
                  size="large"
                  onChange={handleChangeSubTo}
                  showSearch
                >
                  {options.map((option) => (
                    <Option value={option.value}>
                      {option.city.toUpperCase()}
                    </Option>
                  ))}
                </Select>
              </Col>
              <Col
                xs={24}
                xl={20}
                style={{
                  padding: "1em",
                }}
              >
                <label>Date</label>
                <TimePicker.RangePicker
                  className="deparetureTime"
                  label="Departure Time"
                  name="deparetureTime"
                  defaultValue=""
                  rules={[
                    {
                      required: true,
                      message: "Please Select the destination city!",
                    },
                  ]}
                  onChange={onChange}
                  // showTime={{ format: "HH:mm" }}
                  // format="YYYY-MM-DD HH:mm"
                  style={{ width: "100%" }}
                />
              </Col>
              <Col
                xs={24}
                xl={20}
                style={{
                  padding: "1em",
                }}
              >
                <label>Fare</label>
                <InputNumber
                  className="price"
                  label="Total rice"
                  name="fare"
                  defaultValue=""
                  rules={[
                    {
                      required: true,
                      message: "Please Select the destination city!",
                    },
                  ]}
                  min={0}
                  onChange={handleChangeSubFare}
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>

            <div
              style={{
                paddingTop: 10,
                width: "100%",
                paddingLeft: "15px",
              }}
            >
              <Button type="primary" htmlType="submit" size="large">
                {createSubRouteLoading ? <Spin indicator={antIcon} /> : "Save"}
              </Button>
            </div>
          </Form>
        </Content>
      </Modal>
      {/* Delete Account Modal */}
      <Modal
        title="Delete Route"
        visible={isDeleteModalVisible}
        onCancel={handleDeleteModalCancel}
        footer={[
          <Button key="back" onClick={handleDeleteModalCancel}>
            cancel
          </Button>,
          <Button
            key="submit"
            danger
            type="primary"
            onClick={() => handleDelete(currId)}
          >
            Delete
          </Button>,
        ]}
      >
        <p>Are you sure?</p>
        <p>you want to delete the following Route?</p>
        <p>From - {rowData.from}</p>
        <p>To - {rowData.to}</p>
      </Modal>

      {/* Delete Account Modal */}
      <Modal
        title="Delete Sub Route"
        visible={isSubRouteDeleteModalVisible}
        onCancel={handleSubRouteDeleteModal}
        footer={[
          <Button key="back" onClick={handleSubRouteDeleteModal}>
            cancel
          </Button>,
          <Button
            key="submit"
            danger
            type="primary"
            onClick={() => handleSubRouteDelete(subRowId)}
          >
            Delete
          </Button>,
        ]}
      >
        <p>Are you sure?</p>
        <p>you want to delete the following Sub Route?</p>
        <p>From - {rowData.from}</p>
        <p>To - {rowData.to}</p>
      </Modal>
      {/* Eddit Route Modal */}
      <Modal
        title="Edit Route"
        visible={isEditModalVisible}
        onCancel={handleEditModalCancel}
        footer={[
          <Button key="back" onClick={handleEditModalCancel}>
            cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => handleUpdate(rowData)}
          >
            Update
          </Button>,
        ]}
        width={1000}
      >
        <Content className="p-0">
          <Form
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
            className="row-col"
          >
            <Row>
              <Col
                xs={24}
                xl={12}
                style={{
                  padding: "1em",
                }}
              >
                <label>From</label>
                <Select
                  defaultValue={rowData.from}
                  className="from"
                  label="From"
                  name="from"
                  rules={[
                    {
                      required: true,
                      message: "Please the starting destination!",
                    },
                  ]}
                  style={{ width: "100%" }}
                  size="large"
                  onChange={handleChangeFrom}
                  showSearch
                >
                  {options.map((option) => (
                    <Option value={option.value}>
                      {option.city.toUpperCase()}
                    </Option>
                  ))}
                </Select>
              </Col>
              <Col
                xs={24}
                xl={12}
                style={{
                  padding: "1em",
                }}
              >
                <label>To</label>
                <Select
                  defaultValue={rowData.to}
                  style={{ width: "100%" }}
                  size="large"
                  onChange={handleChangeTo}
                  showSearch
                  className="to"
                  label="To"
                  name="to"
                  rules={[
                    {
                      required: true,
                      message: "Please input the destination point!",
                    },
                  ]}
                >
                  {options.map((option) => (
                    <Option value={option.value}>
                      {option.city.toUpperCase()}
                    </Option>
                  ))}
                </Select>
              </Col>
            </Row>
            <Row>
              <Col
                xs={24}
                xl={12}
                style={{
                  padding: "1em",
                }}
              >
                <label>Fare</label>
                <InputNumber
                  className="fare"
                  label="Fare"
                  name="fare"
                  value={rowData.fare}
                  onChange={handleChangeFare}
                  rules={[
                    {
                      required: true,
                      message: "Please input the fare amount!",
                    },
                  ]}
                  min={0}
                  style={{ width: "100%" }}
                  size="large"
                />
              </Col>
              <Col
                xs={24}
                xl={12}
                style={{
                  padding: "1em",
                }}
              >
                <label>Distance</label>
                <InputNumber
                  className="distance"
                  label="Distance"
                  name="distance"
                  value={rowData.distance}
                  onChange={handleChangeDistance}
                  rules={[
                    {
                      required: true,
                      message: "Please input the distance!",
                    },
                  ]}
                  min={0}
                  style={{ width: "100%" }}
                  size="large"
                />
              </Col>
              <Col
                xs={24}
                xl={12}
                style={{
                  padding: "1em",
                }}
              >
                {/* <label>Status</label>
                <Select
                  className="status"
                  label="Status"
                  onChange={handleChangeStatus}
                  rules={[
                    {
                      required: true,
                      message: "Please input Status!",
                    },
                  ]}
                  style={{ width: "100%" }}
                  size="large"
                  placeholder="Conductor Status"
                  value={rowData.status}
                >
                  <Option value="ACTIVE">Active</Option>
                  <Option value="INACTIVE">InActive</Option>
                </Select> */}
              </Col>
            </Row>
          </Form>
        </Content>
      </Modal>
    </div>
  );
}

export default RoutePage;
