export const UserTypes = {
  USER_PENDING: "USER_PENDING",
  USER_SUCCESS: "USER_SUCCESS",
  USER_FAIL: "USER_FAIL",
  CLEAR_USER: "CLEAR_USER",

  USERS_PENDING: "USERS_PENDING",
  USERS_SUCCESS: "USERS_SUCCESS",
  USERS_FAIL: "USERS_FAIL",
  CLEAR_USERS: "CLEAR_USERS",

  CREATE_USER_PENDING: "CREATE_USER_PENDING",
  CREATE_USER_SUCCESS: "CREATE_USER_SUCCESS",
  CREATE_USER_FAIL: "CREATE_USER_FAIL",
  CLEAR_CREATE_USER: "CLEAR_CREATE_USER",

  UPDATE_USER_PENDING: "UPDATE_USER_PENDING",
  UPDATE_USER_SUCCESS: "UPDATE_USER_SUCCESS",
  UPDATE_USER_FAIL: "UPDATE_USER_FAIL",
  CLEAR_UPDATE_USER: "CLEAR_UPDATE_USER",

  DELETE_USER_PENDING: "DELETE_USER_PENDING",
  DELETE_USER_SUCCESS: "DELETE_USER_SUCCESS",
  DELETE_USER_FAIL: "DELETE_USER_FAIL",
  CLEAR_DELETE_USER: "CLEAR_DELETE_USER",
};
