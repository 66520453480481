import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Input,
  Button,
  Space,
  Tabs,
  Table,
  Badge,
  Spin,
  Card,
  Col,
  Row,
  Select,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import Highlighter from "react-highlight-words";
import { dataSaved } from "../../config/key.config";
import Seat47 from "../../components/chart/Seat47";
import Seat49 from "../../components/chart/Seat49";
import { getTrip, updateTrip, getTrips } from "../../redux/trip/action";
import { LoadingOutlined } from "@ant-design/icons";
import { getBuses } from "../../redux/bus/action";
import FeedBack from "../review/FeedBack";

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 40,
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
    spin
  />
);

const { Option } = Select;

function TripDetail() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getBuses);
    dispatch(getTrips);
    dispatch(getTrip(localStorage.getItem(dataSaved)));
  }, [dispatch]);

  const [tab, setTab] = useState("1");
  const [searchText, setSearchText] = useState(false);
  const [searchedColumn, setSearchedColumn] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [filteredInfo, setFilteredInfo] = useState(null);
  const [sortedInfo, setSortedInfo] = useState(null);

  const { buses, bus } = useSelector((state) => state.bus);
  const { trip, trips, tripSuccess } = useSelector((state) => state.trip);

  const subRoutes = [];
  const tripBookings = [];

  trips.forEach((res) => {
    if (res.id == localStorage.getItem(dataSaved)) {
      const tempRoute = res.subRoute;
      const tempBooking = res.booking;

      tempRoute.forEach((r) => {
        subRoutes.push({
          key: r.id,
          id: r.id,
          departureTime: r.departureTime,
          arrivalTime: r.arrivalTime,
          price: r.price,
          distance: r.distance,
          status: r.status,
          from: r.from.name,
          to: r.to.name,
        });
      });

      tempBooking.forEach((r) => {
        tripBookings.push({
          key: r.id,
          id: r.id,
          refNumber: r.refNumber,
          phoneNumber: r.phoneNumber,
          passengers: r.passengers,
          seat: r.seat,
          status: r.status,
          pickup: r.pickup,
          dropoff: r.dropoff,
          paymentMethod: r.paymentMethod,
          conductor: `${r.conductor.firstName} ${r.conductor.lastName}`,
        });
      });
    }
  });

  const busesData = [];
  let count = 0;
  buses.forEach((bus) =>
    busesData.push({
      key: count++,
      value: bus.id,
      plateNumber: bus.plateNumber,
    })
  );

  let getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            setSearchInput(node);
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => {
            handleSearch(selectedKeys, confirm, dataIndex);
          }}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => {
              handleSearch(selectedKeys, confirm, dataIndex);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>

          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => setSearchInput(""), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  let handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  let handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
    setFilteredInfo(null);
    setSortedInfo(null);
  };

  const handleBlockSeat = (seats) => {
    const str = seats.toString();
    const formData = { blockedSeat: str };
    dispatch(updateTrip(formData, localStorage.getItem(dataSaved)));
  };

  const columnsRoute = [
    {
      title: "Id",
      dataIndex: "id",
      width: "7%",
    },
    {
      title: "Departure Time",
      dataIndex: "departureTime",
      ...getColumnSearchProps("departureTime"),
    },
    {
      title: "Arrival Time",
      dataIndex: "arrivalTime",
      ...getColumnSearchProps("arrivalTime"),
      // sorter: (a, b) => a.from.length - b.from.length,
      // sortDirections: ["descend", "ascend"],
    },
    {
      title: "Price",
      dataIndex: "price",
      ...getColumnSearchProps("price"),
    },
    {
      title: "Distance",
      dataIndex: "distance",
      ...getColumnSearchProps("distance"),
    },
    {
      title: "From",
      dataIndex: "from",
      ...getColumnSearchProps("from"),
    },
    {
      title: "To",
      dataIndex: "to",
      ...getColumnSearchProps("to"),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text, record) => {
        const active = (
          <Badge
            className="site-badge-count-109"
            count={"Active"}
            style={{ backgroundColor: "#52c41a" }}
          />
        );

        const inactive = <Badge count={"Cancelled"} />;

        const show = record.status === "ACTIVE" ? active : inactive;

        return show;
      },
    },
  ];

  const columnsBooking = [
    {
      title: "Id",
      dataIndex: "id",
    },
    {
      title: "Ref. No",
      dataIndex: "refNumber",
      ...getColumnSearchProps("departureTime"),
    },
    {
      title: "Phone",
      dataIndex: "phoneNumber",
      ...getColumnSearchProps("phoneNumber"),
      // sorter: (a, b) => a.from.length - b.from.length,
      // sortDirections: ["descend", "ascend"],
    },
    {
      title: "Passengers",
      dataIndex: "passengers",
      ...getColumnSearchProps("passengers"),
    },
    {
      title: "seat",
      dataIndex: "seat",
      ...getColumnSearchProps("seat"),
    },
    {
      title: "Pickup",
      dataIndex: "pickup",
      ...getColumnSearchProps("pickup"),
    },
    {
      title: "Drop off",
      dataIndex: "dropoff",
      ...getColumnSearchProps("dropoff"),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text, record) => {
        const active = (
          <Badge
            className="site-badge-count-109"
            count={"BOOKED"}
            style={{ backgroundColor: "#52c41a" }}
          />
        );

        const inactive = <Badge count={`${record.status}`} />;

        const show = record.status === "BOOKED" ? active : inactive;

        return show;
      },
    },
  ];

  const handleChange = (value) => {};

  return (
    <div>
      <Tabs
        defaultActiveKey="agent"
        tabPosition="top"
        onChange={(e) => setTab(e)}
      >
        <Tabs.TabPane tab="Book Detail" key="book-detail">
          {/* <div>Book Detail</div> */}
          <Table
            columns={columnsBooking}
            dataSource={tripBookings}
            rowKey="id"
            // loading={tripsLoading}
            scroll={{ x: 400 }}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Route" key="route">
          <Table
            columns={columnsRoute}
            dataSource={subRoutes}
            rowKey="id"
            // loading={tripsLoading}
            scroll={{ x: 400 }}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Structure" key="structure">
          <div>
            {tripSuccess ? (
              <Card style={{ paddingBottom: "2rem" }}>
                <Row
                  // gutter={16}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "3rem",
                  }}
                >
                  {
                    <Col span={24}>
                      {trip.trip.bus.busStructure.seater === 49 ? (
                        <Seat49
                          seats={trip.trip.blockedSeats}
                          handleBlockSeat={handleBlockSeat}
                        />
                      ) : (
                        <Seat47
                          seats={trip.trip.blockedSeats}
                          handleBlockSeat={handleBlockSeat}
                        />
                      )}
                    </Col>
                  }
                </Row>
              </Card>
            ) : (
              <Spin indicator={antIcon} />
            )}
          </div>{" "}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Bus" key="bus">
          <div>
            {tripSuccess ? (
              <Card style={{ paddingBottom: "2rem" }}>
                <Row
                  gutter={16}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "3rem",
                  }}
                >
                  <Col span={4}>
                    {/* <Card  bordered={false}> */}
                    <p>
                      Plate Number: <b>{trip.trip.bus.plateNumber}</b>
                    </p>
                    <p>
                      Side Number: <b>{trip.trip.bus.sideNumber}</b>
                    </p>
                    <p>
                      Name: <b>{trip.trip.bus.busAssociation.name}</b>
                    </p>
                    <p>
                      Phone No.: <b>{trip.trip.bus.busAssociation.phone}</b>
                    </p>
                    <p>
                      Structure: <b>{trip.trip.bus.busStructure.name}</b>
                    </p>
                    <p>
                      Status:{" "}
                      {trip.trip.bus.status ? (
                        <Badge count={"Active"} />
                      ) : (
                        <Badge count={"InActive"} />
                      )}
                    </p>
                    {/* </Card> */}
                  </Col>

                  <Col span={6}>
                    <Card
                      title={<h4>Assign another bus</h4>}
                      bordered={false}
                      style={{
                        // backgroundColor: "#EFF4F8",
                        paddingBottom: "1rem",
                      }}
                    >
                      <Select
                        placeholder={trip.trip.bus.plateNumber}
                        // defaultValue={trip.trip.bus.plateNumber}
                        style={{ width: "80%", borderRadius: "50%" }}
                        onChange={handleChange}
                      >
                        {busesData.map((option) => (
                          <Option value={option.value}>
                            {option.plateNumber}
                          </Option>
                        ))}
                      </Select>
                      <Button
                        type="primary"
                        size="large"
                        style={{ marginTop: "1rem" }}
                      >
                        Assing
                      </Button>
                    </Card>
                  </Col>
                </Row>
              </Card>
            ) : (
              <Spin indicator={antIcon} />
            )}
          </div>{" "}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Review" key="review">
          <div>
            <FeedBack />
          </div>{" "}
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
}

export default TripDetail;
