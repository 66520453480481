import React, { useState, useEffect } from "react";
import { Typography, Row, Col, Collapse } from "antd";
import { useDispatch, useSelector } from "react-redux";
import SeatIcon from "../../assets/logo/seatIcon.jpg";
import SteeringWheel from "../../assets/logo/steering.png";
import {
  BusStructureTwo,
  BusStructureFour,
  BusStructureFive,
} from "./BusStructureComponent";
import { getBusStructures } from "../../redux/busStructure/action";
const { Title } = Typography;
const { Panel } = Collapse;

const style = {
  background: "#fff",
};

function BusStruture() {
  // const [bookedBlockedSeats, setBookedBlockedSeats] = useState();

  const dispatch = useDispatch();

  // const {
  //   error,
  //   busStructure,
  //   busStructures,
  //   busStructuresLoading,
  //   busStructuresSuccess,
  //   busStructuresFail,
  // } = useSelector((state) => state.busStructure);
  useEffect(() => {
    dispatch(getBusStructures);
  }, [dispatch]);

  return (
    <div>
      <Row level>
        <Col
          span={24}
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "2rem",
          }}
        >
          <Title>Bus Structure</Title>
        </Col>
      </Row>

      <Row level>
        <Col span={18} offset={2}>
          <Collapse accordion>
            <Panel header="47 seats" key="1">
              <div style={{ display: "flex", justifyContent: "center" }}>
                {seat47}
              </div>
            </Panel>
            <Panel header="49 seats" key="2">
              <div style={{ display: "flex", justifyContent: "center" }}>
                {seat49}
              </div>
            </Panel>
          </Collapse>
        </Col>
      </Row>
    </div>
  );
}

const seat47 = (
  <div style={{ width: "35%" }}>
    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <Col className="gutter-row" span={4}>
        <div style={style}><img src={SteeringWheel} alt="" /></div>
      </Col>
      <Col className="gutter-row" span={4}>
        <div style={style}></div>
      </Col>
      <Col className="gutter-row" span={4}>
        <div></div>
      </Col>
      <Col className="gutter-row" span={4}>
        <div style={style}></div>
      </Col>
      <Col className="gutter-row" span={4}>
        <div style={style}>          
        </div>
      </Col>
    </Row>
    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <BusStructureFour />
    </Row>
    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <BusStructureFour />
    </Row>
    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <BusStructureFour />
    </Row>
    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <BusStructureFour />
    </Row>
    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <BusStructureFour />
    </Row>
    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <BusStructureTwo />
    </Row>
    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <BusStructureTwo />
    </Row>
    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <BusStructureTwo />
    </Row>
    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <BusStructureFour />
    </Row>
    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <BusStructureFour />
    </Row>
    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <BusStructureFour />
    </Row>
    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <BusStructureFour />
    </Row>
    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <BusStructureFive />
    </Row>
  </div>
);
const seat49 = (
  <div style={{ width: "35%" }}>
    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <Col className="gutter-row" span={4}>
        <div style={style}><img src={SteeringWheel} alt="" /></div>
      </Col>
      <Col className="gutter-row" span={4}>
        <div style={style}></div>
      </Col>
      <Col className="gutter-row" span={4}>
        <div></div>
      </Col>
      <Col className="gutter-row" span={4}>
        <div style={style}></div>
      </Col>
      <Col className="gutter-row" span={4}>
        <div style={style}>          
        </div>
      </Col>
    </Row>
    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <BusStructureFour />
    </Row>
    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <BusStructureFour />
    </Row>
    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <BusStructureFour />
    </Row>
    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <BusStructureFour />
    </Row>
    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <BusStructureFour />
    </Row>
    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <BusStructureTwo />
    </Row>    
    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <BusStructureTwo />
    </Row>
    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <BusStructureFour />
    </Row>
    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <BusStructureFour />
    </Row>
    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <BusStructureFour />
    </Row>
    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <BusStructureFour />
    </Row>
    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <BusStructureFour />
    </Row>
    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <BusStructureFive />
    </Row>
  </div>
);

export default BusStruture;
