import { ConductorTypes } from "./types";

const INITIAL_STATE = {
  error: null,
  conductor: null,
  conductors: [],

  conductorLoading: false,
  conductorSuccess: false,
  conductorFail: false,

  conductorsLoading: false,
  conductorsSuccess: false,
  conductorsFail: false,

  createConductorLoading: false,
  createConductorSuccess: false,
  createConductorFail: false,
  
  updateConductorLoading: false,
  updateConductorSuccess: false,
  upateConductorFail: false,
  
  deleteConductorLoading: false,
  deleteConductorSuccess: false,
  deleteConductorFail: false,

  resetConductorLoading: false,
  resetConductorSuccess: false,
  resetConductorFail: false,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ConductorTypes.CONDUCTORS_PENDING:
      return {
        ...state,
        conductorsLoading: true,
      };
    case ConductorTypes.CONDUCTORS_FAIL:
      return {
        ...state,
        error: action.payload.error,
        conductorsLoading: false,
        conductorsSuccess: false,
        conductorsFail: true,
      };
    case ConductorTypes.CONDUCTORS_SUCCESS:
      return {
        ...state,
        conductorsLoading: false,
        conductorsSuccess: true,
        conductorsFail: false,
        conductors: action.payload.conductors,
      };

    case ConductorTypes.CLEAR_CONDUCTORS:
      return {
        ...state,
        conductorsLoading: false,
        conductorsSuccess: false,
        conductorsFail: false,
      };

    case ConductorTypes.CONDUCTOR_PENDING:
      return {
        ...state,
        conductorLoading: true,
      };
    case ConductorTypes.CONDUCTOR_FAIL:
      return {
        ...state,
        conductorLoading: false,
        conductorSuccess: false,
        conductorFail: true,
        error: action.payload.error,
      };
    case ConductorTypes.CONDUCTOR_SUCCESS:
      return {
        ...state,
        conductorLoading: false,
        conductorSuccess: true,
        conductorFail: false,
        conductor: {
          ...state.conductor,
          [action.payload.id]: action.payload.conductor,
        },
      };
    case ConductorTypes.CLEAR_CONDUCTOR:
      return {
        ...state,
        updateConductorLoading: false,
        updateConductorSuccess: false,
        upateConductorFail: false,
      };

    case ConductorTypes.CREATE_CONDUCTOR_PENDING:
      return {
        ...state,
        createConductorLoading: true,
      };
    case ConductorTypes.CREATE_CONDUCTOR_SUCCESS:
      return {
        ...state,
        createConductorSuccess: true,
        createConductorLoading: false,
        conductors: [action.payload.conductor, ...state.conductors],
      };

    case ConductorTypes.CREATE_CONDUCTOR_FAIL:
      return {
        ...state,
        createConductorFail: true,
        createConductorSuccess: false,
        createConductorLoading: false,
        error: action.payload.error,
      };

    case ConductorTypes.CLEAR_CREATE_CONDUCTOR:
      return {
        ...state,
        updateConductorLoading: false,
        updateConductorSuccess: false,
        upateConductorFail: false,
      };

    case ConductorTypes.UPDATE_CONDUCTOR_PENDING:
      return {
        ...state,
        updateConductorLoading: true,
      };

    case ConductorTypes.UPDATE_CONDUCTOR_SUCCESS:
      return {
        ...state,
        updateConductorLoading: false,
        updateConductorSuccess: true,
        upateConductorFail: false,
        conductor: {
          ...state.conductor,
          [action.payload.id]: action.payload.conductor,
        },
      };
    case ConductorTypes.UPDATE_CONDUCTOR_FAIL:
      return {
        ...state,
        updateConductorLoading: false,
        updateConductorSuccess: false,
        upateConductorFail: true,
        error: action.payload.error,
      };
    case ConductorTypes.CLEAR_UPDATE_CONDUCTOR:
      return {
        ...state,
        updateConductorLoading: false,
        updateConductorSuccess: false,
        upateConductorFail: false,
      };

    case ConductorTypes.DELETE_CONDUCTOR_PENDING:
      return {
        ...state,
        deleteConductorLoading: true,
      };
    case ConductorTypes.DELETE_CONDUCTOR_SUCCESS:
      return {
        ...state,
        deleteConductorLoading: false,
        deleteConductorSuccess: true,
        bus: {},
      };
    case ConductorTypes.DELETE_CONDUCTOR_FAIL:
      return {
        ...state,
        deleteConductorLoading: false,
        deleteConductorSuccess: false,
        deleteConductorFail: true,
        error: action.payload.error,
      };
    case ConductorTypes.CLEAR_DELETE_CONDUCTOR:
      return {
        ...state,
        deleteConductorLoading: false,
        deleteConductorSuccess: false,
        deleteConductorFail: false,
      };
    case ConductorTypes.RESET_CONDUCTOR_PENDING:
      return {
        ...state,
        resetConductorLoading: true,
      };
    case ConductorTypes.RESET_CONDUCTOR_SUCCESS:
      return {
        ...state,
        resetConductorSuccess: true,
        resetConductorLoading: false,
        conductors: [action.payload.conductor, ...state.conductors],
      };

    case ConductorTypes.RESET_CONDUCTOR_FAIL:
      return {
        ...state,
        resetConductorFail: true,
        resetConductorSuccess: false,
        resetConductorLoading: false,
        error: action.payload.error,
      };

    default:
      return state;
  }
};

export default reducer;
