import { GET_REVIEWS, GET_RATES, ERROR_REVIEW } from "./types";

const initialState = {
  reviews: [
    {
      id: "1",
      name: "Selam Desalegn",
      rateValue: "4.5",
      comment:
        "We took the bus service from Vienna to Prague as well as \
              from Prague to Berlin. Bus service was excellent. Customer \
              service was great. Buses were clean and comfy. Free hot \
              beverages were very good as well. Really enjoyed their \
              great service.",
      img: "https://images.pexels.com/photos/11073224/pexels-photo-11073224.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    },
    {
      id: "2",
      name: "Kebede Desta",
      rateValue: "4.5",
      comment:
        "I've taken Public Bus twice now to visit my family \
              in Assela and it's been a great trip every time. Staff \
              are friendly and professional, you get a bottle of \
              water before you board, and the busses were clean \
              and comfortable. There is also WiFi on board. \
              Also I love the pick up options in the DMV area, \
              there's one 15 minutes from where I currently \
              live. Thanks!",
      img: "https://images.unsplash.com/photo-1635209098484-c337faa8f429?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80",
    },
    {
      id: "3",
      name: "Alemayew Desta",
      rateValue: "5",
      comment:
        "Outstanding customer service and comfortable \
              trip from Addis Ababa to Assosa. Service with a \
              timely manner and I recommend my family and \
              friends to use the Public Bus service for travel.",
    },
    {
      id: "4",
      name: "Fekede Melaku",
      rateValue: "1.5",
      comment:
        "The best bus would have to be the most courteous and \
              considerate bus line that I have had the greatest \
              fortune to ride. They are quite punctual as well \
              as the staff Bree, polite and thoughtful. Bree \
              informed me prices of tickets which are far \
              more reasonable then that of Greyhound, Peter \
              pan, and any other umbrella bus corporation \
              that travels from City to City.",
    },
    {
      id: "5",
      name: "Murad Jemale",
      rateValue: "3",
      comment:
        "Bestbus is my favorite- they always \
              leave on time and buses are clean. \
              I’ve done 40 trips probably!",
    },
    {
      id: "6",
      name: "Fenet Hailu",
      rateValue: "4.5",
      comment:
        "I enjoy the service the company provide \
              you’re guess are the best i cant wait til \
              the next trip!!!!! #PUBLICBUS",
    },
  ],
  staffRates: {
    totalPositiveRate: 371,
    totalNegativeRate: 69,
    percentPositive: 15,
    percentNegative: 3,
    yearlyReport: [
      {
        name: "Positive Review",
        month: "Jan.",
        value: 30.9,
      },
      {
        name: "Positive Review",
        month: "Feb.",
        value: 55.8,
      },
      {
        name: "Positive Review",
        month: "Mar.",
        value: 60.3,
      },
      {
        name: "Positive Review",
        month: "Apr.",
        value: 53.4,
      },
      {
        name: "Positive Review",
        month: "May",
        value: 70,
      },
      {
        name: "Positive Review",
        month: "Jun.",
        value: 83.3,
      },
      {
        name: "Positive Review",
        month: "Jul.",
        value: 68,
      },
      {
        name: "Positive Review",
        month: "Aug.",
        value: 69.6,
      },
      {
        name: "Negative Review",
        month: "Jan.",
        value: 6.4,
      },
      {
        name: "Negative Review",
        month: "Feb.",
        value: 15.2,
      },
      {
        name: "Negative Review",
        month: "Mar.",
        value: 20.5,
      },
      {
        name: "Negative Review",
        month: "Apr.",
        value: 25.7,
      },
      {
        name: "Negative Review",
        month: "May",
        value: 30.6,
      },
      {
        name: "Negative Review",
        month: "Jun.",
        value: 20.5,
      },
      {
        name: "Negative Review",
        month: "Jul.",
        value: 33.4,
      },
      {
        name: "Negative Review",
        month: "Aug.",
        value: 35.4,
      },
    ],
  },
  cleanRates: {
    totalPositiveRate: 147,
    totalNegativeRate: 20,
    percentPositive: 7,
    percentNegative: 1,
    yearlyReport: [
      {
        name: "Positive Review",
        month: "Jan.",
        value: 60.9,
      },
      {
        name: "Positive Review",
        month: "Feb.",
        value: 25.8,
      },
      {
        name: "Positive Review",
        month: "Mar.",
        value: 90.3,
      },
      {
        name: "Positive Review",
        month: "Apr.",
        value: 73.4,
      },
      {
        name: "Positive Review",
        month: "May",
        value: 40,
      },
      {
        name: "Positive Review",
        month: "Jun.",
        value: 33.3,
      },
      {
        name: "Positive Review",
        month: "Jul.",
        value: 98,
      },
      {
        name: "Positive Review",
        month: "Aug.",
        value: 69.6,
      },
      {
        name: "Negative Review",
        month: "Jan.",
        value: 10.4,
      },
      {
        name: "Negative Review",
        month: "Feb.",
        value: 15.2,
      },
      {
        name: "Negative Review",
        month: "Mar.",
        value: 30.5,
      },
      {
        name: "Negative Review",
        month: "Apr.",
        value: 15.7,
      },
      {
        name: "Negative Review",
        month: "May",
        value: 10.6,
      },
      {
        name: "Negative Review",
        month: "Jun.",
        value: 20.5,
      },
      {
        name: "Negative Review",
        month: "Jul.",
        value: 43.4,
      },
      {
        name: "Negative Review",
        month: "Aug.",
        value: 65.4,
      },
    ],
  },
  drivingRates: {
    totalPositiveRate: 599,
    totalNegativeRate: 88,
    percentPositive: 31,
    percentNegative: 18,
    yearlyReport: [
      {
        name: "Positive Review",
        month: "Jan.",
        value: 20.9,
      },
      {
        name: "Positive Review",
        month: "Feb.",
        value: 35.8,
      },
      {
        name: "Positive Review",
        month: "Mar.",
        value: 40.3,
      },
      {
        name: "Positive Review",
        month: "Apr.",
        value: 53.4,
      },
      {
        name: "Positive Review",
        month: "May",
        value: 60,
      },
      {
        name: "Positive Review",
        month: "Jun.",
        value: 70.3,
      },
      {
        name: "Positive Review",
        month: "Jul.",
        value: 70,
      },
      {
        name: "Positive Review",
        month: "Aug.",
        value: 88.6,
      },
      {
        name: "Negative Review",
        month: "Jan.",
        value: 6.4,
      },
      {
        name: "Negative Review",
        month: "Feb.",
        value: 15.2,
      },
      {
        name: "Negative Review",
        month: "Mar.",
        value: 40.5,
      },
      {
        name: "Negative Review",
        month: "Apr.",
        value: 33.7,
      },
      {
        name: "Negative Review",
        month: "May",
        value: 30.6,
      },
      {
        name: "Negative Review",
        month: "Jun.",
        value: 20.5,
      },
      {
        name: "Negative Review",
        month: "Jul.",
        value: 15.4,
      },
      {
        name: "Negative Review",
        month: "Aug.",
        value: 7.4,
      },
    ],
  },
  serviceRates: {
    totalPositiveRate: 41,
    totalNegativeRate: 73,
    percentPositive: 9,
    percentNegative: 11,
    yearlyReport: [
      {
        name: "Positive Review",
        month: "Jan.",
        value: 30.9,
      },
      {
        name: "Positive Review",
        month: "Feb.",
        value: 70.8,
      },
      {
        name: "Positive Review",
        month: "Mar.",
        value: 40.3,
      },
      {
        name: "Positive Review",
        month: "Apr.",
        value: 73.4,
      },
      {
        name: "Positive Review",
        month: "May",
        value: 40,
      },
      {
        name: "Positive Review",
        month: "Jun.",
        value: 63.3,
      },
      {
        name: "Positive Review",
        month: "Jul.",
        value: 98,
      },
      {
        name: "Positive Review",
        month: "Aug.",
        value: 29.6,
      },
      {
        name: "Negative Review",
        month: "Jan.",
        value: 86.4,
      },
      {
        name: "Negative Review",
        month: "Feb.",
        value: 45.2,
      },
      {
        name: "Negative Review",
        month: "Mar.",
        value: 20.5,
      },
      {
        name: "Negative Review",
        month: "Apr.",
        value: 25.7,
      },
      {
        name: "Negative Review",
        month: "May",
        value: 10.6,
      },
      {
        name: "Negative Review",
        month: "Jun.",
        value: 40.5,
      },
      {
        name: "Negative Review",
        month: "Jul.",
        value: 32.4,
      },
      {
        name: "Negative Review",
        month: "Aug.",
        value: 70.4,
      },
    ],
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_REVIEWS:
      return {
        ...state,
        // reviews: action.payload
      };
    case GET_RATES:
      return {
        ...state,
        // rates: action.payload
      };
    case ERROR_REVIEW:
      return state;
    default:
      return state;
  }
}
