import { HumanResourceTypes } from "./types";

const INITIAL_STATE = {
  error: null,
  humanResource: {},
  humanResources: [],

  humanResourceLoading: false,
  humanResourceSuccess: false,
  humanResourceFail: false,

  humanResourcesLoading: false,
  humanResourcesSuccess: false,
  humanResourcesFail: false,

  createHumanResourceLoading: false,
  createHumanResourceSuccess: false,
  createHumanResourceFail: false,

  updateHumanResourceLoading: false,
  updateHumanResourceSuccess: false,
  updateHumanResourceFail: false,

  deleteHumanResourceLoading: false,
  deleteHumanResourceSuccess: false,
  deleteHumanResourceFail: false,

  assignBusLoading: false,
  assignBusSuccess: false,
  assignBusFail: false,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case HumanResourceTypes.HUMAN_RESOURCE_PENDING:
      return {
        ...state,
        humanResourceLoading: true,
      };
    case HumanResourceTypes.HUMAN_RESOURCE_SUCCESS:
      return {
        ...state,
        humanResourceSuccess: true,
        humanResourceLoading: false,
        humanResource: {
          humanResource: action.payload.humanResource,
        },
      };
    case HumanResourceTypes.HUMAN_RESOURCE_FAIL:
      return {
        ...state,
        humanResourceLoading: false,
        humanResourceSuccess: false,
        error: action.payload.error,
      };
    case HumanResourceTypes.CLEAR_HUMAN_RESOURCE:
      return {
        ...state,
        humanResourceLoading: false,
        humanResourceSuccess: false,
        humanResourceFail: false,
      };

    case HumanResourceTypes.HUMAN_RESOURCES_PENDING:
      return {
        ...state,
        humanResourcesLoading: true,
      };
    case HumanResourceTypes.HUMAN_RESOURCES_SUCCESS:
      return {
        ...state,
        humanResourcesSuccess: true,
        humanResourcesLoading: false,
        humanResources: action.payload.humanResources,
      };
    case HumanResourceTypes.HUMAN_RESOURCES_FAIL:
      return {
        ...state,
        humanResourcesLoading: false,
        humanResourcesSuccess: false,
        error: action.payload.error,
      };
    case HumanResourceTypes.CLEAR_HUMAN_RESOURCES:
      return {
        ...state,
        humanResourcesSuccess: false,
        humanResourcesLoading: false,
        humanResourcesFail: false,
      };

    case HumanResourceTypes.CREATE_HUMAN_RESOURCE_PENDING:
      return {
        ...state,
        createHumanResourceLoading: true,
      };
    case HumanResourceTypes.CREATE_HUMAN_RESOURCE_SUCCESS:
      return {
        ...state,
        createHumanResourceSuccess: true,
        createHumanResourceLoading: false,
        humanResources: [action.payload.humanResource, ...state.humanResources],
      };
    case HumanResourceTypes.CREATE_HUMAN_RESOURCE_FAIL:
      return {
        ...state,
        createHumanResourceLoading: false,
        createHumanResourceFail: true,
        createHumanResourceSuccess: false,
        error: action.payload.error,
      };
    case HumanResourceTypes.CREATE_CLEAR_HUMAN_RESOURCE:
      return {
        ...state,
        createHumanResourceSuccess: false,
        createHumanResourceLoading: false,
        createHumanResourceFail: false,
      };

    case HumanResourceTypes.UPDATE_HUMAN_RESOURCE_PENDING:
      return {
        ...state,
        updateHumanResourceLoading: true,
      };
    case HumanResourceTypes.UPDATE_HUMAN_RESOURCE_SUCCESS:
      return {
        ...state,
        updateHumanResourceSuccess: true,
        updateHumanResourceLoading: false,
        humanResource: {
          ...state.humanResource,
          [action.payload.id]: action.payload.humanResource,
        },
      };
    case HumanResourceTypes.UPDATE_HUMAN_RESOURCE_FAIL:
      return {
        ...state,
        updateHumanResourceLoading: false,
        updateHumanResourceSuccess: false,
        upateHumanResourceFail: true,
        error: action.payload.error,
      };
    case HumanResourceTypes.UPDATE_CLEAR_HUMAN_RESOURCE:
      return {
        ...state,
        updateHumanResourceSuccess: false,
        updateHumanResourceLoading: false,
        updateHumanResourceFail: false,
      };

      case HumanResourceTypes.DELETE_HUMAN_RESOURCE_PENDING:
        return {
          ...state,
          deleteHumanResourceLoading: false,
        };
      case HumanResourceTypes.DELETE_HUMAN_RESOURCE_SUCCESS:
        return {
          ...state,
          deleteHumanResourceLoading: false,
          deleteHumanResourceSuccess: true,
          HumanResource: {},
        };
      case HumanResourceTypes.DELETE_HUMAN_RESOURCE_FAIL:
        return {
          ...state,
          deleteHumanResourceLoading: false,
          deleteHumanResourceSuccess: false,
          error: action.payload.error,
        };
      case HumanResourceTypes.DELETE_CLEAR_HUMAN_RESOURCE:
      return {
        ...state,
        deleteHumanResourceSuccess: false,
        deleteHumanResourceLoading: false,
        deleteHumanResourceFail: false,
      };

    case HumanResourceTypes.ASSIGN_BUS_HUMAN_RESOURCE_PENDING:
      return {
        ...state,
        assignBusLoading: true,
      };
    case HumanResourceTypes.ASSIGN_BUS_HUMAN_RESOURCE_SUCCESS:
      return {
        ...state,
        assignBusSuccess: true,
        assignBusLoading: false,
        humanResource: {
          ...state.humanResource,
          [action.payload.id]: action.payload.humanResource,
        },
      };
    case HumanResourceTypes.ASSIGN_BUS_HUMAN_RESOURCE_FAIL:
      return {
        ...state,
        assignBusLoading: false,
        assignBusSuccess: false,
        assignBusFail: true,
        error: action.payload.error,
      };
    case HumanResourceTypes.CLEAR_ASSIGN_BUS_HUMAN_RESOURCE:
      return {
        ...state,
        assignBusSuccess: false,
        assignBusLoading: false,
        assignBusFail: false,
      };

    default:
      return state;
  }
};

export default reducer;
