import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Table,
  Space,
  Divider,
  Typography,
  Input,
} from "antd";
import Highlighter from "react-highlight-words";
import { useDispatch, useSelector } from "react-redux";
import {
  EditOutlined,
  DeleteOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import BOOK from "../../_mocks_/bookingDetail";
const { Title } = Typography;

const BookingDetail = () => {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState(false);
  const [searchedColumn, setSearchedColumn] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [filteredInfo, setFilteredInfo] = useState(null);
  const [sortedInfo, setSortedInfo] = useState(null);

  const { ticket, ticketSuccess } = useSelector((state) => state.ticket);  

  let getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            setSearchInput(node);
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>

          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => setSearchInput(""), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  let handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys);
    setSearchedColumn(dataIndex);
  };

  let handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
    setFilteredInfo(null);
    setSortedInfo(null);
  };

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Seat Name",
      dataIndex: "seatName",
      key: "seatName",
      ...getColumnSearchProps("firstName"),
    },
    {
      title: "Adult",
      dataIndex: "adult",
      key: "adult",
      ...getColumnSearchProps("lastName"),
    },
    {
      title: "Child",
      dataIndex: "child",
      key: "child",
      ...getColumnSearchProps("phoneNo"),
    },
    {
      title: "Special",
      dataIndex: "special",
      key: "special",
      ...getColumnSearchProps("phoneNo"),
    },
    {
      title: "Package",
      dataIndex: "package",
      key: "package",
      ...getColumnSearchProps("phoneNo"),
    },
    {
      title: "Total Amount",
      dataIndex: "amount",
      key: "amount",
      ...getColumnSearchProps("phoneNo"),
    },

    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Button
            style={{ background: "#eba134", borderColor: "#eba134" }}
            type="primary"
            shape="round"
            icon={<EditOutlined />}
            size="small"
            // onClick={showEditModal}
          />
          <Button
            style={{ background: "#db0925", borderColor: "#db0925" }}
            shape="round"
            type="primary"
            icon={<DeleteOutlined />}
            size="small"
            // onClick={showDeleteModal}
          />
        </Space>
      ),
    },
  ];
  return (
    <div className="tabled">
      <Row gutter={[24, 0]}>
        <Col span={24}>
          <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title="Booking Detail"
            style={{
              padding: "2em 2em 2em 2em",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <Row
                style={{
                  padding: "2em 2em 2em 2em",
                  width: "100%",
                }}
              >
                <Col xs={{ span: 8 }} lg={{ span: 8 }}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Title level={5}>
                      Pickup Location:{" "}
                      {ticketSuccess ? ticket.pickup : "Loading..."}
                      {/* {booking.id} */}
                    </Title>
                  </div>
                </Col>
                <Col xs={{ span: 8 }} lg={{ span: 8 }}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Title level={5}>
                      Drop Location:{" "}
                      {ticketSuccess ? ticket.dropoff : "Loading..."}
                    </Title>
                  </div>
                </Col>
                <Col xs={{ span: 8 }} lg={{ span: 8 }}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Title level={5}>
                      Date:{" "}
                      {ticketSuccess
                        ? ticket.firstSeatReserved
                        : "Loading..."}
                    </Title>
                  </div>
                </Col>
              </Row>
              <Divider />
              <Row
                style={{
                  padding: "2em 2em 2em 2em",
                  width: "100%",
                }}
              >
                <Col
                  xs={{ span: 8 }}
                  lg={{ span: 8 }}
                  style={{ border: "1px solid", padding: ".25rem" }}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Title level={5}>
                      Passenger Name :{" "}
                      {ticketSuccess ? ticket.passengers : "Loading..."}
                    </Title>
                  </div>
                </Col>
                <Col
                  xs={{ span: 8 }}
                  lg={{ span: 8 }}
                  style={{ border: "1px solid", padding: ".25rem" }}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Title level={5}>
                      Phone:{" "}
                      {ticketSuccess ? ticket.phoneNumber : "Loading..."}
                    </Title>
                  </div>
                </Col>
                <Col
                  xs={{ span: 8 }}
                  lg={{ span: 8 }}
                  style={{ border: "1px solid", padding: ".25rem" }}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Title level={5}>
                      Passenger ID.:{" "}
                      {ticketSuccess ? ticket.refNumber : "Loading..."}
                    </Title>
                  </div>
                </Col>
                <Col
                  xs={{ span: 8 }}
                  lg={{ span: 8 }}
                  style={{ border: "1px solid", padding: ".25rem" }}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Title level={5}>Facilities: Snacks, Free wifi</Title>
                  </div>
                </Col>
                <Col
                  xs={{ span: 8 }}
                  lg={{ span: 8 }}
                  style={{ border: "1px solid", padding: ".25rem" }}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Title level={5}>
                      Booking ID. : {ticketSuccess ? ticket.id : "Loading..."}
                    </Title>
                  </div>
                </Col>
                <Col
                  xs={{ span: 8 }}
                  lg={{ span: 8 }}
                  style={{ border: "1px solid", padding: ".25rem" }}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Title level={5}>
                      Route Name :{" "}
                      {ticketSuccess ? ticket.routeName : "Loading..."}
                    </Title>
                  </div>
                </Col>
                <Col
                  xs={{ span: 8 }}
                  lg={{ span: 8 }}
                  style={{ border: "1px solid", padding: ".25rem" }}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Title level={5}>
                      Trip ID. :{" "}
                      {ticketSuccess ? ticket.routeId : "Loading..."}
                    </Title>
                  </div>
                </Col>
                <Col
                  xs={{ span: 8 }}
                  lg={{ span: 8 }}
                  style={{ border: "1px solid", padding: ".25rem" }}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Title level={5}>
                      Ticket Status :{" "}
                      {ticketSuccess ? ticket.status : "Loading..."}
                    </Title>
                  </div>
                </Col>
              </Row>

              <Divider />
              <Row
                style={{
                  padding: "2.5em 2em 0em 2em",
                  width: "100%",
                }}
              >
                <Table
                  style={{ width: "100%" }}
                  dataSource={BOOK}
                  columns={columns}
                  scroll={{ x: 400 }}
                />
              </Row>
              <Row
                style={{
                  padding: "0em 2em 0em 2em",
                  width: "100%",
                }}
              >
                <Col xs={{ span: 8 }} lg={{ span: 8 }}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Title level={5}>Discount: 0</Title>
                  </div>
                </Col>
                <Col xs={{ span: 8 }} lg={{ span: 8 }}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Title level={5}>Grand Total: 1545</Title>
                  </div>  
                </Col>
              </Row>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default BookingDetail;
