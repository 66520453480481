import { useDispatch } from "react-redux"
import { Layout, Row, Col, Button, Card, Form, Input } from "antd";
import { addAccount } from "../../redux/account/action";

const { Content } = Layout;

function NewAccount() {
  const dispatch = useDispatch();

  const onFinish = (values) => {    
    const {bankName, accountName, accountNumber} = values;
    const formData ={
      id: "10",
      bankName,
      accountName,
      accountNumber,
      status: "ACTIVE"
    }
    dispatch(addAccount(formData))
  };

  const onFinishFailed = (errorInfo) => {
  };  

  return (
    <div className="tabled">
      <Row gutter={[24, 0]}>
        <Col xs="24" xl={24}>
          <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title="Register new conductors for bublic bus"
          >
            <div
              style={{
                display: "flex",
                padding: "2em 2em 2em 2em",
                width: "100%",
              }}
            >
              <Content className="p-0">
                <Form
                  name="basic"
                  initialValues={{ remember: true }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  layout="vertical"
                  className="row-col"
                >
                  <Row>
                    <Col
                      xs="8"
                      xl={8}
                      style={{
                        padding: "1em",
                      }}
                    >
                      <Form.Item
                        className="bankName"
                        label="Bank Name"
                        name="bankName"
                        rules={[
                          {
                            required: true,
                            message: "Please input bank name!",
                          },
                        ]}
                      >
                        <Input placeholder="Bank Name..." />
                      </Form.Item>
                    </Col>
                    <Col
                      xs="8"
                      xl={8}
                      style={{
                        padding: "1em",
                      }}
                    >
                      <Form.Item
                        className="accountName"
                        label="Account Name"
                        name="accountName"
                        rules={[
                          {
                            required: true,
                            message: "Please input Account Name!",
                          },
                        ]}
                      >
                        <Input placeholder="Account name..." />
                      </Form.Item>
                    </Col>
                    <Col
                      xs="8"
                      xl={8}
                      style={{
                        padding: "1em",
                      }}
                    >
                      <Form.Item
                        className="accountNumber"
                        label="Account Number"
                        name="accountNumber"
                        rules={[
                          {
                            required: true,
                            message: "Please input Account Number!",
                          },
                        ]}
                      >
                        <Input placeholder="Account Number..." />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Form.Item>
                    <div
                      style={{
                        paddingTop: 30,
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        type="primary"
                        htmlType="submit"
                        style={{
                          width: "20%",
                        }}                        
                      >
                        Register
                      </Button>
                    </div>
                  </Form.Item>
                </Form>
              </Content>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default NewAccount;
