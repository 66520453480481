import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  Row,
  Col,
  Card,
  Table,
  Input,
  Button,
  Space,
  Select,
  Badge,
  Modal,
  Form,
  Layout,
  DatePicker,
  notification,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import Highlighter from "react-highlight-words";
import { Link, useHistory } from "react-router-dom";
import {
  SearchOutlined,
  EditOutlined,
  DeleteOutlined,
  SwapOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { dataSaved } from "../../config/key.config";
import {
  getTrips,
  getTripsByRoute,
  getTripsByDestinations,
  getTripsByBus,
  updateTrip,
  deleteTrip,
  getTripsByDate,
} from "../../redux/trip/action";
import { getCities } from "../../redux/city/action";
import { getRoutes, getRoutesByStatus } from "../../redux/route/action";
import { getBuses } from "../../redux/bus/action";
const { Option } = Select;
const { Content } = Layout;

function disabledDate(current) {
  return current && current > moment().endOf("day");
}

function Trips() {
  const [searchText, setSearchText] = useState(false);
  const [searchedColumn, setSearchedColumn] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [filteredInfo, setFilteredInfo] = useState(null);
  const [sortedInfo, setSortedInfo] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
  const [isEditModalVisible, setEditModalVisible] = useState(false);

  const [currId, setCurrId] = useState("");
  const [rowData, setRowData] = useState("");

  const history = useHistory();
  const dispatch = useDispatch();
  const {
    trip,
    trips,
    tripsLoading,
    tripsSuccess,
    deleteTripLoading,
    updateTripLoading,
  } = useSelector((state) => state.trip);

  useEffect(() => {
    dispatch(getTrips);
    dispatch(getBuses);
    dispatch(getCities);
    dispatch(getRoutes);
    dispatch(getRoutesByStatus("ACTIVE"));
  }, [dispatch, deleteTripLoading, updateTripLoading]);
  function insert(array, index) {
    const items = Array.prototype.slice.call(arguments, 2);

    return [].concat(array.slice(0, index), items, array.slice(index));
  }
  const { citys } = useSelector((state) => state.city);
  const cities = insert(citys, 0, { id: 0, name: "All" });
  const { buses, bus } = useSelector((state) => state.bus);
  const busess = insert(buses, 0, { id: 0, plateNumber: "All" });
  const { routes, routesStatus } = useSelector((state) => state.route);
  const routess = insert(routes, 0, { id: 0, from: "All" });
  const busesData = [];

  const routesData = [];
  let count = 0;
  routesStatus.forEach((route) =>
    routesData.push({
      key: count++,
      value: route.id,
      routeName: `${route.from} - ${route.to}`,
    })
  );

  const tripData = [];
  trips.forEach((trip) =>
    tripData.push({
      key: trip.id,
      id: trip.id,
      date: trip.travelDate,
      destination: `${trip.from} - ${trip.to}`,
      status: trip.status,
    })
  );
  let getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            setSearchInput(node);
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => {
            handleSearch(selectedKeys, confirm, dataIndex);
          }}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => {
              handleSearch(selectedKeys, confirm, dataIndex);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>

          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => setSearchInput(""), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleChange = (e) => {
    setRowData({ ...rowData, [e.target.name]: e.target.value });
  };

  const handleOk = () => {
    setIsModalVisible(false);
    setCurrId("");
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setCurrId(""); //
  };

  const handleDelete = (id) => {
    dispatch(deleteTrip(id));
    dispatch(getTrips);

    dispatch(getTrips);
    setDeleteModalVisible(false);
  };

  let dateData;
  const handleDate = (date, dateString) => {
    dateData = dateString;
  };

  const onFinish = (values) => {
    const date = dateData;
    const { from, to, route, bus } = values;
    if (route && route !== 0) {
      dispatch(getTripsByRoute(route));
    } else if (bus && bus !== 0) {
      dispatch(getTripsByBus(bus));
    } else if (date) {
      dispatch(getTripsByDate(date));
    } else if (from && to) {
      dispatch(getTripsByDestinations(from, to));
    } else {
      dispatch(getTrips);
    }
  };
  const handleEditModalCancel = () => {
    setEditModalVisible(false);
    setCurrId("");
  };
  const onFinishFailed = (errorInfo) => {};

  const showDeleteModal = () => {
    setDeleteModalVisible(true);
  };

  const handleDeleteModalCancel = () => {
    setDeleteModalVisible(false);
    setCurrId("");
  };

  const showEditModal = (record) => {
    setRowData(record);
    setEditModalVisible(true);
  };

  const openNotification = (placement, message) => {
    notification.error({
      message: message,
      description: ``,
      placement,
    });
  };

  let statusData = null;
  function handleChangeStatus(value) {
    statusData = value;
  }

  let routeValue = null;
  const onChangeRoute = (value) => {
    routeValue = value;
  };

  let busValue = null;
  const onChangeBus = (value) => {
    busValue = value;
  };

  let dateValue = null;
  const onChangeDate = (date, dateString) => {
    dateValue = dateString;
  };

  const showTripDetail = (id) => {
    localStorage.setItem(dataSaved, id);
    history.push(`/director/trip-detail`);
  };

  const handleUpdate = () => {
    let defaultStatus = rowData.status;
    let defaultDate = rowData.date;
    let defaultBus = rowData.busId;
    let defaultRoute = rowData.routeId;

    let status;
    if (statusData !== null) {
      status = statusData;
    } else {
      status = defaultStatus;
    }

    let date;
    if (dateValue !== null) {
      date = dateValue;
    } else {
      date = defaultDate;
    }

    let bus;
    if (busValue !== null) {
      bus = busValue;
    } else {
      bus = defaultBus;
    }

    let route;
    if (routeValue !== null) {
      route = routeValue;
    } else {
      route = defaultRoute;
    }

    if (status === "") {
      openNotification("topRight", "Status is required");
    } else if (date === "") {
      openNotification("topRight", "Date is required");
    } else if (bus === "") {
      openNotification("topRight", "Bus is required");
    } else if (route === "") {
      openNotification("topRight", "Route is required");
    } else {
      const formData = { status, date, bus, route };
      // dispatch(updateTrip(formData, rowData.id));
      // dispatch(getTrips);
      // dispatch(getTrips);
      setEditModalVisible(false);
    }
  };

  let handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  let handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
    setFilteredInfo(null);
    setSortedInfo(null);
  };

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      width: "7%",
    },
    {
      title: "Date",
      dataIndex: "date",
      width: "17%",
      ...getColumnSearchProps("date"),
    },
    {
      title: "Destinations",
      dataIndex: "destination",
      width: "22%",
      ...getColumnSearchProps("from"),
      // sorter: (a, b) => a.from.length - b.from.length,
      // sortDirections: ["descend", "ascend"],
    },
    {
      title: "Bus",
      dataIndex: "bus",
      width: "12%",
      ...getColumnSearchProps("bus"),
    },
    {
      title: "Status",
      dataIndex: "status",
      width: "15%",
      render: (text, record) => {
        const active = (
          <Badge
            className="site-badge-count-109"
            count={"Active"}
            style={{ backgroundColor: "#52c41a" }}
          />
        );

        const inactive = <Badge count={"Cancelled"} />;

        const show = record.status === "ACTIVE" ? active : inactive;

        return show;
      },
    },
    {
      title: "Action",
      width: "20%",
      render: (text, record) => (
        <Space size="middle">
          <Button
            style={{ background: "#eba134", borderColor: "#eba134" }}
            type="primary"
            shape="round"
            icon={<EditOutlined />}
            size="small"
            onClick={() => {
              setRowData(record);
              setCurrId(record.id);
              showEditModal(record);
            }}
          />
          <Button
            style={{ background: "#db0925", borderColor: "#db0925" }}
            shape="round"
            type="primary"
            icon={<DeleteOutlined />}
            size="small"
            onClick={() => {
              setRowData(record);
              setCurrId(record.id);
              showDeleteModal();
            }}
          />
          <Button
            style={{ background: "#41b0a5", borderColor: "#2f998f" }}
            shape="round"
            type="primary"
            icon={<RightOutlined />}
            size="small"
            onClick={() => {
              setRowData(record);
              showTripDetail(record.id);
            }}
          />
        </Space>
      ),
    },
  ];
  return (
    <div className="tabled">
      <Row gutter={[24, 0]}>
        <Col xs={7} xl={6}>
          <Card
            bordered={false}
            className="criclebox tablespace mb-24 pb-24"
            title="Filter"
            style={{ height: "35rem" }}
          >
            <Form
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "space-between",
                height: "100%",
                paddingLeft: "1rem",
              }}
              name="basic"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <div style={{ padding: "0", margin: "0" }}>
                <label>Sort by City</label>
                <div
                  stylle={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Form.Item
                    style={{ padding: "0", margin: "0" }}
                    name="from"
                    label="From"
                  >
                    <Select
                      style={{ width: "85%", margin: "1rem 1rem" }}
                      placeholder="Select a city"
                      // defaultValue={cities[0].id}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {cities.map((city) => (
                        <Option value={city.id}>{city.name}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
                <div
                  stylle={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <Form.Item
                    style={{ padding: "0", margin: "0" }}
                    name="to"
                    label="To"
                  >
                    <Select
                      style={{ width: "80%", margin: "1rem 2rem" }}
                      placeholder="Select a city"
                      optionFilterProp="children"
                      // defaultValue={cities[0].id}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      searchable="true"
                    >
                      {cities.map((city) => (
                        <Option value={city.id}>{city.name}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </div>
              <label>Sort by route</label>
              <Form.Item style={{ padding: "0", margin: "0" }} name="route">
                <Select
                  style={{ width: "90%", margin: "1rem 0" }}
                  placeholder="Select a route"
                  optionFilterProp="children"
                >
                  {routess.map((route) => (
                    <Option value={route.id}>
                      {route.from} - {route.to}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <label>Sort by bus</label>
              <Form.Item style={{ padding: "0", margin: "0" }} name="bus">
                <Select
                  style={{ width: "90%", margin: "1rem 0" }}
                  placeholder="Sort by bus"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {busess.map((bus) => (
                    <Option value={bus.id}>{bus.plateNumber}</Option>
                  ))}
                </Select>
              </Form.Item>
              <label>Sort by date</label>
              <Form.Item style={{ padding: "0", margin: "0" }} name="date">
                <DatePicker
                  onChange={handleDate}
                  style={{ width: "90%", margin: "1rem 0" }}
                />
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Filter
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
        <Col xs={14} xl={18}>
          <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title="Fillter data"
            extra={
              <Link to="new-trip">
                <Button type="primary" icon={<SwapOutlined />} size="large">
                  New Trip
                </Button>
              </Link>
            }
          >
            <Table
              columns={columns}
              dataSource={trips}
              rowKey="id"
              loading={tripsLoading}
              scroll={{ x: 400 }}
            />
          </Card>
        </Col>
      </Row>

      <Modal
        title="Delete Route"
        visible={isDeleteModalVisible}
        onCancel={handleDeleteModalCancel}
        footer={[
          <Button key="back" onClick={handleDeleteModalCancel}>
            cancel
          </Button>,
          <Button
            key="submit"
            danger
            type="primary"
            onClick={() => handleDelete(currId)}
          >
            Delete
          </Button>,
        ]}
      >
        <p>Are you sure?</p>
        <p>you want to delete the following Trip?</p>
        <p>Destination - {rowData.destination}</p>
        <p>Bus - {rowData.bus}</p>
        <p>Id - {rowData.id}</p>
      </Modal>

      {/* TRIP MODAL */}
      <Modal
        title={`Trip - ${rowData ? rowData.destination.toUpperCase() : ""}`}
        visible={isEditModalVisible}
        onOk={handleOk}
        onCancel={handleEditModalCancel}
        footer={[
          <Button key="back" onClick={handleEditModalCancel}>
            cancel
          </Button>,
          <Button key="back" type="primary" onClick={() => handleUpdate()}>
            Update
          </Button>,
        ]}
        width={600}
      >
        <Content className="p-0">
          <Form
            name="basic"
            initialValues={{ remember: true }}
            onFinishFailed={onFinishFailed}
            layout="vertical"
            className="row-col"
          >
            <Row>
              <Col
                xs={24}
                xl={24}
                style={{
                  padding: "1em",
                }}
              >
                <label>Route</label>
                <Select
                  className="route"
                  label="Route"
                  name="route"
                  rules={[
                    {
                      required: true,
                      message: "Enter the starting city!",
                    },
                  ]}
                  style={{ width: "100%", margin: "0.25rem 0" }}
                  onChange={onChangeRoute}
                  placeholder={rowData.destination}
                >
                  {routesData.map((option) => (
                    <Option value={option.value}>{option.routeName}</Option>
                  ))}
                </Select>
              </Col>
              <Col
                xs={24}
                xl={24}
                style={{
                  padding: "1em",
                }}
              >
                <label>Bus</label>
                <Select
                  className="bus"
                  label="Bus"
                  placeholder={rowData.bus}
                  name="bus"
                  rules={[
                    {
                      required: true,
                      message: "Enter the starting city!",
                    },
                  ]}
                  style={{ width: "100%", margin: "0.25rem 0" }}
                  onChange={onChangeBus}
                  // defaultValue={rowData.bus}
                >
                  {busesData.map((option) => (
                    <Option value={option.value}>{option.plateNumber}</Option>
                  ))}
                </Select>
              </Col>
              <Col
                xs={24}
                xl={24}
                style={{
                  padding: "1em",
                }}
              >
                <label>Status</label>

                <Select
                  style={{ width: "100%", margin: "0.25rem 0" }}
                  onChange={handleChangeStatus}
                  // defaultValue={rowData.status}
                  placeholder={rowData.status}
                  className="status"
                  label="Status"
                  name="status"
                  rules={[
                    {
                      required: true,
                      message: "Please Select the destination city!",
                    },
                  ]}
                >
                  <Option value="ACTIVE">Active</Option>
                  <Option value="INACTIVE">InActive</Option>
                </Select>
              </Col>

              <Col
                xs={24}
                xl={24}
                style={{
                  padding: "1em",
                }}
              >
                <label>Date</label>

                <DatePicker
                  name="date"
                  onChange={onChangeDate}
                  placeholder={rowData.date}
                  style={{ width: "100%", margin: "0.25rem 0" }}
                />
              </Col>
            </Row>
          </Form>
        </Content>
      </Modal>
    </div>
  );
}

export default Trips;
