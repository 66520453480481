import { SubRouteTypes } from "./types";

const INITIAL_STATE = {
  error: null,
  subRoute: {},
  subRoutes: [],

  subRouteLoading: false,
  subRouteSuccess: false,
  subRouteFail: false,

  subRoutesLoading: false,
  subRoutesSuccess: false,
  subRoutesFail: false,

  createSubRouteLoading: false,
  createSubRouteSuccess: false,
  createSubRouteFail: false,

  updateSubRouteLoading: false,
  updateSubRouteSuccess: false,
  upateSubRouteFail: false,

  deleteSubRouteLoading: false,
  deleteSubRouteSuccess: false,
  deleteSubRouteFail: false,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SubRouteTypes.SUB_ROUTE_PENDING:
      return {
        ...state,
        subRouteLoading: true,
      };
    case SubRouteTypes.SUB_ROUTE_SUCCESS:
      return {
        ...state,
        subRouteSuccess: true,
        subRouteLoading: false,
        subRoute: {
          ...state.subRoute,
          [action.payload.id]: action.payload.subRoute,
        },
      };
    case SubRouteTypes.SUB_ROUTE_FAIL:
      return {
        ...state,
        subRouteLoading: false,
        subRouteSuccess: false,
        error: action.payload.error,
      };
    case SubRouteTypes.CLEAR_SUB_ROUTE:
      return {
        ...state,
        subRouteLoading: false,
        subRouteSuccess: false,
        subRouteFail: false,
      };

    case SubRouteTypes.SUB_ROUTES_PENDING:
      return {
        ...state,
        subRoutesLoading: true,
      };
    case SubRouteTypes.SUB_ROUTES_SUCCESS:
      return {
        ...state,
        subRoutesSuccess: true,
        subRoutesLoading: false,
        subRoutes: action.payload.subRoutes,
      };
    case SubRouteTypes.SUB_ROUTES_FAIL:
      return {
        ...state,
        subRoutesLoading: false,
        subRoutesSuccess: false,
        error: action.payload.error,
      };
    case SubRouteTypes.CLEAR_SUB_ROUTES:
      return {
        ...state,
        subRoutesSuccess: false,
        subRoutesLoading: false,
        subRoutesFail: false,
      };

    case SubRouteTypes.CREATE_SUB_ROUTE_PENDING:
      return {
        ...state,
        createSubRouteLoading: true,
      };
    case SubRouteTypes.CREATE_SUB_ROUTE_SUCCESS:
      return {
        ...state,
        createSubRouteSuccess: true,
        createSubRouteLoading: false,
        subRoutes: [action.payload.subRoute, ...state.subRoutes],
      };
    case SubRouteTypes.CREATE_SUB_ROUTE_FAIL:
      return {
        ...state,
        createSubRouteLoading: false,
        createSubRouteSuccess: false,
        error: action.payload.error,
      };
    case SubRouteTypes.CREATE_CLEAR_SUB_ROUTE:
      return {
        ...state,
        createSubRouteSuccess: false,
        createSubRouteLoading: false,
        createSubRouteFail: false,
      };

    case SubRouteTypes.UPDATE_SUB_ROUTE_PENDING:
      return {
        ...state,
        updateSubRouteLoading: true,
      };
    case SubRouteTypes.UPDATE_SUB_ROUTE_SUCCESS:
      return {
        ...state,
        updateSubRouteSuccess: true,
        updateSubRouteLoading: false,
        subRoute: {
          ...state.subRoute,
          [action.payload.id]: action.payload.subRoute,
        },
      };
    case SubRouteTypes.UPDATE_SUB_ROUTE_FAIL:
      return {
        ...state,
        updateSubRouteLoading: false,
        updateSubRouteSuccess: false,
        error: action.payload.error,
      };
    case SubRouteTypes.UPDATE_CLEAR_SUB_ROUTE:
      return {
        ...state,
        updateSubRouteSuccess: false,
        updateSubRouteLoading: false,
        upateSubRouteFail: false,
      };

    case SubRouteTypes.DELETE_SUB_ROUTE_PENDING:
      return {
        ...state,
        deleteSubRouteLoading: true,
      };
    case SubRouteTypes.DELETE_SUB_ROUTE_SUCCESS:
      return {
        ...state,
        deleteSubRouteSuccess: true,
        deleteSubRouteLoading: false,
      };
    case SubRouteTypes.DELETE_SUB_ROUTE_FAIL:
      return {
        ...state,
        deleteSubRouteLoading: false,
        deleteSubRouteSuccess: false,
        error: action.payload.error,
      };
    case SubRouteTypes.DELETE_CLEAR_SUB_ROUTE:
      return {
        ...state,
        deleteSubRouteSuccess: false,
        deleteSubRouteLoading: false,
        deleteSubRouteFail: false,
      };

    default:
      return state;
  }
};

export default reducer;
